import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  Grid,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import {
  BSSubMenu,
  BSLegendTable,
  BSHeader,
  TooltipCard,
  TooltipText,
  TooltipBox,
  WrapperCardSubMenu,
} from "./DatailProducts.element";

// Images
import product from "../../../../assets/OnePage/products_white.png";
import shopping from "../../../../assets/OnePage/banner Store/store_color.png";
import Less from "../../../../assets/OnePage/banner Store/minus-circle.png";
import More from "../../../../assets/OnePage/banner Store/plus-circle.png";

// graphics
import MarketShareValue from "../../../../components/Graphics/MarketShareValue/MarketShareValue";

// Actions
import * as ANavigator from "../../../../store/Navigation/actions";

import {
  ENavSubMenu,
  filterComponent,
  IMainMenu,
  INavContent,
  stateType,
} from "../../../../store/Navigation/types";

import { Divisor, ItemAtivo } from "../../../../components/Contents/subMenu";

import {
  IProduct,
  SubMenuItem,
  TableSubView,
} from "../../../../components/Contents/Resources/SubView/subView";

import { GlobalProps } from "../../../../store";
import { IStateFilters } from "../../../../store/SideFilters/types";
import { useRequestDetailStore } from "../../../../services/Hooks/MyExecution/DetailStore/useRequestDetailStore";
import LoadingComponent from "../../../../components/LoadingComponent";

// config system
import {
  filter,
  stateMarketShare,
} from "../../../../store/SideFilters/RequestFilters/types";
import { theme } from "../../../../styles/theme";
import TemporalFilter from "../../../../components/TemporalFilter";
import HeaderTable from "../../../../components/Table/Header";
import { ITypeGraphicHorizontalBar } from "../../../../services/Hooks/OnePage/onePageTypes";
import Column from "../../../../components/Table/Column";

interface IStateProps {
  navigation: stateType;
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeMenu(menuItem: IMainMenu): void;
    changeSubMenu(index: ENavSubMenu): void;
    changeFilters(filters: filterComponent[]): void;
  };
}

type props = IStateProps & IDispatchProps;

const DetailProducts: React.FC<props> = ({
  navigation,
  DNavigator,
  SFilters,
  SAllFilters,
}: props): JSX.Element => {
  const [widthContent, setWidthContent] = useState<string>("");
  const [temporalFilter, setTemporalFilter] = useState<filter>({
    id: "MAT",
    label: "MAT",
  });
  const [limitItem, setLimitItem] = useState<number>(50);
  const refTable = useRef<HTMLTableElement>(null);
  const refSubMenu = useRef<HTMLDivElement>(null);

  const detail = useRequestDetailStore(SFilters);

  const headers = ["#", "Cod EAN", "Descrição do Produto", "Marca", "Segmento"];

  useEffect(() => {
    DNavigator.changeMenu({
      index: navigation.menuItem.index,
      name: "One Page | Detalhe Produto",
    });
  }, []);

  const handleLimitItem = (e: ChangeEvent<HTMLSelectElement>) => {
    const limitSelected = parseInt(e.currentTarget.value, 10);
    setLimitItem(limitSelected);
  };

  setTimeout(() => {
    if (refTable.current !== null) {
      const widthDiv = refTable.current.clientWidth - 148;
      const currentWidth = parseInt(widthContent.replace("px", ""), 10);
      if (widthDiv !== currentWidth) {
        setWidthContent(`${widthDiv}px`);
      }
    }
  });

  interface IHeaderDP {
    value: string;
    label: string;
  }

  interface IContentDP {
    shareValue: string;
    presence: string;
    extraPoint: string;
    storePoint: string;
  }

  interface IDetailProduct {
    table: IProduct[];
    headers: IHeaderDP[];
    content: IContentDP[];
    varShareValue: ITypeGraphicHorizontalBar;
    varSellOut: ITypeGraphicHorizontalBar;
    growthSell: ITypeGraphicHorizontalBar;
    varPresence: ITypeGraphicHorizontalBar;
    varPrice: ITypeGraphicHorizontalBar;
    varPoint: ITypeGraphicHorizontalBar;
  }

  const mockDetailProduct: IDetailProduct = {
    table: [
      {
        id: 1,
        cod: 78912345678910,
        describe: "Produto A",
        brand: "Termometro",
        segment: "Vitaminas",
      },
      {
        id: 2,
        cod: 78912345678910,
        describe: "Produto B",
        brand: "Termometro",
        segment: "Vitaminas",
      },
      {
        id: 3,
        cod: 78912345678910,
        describe: "Produto C",
        brand: "Kmed",
        segment: "Sexual",
      },
      {
        id: 4,
        cod: 78912345678910,
        describe: "Produto D",
        brand: "Kmed",
        segment: "Sexual",
      },
      {
        id: 5,
        cod: 78912345678910,
        describe: "Produto E",
        brand: "Termometro",
        segment: "Vitaminas",
      },
      {
        id: 6,
        cod: 78912345678910,
        describe: "Produto F",
        brand: "Kmed",
        segment: "Sexual",
      },
    ],
    headers: [
      {
        value: "-25,3%",
        label: "% Share Valor",
      },
      {
        value: "26,6%",
        label: "% Var. Share",
      },
      {
        value: "R$ 267,4",
        label: "R$ Sell Out ('000)",
      },
      {
        value: "-10,2%",
        label: "% Cresc. Sell Out",
      },
      {
        value: "38,9%",
        label: "% Presença",
      },
      {
        value: "0,0%",
        label: "% Var. Presenca",
      },
      {
        value: "9",
        label: "Aderencia Preço",
      },
      {
        value: "0,0%",
        label: "% Var. Ader. Preço",
      },
      {
        value: "9",
        label: "Lj. C/ Ponto Extra",
      },
      {
        value: "0,0%",
        label: "% Var. Pontos Extras",
      },
    ],
    content: [
      {
        shareValue: "-123,5%",
        presence: "30,0%",
        extraPoint: "1",
        storePoint: "1",
      },
      {
        shareValue: "24,9%",
        presence: "32,0%",
        extraPoint: "2",
        storePoint: "2",
      },
      {
        shareValue: "-49,5%",
        presence: "34,0%",
        extraPoint: "1",
        storePoint: "1",
      },
      {
        shareValue: "-33,1%",
        presence: "36,0%",
        extraPoint: "2",
        storePoint: "2",
      },
      {
        shareValue: "-10,3%",
        presence: "38,0%",
        extraPoint: "1",
        storePoint: "1",
      },
      {
        shareValue: "-70,0%",
        presence: "40,0%",
        extraPoint: "2",
        storePoint: "2",
      },
    ],
    varShareValue: {
      label: ["100,0%", "0,0%", "0,0%", "22,1%", "20,7%", "0,0%"],
      x: [100.0, 0.0, 0.0, 22.1, 20.7, 0.0],
      y: ["100.0", "0.0", "0.0", "22.1", "20.7", "0.0"],
    },
    varSellOut: {
      label: ["6", "24", "9", "5", "15", "2"],
      x: [6, 24, 9, 5, 15, 2],
      y: ["6", "24", "9", "5", "15", "2"],
    },
    growthSell: {
      label: ["227%", "954%", "-72%", "6%", "42%", "-80%"],
      x: [227, 954, -72, 6, 42, -80],
      y: ["227", "954", "-72", "6", "42", "-80"],
    },
    varPresence: {
      label: ["18%", "21%", "-5%", "-12%", "9%", "4%"],
      x: [18, 21, -5, -12, 9, 4],
      y: ["18", "21", "-5", "-12", "9", "4"],
    },
    varPrice: {
      label: ["18%", "21%", "-5%", "-12%", "9%", "4%"],
      x: [18, 21, -5, -12, 9, 4],
      y: ["18", "21", "-5", "-12", "9", "4"],
    },
    varPoint: {
      label: ["18%", "21%", "-5%", "-12%", "9%", "4%"],
      x: [18, 21, -5, -12, 9, 4],
      y: ["18", "21", "-5", "-12", "9", "4"],
    },
  };

  const renderFilter = useCallback(() => {
    // if (config.showSideFilters.detailProductOP)
    //   DNavigator.changeFilters(
    //     FiltersList(SAllFilters, SFilters, {
    //       ...INITIAL_FILTERS,
    //       typeExecution: false,
    //       period: false,
    //       priorityItens: false,
    //       segment: false,
    //     })
    //   );
  }, [SAllFilters, SFilters, DNavigator]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter, SFilters]);

  return (
    <Grid
      templateRows="auto 50px 1fr"
      m="5px"
      h="calc(100% - 130px)"
      color="black"
      overflowY="hidden"
      css={`
        &::-webkit-scrollbar {
          background-color: darkgray;
          border-radius: 20px;
          width: 12px;
          height: 12px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background-color: ${theme.scrollColor};
        }
      `}
    >
      <BSSubMenu ref={refSubMenu}>
        <ItemAtivo icon={shopping} noMargin active={false} width="150px" />
        <Divisor />
        {!detail.isLoading && (
          <>
            <BSLegendTable minWidth={widthContent || "500px"}>
              <SubMenuItem
                text="Skus Ativos"
                bold
                value="500"
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                text="Market Share"
                value="300"
                icon={More}
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                text="Market Share"
                value="200"
                icon={Less}
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                text="Performance"
                bold
                noMargin
                value="60,0%"
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
            </BSLegendTable>
            <WrapperCardSubMenu>
              <SubMenuItem
                font="14px"
                text="% Share Valor"
                bold
                noMargin
                value="-4,6%"
                minWidth="165px"
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                font="14px"
                text="% Var. Share"
                bold
                noMargin
                value="35,5%"
                minWidth="165px"
                subTitleBold
                titleBoxAlign="center"
              />

              <SubMenuItem
                text={`R$ Sell Out ('000)`}
                bold
                noMargin
                font="14px"
                value="R$ 1.351,8"
                minWidth="165px"
                titleBoxAlign="center"
                subTitleBold
              />

              <SubMenuItem
                text="% Cresc. Sell Out"
                bold
                noMargin
                font="14px"
                value="-26,9%"
                titleBoxAlign="center"
                minWidth="165px"
                subTitleBold
              />

              <TooltipCard>
                <TooltipText>
                  <SubMenuItem
                    text="% Presença"
                    font="14px"
                    titleBoxAlign="center"
                    bold
                    noMargin
                    value="45,2%"
                    minWidth="165px"
                    subTitleBold
                  />
                </TooltipText>
                <TooltipBox>
                  <p>
                    Crescimentos acima de 100% e abaixo de -100% travados em
                    escalas de até 100%.
                  </p>
                  <p>Cálculos com divisão por 0, fixados em 0%.</p>
                </TooltipBox>
              </TooltipCard>

              <TooltipCard>
                <TooltipText>
                  <SubMenuItem
                    text="% Var. Presença"
                    font="14px"
                    bold
                    titleBoxAlign="center"
                    noMargin
                    value="-35,4%"
                    minWidth="165px"
                    subTitleBold
                  />
                </TooltipText>
                <TooltipBox>
                  <p>
                    Crescimentos acima de 100% e abaixo de -100% travados em
                    escalas de até 100%.
                  </p>
                  <p>Cálculos com divisão por 0, fixados em 0%.</p>
                </TooltipBox>
              </TooltipCard>
              <SubMenuItem
                text="Aderencia Preço"
                font="14px"
                titleBoxAlign="center"
                bold
                value="0"
                minWidth="165px"
                subTitleBold
              />
              <SubMenuItem
                text="% Var. Ader. Preço"
                font="14px"
                bold
                value="-35,4%"
                minWidth="165px"
                subTitleBold
                titleBoxAlign="center"
              />
              <SubMenuItem
                text="Lj. C/ Ponto Extra"
                font="14px"
                bold
                value="0"
                minWidth="165px"
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                text="% Var. Pontos Extras"
                font="14px"
                bold
                value="-6,0%"
                titleBoxAlign="center"
                minWidth="165px"
                subTitleBold
              />
            </WrapperCardSubMenu>
          </>
        )}
      </BSSubMenu>
      <Flex>
        <BSHeader>
          <div className="header-msl-products">
            <img src={product} alt="icon-page" />
            <h1>
              Detalhamento de market share por produto | Bandeira:
              {` ${SFilters.promoFilter.banner.selectedFilterName || "Todas"} `}
              | Região:
              {` ${SFilters.promoFilter.region.selectedFilterName || "Todas"}`}
            </h1>
          </div>
        </BSHeader>
      </Flex>
      {(detail.isLoading && (
        <Flex h="calc(100% - 5px)" mt="5px" bg="white">
          <LoadingComponent />
        </Flex>
      )) || (
        <Flex
          h="calc(100% - 5px)"
          mt="5px !important"
          bg="white"
          overflowX="hidden"
          css={`
            &::-webkit-scrollbar {
              background-color: darkgray;
              border-radius: 20px;
              width: 12px;
              height: 12px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 20px;
              background-color: ${theme.scrollColor};
            }
          `}
        >
          <Stack pt="5px" mr="4px" bg="white" zIndex={1000} minW="600px">
            <Flex alignItems="center">
              {/* <Image src={store} w="50px" /> */}
              <TemporalFilter
                setTemporalFilter={setTemporalFilter}
                temporalFilter={temporalFilter}
                itens={[
                  { id: "MAT", label: "MAT" },
                  { id: "YTD", label: "YTD" },
                  { id: "U3M", label: "U3M" },
                  { id: "LP", label: "LP" },
                ]}
              />
              <Text ml="20px">Exibir</Text>
              <Select w="90px" ml="10px" h="30px" onChange={handleLimitItem}>
                <option>25</option>
                <option selected>50</option>
                <option>100</option>
              </Select>
            </Flex>
            <Text
              bg="#E7E6E6"
              color="#c00000"
              fontSize="14px"
              fontWeight="bold"
              p="10px"
              mt="8px !important"
            >
              Top {limitItem} Lojas OFENSORAS em Market share
            </Text>
            <TableSubView
              refElement={refTable}
              headers={headers}
              dataTable={mockDetailProduct.table || []}
            />
          </Stack>
          <Table h="fit-content" mt="54px">
            <Thead>
              <Tr>
                {mockDetailProduct.headers.map((header) => (
                  <HeaderTable h="41px" br="5px solid white" mw="165px">
                    {header.value}
                  </HeaderTable>
                ))}
              </Tr>
              <Tr>
                {mockDetailProduct.headers.map((header) => (
                  <Th
                    h="31px"
                    color="black"
                    fontStyle="italic"
                    textTransform="none"
                    mw="160px"
                    textAlign="center"
                    p="13px 0 9px 0"
                  >
                    {header.label}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {mockDetailProduct.content.map((value, index) => (
                <Tr>
                  <Column
                    border="2px solid white"
                    bg="#E7E6E6"
                    isBold
                    value={value.shareValue}
                  />
                  {index === 0 && (
                    <Td
                      border="none"
                      rowSpan={20}
                      p={0}
                      pr="2px"
                      position="relative"
                    >
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={mockDetailProduct.varShareValue}
                          color="#A6A6A6"
                          height={mockDetailProduct.varShareValue.y.length * 31}
                          w={165}
                        />
                      </Box>
                    </Td>
                  )}
                  {index === 0 && (
                    <Td
                      border="none"
                      rowSpan={20}
                      p={0}
                      pr="2px"
                      position="relative"
                    >
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={mockDetailProduct.varSellOut}
                          color="#1F497D"
                          height={mockDetailProduct.varSellOut.y.length * 31}
                          w={165}
                        />
                      </Box>
                    </Td>
                  )}
                  {index === 0 && (
                    <Td
                      border="none"
                      rowSpan={20}
                      p={0}
                      pr="2px"
                      position="relative"
                    >
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={mockDetailProduct.growthSell}
                          height={mockDetailProduct.growthSell.y.length * 31}
                          w={165}
                        />
                      </Box>
                    </Td>
                  )}
                  <Column
                    border="2px solid white"
                    bg="#E7E6E6"
                    isBold
                    value={value.presence}
                  />
                  {index === 0 && (
                    <Td border="none" rowSpan={20} p="0" position="relative">
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={mockDetailProduct.varPresence}
                          height={mockDetailProduct.varPresence.y.length * 31}
                          w={165}
                        />
                      </Box>
                    </Td>
                  )}
                  <Column
                    border="2px solid white"
                    bg="#E7E6E6"
                    isBold
                    value={value.extraPoint}
                  />
                  {index === 0 && (
                    <Td border="none" rowSpan={20} p="0" position="relative">
                      <MarketShareValue
                        dataGraphic={mockDetailProduct.varPrice}
                        height={mockDetailProduct.varPrice.y.length * 31}
                        w={165}
                      />
                    </Td>
                  )}
                  <Column
                    border="2px solid white"
                    bg="#E7E6E6"
                    isBold
                    value={value.storePoint}
                  />
                  {index === 0 && (
                    <Td border="none" rowSpan={20} p="0" position="relative">
                      <MarketShareValue
                        dataGraphic={mockDetailProduct.varPoint}
                        height={mockDetailProduct.varPoint.y.length * 31}
                        w={165}
                      />
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      )}
    </Grid>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailProducts);
