import React, { useCallback, useContext, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/all";
import { Flex, Box, Stack, Image, Button, Text } from "@chakra-ui/react";
import UserAvatar from "../../assets/Profile/avatar-not-defined.jpg";
import BarProfile from "../SidebarProfile/SideBarProfile";
import * as ANav from "../../store/Navigation/actions";
import * as ALogout from "../../store/Authentication/Logout/actions";
import { GlobalProps } from "../../store/index";
import {
  ENavSubMenu,
  IMainMenu,
  INavMainMenu,
  INavPage,
  stateType,
} from "../../store/Navigation/types";
import { stateProfile } from "../../store/Profile/types";
import { actionLogout } from "../../store/Authentication/Logout/types";
import Itens from "./ItensMenu";
import { useRequestNotification } from "../../services/Hooks/Notification";
import { ConfigContext } from "../../services/Context/ConfigContext";

interface IOwnProps {
  showMenu: boolean;
  setShowMenu(show: boolean): void;
}

interface IStateProps {
  navigation: stateType;
  profile: stateProfile;
}

interface IDispatchProps {
  ANavigator: {
    changeMain(indexPage: INavPage): void;
    changeMenu(menuItem: IMainMenu): void;
    changeSubMenu(index: ENavSubMenu): void;
  };
  ALogout: {
    requestLogout(page: actionLogout): void;
  };
}

type props = IStateProps & IDispatchProps & IOwnProps;

const Nav: React.FC<props> = (props: props) => {
  // variaveis
  const title = props.navigation.menuItem.name;
  const { changeMain, changeMenu, changeSubMenu } = props.ANavigator;
  const { requestLogout } = props.ALogout;
  const { data } = props.profile;

  const { config } = useContext(ConfigContext);

  // Request data api
  const notification = useRequestNotification();

  const [showProfile, setProfile] = useState<boolean>(false);
  const setVisibleProfile = () => {
    setProfile(true);
  };

  const setViewMarketShare = useCallback(() => {
    changeMenu({
      index: INavMainMenu.MY_ONE_PAGE,
      name: "One Page",
    });
    changeSubMenu(
      (config.general.hasPromo && ENavSubMenu.ONE_PAGE) ||
        ENavSubMenu.ONE_PAGE_ROCKET
    );
  }, [changeMenu, changeSubMenu]);

  const setViewMyExecution = useCallback(() => {
    changeMenu({ index: INavMainMenu.MY_EXECUTION, name: "Minha Execução" });
    changeSubMenu(ENavSubMenu.ME_NPD);
  }, [changeMenu, changeSubMenu]);

  const setViewMyNegociation = useCallback(() => {
    changeMenu({
      index: INavMainMenu.MY_NEGOCIATIONS,
      name: "Calendario Promocional",
    });
    changeSubMenu(ENavSubMenu.MN_CALENDAR);
  }, [changeMenu, changeSubMenu]);

  const setViewPromo = useCallback(() => {
    changeMenu({ index: INavMainMenu.PROMO, name: "Meu dia a dia" });
    changeSubMenu(ENavSubMenu.MD_BOOK);
  }, [changeMenu, changeSubMenu]);

  const setViewPerfectStore = useCallback(() => {
    changeMenu({
      index: INavMainMenu.MY_PERFECT_STORE,
      name: "Minha Loja Perfeita",
    });
    changeSubMenu(ENavSubMenu.MD_BOOK);
  }, [changeMenu, changeSubMenu]);

  return (
    <Box w="100%" height="120px" minW="790px">
      <BarProfile
        active={showProfile}
        hiddenProfile={setProfile}
        signOut={requestLogout}
        changePage={changeMain}
        payloadProfile={data}
        notification={notification.data?.feedback || []}
      />
      <Flex
        as="header"
        bg="headerColor"
        w="100%"
        h="100%"
        justify="center"
        align="center"
      >
        <Stack w="100%" h="100%" color="textColor">
          <Flex h="100%" align="center" pl="20px">
            {(props.showMenu && (
              <BsFillArrowLeftCircleFill
                size={30}
                onClick={() => props.setShowMenu(false)}
              />
            )) || (
              <BsFillArrowRightCircleFill
                size={30}
                onClick={() => props.setShowMenu(true)}
              />
            )}

            <Text fontSize="24px" ml="15px !important">
              TRADEX <b>BIP |</b> {title}
            </Text>
          </Flex>
          <Flex h="43px" mt="0 !important">
            <Itens
              setViewMarketShare={setViewMarketShare}
              setViewMyExecution={setViewMyExecution}
              setViewMyNegociation={setViewMyNegociation}
              setViewPromo={setViewPromo}
              setViewPerfectStore={setViewPerfectStore}
            />
          </Flex>
        </Stack>
        {notification.data && notification.data.feedback.length > 0 && (
          <Flex
            minW="25px"
            px="5px"
            h="25px"
            borderRadius="30%"
            bg="red"
            position="absolute"
            zIndex={10}
            justify="center"
            align="center"
            top={1}
            right={notification.data.feedback.length > 99 ? 2 : 3}
          >
            {notification.data.feedback.length > 999
              ? "999+"
              : notification.data.feedback.length}
          </Flex>
        )}
        <Stack align="center" justify="center" mr="15px" position="relative">
          <Image
            w="70px"
            h="70px"
            borderRadius={50}
            border="2px solid"
            objectFit="cover"
            borderColor="profile"
            src={data.avatar ? data.avatar : UserAvatar}
          />
          <Button
            color="white"
            bg="profile"
            fontWeight="bold"
            textTransform="uppercase"
            fontSize="11px"
            borderRadius="5px"
            p="5px 10px"
            h="auto"
            onClick={() => setVisibleProfile()}
          >
            {data.firstName ? data.firstName : "Sem nome"}
          </Button>
        </Stack>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  profile: state.profile,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ANavigator: bindActionCreators(ANav, dispatch),
    ALogout: bindActionCreators(ALogout, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
