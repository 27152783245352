import React from "react";
import { useRequestShareOfShelfTable } from "../../../services/Hooks/MyExecution/useRequestShareOfShelfTable";
import { IStateFilters } from "../../../store/SideFilters/types";
import TableComplements from "../../TableComplements";

interface IOwnProps {
  SFilters: IStateFilters;
  period: string;
}

const PerfectStoreTable: React.FC<IOwnProps> = ({ SFilters, period }) => {
  const shareOfShelfTable = useRequestShareOfShelfTable(period, SFilters);

  return (
    <TableComplements
      noFilter
      titleCard="Loja on"
      subTitleCard="(Mensal)"
      data={{ contentPrimary: undefined, contentSecundary: [] }}
      isLoading={false}
    />
  );
};

export default PerfectStoreTable;
