import { Button } from "@chakra-ui/react";
import react from "react";
import { useFetchEvidences } from "../../services/Hooks/Evidences";
import {
  ELavarage,
  useRequestKpiProduct,
} from "../../services/Hooks/MyExecution/DetailKpiProduct/useRequestKpi";
import { useRequestKpiProductSOS } from "../../services/Hooks/MyExecution/DetailKpiProduct/useRequestKpiSOS";
import { useRequestKpiProductSOV } from "../../services/Hooks/MyExecution/DetailKpiProduct/useRequestKpiSOV";
import { useRequestKpiStore } from "../../services/Hooks/MyExecution/DetailKpiStore/useRequestKpiStore";
import { useStoreAbsolute } from "../../services/Hooks/MyPerfectStore/useStoreAbsolute";
import {
  ENavSubMenu,
  INavContent,
  INavMainMenu,
  stateType,
} from "../../store/Navigation/types";
import { IStateFilters } from "../../store/SideFilters/types";

interface IOwnProps {
  SFilters: IStateFilters;
  navigation: stateType;
  industryId: string;
  onShowToast(): void;
}

const ButtonFilter: React.FC<IOwnProps> = ({
  SFilters,
  navigation,
  industryId,
  onShowToast,
}: IOwnProps) => {
  const evidence = useFetchEvidences(SFilters, 1, industryId);
  const kpiProduct = useRequestKpiProduct(SFilters);
  const kpiProductSOS = useRequestKpiProductSOS(SFilters);
  const kpiProductSOV = useRequestKpiProductSOV(SFilters);
  const kpiStore = useRequestKpiStore(SFilters);
  const perfectStore = useStoreAbsolute(SFilters);

  const fetchMyOnePageFilter = () => {
    console.log("implementar o filtro de one page");
  };

  const fetchMyExecutionFilter = () => {
    console.log("implementar o filtro de execução");
  };

  const fetchMyNegociationFilter = () => {
    console.log("implementar o filtro de negociação");
  };

  const fetchMyDayFilter = () => {
    console.log("lol");
  };

  const fetchMyPerfectStore = () => {
    perfectStore.refetch();
  };

  const fetchGenerateBook = () => {
    if (
      SFilters.promoFilter.dateInitial === "dd/mm/aaaa" ||
      SFilters.promoFilter.dateFinal === "dd/mm/aaaa"
    ) {
      onShowToast();
      return;
    }
    evidence.refetch();
  };

  const isDashboard: boolean =
    navigation.contentDash === INavContent.MAIN_DASH_CONTENT;

  const filterDashboard = () => {
    const selecteMenu: string = navigation.menuItem.index;

    switch (selecteMenu) {
      case INavMainMenu.MY_ONE_PAGE:
        fetchMyOnePageFilter();
        break;
      case INavMainMenu.MY_EXECUTION:
        fetchMyExecutionFilter();
        break;
      case INavMainMenu.MY_NEGOCIATIONS:
        fetchMyNegociationFilter();
        break;
      case INavMainMenu.MY_PERFECT_STORE:
        fetchMyPerfectStore();
        break;
      case INavMainMenu.PROMO:
        fetchGenerateBook();
        break;
      default:
    }
  };

  const switchRequestFilter = () => {
    if (isDashboard) {
      filterDashboard();
    }
    if (navigation.contentDash === INavContent.ME_DETAIL_KPI) {
      if (
        SFilters.internalKpiProduct.selectedPresence.id === ELavarage.PRESENCA
      ) {
        kpiProduct.refetch();
      } else if (
        SFilters.internalKpiProduct.selectedPresence.id === ELavarage.GONDOLA
      ) {
        kpiProductSOS.refetch();
      } else if (
        SFilters.internalKpiProduct.selectedPresence.id ===
        ELavarage.PONTO_EXTRA
      ) {
        kpiProductSOV.refetch();
      }
    } else if (navigation.contentDash === INavContent.ME_DETAIL_KPI_STORE) {
      kpiStore.refetch();
    }
  };

  return (
    <>
      {(navigation.indexSubMenu === ENavSubMenu.MD_BOOK ||
        navigation.contentDash === INavContent.ME_DETAIL_KPI ||
        navigation.contentDash === INavContent.ME_DETAIL_KPI_STORE) && (
        <Button
          onClick={() => switchRequestFilter()}
          my="15px !important"
          py="20px"
          fontSize="14px"
          w="calc(100% - 20px)"
          cursor="pointer"
          color="black"
          lineHeight={0}
          mx="10px !important"
        >
          Filtrar
        </Button>
      )}
    </>
  );
};

export default ButtonFilter;
