import styled from "styled-components";

export const BSSubMenu = styled.div`
  margin: auto auto;
  display: flex;
  width: 100%;
  min-width: fit-content;
  align-items: center;
  justify-content: left;
  background-color: white;
  height: 85px;
  padding: 4px 0;
  box-sizing: border-box;
  padding-right: 9px;

  & > button {
    min-width: 142px;
  }

  div:last-child {
    padding-right: 0;
  }
`;

export const BSLegendTable = styled.section<{
  minWidth: string;
}>`
  background-color: #e7e6e6;
  width: ${(props) => props.minWidth};
  min-width: ${(props) => props.minWidth};
  border-radius: 7px;
  height: 70px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  transition: 0.5s;

  div {
    min-width: 0;
    width: 100%;

    span {
      min-width: 0;
    }
  }
`;
