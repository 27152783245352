import React, { useState, useEffect, ReactNode } from "react";
import ReactDom from "react-dom";
import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalBodyContainer,
  ModalHeaderContainer,
  ModalFooterContainer,
  ModalButtonClose,
} from "./styles";

interface ModalProps {
  show: boolean;
  setShow: () => void;
  children: ReactNode;
  bgColor?: string;
  width?: string;
  margin?: string;
  isToast: boolean;
  opacity?: string;
  borderRadius?: string;
  border?: string;
  overflow?: string;
  maxH?: string;
}

interface ModalHeaderProps {
  setShow: () => void;
  children: ReactNode;
  border?: string;
  color?: string;
  position: string;
  showCloseButton?: boolean;
  fontSize: string;
  isToast?: boolean;
  bg?: string;
  mx?: string;
}

interface ModalBodyProps {
  children: ReactNode;
  height: string;
  maxH?: string;
  overflow?: string;
}

interface ModalFooterProps {
  children: ReactNode;
  margin: string;
  border: string;
}

const Modal: React.FC<ModalProps> = ({
  show,
  setShow,
  children,
  bgColor,
  width,
  margin,
  isToast,
  opacity,
  borderRadius,
  border,
}) => {
  useEffect(() => {
    const listner = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
        e.preventDefault();
        e.stopPropagation();

        if (show) {
          setShow();
        }
      }
    };

    window.addEventListener("keyup", listner);

    return () => {
      window.removeEventListener("keyup", listner);
    };
  }, [show, setShow]);

  return show
    ? ReactDom.createPortal(
        <ModalOverlay isToast={isToast} onScreen={!show}>
          <ModalWrapper isToast={isToast}>
            <ModalContainer
              bgColor={bgColor}
              width={width}
              margin={margin}
              opacity={opacity}
              borderRadius={borderRadius}
              border={border}
            >
              {children}
            </ModalContainer>
          </ModalWrapper>
        </ModalOverlay>,
        document.body
      )
    : null;
};

Modal.defaultProps = {
  borderRadius: "4px",
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  setShow,
  children,
  border,
  color,
  position,
  showCloseButton,
  fontSize,
  isToast,
  mx,
  bg,
}: ModalHeaderProps) => (
  <ModalHeaderContainer
    border={border}
    color={color}
    position={position}
    showCloseButton={showCloseButton}
    fontSize={fontSize}
    bgColor={bg}
    marginX={mx}
  >
    {children || "Title"}
    {!showCloseButton && (
      <ModalButtonClose
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={setShow}
        showCloseButton={showCloseButton}
        isToast={isToast}
      >
        &times;
      </ModalButtonClose>
    )}
  </ModalHeaderContainer>
);

export const ModalBody: React.FC<ModalBodyProps> = ({
  children,
  height,
  maxH,
  overflow,
}: ModalBodyProps) => (
  <ModalBodyContainer maxH={maxH} overflow={overflow} height={height}>
    {children}
  </ModalBodyContainer>
);

export const ModalFooter: React.FC<ModalFooterProps> = ({
  children,
  margin,
  border,
}: ModalFooterProps) => (
  <ModalFooterContainer margin={margin} border={border}>
    {children}
  </ModalFooterContainer>
);

export interface useModalHookProps {
  show: boolean;
  setShow: () => void;
}

export const useModal = (): useModalHookProps => {
  const [show, setshow] = useState(false);

  function setShow() {
    setshow(!show);
  }

  return {
    show,
    setShow,
  };
};

ModalHeader.defaultProps = {
  bg: "transparent",
  showCloseButton: false,
  isToast: false,
  border: "none",
  mx: "0",
  color: "black",
};

ModalBody.defaultProps = {
  maxH: "",
  overflow: "",
};

export default Modal;
