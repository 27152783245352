/* eslint-disable camelcase */
import { useQuery, UseQueryResult } from "react-query";
import api from "../../api";

export interface IProductData {
  id: string;
  name: string;
  msl: boolean;
  ean: string;
  category: string;
  sub_category: string;
  segment: string;
}

const requestProducts = async (): Promise<IProductData[]> => {
  const response = await api.get<{ productsBip: IProductData[] }>(
    "products_bip/"
  );

  return response.data.productsBip;
};

export const useRequestProducts = (): UseQueryResult<
  IProductData[],
  unknown
> => {
  return useQuery(["requestProducts"], requestProducts, {
    refetchOnWindowFocus: false,
    enabled: false,
    retry: 2,
  });
};
