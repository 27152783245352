import styled from 'styled-components';

export const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Main = styled.main<{ showMenu: boolean }>`
  width: ${props => (props.showMenu && 'calc(100% - 230px)') || '100%'};
  position: relative;
  overflow: hidden;
`;
