import { Flex, Stack } from "@chakra-ui/react";
import logo from "../../../../../assets/Industria/logo.png";
import marca1 from "../../../../../assets/Industria/marca_4.png";
import marca2 from "../../../../../assets/Industria/marca_2.png";
import map from "../../../../../assets/MyNegociation/regions/mapa.png";
import norte from "../../../../../assets/MyNegociation/regions/norte.png";
import nordeste from "../../../../../assets/MyNegociation/regions/nordeste.png";
import sudeste from "../../../../../assets/MyNegociation/regions/sudeste.png";
import centroeste from "../../../../../assets/MyNegociation/regions/centro-oeste.png";
import sul from "../../../../../assets/MyNegociation/regions/sul.png";
import subchannel from "../../../../../assets/MyNegociation/basket_black_mini.png";
import brandImg from "../../../../../assets/img/icons/store_black_mini.png";
import ItemTable from "./itemTable";

const tableContent = {
  firstTable: {
    headers: ["Tipo de Alavanca", "Invest. % YTD", "ROI YTD", "Contribuição"],
    content: [
      {
        segmento: "Ações de SellOut (Rebaixadas, Encartes, CRM)",
        values: ["45%", "0.9"],
      },
      {
        segmento: "Ações de Visibilidade (Pontos Extras)",
        values: ["25%", "0.1"],
      },
      {
        segmento: "Campanhas / Festivais / Aniversários",
        values: ["30%", "-2,1"],
      },
      {
        segmento: "Total Calendário Promocional",
        values: ["100%", "0,3"],
      },
    ],
  },
  secondTable: {
    headers: [
      "JAN'22",
      "JAN'22",
      "JAN'22",
      "JAN'22",
      "JAN'22",
      "JAN'22",
      "JAN'22",
      "JAN'22",
      "JAN'22",
      "JAN'22",
    ],
    content: [
      {
        segmento: "Ações de SellOut (Rebaixadas, Encartes, CRM)",
        values: [
          "0,9",
          "0,9",
          "0,9",
          "0,9",
          "0,9",
          "0,9",
          "0,9",
          "0,9",
          "0,9",
          "0,9",
        ],
      },
      {
        segmento: "Ações de Visibilidade (Pontos Extras)",
        values: [
          "0,1",
          "0,1",
          "0,1",
          "0,1",
          "0,1",
          "0,1",
          "0,1",
          "0,1",
          "0,1",
          "0,1",
        ],
      },
      {
        segmento: "Campanhas / Festivais / Aniversários",
        values: [
          "-2,1",
          "-2,1",
          "-2,1",
          "-2,1",
          "-2,1",
          "-2,1",
          "-2,1",
          "-2,1",
          "-2,1",
          "-2,1",
        ],
      },
      {
        segmento: "Total Calendário Promocional",
        values: [
          "0,3",
          "0,3",
          "0,3",
          "0,3",
          "0,3",
          "0,3",
          "0,3",
          "0,3",
          "0,3",
          "0,3",
        ],
      },
    ],
  },
};

const tableData = {
  brands: [
    {
      label: "TODAS",
      imageBrand: logo,
    },
    {
      label: "Termometro",
      imageBrand: marca1,
    },
    {
      label: "Personal",
      imageBrand: marca2,
    },
  ],
  region: [
    {
      label: "TODAS",
      imageBrand: map,
    },
    {
      label: "Norte",
      imageBrand: norte,
    },
    {
      label: "Nordeste",
      imageBrand: nordeste,
    },
    {
      label: "Centro-Oeste",
      imageBrand: centroeste,
    },
    {
      label: "Sudeste",
      imageBrand: sudeste,
    },
    {
      label: "Sul",
      imageBrand: sul,
    },
  ],
  subChannel: [
    {
      label: "TODAS",
      imageBrand: subchannel,
    },
    {
      label: "Grandes redes",
      imageBrand: subchannel,
    },
    {
      label: "Pequenas redes",
      imageBrand: subchannel,
    },
    {
      label: "Médias redes",
      imageBrand: subchannel,
    },
    {
      label: "Independentes",
      imageBrand: subchannel,
    },
    {
      label: "Associativistas",
      imageBrand: subchannel,
    },
    {
      label: "Médias redes",
      imageBrand: subchannel,
    },
  ],
  topTen: [
    {
      label: "TODAS",
      imageBrand: brandImg,
    },
    {
      label: "DSP",
      imageBrand: brandImg,
    },
    {
      label: "Drogaria Venancio",
      imageBrand: brandImg,
    },
    {
      label: "Drogasil",
      imageBrand: brandImg,
    },
    {
      label: "Indiano",
      imageBrand: brandImg,
    },
    {
      label: "Pague Menos",
      imageBrand: brandImg,
    },
    {
      label: "Carrefour",
      imageBrand: brandImg,
    },
    {
      label: "Farmais",
      imageBrand: brandImg,
    },
    {
      label: "Drogaria raia",
      imageBrand: brandImg,
    },
    {
      label: "Ultra popular",
      imageBrand: brandImg,
    },
    {
      label: "Coop",
      imageBrand: brandImg,
    },
  ],
};

interface FiltersProps {
  filterSection: string;
}

export function TableEvolutivo({ filterSection }: FiltersProps): JSX.Element {
  return (
    <Flex my="5px">
      {filterSection === "category" && (
        <Stack w="100%">
          {tableData.brands.map((brand, index) => {
            return (
              <ItemTable
                data={tableContent}
                image={brand.imageBrand}
                label={brand.label}
                hasLine={index === 0}
              />
            );
          })}
        </Stack>
      )}

      {filterSection === "region" && (
        <Stack w="100%">
          {tableData.region.map((region) => {
            return (
              <ItemTable
                data={tableContent}
                image={region.imageBrand}
                label={region.label}
              />
            );
          })}
        </Stack>
      )}

      {filterSection === "subchannel" && (
        <Stack w="100%">
          {tableData.subChannel.map((sc) => {
            return (
              <ItemTable
                data={tableContent}
                image={sc.imageBrand}
                label={sc.label}
              />
            );
          })}
        </Stack>
      )}

      {filterSection === "brand" && (
        <Stack w="100%">
          {tableData.topTen.map((pharma) => {
            return (
              <ItemTable
                data={tableContent}
                image={pharma.imageBrand}
                label={pharma.label}
              />
            );
          })}
        </Stack>
      )}
    </Flex>
  );
}
