import {
  Flex,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import {
  TFilterBanner,
  TFilterBrand,
  TFilterRegion,
  TFilterCategory,
  TFilterSegment,
  TFilterPromoCategory,
  TFilterPromoBrand,
  TFilterPromoSegment,
  TFilterManager,
  TFilterSalesRep,
  TFilterCoordinator,
  filter,
  TFilterDetailEnviroment,
} from "../../store/SideFilters/RequestFilters/types";
import Card from "../Card";
import HeaderGraphic from "../Graphics/HeaderGraphic";
import ListBox from "../ListBox";
import Column from "../Table/Column";
import HeaderTable from "../Table/Header";

// imagens
import planograma from "../../assets/Laverage/Planograma.png";
import logo1 from "../../assets/Industria/logo.png";
import brand1 from "../../assets/Industria/marca_1.png";
import brand2 from "../../assets/Industria/marca_2.png";
import pontoDeGondola from "../../assets/Laverage/PontaGondola.png";
import displayChao from "../../assets/Laverage/DisplayChao.png";
import displayBalcao from "../../assets/Laverage/DisplayBalcao.png";
import clipStrip from "../../assets/Laverage/ClipStrip.png";
import rocket from "../../assets/myExecution/rocket.svg";
import compliance from "../../assets/myExecution/compliance.svg";
import iconLavarage from "../../assets/myExecution/iconLavarage.svg";
import box from "../../assets/myExecution/iconsBox.svg";
import LoadingComponent from "../LoadingComponent";
import { theme } from "../../styles/theme";
import TemporalFilter from "../TemporalFilter";
import { TFilterExecutionLeverage } from "../../store/SideFilters/types";
import SeeDetailButton from "../SeeDetailButton";
import { INavContent } from "../../store/Navigation/types";

interface IContent {
  category?: string;
  values: string[];
}

interface mainTable {
  headers: string[];
  content: string[][];
}

enum EAdherenceFilter {
  ADHERENCE = "ADERÊNCIA %",
  AVARAGE_PRICE = "R$ MÉDIO",
}

export interface IDataFormat {
  contentPrimary?: {
    title: string;
    image?: string;
    headers: string[];
    content: IContent[];
    performance?: mainTable;
  };
  contentSecundary?: {
    title?: string;
    image: string;
    indicator?: {
      headers: string[];
      content: string[][];
    };
    headers: string[];
    content: IContent[];
  }[];
}

interface IGoDetail {
  label: string;
  detailPage: INavContent;
}

interface IOwnProps {
  titleCard: string;
  subTitleCard?: string;
  subTitle?: string;
  adherence?: filter;
  setAdherence?: (filter: filter) => void;
  data?: IDataFormat;
  noFilter?: boolean;
  noSeparate?: boolean;
  isLoading?: boolean;
  firstColumnMinWidth?: string;
  goDetail?: IGoDetail;
  minWidthImage?: string;
  mainFilter?:
    | TFilterExecutionLeverage[]
    | TFilterBanner[]
    | TFilterRegion[]
    | TFilterCategory[]
    | TFilterPromoCategory[]
    | TFilterBrand[]
    | TFilterPromoBrand[]
    | TFilterSegment[]
    | TFilterPromoSegment[]
    | TFilterManager[]
    | TFilterDetailEnviroment[]
    | TFilterCoordinator[]
    | TFilterSalesRep[];
  secundaryFilter?:
    | TFilterExecutionLeverage[]
    | TFilterBanner[]
    | TFilterRegion[]
    | TFilterCategory[]
    | TFilterPromoCategory[]
    | TFilterBrand[]
    | TFilterPromoBrand[]
    | TFilterSegment[]
    | TFilterPromoSegment[]
    | TFilterManager[]
    | TFilterDetailEnviroment[]
    | TFilterCoordinator[]
    | TFilterSalesRep[];
}

export const mockBrands: IDataFormat = {
  contentPrimary: {
    title: "TOTAL CATEGORIAS",
    image: planograma,
    headers: [
      "Categorias",
      "YTD'21",
      "YTD'22",
      "Var.%",
      "U3M",
      "LP",
      "Actual",
      "U3M %",
      "LP %",
      "JAN'22",
      "FEV'22",
      "MAR'22",
      "ABR'22",
      "MAI'22",
      "JUN'22",
      "JUL'22",
      "AGO'22",
      "SET'22",
      "OUT'22",
      "NOV'22",
      "P12'22",
    ],
    content: [
      {
        category: "Reckitt",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Termometro",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Cardio",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Eq.Med-Hosp",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Respiratoria",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
    ],
  },
  contentSecundary: [
    {
      title: "TERMOMETRO",
      image: brand1,
      headers: [
        "Marcas",
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: "Termometro",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca A",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca B",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca C",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Others",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
    {
      title: "BALANÇA",
      image: brand2,
      headers: [
        "Marcas",
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: "Balança",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca A",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca B",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca C",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Others",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
  ],
};

export const extraPoint: IDataFormat = {
  contentPrimary: {
    title: "TOTAL ALAVANCAS",
    image: logo1,
    headers: [
      "Categorias",
      "YTD'21",
      "YTD'22",
      "Var.%",
      "U3M",
      "LP",
      "Actual",
      "U3M %",
      "LP %",
      "JAN'22",
      "FEV'22",
      "MAR'22",
      "ABR'22",
      "MAI'22",
      "JUN'22",
      "JUL'22",
      "AGO'22",
      "SET'22",
      "OUT'22",
      "NOV'22",
      "P12'22",
    ],
    content: [
      {
        category: "Reckitt",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Termometro",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Cardio",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Eq. Med-Hosp",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Respiratoria",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
    ],
  },
  contentSecundary: [
    {
      title: "PONTA DE GONDOLA",
      image: pontoDeGondola,
      headers: [
        "Marcas",
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: "Reckitt",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca A",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca B",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca C",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Others",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
    {
      title: "DISPLAYS",
      image: displayChao,
      headers: [
        "Marcas",
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: "Reckitt",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca A",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca B",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca C",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Others",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
    {
      title: "CHECK-OUT / BALCÃO",
      image: displayBalcao,
      headers: [
        "Marcas",
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: "Reckitt",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca A",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca B",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca C",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Others",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
    {
      title: "CROSS MERCHAN",
      image: clipStrip,
      headers: [
        "Marcas",
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: "Reckitt",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca A",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca B",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Marca C",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Others",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
  ],
};

export const priceMock: IDataFormat = {
  contentPrimary: {
    title: "PERFORMANCE FARMÁCIA AMARELA",
    image: logo1,
    headers: [
      "YTD'21",
      "YTD'22",
      "Var.%",
      "U3M",
      "LP",
      "Actual",
      "U3M %",
      "LP %",
      "JAN'22",
      "FEV'22",
      "MAR'22",
      "ABR'22",
      "MAI'22",
      "JUN'22",
      "JUL'22",
      "AGO'22",
      "SET'22",
      "OUT'22",
      "NOV'22",
      "P12'22",
    ],
    content: [
      {
        category: "Reckitt",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Termometro",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Cardio",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Eq. Med-Hosp",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
      {
        category: "Respiratoria",
        values: [
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "Var.%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
          "0,0%",
        ],
      },
    ],
  },
  contentSecundary: [
    {
      title: "INDICADOR - PRESENÇA",
      image: rocket,
      headers: [
        "Marcas",
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: "Sku 1",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 2",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 3",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 4",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 5",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
    {
      title: "INDICADOR - INOVAÇÃO",
      image: compliance,
      headers: [
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: "Sku 1",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 2",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 3",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 4",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 5",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
    {
      title: "INDICADOR - CAL. PROMOCIONAL",
      image: iconLavarage,
      headers: [
        "Marcas",
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: "Sku 1",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 2",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 3",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 4",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 5",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
    {
      title: "INDICADOR - SHARE DE GONDOLA",
      image: box,
      headers: [
        "Marcas",
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: "Sku 1",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 2",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 3",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 4",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Sku 5",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
  ],
};

export const priceYellowPharma: IDataFormat = {
  contentPrimary: {
    title: "PERFORMANCE LOJA ON",
    performance: {
      headers: ["PERFORMANCE LOJA ON"],
      content: [
        ["Loja on (Lojas %)", "24,1%"],
        ["Loja on (Lojas absoluto)", "96"],
        ["Loja on (Pts Md p/Loja)", "65"],
      ],
    },
    headers: [
      "YTD'21",
      "YTD'22",
      "Var.%",
      "U3M",
      "LP",
      "Actual",
      "U3M %",
      "LP %",
      "JAN'22",
      "FEV'22",
      "MAR'22",
      "ABR'22",
      "MAI'22",
      "JUN'22",
      "JUL'22",
      "AGO'22",
      "SET'22",
      "OUT'22",
      "NOV'22",
      "DEZ'22",
    ],
    content: [
      {
        values: [
          "-",
          "-",
          "-",
          "-",
          "-",
          "24,1%",
          "-",
          "0,0%",
          "-",
          "-",
          "-",
          "-",
          "24,1%",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        values: [
          "-",
          "-",
          "-",
          "-",
          "-",
          "96",
          "-",
          "0,0%",
          "-",
          "-",
          "-",
          "-",
          "96",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
      {
        values: [
          "-",
          "-",
          "-",
          "-",
          "-",
          "65",
          "-",
          "0,0%",
          "-",
          "-",
          "-",
          "-",
          "65",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
        ],
      },
    ],
  },
  contentSecundary: [
    {
      indicator: {
        headers: ["INDICADOR - PRESENÇA", "#Lojas", "%"],
        content: [
          ["DE 0", "44", "11,1%"],
          ["1 A 2", "79", "19,8%"],
          ["3 A 4", "214", "53,8%"],
          ["5 A 6", "61", "15,3%"],
        ],
      },
      image: box,
      headers: [
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "DEZ'22",
      ],
      content: [
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "44",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "44",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "79",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "79",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "214",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "214",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "61",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "61",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
      ],
    },
    {
      indicator: {
        headers: ["INDICADOR - PREÇO", "#Lojas", "%"],
        content: [
          ["DE 1 A 2", "398", "100.0%"],
          ["3 A 4", "0", "0,0%"],
          ["5 A 6", "0", "0,0%"],
        ],
      },
      image: rocket,
      headers: [
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "DEZ'22",
      ],
      content: [
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "398",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "398",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "0",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "0",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "0",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
      ],
    },
    {
      indicator: {
        headers: ["INDICADOR - CAL. PROMOCIONAL", "#Lojas", "%"],
        content: [
          ["DE 0% A 25%", "369", "92,7%"],
          ["25% A 50%", "6", "1,5%"],
          ["51% A 75%", "5", "1,3%"],
          ["76% A 100%", "18", "4,5%"],
        ],
      },
      image: compliance,
      headers: [
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "DEZ'22",
      ],
      content: [
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "369",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "369",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "6",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "6",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "5",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "5",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "18",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "18",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
      ],
    },
    {
      indicator: {
        headers: ["INDICADOR - SHARE DE GONDOLA", "#Lojas", "%"],
        content: [
          ["DE 0% A 30%", "65", "17,5%"],
          ["40% A 50%", "225", "60,5%"],
          ["> 60%", "82", "22,0%"],
        ],
      },
      image: iconLavarage,
      headers: [
        "YTD'21",
        "YTD'22",
        "Var.%",
        "U3M",
        "LP",
        "Actual",
        "U3M %",
        "LP %",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "DEZ'22",
      ],
      content: [
        {
          values: [
            "-",
            "-",
            "-",
            "-",
            "-",
            "106",
            "-",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "106",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
        {
          values: [
            "-",
            "-",
            "0,0%",
            "-",
            "-",
            "263",
            "0,0%",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "263",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
        {
          values: [
            "-",
            "-",
            "0,0%",
            "-",
            "-",
            "29",
            "0,0%",
            "0,0%",
            "-",
            "-",
            "-",
            "-",
            "29",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
            "-",
          ],
        },
      ],
    },
  ],
};

const TableComplements: React.FC<IOwnProps> = ({
  titleCard,
  subTitleCard,
  mainFilter,
  secundaryFilter,
  data,
  subTitle,
  noFilter,
  noSeparate,
  isLoading,
  firstColumnMinWidth,
  adherence,
  goDetail,
  setAdherence,
  minWidthImage,
}: IOwnProps) => {
  return (
    <Card title={titleCard} subTitle={subTitleCard} bg="transparent">
      <Stack w="100%">
        <Stack w="100%" align="flex-start" bg="white" p="10px 5px">
          <Flex
            w="100%"
            justifyContent={noFilter ? "flex-end" : "space-between"}
            alignItems="center"
          >
            {!noFilter && (
              <ListBox
                justify="end"
                itens={[
                  {
                    optionSelect: mainFilter || [],
                    selected: "",
                    id: "pr",
                    onChange: () => console.log("lol"),
                  },
                  {
                    optionSelect: secundaryFilter || [],
                    selected: "",
                    id: "sec",
                    onChange: () => console.log("lol"),
                  },
                ]}
              />
            )}
            {setAdherence && adherence && (
              <TemporalFilter
                temporalFilter={adherence}
                setTemporalFilter={setAdherence}
                itens={[
                  {
                    id: "adherence",
                    label: EAdherenceFilter.ADHERENCE,
                  },
                  { id: "price", label: EAdherenceFilter.AVARAGE_PRICE },
                ]}
              />
            )}
            {goDetail && (
              <SeeDetailButton
                label={goDetail.label}
                goPage={goDetail.detailPage}
              />
            )}
          </Flex>
          {data?.contentPrimary && (
            <Flex w="100%">
              {(isLoading && <LoadingComponent />) ||
                (!data && <Text>lolzinho</Text>) ||
                (data?.contentPrimary.content.length !== 0 && (
                  <>
                    {(data?.contentPrimary.performance && (
                      <TableContainer
                        minW="403px"
                        mt="3px"
                        p="1px"
                        border="1px solid #E7E6E6"
                      >
                        <Table style={{ borderCollapse: "separate" }}>
                          <Thead>
                            <Tr>
                              {data?.contentPrimary.performance.headers.map(
                                (header, index) => {
                                  return (
                                    <HeaderTable
                                      mw={(index === 1 && "70px") || ""}
                                      isTextCenter={false}
                                      colSpan={2}
                                    >
                                      {header}
                                    </HeaderTable>
                                  );
                                }
                              )}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {data?.contentPrimary.performance.content.map(
                              (content) => {
                                return (
                                  <Tr>
                                    {content.map((value, index) => (
                                      <Column
                                        isTextCenter={index !== 0}
                                        bg="#E7E6E6"
                                        isBold
                                        value={value}
                                        mw={(index === 1 && "52px") || ""}
                                      />
                                    ))}
                                  </Tr>
                                );
                              }
                            )}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    )) || (
                      <Stack
                        whiteSpace="nowrap"
                        minW="210px"
                        mt="2px"
                        border="1px solid #E7E6E6"
                        p={1}
                      >
                        <HeaderGraphic>
                          {data.contentPrimary.title || ""}
                        </HeaderGraphic>
                        <Flex
                          h="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Image
                            src={data.contentPrimary.image}
                            alt="categoria"
                            maxWidth="200px"
                            maxHeight="200px"
                          />
                        </Flex>
                      </Stack>
                    )}

                    <TableContainer
                      border="1px solid #E7E6E6"
                      ml={1}
                      p="1px"
                      mt="3px"
                      w="100%"
                      css={`
                        &::-webkit-scrollbar {
                          background-color: darkgray;
                          border-radius: 20px;
                          width: 12px;
                          height: 12px;
                        }

                        &::-webkit-scrollbar-thumb {
                          border-radius: 20px;
                          background-color: ${theme.scrollColor};
                        }
                      `}
                    >
                      <Table style={{ borderCollapse: "separate" }}>
                        <Thead>
                          <Tr>
                            {data?.contentPrimary.headers.map(
                              (brand, index) => (
                                <HeaderTable
                                  key={Math.random()}
                                  mw={
                                    firstColumnMinWidth && index === 0
                                      ? firstColumnMinWidth
                                      : "70px"
                                  }
                                >
                                  {brand}
                                </HeaderTable>
                              )
                            )}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {data?.contentPrimary.content.map((item) => (
                            <Tr key={Math.random()}>
                              {item.category && (
                                <Column
                                  isTextCenter={false}
                                  value={item.category}
                                  mw="70px"
                                />
                              )}
                              {item.values.map((brand, index) => (
                                <Column
                                  key={Math.random()}
                                  bg={
                                    index === 2 || index === 6 || index === 7
                                      ? "#E7E6E6"
                                      : "white"
                                  }
                                  isBold={
                                    !!(
                                      index === 2 ||
                                      index === 6 ||
                                      index === 7
                                    )
                                  }
                                  value={brand}
                                />
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </>
                ))}
            </Flex>
          )}
        </Stack>
        {data?.contentSecundary && (
          <Stack
            bg="white"
            p="10px 5px"
            mt={noSeparate ? "-10px !important" : "5px !important"}
          >
            {subTitle && (
              <Text
                textAlign="center"
                fontSize="14px"
                color="black"
                fontWeight="bold"
                p="5px"
                bg="#D9D9D9"
                borderRadius="3px"
              >
                {subTitle}
              </Text>
            )}
            {data?.contentSecundary.map((contentSecundary) => {
              return (
                contentSecundary.content.length > 0 && (
                  <Flex w="100%" key={Math.random()}>
                    {(contentSecundary.indicator && (
                      <TableContainer
                        minW="400px"
                        mt="3px"
                        p="1px"
                        border="1px solid #E7E6E6"
                      >
                        <Table style={{ borderCollapse: "separate" }}>
                          <Thead>
                            <Tr>
                              {contentSecundary.indicator.headers.map(
                                (header, index) => (
                                  <HeaderTable
                                    key={Math.random()}
                                    isTextCenter={index !== 0}
                                    colSpan={index === 0 ? 2 : 1}
                                    mw={index === 0 ? "240px" : "70px"}
                                  >
                                    {header}
                                  </HeaderTable>
                                )
                              )}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {contentSecundary.indicator.content.map(
                              (content, idx) => {
                                return (
                                  <Tr key={Math.random()}>
                                    {idx === 0 && (
                                      <Td
                                        rowSpan={
                                          contentSecundary.indicator?.content
                                            .length
                                        }
                                      >
                                        <Flex
                                          h="100%"
                                          w="80px"
                                          justify="center"
                                        >
                                          <Image
                                            src={contentSecundary.image}
                                            w="60px"
                                          />
                                        </Flex>
                                      </Td>
                                    )}
                                    {content.map((value, index) => (
                                      <Column
                                        isTextCenter={index !== 0}
                                        key={Math.random()}
                                        value={value}
                                        w="130px"
                                      />
                                    ))}
                                  </Tr>
                                );
                              }
                            )}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    )) || (
                      <Stack
                        whiteSpace="nowrap"
                        minW="210px"
                        mt="2px"
                        border="1px solid #E7E6E6"
                        p={1}
                      >
                        <HeaderGraphic>
                          {contentSecundary.title || ""}
                        </HeaderGraphic>
                        <Flex
                          h="100%"
                          justifyContent="center"
                          alignItems="center"
                          mt="0 !important"
                        >
                          <Image
                            src={contentSecundary.image}
                            alt="categoria"
                            maxWidth={minWidthImage || "200px"}
                            maxHeight={minWidthImage || "200px"}
                          />
                        </Flex>
                      </Stack>
                    )}

                    <TableContainer
                      border="1px solid #E7E6E6"
                      ml={1}
                      p="1px"
                      mt="3px"
                      w="100%"
                      css={`
                        &::-webkit-scrollbar {
                          background-color: darkgray;
                          border-radius: 20px;
                          width: 12px;
                          height: 12px;
                        }

                        &::-webkit-scrollbar-thumb {
                          border-radius: 20px;
                          background-color: ${theme.scrollColor};
                        }
                      `}
                    >
                      <Table style={{ borderCollapse: "separate" }}>
                        <Thead>
                          <Tr>
                            {contentSecundary.headers.map((brand, index) => (
                              <HeaderTable
                                key={Math.random()}
                                mw={
                                  firstColumnMinWidth && index === 0
                                    ? firstColumnMinWidth
                                    : "70px"
                                }
                              >
                                {brand}
                              </HeaderTable>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {contentSecundary.content.map((item, idx) => (
                            <Tr key={Math.random()}>
                              {item.category && (
                                <Column
                                  isTextCenter={false}
                                  isBold={idx === 0}
                                  value={item.category}
                                />
                              )}
                              {item.values.map((brand, index) => (
                                <Column
                                  key={Math.random()}
                                  bg={
                                    index === 2 || index === 6 || index === 7
                                      ? "#E7E6E6"
                                      : "white"
                                  }
                                  isBold={
                                    !!(
                                      index === 2 ||
                                      index === 6 ||
                                      index === 7
                                    )
                                  }
                                  value={brand}
                                />
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Flex>
                )
              );
            })}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

TableComplements.defaultProps = {
  subTitle: "",
  mainFilter: [],
  secundaryFilter: [],
  noFilter: false,
  adherence: undefined,
  noSeparate: false,
  subTitleCard: "",
  isLoading: false,
  firstColumnMinWidth: undefined,
  goDetail: undefined,
  setAdherence: undefined,
  minWidthImage: undefined,
  data: {
    contentPrimary: {
      title: "",
      image: "",
      content: [],
      headers: [],
    },
  },
};

export default TableComplements;
