import React from 'react';

import { CustomRadioContainer } from './styles';

export interface IListProductSelected {
  id: string;
  tpr: number;
  shelfSelecteds: number[];
  observation: string;
}

interface IOwnProps {
  id: string;
  activeList: IListProductSelected[];
  setActiveList(items: IListProductSelected[]): void;
}

export const handleSelectedItem = (
  list: IListProductSelected[],
  id: string,
): boolean => {
  if (list.find(item => item.id === id)) {
    return false;
  }
  return true;
};

export const CustomRadio: React.FC<IOwnProps> = ({
  id,
  activeList,
  setActiveList,
}: IOwnProps) => {
  const handleSelectedId = () => {
    if (activeList.find(item => item.id === id)) {
      const removeElement: IListProductSelected[] = activeList
        .filter(item => item.id !== id)
        .map(item => {
          return item;
        });
      setActiveList(removeElement);
    } else {
      setActiveList([
        ...activeList,
        {
          id,
          observation: '',
          shelfSelecteds: [],
          tpr: 0,
        },
      ]);
    }
  };

  return (
    <CustomRadioContainer aria-hidden="true" onClick={() => handleSelectedId()}>
      <button type="button" id={id} hidden={handleSelectedItem(activeList, id)}>
        &#10004;
      </button>
    </CustomRadioContainer>
  );
};
