import React, { ReactNode } from "react";
import { BSConteinerColumn, Item } from "./DatailProducts.element";

import success from "../../../../../assets/img/icons/checkmark-circle.svg";
import error from "../../../../../assets/img/icons/close-circle.svg";
import { IColumnsData } from "../Banner/BSColumn";

interface IStyleBSColum {
  data?: IColumnsData;
  icons?: number[];
  children?: ReactNode;
  bg?: string;
  color?: string;
}

export const BSColumn: React.FC<IStyleBSColum> = (
  props: IStyleBSColum
): JSX.Element => {
  return (
    <BSConteinerColumn>
      {(props.data &&
        props.data.text.length > 0 &&
        props.data.text.map((item: string | number) => (
          <Item bg={props.bg} color={props.color}>
            {item}
          </Item>
        ))) ||
        (props.icons &&
          props.icons?.length > 0 &&
          props.icons?.map((item) => {
            return (
              <Item color={props.color} key={Math.random()} bg={props.bg}>
                <img
                  key={Math.random()}
                  src={(item === 1 && success) || error}
                  alt="icons-store"
                />
              </Item>
            );
          })) ||
        props.children}
    </BSConteinerColumn>
  );
};

BSColumn.defaultProps = {
  data: { text: [], total: 0, x: [], y: [] },
  icons: [],
  children: <></>,
  bg: "#a6a6a6",
  color: "white",
};
