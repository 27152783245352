import { INavPage } from '../../Navigation/types';

export enum ALogout {
  REQUEST_LOGOUT = '@Profile/REQUEST_LOGOUT',
  LOGOUT_SUCCESS = '@Profile/LOGOUT_SUCCESS',
  LOGOUT_ERROR = '@Profile/LOGOUT_ERROR',
}

export interface actionLogout {
  setPage(indexPage: INavPage): void;
}
export interface IRequestLogout {
  type: ALogout.REQUEST_LOGOUT;
  payload: actionLogout;
}

export interface ISuccessLogout {
  type: ALogout.LOGOUT_SUCCESS;
}

export interface IErrorLogout {
  type: ALogout.LOGOUT_ERROR;
}

export interface stateLogout {
  isLoading: boolean;
  error: boolean;
}
