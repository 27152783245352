export const theme = {
  colors: {
    sideBar: "#000040",
    header: "#000000",
    subMenu: "#ffffff",
    profile: "#a144ac",
    borderProfile: "#000000",
    text: "#ffffff",
    button: "#bf9000",
    border: "#ffc000",
    numberCards: "#ffc000",
    arrow: "#000000",
    profileSideBar: "#000000",
    textHeaderMenu: "#000000",
    borderHeaderMenu: "#000000",
  },
  graphics: {
    light: "#ffe699",
    dark: "#bf9000",
    period: "#c00000",
    target: "#c00000",
  },
  scrollColor: "black",
};
