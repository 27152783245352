/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/no-shadow */
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import { Box, Icon, Text, Collapse, Tooltip } from "@chakra-ui/react";
import { GoTriangleDown } from "react-icons/go";
import Dropdown from "./Dropdown";
import {
  filter,
  stateMarketShare,
} from "../../../../store/SideFilters/RequestFilters/types";
import * as AFilters from "../../../../store/SideFilters/actions";
import { GlobalProps } from "../../../../store";
import {
  IStateFilters,
  TPromoFilter,
  IProductFilter,
} from "../../../../store/SideFilters/types";
import { IDispatchFilters } from "../../../SideFilterMenu/Resource";
import { INavMainMenu, stateType } from "../../../../store/Navigation/types";

interface IOwnProps {
  label?: "";
  marginTop?: string;
  data: any;
  value: string;
  name: string;
  onChange: any;
  searchPlaceholder?: string;
  requestFilter(
    id: string,
    SFilters: IStateFilters,
    SAllFilters: stateMarketShare,
    DFilters: IDispatchFilters,
    isPromo: boolean
  ): void;
  selectedItem: string;
  position: any;
  disableInput: boolean;
}

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
  INavigation: stateType;
}

interface IDispatchProps {
  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
  };
}

type props = IOwnProps & IStateProps & IDispatchProps;

const CustomSelect: React.FC<props> = ({
  data,
  value,
  name,
  onChange,
  searchPlaceholder,
  requestFilter,
  selectedItem,
  position,
  SFilters,
  SAllFilters,
  DFilters,
  marginTop,
  INavigation,
  disableInput,
}: props) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedIndex, setSelectedIndex] = useState(
    value !== "" ? data.label.indexOf(value) : null
  );
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownEl = useRef<any>();

  // Hide dropdown if clicked outside of dropdown
  const handleClickOutside = useCallback(
    (e) => {
      if (
        showDropdown &&
        e.target.closest(".dropdown") !== dropdownEl.current
      ) {
        setShowDropdown(false);
        setSearch("");
      }
    },
    [showDropdown, setShowDropdown, dropdownEl, data]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setOptions(data);
  }, [data]);

  const changeSelectedHandler = (
    item: filter,
    name: any,
    index: any,
    requestFilter?: any
  ) => {
    const isPromo = INavigation.menuItem.index !== INavMainMenu.MY_ONE_PAGE;

    setSelectedValue(name);
    setSelectedIndex(index);
    setShowDropdown(false);
    onChange(item, name);
    if (requestFilter)
      requestFilter(item.id, SFilters, SAllFilters, DFilters, isPromo);
  };

  const searchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    const filteredOptions = data.filter((opt: filter) => {
      return opt.label
        .toLowerCase()
        .includes(e.target.value.trim().toLowerCase());
    });
    setOptions(filteredOptions);
  };

  return (
    <>
      <Box
        bg="white"
        borderRadius="4px"
        className="dropdown"
        ref={dropdownEl}
        justifyContent="center"
        border="1px solid"
        position={position}
        fontSize={11}
        w="100%"
        mt={`${marginTop} !important`}
        zIndex={100}
      >
        <input
          type="hidden"
          name={name}
          value={value}
          onChange={() => onChange(value, name)}
        />
        <Box
          w="100% !important"
          h="25px"
          bg={
            disableInput
              ? disableInput && "#ebebeb"
              : (showDropdown && "gray.75") || "white"
          }
          borderRadius="4px"
          p="0 8px"
          display="flex"
          align="center"
          justify="space-between"
          variant="outline"
          placeholder="Todas"
          // mb={1}
          className="dropdown__selected"
          onClick={() => setShowDropdown(!showDropdown)}
          color="black"
        >
          <Box m="auto 0" width="100%">
            {(selectedItem.length > 28 && (
              <Tooltip label={selectedItem}>
                <Text color="#000" textAlign="left">
                  {`${selectedItem.substring(0, 28)}...` || "Todos"}
                </Text>
              </Tooltip>
            )) || (
              <Text color="#000" textAlign="left">
                {selectedItem || "Todos"}
              </Text>
            )}
          </Box>
          <Box display="flex" alignItems="center" width="auto !important">
            <Icon as={GoTriangleDown} fontSize="16" />
          </Box>
        </Box>
        <Collapse
          in={showDropdown && !disableInput}
          animateOpacity
          style={{ width: "100%" }}
        >
          <Dropdown
            searchPlaceholder={searchPlaceholder}
            search={search}
            searchChangeHandler={searchChangeHandler}
            options={options}
            selectedValue={selectedValue}
            selectedIndex={selectedIndex}
            changeSelectedHandler={changeSelectedHandler}
            data={data}
            filter={requestFilter}
          />
        </Collapse>
      </Box>
    </>
  );
};

CustomSelect.defaultProps = {
  searchPlaceholder: "",
  label: "",
  marginTop: "0.5rem",
};

const mapStateToProps = (state: GlobalProps) => ({
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
  INavigation: state.navigation,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DFilters: bindActionCreators(AFilters, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomSelect);
