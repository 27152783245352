import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { BiBrushAlt } from "react-icons/bi";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Flex,
  Grid,
  Image,
  Select,
  Stack,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";

import * as ANavigator from "../../../../../store/Navigation/actions";
import * as AFilters from "../../../../../store/SideFilters/actions";

import {
  ENavSubMenu,
  filterComponent,
  INavContent,
} from "../../../../../store/Navigation/types";

import ColumnTable, { IMonTable } from "../../../../../components/Table/Column";
import ShareValue from "../../../../../components/Graphics/MarketShareValue/MarketShareValue";

import {
  TableSubView,
  SubMenuItem,
} from "../../../../../components/Contents/Resources/SubView/subView";

import success from "../../../../../assets/myExecution/success.svg";
import error from "../../../../../assets/myExecution/erro.svg";
import warning from "../../../../../assets/myExecution/atencao.svg";

import { GlobalProps } from "../../../../../store";
import { stateMarketShare } from "../../../../../store/SideFilters/RequestFilters/types";
import Column from "../../../../../components/DetailPages/Columns";
import HeaderDetailPage, {
  IGeneralPanel,
} from "../../../../../components/DetailPages/HeaderDetailPage";
import { useRequestComplianceStore } from "../../../../../services/Hooks/MyExecution/DetailStore/useComplianceStore";
import {
  INITIAL_FILTERS,
  IProductFilter,
  IStateFilters,
  TPromoFilter,
} from "../../../../../store/SideFilters/types";
import LoadingComponent from "../../../../../components/LoadingComponent";
import HeaderTable from "../../../../../components/Table/Header";
import EmptyMessage from "../../../../../components/EmptyMessage";
import FiltersList from "../../../../../components/SideFilterMenu/Resource/FiltersList";
import { ConfigContext } from "../../../../../services/Context/ConfigContext";
import { ITypeGraphicHorizontalBar } from "../../../../../services/Hooks/OnePage/onePageTypes";

interface IStateProps {
  SAllFilters: stateMarketShare;
  SFilters: IStateFilters;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeTitleMenu(newTitle: string): void;
    changeSubMenu(index: ENavSubMenu): void;
    changeFilters(filters: filterComponent[]): void;
  };

  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
    changeCnpjStore(payload: string): void;
  };
}

type props = IStateProps & IDispatchProps;

const DetailKPI: React.FC<props> = ({
  DNavigator,
  SAllFilters,
  SFilters,
  DFilters,
}): JSX.Element => {
  const [widthContent, setWidthContent] = useState<number>(0);
  const [limitItem, setLimitItem] = useState<number>(50);
  const refDivContent = useRef<HTMLDivElement>(null);
  const [heightGraphics, setHeightGraphics] = useState<number>(0);
  const refTable = useRef<HTMLTableElement>(null);

  const { config } = useContext(ConfigContext);

  const { data, isLoading } = useRequestComplianceStore(SFilters);

  const limitMainTable: IMonTable[] =
    data?.data.descriptionStore?.slice(0, limitItem) || [];

  const limitContent =
    data?.data.complianceData?.content.slice(0, limitItem) || [];

  const LimitGraphicItem: ITypeGraphicHorizontalBar = {
    label: data?.data.complianceData?.growth.label.slice(0, limitItem) || [],
    x: data?.data.complianceData?.growth.x.slice(0, limitItem) || [],
    y: data?.data.complianceData?.growth.y.slice(0, limitItem) || [],
  };

  const headers: string[] = ["#", "CNPJ", "Nome da Loja", "Bandeira", "UF"];

  const dataHeader: IGeneralPanel[] = [
    {
      text: "Total de Lojas",
      value: data?.info?.store.total || "-",
    },
    {
      text: "Lojas c/ Comp.",
      value: data?.info?.store.withCompliance || "-",
    },
    {
      text: "Lojas s/ Comp.",
      value: data?.info?.store.withoutCompliance || "-",
    },
    {
      text: "Performance",
      value: data?.info?.store.compliance || "-",
    },
  ];

  const handleLimitItem = (e: ChangeEvent<HTMLSelectElement>) => {
    const limitSelected = parseInt(e.currentTarget.value, 10);
    setLimitItem(limitSelected);
  };

  useEffect(() => {
    DNavigator.changeTitleMenu("Minha Execucao | Presenca | Visão Loja");
  }, [DNavigator]);

  setTimeout(() => {
    if (refDivContent.current !== null) {
      const widthDiv = refDivContent.current.clientWidth - 147;
      if (widthDiv !== widthContent) {
        setWidthContent(widthDiv);
      }
    }

    if (refTable.current) {
      const heightDiv = refTable.current.clientHeight - 30;
      setHeightGraphics(heightDiv);
    }
  });

  const renderFilter = useCallback(() => {
    if (config.showSideFilters.myExecution)
      DNavigator.changeFilters(
        FiltersList(
          SAllFilters,
          SFilters,
          {
            ...INITIAL_FILTERS,
            mainCategory: true,
            category: true,
            subCategory: true,
            subChannel: true,
            isPromo: true,
            banner: true,
            region: true,
            uf: true,
            storeCNPJ: true,
            commercialStructureDirector: true,
            commercialStructureManager: true,
            commercialStructurCoordinator: true,
            commercialStructureSales: true,
          },
          DFilters
        )
      );
  }, [SAllFilters, SFilters, DNavigator]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter]);

  const selectIcon = (icon: number) => {
    switch (icon) {
      case -1:
        return warning;
      case 0:
        return error;
      case 1:
        return success;
      default:
        return "";
    }
  };

  return (
    <Grid
      templateRows="auto 1fr"
      h="calc(100% - 120px)"
      m="5px !important"
      overflowY="hidden"
    >
      <HeaderDetailPage
        gridColumn={`205px repeat(${data?.info?.leverages.length}, 480px)`}
        margin="0 12.5px 0 0"
        widthLeftHeader={`${widthContent}px`}
        generalPanel={dataHeader}
        isLoading={isLoading}
        isEmpty={data?.total === 0}
        filters={SFilters}
        quantityItem={
          (data && (data.total < limitItem ? data.total : limitItem)) || 0
        }
      >
        <>
          <Stack fontSize="14px" py="3px" ml="5px !important">
            <Text fontWeight="bold" pl="10px">
              % Compliance
            </Text>
            <Text
              background="#e7e6e6"
              mt="5px !important"
              p="5px 15px"
              textAlign="center"
              fontWeight="bold"
            >
              {/* {data?.info?.compliance || "-"} */}-
            </Text>
          </Stack>
          {data?.info?.leverages.map((header) => (
            <Stack fontSize="14px" py="3px" ml="5px !important">
              <Text fontWeight="bold" pl="10px">
                {header.label}
              </Text>
              <Text
                background="#e7e6e6"
                mt="5px !important"
                p="5px 15px"
                textAlign="center"
              >
                Negociadas <b>| - |</b> Executadas <b>| - |</b> Compliance %{" "}
                <b>| - |</b>
              </Text>
            </Stack>
          ))}
        </>
      </HeaderDetailPage>

      <Stack bg="white" mt="5px !important" p="5px" overflow="hidden">
        {(isLoading && <LoadingComponent />) ||
          (data?.total === 0 && (
            <Stack
              mt="0 !important"
              h="100%"
              w="100%"
              bg="white"
              align="center"
              justify="center"
            >
              <BiBrushAlt size={45} color="#7A7A7A" />
              <Text mt="15px" color="#7A7A7A" fontWeight="bold" fontSize="16px">
                Não existe dados disponiveis para a sua seleção.
              </Text>
            </Stack>
          )) || (
            <>
              <Flex
                pl="16px"
                py="5px"
                alignItems="center"
                color="black"
                zIndex={9999}
              >
                <Text ml="20px">Exibir</Text>
                <Select
                  w="160px"
                  ml="10px"
                  h="30px"
                  onChange={handleLimitItem}
                  borderColor="black"
                >
                  <option value={data?.total || 0}>Todos</option>
                  <option selected={limitItem === 25} value={25}>
                    25
                  </option>
                  <option selected={limitItem === 50} value={50}>
                    50
                  </option>
                  <option selected={limitItem === 100} value={100}>
                    100
                  </option>
                </Select>
                <Text ml="10px !important">Itens</Text>
              </Flex>
              <Flex overflowX="hidden">
                <Stack ref={refDivContent}>
                  <Flex
                    backgroundColor="#e7e6e6"
                    p="8px 12px"
                    alignItems="center"
                  >
                    <Text color="#c00000" fontWeight="bold" fontSize="14px">
                      Top{" "}
                      {(data &&
                        (data.total < limitItem ? data.total : limitItem)) ||
                        0}{" "}
                      Lojas OFENSORAS no COMPLIANCE
                    </Text>
                  </Flex>
                  <TableSubView
                    refElement={refTable}
                    headers={headers}
                    dataTable={limitMainTable}
                    collapse
                  />
                </Stack>
                <Column
                  title={data?.info?.compliance || "-"}
                  subTitle="% Compliance"
                >
                  <Stack mt="-2px !important">
                    <ShareValue
                      dataGraphic={LimitGraphicItem}
                      height={heightGraphics}
                      gap={0.07}
                      color="#a6a6a6"
                      w={200}
                    />
                  </Stack>
                </Column>
                <Stack>
                  <Flex color="black" position="sticky" top="0px">
                    {data?.data.complianceData.headers
                      .slice(1)
                      .map((dataLavarage) => (
                        <Flex
                          w="475px"
                          p="8px 12px"
                          justifyContent="center"
                          bg="#e7e6e6"
                          ml="5px"
                        >
                          <Text
                            textAlign="center"
                            fontSize="14px"
                            fontWeight="bold"
                          >
                            {dataLavarage.label} | {dataLavarage.value}
                          </Text>
                        </Flex>
                      ))}
                  </Flex>
                  <Flex
                    mt="0 !important"
                    color="black"
                    flex="1"
                    position="sticky"
                    top="0px"
                  >
                    {data?.data.complianceData.subHeaders
                      .slice(1)
                      .map((subHeader, index) => (
                        <Stack mt="5px" w="475px" ml="5px">
                          <Stack>
                            <Table
                              style={{ borderCollapse: "separate" }}
                              mt="0px !important"
                              fontSize="12px"
                            >
                              <Thead>
                                <HeaderTable bg="transparent">
                                  Lojas Negociadas?
                                </HeaderTable>
                                <HeaderTable
                                  isTextCenter={false}
                                  bg="transparent"
                                >
                                  Status / Compliance
                                </HeaderTable>
                              </Thead>
                              <Tbody>
                                {limitContent.map((content, idx) => (
                                  <Tr>
                                    <ColumnTable
                                      borderLeft="1px solid black"
                                      isTextCenter={false}
                                      w="150px"
                                      border="none"
                                    >
                                      <Flex>
                                        <Image
                                          src={selectIcon(
                                            content[index + index].icon
                                          )}
                                          w="20px"
                                        />
                                        <Text pl="15px">
                                          {content[index + index].label}
                                        </Text>
                                      </Flex>
                                    </ColumnTable>
                                    <ColumnTable
                                      isTextCenter={false}
                                      border="none"
                                    >
                                      <Flex>
                                        <Image
                                          src={selectIcon(
                                            content[index + index + 1].icon
                                          )}
                                          w="20px"
                                          ml="10px"
                                        />
                                        <Text pl="15px">
                                          {content[index + index + 1].label}
                                        </Text>
                                      </Flex>
                                    </ColumnTable>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </Stack>
                        </Stack>
                      ))}
                  </Flex>
                </Stack>
                {/* <Column
              title="77,5%"
              subTitle={`% Presença (${
                temporalFilter === "SEMANAL" ? "MTD" : "Act"
              })`}
            >
              <Stack mt="-2px !important">
                <ShareValue
                  dataGraphic={{
                    label: [],
                    x: [],
                    y: [],
                  }}
                  height={heightGraphics}
                  gap={0.07}
                  color="#a6a6a6"
                  w={200}
                />
              </Stack>
            </Column> */}
              </Flex>
            </>
          )}
      </Stack>
    </Grid>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
    DFilters: bindActionCreators(AFilters, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailKPI);
