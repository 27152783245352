import React from "react";
import PlotlyChart from "react-plotlyjs-ts";

const PieChart: React.FC = (): JSX.Element => {
  const data = [
    {
      values: [15, 20, 65],
      labels: ["Estoque virtual", "Cadastro", "Ruptura"],
      textposition: "inside",
      marker: {
        colors: ["#ADB9CA", "#E7E6E6", "#001D58"],
      },
      domain: { column: 1 },
      hoverinfo: "label+percent+name",
      hole: 0.6,
      type: "pie",
    },
  ];

  const layout = {
    title: "<b>Detalhamento do KPI Presença</b>",
    height: 400,
    width: 470,
    showlegend: true,

    legend: {
      xanchor: "center",
      yanchor: "top",
      orientation: "h",
      y: -0.3, // play with it
      x: 0.5, // play with it
    },
  };

  const configGraphic = { displayModeBar: false };

  return <PlotlyChart data={data} layout={layout} config={configGraphic} />;
};

export default PieChart;
