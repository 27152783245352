import styled, { keyframes } from "styled-components";

const animationSpinner = keyframes`
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`;

export const ConteinerSpinnerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 330px;
  width: 100%;
  color: black;

  svg {
    animation: ${animationSpinner} 1s linear infinite;
    margin-bottom: 10px;
  }

  span {
    font-weight: bold;
  }
`;
