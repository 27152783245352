import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import { ChakraProvider, extendTheme, Stack } from "@chakra-ui/react";
import { RootContainer } from "./Dashboard.elements";
import Header from "../../../components/Header/Header";
import SideMenu from "../../../components/SideMenu/SideMenu";
import RenderContent from "./RenderContent";
import * as ANavigator from "../../../store/Navigation/actions";
import { INavContent, stateType } from "../../../store/Navigation/types";
import { GlobalProps } from "../../../store/index";
import { ConfigContext } from "../../../services/Context/ConfigContext";
// import Communication from "../../../components/Communication";

interface stateProps {
  navigation: stateType;
}

interface dispatchProps {
  DNavigation: {
    changeDash(content: INavContent): void;
  };
}

type props = stateProps & dispatchProps;

const Dashboard: React.FC<props> = (props: props) => {
  const [showSideBar, setShowSideBar] = useState<boolean>(true);
  const { config } = useContext(ConfigContext);

  const themeChakra = extendTheme({
    colors: config.theme,
    components: {
      Text: {
        baseStyle: {
          margin: "0 !important",
        },
      },
    },
  });

  useEffect(() => {
    props.DNavigation.changeDash(INavContent.MAIN_DASH_CONTENT);
  }, [props.DNavigation]);

  return (
    <>
      <ChakraProvider theme={themeChakra}>
        <RootContainer>
          {showSideBar && <SideMenu />}
          <Stack as="main" overflow="hidden" flex={1} position="relative">
            <Header showMenu={showSideBar} setShowMenu={setShowSideBar} />
            {RenderContent(props.navigation.contentDash)}
          </Stack>
        </RootContainer>
      </ChakraProvider>
      {/* <Communication /> */}
    </>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigation: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
