import styled from "styled-components";

interface ITableHead {
  paddingHead?: string;
  minWidth?: string;
  whiteSpace?: string;
}

export const THeadCalendar = styled.th<ITableHead>`
  font-weight: bold;
  padding: ${(props) => props.paddingHead || "14px 10px"};
  background: rgb(231, 230, 230);
  text-align: center;
  min-width: ${(props) => props.minWidth};
  white-space: ${(props) => props.whiteSpace};
`;

interface ITableProps {
  main?: boolean;
  mt?: string;
}

export const Table = styled.table<ITableProps>`
  margin-top: ${(props) => props.mt || "10px"};
  border-spacing: 5px;
  border-collapse: separate;
  width: 100%;
  height: fit-content;
  background-color: #fff;
  margin-left: -5px;

  thead {
    position: sticky;
    top: 0px;
    z-index: 10;
  }

  thead > :nth-child(n + 4) {
    padding: 0;
    min-width: 150px;
  }

  td {
    text-align: center;
    white-space: nowrap;

    & > img {
      margin: 0 auto;
      max-width: 94px;
    }
  }

  ${(props) =>
    props.main &&
    `
    min-width: 630px;
    position: sticky;
    left: 0;
    z-index: 100;
  `}

  & > tbody > tr > td > tr > td {
    width: 30%;
  }
`;

export const ContainerItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div + div > :first-child > span {
    display: none;
  }
`;

export const ItemCalendar = styled.div<{ line?: boolean; noBorder?: boolean }>`
  display: grid;
  min-height: 95px;
  grid-template-columns: repeat(2, 1fr);

  td + td {
    border-bottom: 1px solid black;
    padding-top: 4px;
  }

  & > :first-child {
    margin-right: 2px;

    & > span {
      position: absolute;
      bottom: -6px;
    }
  }

  td {
    position: relative;

    & > p {
      margin: 0 4px 4px;
    }
  }

  & > :first-child {
    ${(props) =>
      (props.line &&
        `
        ::after{
          content: '';
          background: black;
          position: absolute;
          bottom: 0px;
          margin-left: -10px;
          width: 20px;
          height: 1px;
        }
    `) ||
      (!props.noBorder &&
        `
      border-bottom: 1px solid black;
      margin: 0;
    `)}
  }
`;
