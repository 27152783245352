import React, { useState } from "react";
import SubTableComplements from "../../subTableComplements";
import { PSAbsolutSubCard } from "../data2";
import BottomTable from "./BottomTable";
import DetailPerfectStore from "./DetailPerfectStore";

const PerfectStoreAbsolut: React.FC = (): JSX.Element => {
  const [selectedSubCard, setSelectedSubCard] = useState<string>("");

  return (
    <>
      <DetailPerfectStore />
      {/* <BottomTable /> */}
    </>
  );
};

export default PerfectStoreAbsolut;
