import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";

import {
  Flex,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  BSMainConteiner,
  BSSubMenu,
  BSLegendTable,
  BSConteinerData,
  BSCard,
  BSHeader,
  ConteinerSelectProduct,
  FormDetail,
} from "./MyDayDatailStores.element";
import { WrapperContent } from "../../../../../components/MainContent/MainContent.elements";
import selectABrand from "../../../../../assets/img/icons/farmacia.png";
import * as ANavigator from "../../../../../store/Navigation/actions";
import {
  ENavSubMenu,
  IMainMenu,
  INavContent,
  stateType,
} from "../../../../../store/Navigation/types";

import { Divisor, ItemAtivo } from "../../../../../components/Contents/subMenu";

import Less from "../../../../../assets/img/icons/minus-circle.png";
import More from "../../../../../assets/img/icons/plus-circle.png";
import product from "../../../../../assets/OnePage/products_black.png";
import store from "../../../../../assets/Menu/store-white.png";

import { IStateFilters } from "../../../../../store/SideFilters/types";
import Column from "../../../../../components/Table/Column";
import TemporalFilter from "../../../../../components/TemporalFilter";
import HeaderTable from "../../../../../components/Table/Header";
import { GlobalProps } from "../../../../../store";
import { SubMenuItem } from "../../../../../components/Contents/Resources/SubView/subView";
import { Form } from "../../../../Public/Login/Login.element";
import { filter } from "../../../../../store/SideFilters/RequestFilters/types";

interface IStateProps {
  navigation: stateType;
  SFilters: IStateFilters;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeMenu(menuItem: IMainMenu): void;
    changeSubMenu(index: ENavSubMenu): void;
  };
}

enum ELineFilter {
  TOP = "TOP",
  ESG = "ESG",
  CLN_PROMO = "CLN PROMO",
  FCIA_AMARELA = "FCIA AMARELA",
}

type props = IStateProps & IDispatchProps;

const MyDayDetailStores: React.FC<props> = ({
  navigation,
  DNavigator,
  SFilters,
}: props): JSX.Element => {
  const [widthContent, setWidthContent] = useState<string>("");
  const refDivContent = useRef<HTMLTableElement>(null);
  const refSubMenu = useRef<HTMLDivElement>(null);
  const [filterKPI, setFilterKPI] = useState<filter>({
    id: ELineFilter.ESG,
    label: ELineFilter.ESG,
  });

  const headers = ["#", "Cnpj", "Nome da Loja", "Bandeira", "UF"];

  useEffect(() => {
    DNavigator.changeMenu({
      index: navigation.menuItem.index,
      name: "Minha Execução | Presença - Visão Loja",
    });
  }, []);

  setTimeout(() => {
    if (refDivContent.current !== null) {
      const widthDiv = refDivContent.current.clientWidth - 148.5;
      const currentWidth = parseInt(widthContent.replace("px", ""), 10);
      if (widthDiv !== currentWidth) {
        setWidthContent(`${widthDiv}px`);
      }
    }
  });

  const detailStore = {
    data: {
      body: {
        offendingStoresTable: [
          {
            order: 1,
            storesIds: "40576",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-VILA GOMES CARDIM-5803",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 2,
            storesIds: "35284",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-BELA VISTA-5215",
            storeBanners: "Panvel",
            storeUf: "RJ",
          },
          {
            order: 3,
            storesIds: "13143",
            storeNames:
              "RAIA DROGASIL-SP-CAMPOS DO JORDAO-VILA ABERNESSIA-6451",
            storeBanners: "Raia Drogasil",
            storeUf: "SC",
          },
          {
            order: 4,
            storesIds: "236417",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-BUTANTA-5110",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 5,
            storesIds: "79954",
            storeNames: "RAIA DROGASIL-SP-SUZANO-CENTRO-5016",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 6,
            storesIds: "97835",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-CAMBUCI-1922",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 7,
            storesIds: "43546",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-CERQUEIRA CESAR-5405",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 8,
            storesIds: "43553",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-LAPA-3762",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 9,
            storesIds: "714673",
            storeNames:
              "RAIA DROGASIL-SP-BARUERI-ALPHAVILLE CENTRO INDUSTRIAL E EMPRESARIAL/ALPHAVI-8923",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 10,
            storesIds: "137260",
            storeNames: "RAIA DROGASIL-SP-BARRETOS-CENTRO-3006",
            storeBanners: "Panvel",
            storeUf: "PR",
          },
          {
            order: 11,
            storesIds: "28",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-VILA GOMES CARDIM-5803",
            storeBanners: "Panvel",
            storeUf: "SC",
          },
          {
            order: 12,
            storesIds: "93525",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-BELA VISTA-5215",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 13,
            storesIds: "4565",
            storeNames:
              "RAIA DROGASIL-SP-CAMPOS DO JORDAO-VILA ABERNESSIA-6451",
            storeBanners: "Raia Drogasil",
            storeUf: "PR",
          },
          {
            order: 14,
            storesIds: "93528",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-BUTANTA-5110",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 15,
            storesIds: "93529",
            storeNames: "RAIA DROGASIL-SP-SUZANO-CENTRO-5016",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 16,
            storesIds: "164767",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-CAMBUCI-1922",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 17,
            storesIds: "165411",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-CERQUEIRA CESAR-5405",
            storeBanners: "Panvel",
            storeUf: "CE",
          },
          {
            order: 18,
            storesIds: "19962",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-LAPA-3762",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 19,
            storesIds: "24481",
            storeNames:
              "RAIA DROGASIL-SP-BARUERI-ALPHAVILLE CENTRO INDUSTRIAL E EMPRESARIAL/ALPHAVI-8923",
            storeBanners: "Panvel",
            storeUf: "DF",
          },
          {
            order: 20,
            storesIds: "17104",
            storeNames: "RAIA DROGASIL-SP-BARRETOS-CENTRO-3006",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 21,
            storesIds: "11251",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-VILA GOMES CARDIM-5803",
            storeBanners: "Raia Drogasil",
            storeUf: "PR",
          },
          {
            order: 22,
            storesIds: "189901",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-BELA VISTA-5215",
            storeBanners: "Panvel",
            storeUf: "RN",
          },
          {
            order: 23,
            storesIds: "539571",
            storeNames:
              "RAIA DROGASIL-SP-CAMPOS DO JORDAO-VILA ABERNESSIA-6451",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 24,
            storesIds: "587640",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-BUTANTA-5110",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 25,
            storesIds: "713093",
            storeNames: "RAIA DROGASIL-SP-SUZANO-CENTRO-5016",
            storeBanners: "Panvel",
            storeUf: "MA",
          },
          {
            order: 26,
            storesIds: "713907",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-CAMBUCI-1922",
            storeBanners: "Raia Drogasil",
            storeUf: "DF",
          },
          {
            order: 27,
            storesIds: "716984",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-CERQUEIRA CESAR-5405",
            storeBanners: "Panvel",
            storeUf: "DF",
          },
          {
            order: 28,
            storesIds: "877912",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-LAPA-3762",
            storeBanners: "Raia Drogasil",
            storeUf: "RJ",
          },
          {
            order: 29,
            storesIds: "1271026",
            storeNames:
              "RAIA DROGASIL-SP-BARUERI-ALPHAVILLE CENTRO INDUSTRIAL E EMPRESARIAL/ALPHAVI-8923",
            storeBanners: "Panvel",
            storeUf: "RO",
          },
          {
            order: 30,
            storesIds: "18221",
            storeNames: "RAIA DROGASIL-SP-BARRETOS-CENTRO-3006",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 31,
            storesIds: "12570",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-VILA GOMES CARDIM-5803",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 32,
            storesIds: "40951",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-BELA VISTA-5215",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 33,
            storesIds: "40566",
            storeNames:
              "RAIA DROGASIL-SP-CAMPOS DO JORDAO-VILA ABERNESSIA-6451",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 34,
            storesIds: "12008",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-BUTANTA-5110",
            storeBanners: "Raia Drogasil",
            storeUf: "CE",
          },
          {
            order: 35,
            storesIds: "15321",
            storeNames: "RAIA DROGASIL-SP-SUZANO-CENTRO-5016",
            storeBanners: "Panvel",
            storeUf: "PR",
          },
          {
            order: 36,
            storesIds: "218309",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-CAMBUCI-1922",
            storeBanners: "Raia Drogasil",
            storeUf: "AL",
          },
          {
            order: 37,
            storesIds: "76044",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-CERQUEIRA CESAR-5405",
            storeBanners: "Panvel",
            storeUf: "GO",
          },
          {
            order: 38,
            storesIds: "80484",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-LAPA-3762",
            storeBanners: "Raia Drogasil",
            storeUf: "RS",
          },
          {
            order: 39,
            storesIds: "9730",
            storeNames:
              "RAIA DROGASIL-SP-BARUERI-ALPHAVILLE CENTRO INDUSTRIAL E EMPRESARIAL/ALPHAVI-8923",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 40,
            storesIds: "98093",
            storeNames: "RAIA DROGASIL-SP-BARRETOS-CENTRO-3006",
            storeBanners: "Panvel",
            storeUf: "AL",
          },
          {
            order: 41,
            storesIds: "15566",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-VILA GOMES CARDIM-5803",
            storeBanners: "Raia Drogasil",
            storeUf: "PR",
          },
          {
            order: 42,
            storesIds: "43550",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-BELA VISTA-5215",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 43,
            storesIds: "43552",
            storeNames:
              "RAIA DROGASIL-SP-CAMPOS DO JORDAO-VILA ABERNESSIA-6451",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 44,
            storesIds: "43555",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-BUTANTA-5110",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 45,
            storesIds: "126930",
            storeNames: "RAIA DROGASIL-SP-SUZANO-CENTRO-5016",
            storeBanners: "Raia Drogasil",
            storeUf: "MG",
          },
          {
            order: 46,
            storesIds: "128927",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-CAMBUCI-1922",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 47,
            storesIds: "139043",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-CERQUEIRA CESAR-5405",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 48,
            storesIds: "149983",
            storeNames: "RAIA DROGASIL-SP-SAO PAULO-LAPA-3762",
            storeBanners: "Panvel",
            storeUf: "SP",
          },
          {
            order: 49,
            storesIds: "136834",
            storeNames:
              "RAIA DROGASIL-SP-BARUERI-ALPHAVILLE CENTRO INDUSTRIAL E EMPRESARIAL/ALPHAVI-8923",
            storeBanners: "Raia Drogasil",
            storeUf: "SP",
          },
          {
            order: 50,
            storesIds: "140034",
            storeNames: "RAIA DROGASIL-SP-BARRETOS-CENTRO-3006",
            storeBanners: "Raia Drogasil",
            storeUf: "PR",
          },
        ],
        activeMsl: {
          total: 0,
          values: [
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "0 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
            "1 / 7",
          ],
        },
        mslMtd: {
          text: [
            "13,7%",
            "31,6%",
            "20,0%",
            "39,8%",
            "44,0%",
            "61,5%",
            "31,7%",
            "34,4%",
            "25,8%",
            "33,5%",
            "32,2%",
            "42,5%",
            "78,5%",
            "62,8%",
            "19,5%",
            "56,7%",
            "67,6%",
            "65,4%",
            "11,0%",
            "29,9%",
            "66,2%",
            "32,6%",
            "24,0%",
            "23,0%",
            "35,5%",
            "1,8%",
            "70,2%",
            "56,8%",
            "20,3%",
            "60,1%",
            "51,2%",
            "8,9%",
            "8,8%",
            "18,6%",
            "47,2%",
            "88,0%",
            "56,1%",
            "84,2%",
            "33,4%",
            "7,0%",
            "54,0%",
            "92,8%",
            "44,0%",
            "25,6%",
            "7,3%",
            "6,6%",
            "27,7%",
            "83,2%",
            "41,0%",
            "16,9%",
          ],
          total: 2430.1,
          x: [
            13.7, 31.6, 20.0, 39.8, 44.0, 61.5, 31.7, 34.4, 25.8, 33.5, 32.2,
            42.5, 78.5, 62.8, 19.5, 56.7, 67.6, 65.4, 11.0, 29.9, 66.2, 32.6,
            24.0, 23.0, 35.5, 1.8, 70.2, 56.8, 20.3, 60.1, 51.2, 8.9, 8.8, 18.6,
            47.2, 88.0, 56.1, 84.2, 33.4, 7.0, 54.0, 92.8, 44.0, 25.6, 7.3, 6.6,
            27.7, 83.2, 41.0, 16.9,
          ],
          y: [
            13.7, 31.6, 20.0, 39.8, 44.001, 61.5, 31.7, 34.4, 25.8, 33.5, 32.2,
            42.5, 78.5, 62.8, 19.5, 56.7, 67.6, 65.4, 11.0, 29.9, 66.2, 32.6,
            24.0, 23.0, 35.5, 1.8, 70.2, 56.8, 20.3, 60.1, 51.2, 8.9, 8.8, 18.6,
            47.2, 88.0, 56.1, 84.2, 33.4, 7.0, 54.0, 92.8, 44.0, 25.6, 7.3, 6.6,
            27.7, 83.2, 41.0, 16.9,
          ],
        },
        mslLp: {
          text: [
            "56,3%",
            "70,4%",
            "83,5%",
            "57,2%",
            "29,4%",
            "40,5%",
            "1,4%",
            "66,9%",
            "60,7%",
            "15,2%",
            "94,9%",
            "31,2%",
            "32,6%",
            "37,4%",
            "35,3%",
            "8,1%",
            "89,1%",
            "95,7%",
            "97,1%",
            "54,8%",
            "3,6%",
            "4,1%",
            "37,0%",
            "95,4%",
            "64,8%",
            "81,8%",
            "7,1%",
            "16,5%",
            "9,9%",
            "28,6%",
            "0,2%",
            "64,4%",
            "35,3%",
            "59,1%",
            "43,2%",
            "53,4%",
            "74,5%",
            "64,6%",
            "22,6%",
            "63,0%",
            "80,6%",
            "79,2%",
            "56,2%",
            "30,0%",
            "9,4%",
            "18,9%",
            "57,8%",
            "5,3%",
            "55,7%",
            "19,1%",
          ],
          total: 28.3,
          x: [
            56.3, 70.4, 83.5, 57.2, 29.4, 40.5, 1.4, 66.9, 60.7, 15.2, 94.9,
            31.2, 32.6, 37.4, 35.3, 8.1, 89.1, 95.7, 97.1, 54.8, 3.6, 4.1, 37.0,
            95.4, 64.8, 81.8, 7.1, 16.5, 9.9, 28.6, 0.2, 64.4, 35.3, 59.1, 43.2,
            53.4, 74.5, 64.6, 22.6, 63.0, 80.6, 79.2, 56.2, 30.0, 9.4, 18.9,
            57.8, 5.3, 55.7, 19.1,
          ],
          y: [
            56.3, 70.4, 83.5, 57.2, 29.4, 40.5, 1.4, 66.9, 60.7, 15.2, 94.9,
            31.2, 32.6, 37.4, 35.301, 8.1, 89.1, 95.7, 97.1, 54.8, 3.6, 4.1,
            37.0, 95.4, 64.8, 81.8, 7.1, 16.5, 9.9, 28.6, 0.2, 64.4, 35.3, 59.1,
            43.2, 53.4, 74.5, 64.6, 22.6, 63.0, 80.6, 79.2, 56.2, 30.0, 9.4,
            18.9, 57.8, 5.3, 55.7, 19.1,
          ],
        },
        mslThreeMonth: {
          text: [
            "22,2%",
            "11,5%",
            "75,2%",
            "3,2%",
            "89,6%",
            "14,6%",
            "6,9%",
            "0,8%",
            "45,6%",
            "20,2%",
            "75,1%",
            "77,2%",
            "54,8%",
            "28,2%",
            "84,0%",
            "23,1%",
            "34,1%",
            "22,1%",
            "70,9%",
            "62,3%",
            "82,0%",
            "8,7%",
            "97,6%",
            "79,9%",
            "17,3%",
            "27,3%",
            "1,2%",
            "80,0%",
            "28,5%",
            "62,4%",
            "53,4%",
            "93,2%",
            "98,5%",
            "96,5%",
            "76,3%",
            "57,5%",
            "68,9%",
            "93,1%",
            "95,4%",
            "12,0%",
            "54,0%",
            "3,9%",
            "5,9%",
            "72,6%",
            "86,8%",
            "9,7%",
            "95,7%",
            "99,0%",
            "16,6%",
            "27,5%",
          ],
          total: 6.0,
          x: [
            22.2, 11.5, 75.2, 3.2, 89.6, 14.6, 6.9, 0.8, 45.6, 20.2, 75.1, 77.2,
            54.8, 28.2, 84.0, 23.1, 34.1, 22.1, 70.9, 62.3, 82.0, 8.7, 97.6,
            79.9, 17.3, 27.3, 1.2, 80.0, 28.5, 62.4, 53.4, 93.2, 98.5, 96.5,
            76.3, 57.5, 68.9, 93.1, 95.4, 12.0, 54.0, 3.9, 5.9, 72.6, 86.8, 9.7,
            95.7, 99.0, 16.6, 27.5,
          ],
          y: [
            22.2, 11.5, 75.2, 3.2, 89.6, 14.6, 6.9, 0.8, 45.6, 20.2, 75.1, 77.2,
            54.8, 28.2, 84.0, 23.1, 34.1, 22.1, 70.9, 62.3, 82.0, 8.7, 97.6,
            79.9, 17.3, 27.3, 1.2, 80.0, 28.5, 62.4, 53.4, 93.2, 98.5, 96.5,
            76.3, 57.5, 68.9, 93.1, 95.4, 12.0, 54.0, 3.9, 5.9, 72.6, 86.8, 9.7,
            95.7, 99.0, 16.6, 27.5,
          ],
        },
        variationLp: {
          total: -78.8,
          text: [
            "34%",
            "59%",
            "8%",
            "54%",
            "-60%",
            "26%",
            "-5%",
            "66%",
            "15%",
            "-5%",
            "20%",
            "-46%",
            "-22%",
            "9%",
            "-49%",
            "-15%",
            "55%",
            "74%",
            "26%",
            "-8%",
            "-78%",
            "-5%",
            "-61%",
            "16%",
            "47%",
            "55%",
            "6%",
            "-64%",
            "-19%",
            "-34%",
            "-53%",
            "-29%",
            "-63%",
            "-37%",
            "-33%",
            "-4%",
            "6%",
            "-29%",
            "-73%",
            "51%",
            "27%",
            "75%",
            "50%",
            "-43%",
            "-77%",
            "9%",
            "-38%",
            "-94%",
            "39%",
            "-8%",
          ],
          x: [
            34, 59, 8, 54, -60, 26, -5, 66, 15, -5, 20, -46, -22, 9, -49, -15,
            55, 74, 26, -8, -78, -5, -61, 16, 47, 55, 6, -64, -19, -34, -53,
            -29, -63, -37, -33, -4, 6, -29, -73, 51, 27, 75, 50, -43, -77, 9,
            -38, -94, 39, -8,
          ],
          y: [
            34.001, 59, 8.001, 54, -60, 26.001, -5.001, 66, 15, -5.002, 20, -46,
            -22, 9.001, -49, -15, 55.001, 74, 26, -8.001, -78, -5, -61, 16, 47,
            55, 6.001, -64, -19, -34, -53, -29.001, -63, -37, -33, -4, 6, -29,
            -73, 51, 27, 75, 50, -43, -77, 9, -38, -94, 39, -8,
          ],
        },
        opp: {
          total: -22.3,
          values: [
            "-28,6 pp",
            "-42,9 pp",
            "-14,3 pp",
            "-14,3 pp",
            "-28,6 pp",
            "-28,6 pp",
            "-14,3 pp",
            "-14,3 pp",
            "-14,3 pp",
            "-14,3 pp",
            "-14,3 pp",
            "-14,3 pp",
            "-28,6 pp",
            "-28,6 pp",
            "-71,4 pp",
            "-28,6 pp",
            "-14,3 pp",
            "-28,6 pp",
            "-42,9 pp",
            "-42,9 pp",
            "-14,3 pp",
            "-14,3 pp",
            "-14,3 pp",
            "-42,9 pp",
            "-57,1 pp",
            "-42,9 pp",
            "-28,6 pp",
            "-28,6 pp",
            "-71,4 pp",
            "-57,1 pp",
            "-14,3 pp",
            "0,0 pp",
            "0,0 pp",
            "-28,6 pp",
            "0,0 pp",
            "-28,6 pp",
            "-14,3 pp",
            "0,0 pp",
            "0,0 pp",
            "-28,6 pp",
            "-14,3 pp",
            "0,0 pp",
            "-42,9 pp",
            "0,0 pp",
            "-14,3 pp",
            "0,0 pp",
            "0,0 pp",
            "-14,3 pp",
            "0,0 pp",
            "-14,3 pp",
          ],
        },
      },
      headers: {
        totalStore: "412",
        storeLessGap: "0",
        storeWithGap: "412",
        performance: "0,0%",
        activeMsl: "-",
        mslMtd: "100,0%",
        mslLp: "65,8%",
        mslThreeMonth: "57,1%",
        variationLp: "-13,1%",
        opp: "-8,6 pp",
      },
    },
    isLoading: false,
    error: false,
  };

  const setTemporalFilter = (filterSelected: filter) => {
    setFilterKPI(filterSelected);
  };

  return (
    <WrapperContent>
      {/* <Toast
        {...{ showToast, setShowToast }}
        width="400px"
        margin="25% auto 0 auto"
        toastType={toastType}
        text={toastText}
        timeOnScreen={toastTimeOnScreen}
      /> */}
      {/* <SpinnerLoader show={SBannerStore.loading} /> */}

      <BSMainConteiner>
        <BSSubMenu ref={refSubMenu}>
          <ItemAtivo
            icon={selectABrand}
            noMargin
            active={false}
            width="150px"
          />
          <Divisor />
          <BSLegendTable minWidth={widthContent}>
            <SubMenuItem
              text="Total de Lojas"
              bold
              value={detailStore.data.headers.totalStore}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Lojas s/ GAP"
              value={detailStore.data.headers.storeLessGap}
              icon={More}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Lojas c/ GAP"
              value={detailStore.data.headers.storeWithGap}
              icon={Less}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
            <SubMenuItem
              text="Performance"
              bold
              noMargin
              value={detailStore.data.headers.performance}
              font="24px"
              marginP
              titleBoxAlign="center"
              subTitleBold
            />
          </BSLegendTable>
        </BSSubMenu>
        <BSConteinerData>
          <BSHeader>
            <div className="header-msl-products">
              <img src={store} alt="icon-page" />
              <h1>
                Detalhamento do MSL%TOP 50 Lojas Ofensoras | Bandeira
                {` ${
                  SFilters.promoFilter.banner.selectedFilterName || "Todas"
                } `}
                | Região
                {` ${SFilters.promoFilter.uf.selectedFilterName || "Todas"}`}
              </h1>
            </div>
          </BSHeader>
          <BSCard>
            {/* <BSBoxSelection>
              <img src={product} alt="logo-store" />
              <span>Exibir</span>
              <Form.Control
                as="select"
                className="selectForm"
                onChange={handleLimitItem}
              >
                <option>25</option>
                <option selected>50</option>
                <option>100</option>
              </Form.Control>
              <span>Produtos</span>
            </BSBoxSelection> */}
            <FormDetail>
              <ConteinerSelectProduct
                width={
                  refDivContent.current
                    ? `${refDivContent.current.clientWidth - 10}px`
                    : ""
                }
              >
                <img src={product} alt="icon-product" />
                <Form as="select" className="selectForm">
                  <option>Todos</option>
                </Form>
              </ConteinerSelectProduct>
              <TemporalFilter
                itens={[
                  { id: ELineFilter.TOP, label: "TOP 50" },
                  { id: ELineFilter.ESG, label: "ESG" },
                  { id: ELineFilter.CLN_PROMO, label: "CLN PROMO" },
                ]}
                setTemporalFilter={setTemporalFilter}
                temporalFilter={filterKPI}
              />
            </FormDetail>
            <TableContainer>
              <Flex bg="#E7E6E6" fontWeight="bold" p="10px" mx="5px">
                <Text fontSize="14px" color="#C00000">
                  Top 50 Lojas OFENSORAS no MSL
                </Text>
              </Flex>
              <Flex>
                <Table
                  style={{ borderCollapse: "separate" }}
                  ref={refDivContent}
                  minW="610px"
                >
                  <Thead>
                    <Tr>
                      {headers.map((headerText) => {
                        return (
                          <HeaderTable
                            isTextCenter={false}
                            bg="#fff"
                            color="black"
                          >
                            {headerText}
                          </HeaderTable>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="1" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="1234567891011"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="RAIA DROGASIL LOJA 001"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="RAIA"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="SP"
                      />
                    </Tr>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="2" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="1234567891011"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="RAIA DROGASIL LOJA 001"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="RAIA"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="SP"
                      />
                    </Tr>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="3" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="1234567891011"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="RAIA DROGASIL LOJA 001"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="RAIA"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="SP"
                      />
                    </Tr>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="4" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="1234567891011"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="RAIA DROGASIL LOJA 001"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="RAIA"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="SP"
                      />
                    </Tr>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="5" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="1234567891011"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="RAIA DROGASIL LOJA 001"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="RAIA"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="SP"
                      />
                    </Tr>
                    <Tr>
                      <Column border="none" isTextCenter={false} value="6" />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="1234567891011"
                      />
                      <Column
                        border="none"
                        isTextCenter={false}
                        value="RAIA DROGASIL LOJA 001"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="RAIA"
                      />
                      <Column
                        border="none"
                        isBold
                        isTextCenter={false}
                        value="SP"
                      />
                    </Tr>
                  </Tbody>
                </Table>
                <Stack mt="2px" minW="90px" mx="5px">
                  <Flex
                    fontSize="12px"
                    fontWeight="bold"
                    h="30px"
                    mb="4px"
                    align="center"
                    justifyContent="center"
                  >
                    <Text># Skus Ativos</Text>
                  </Flex>
                  {[
                    "350 / 500",
                    "351 / 500",
                    "352 / 500",
                    "353 / 500",
                    "354 / 500",
                    "355 / 500",
                  ].map((item, index) => {
                    return (
                      <Flex
                        bg="#A6A6A6"
                        h="30px"
                        fontSize="11px"
                        mt="0px !important"
                        mb={(index !== 5 && "3px !important") || "0 !important"}
                        align="center"
                        justifyContent="center"
                        fontWeight="bold"
                        color="white"
                      >
                        {item}
                      </Flex>
                    );
                  })}
                </Stack>
                <Table
                  style={{ borderCollapse: "separate" }}
                  borderRight="1px solid #E7E6E6"
                  pr="10px"
                >
                  <Thead>
                    <Tr>
                      <HeaderTable color="black" bg="#E7E6E6">
                        Ult. 3 M
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        Ult. 3 Mês
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        Atual
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        Ult. 3M %
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        Ult.Mês %
                      </HeaderTable>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                      <Column isBold bg="#E7E6E6" value="0,0%" />
                    </Tr>
                  </Tbody>
                </Table>
                <Table style={{ borderCollapse: "separate" }} pl="10px">
                  <Thead>
                    <Tr>
                      <HeaderTable color="black" bg="#E7E6E6">
                        SEM 22
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        SEM 23
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        SEM 24
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        SEM 25
                      </HeaderTable>
                      <HeaderTable color="black" bg="#E7E6E6">
                        SEM 26
                      </HeaderTable>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Column bg="#E7E6E6" isBold value="0,0%" />
                      <Column bg="#E7E6E6" isBold value="0,0%" />
                      <Column bg="#E7E6E6" isBold value="0,0%" />
                      <Column bg="#E7E6E6" isBold value="0,0%" />
                      <Column bg="#E7E6E6" isBold value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                    </Tr>
                    <Tr>
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                      <Column value="0,0%" />
                    </Tr>
                  </Tbody>
                </Table>
              </Flex>
            </TableContainer>
          </BSCard>
        </BSConteinerData>
      </BSMainConteiner>
    </WrapperContent>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDayDetailStores);
