import { Reducer } from 'redux';
import { stateLogout, ALogout } from './types';

const INITIAL_STATE: stateLogout = {
  isLoading: false,
  error: false,
};

const reducerProfile: Reducer<stateLogout> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ALogout.REQUEST_LOGOUT:
      return { ...state, isLoading: true };
    case ALogout.LOGOUT_SUCCESS:
      return { ...state, isLoading: false, error: false };
    case ALogout.LOGOUT_ERROR:
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
};

export default reducerProfile;
