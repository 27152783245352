import { Reducer } from "redux";
import {
  stateType,
  menuTypes,
  INavPage,
  INavContent,
  INavMainMenu,
  ENavSubMenu,
} from "./types";

const INITIAL_STATE: stateType = {
  menuItem: {
    index: INavMainMenu.MY_ONE_PAGE,
    name: "One Page",
  },
  indexSubMenu: ENavSubMenu.ONE_PAGE,
  filters: [],
  mainPage: INavPage.LOGIN_PAGE,
  contentDash: INavContent.MAIN_DASH_CONTENT,
};

const reducerMenu: Reducer<stateType> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case menuTypes.CHANGE_MENU:
      return {
        ...state,
        menuItem: action.payload,
        indexSubMenu: ENavSubMenu.ONE_PAGE,
      };
    case menuTypes.CHANGE_TITLE_MENU:
      return {
        ...state,
        menuItem: { index: state.menuItem.index, name: action.payload },
      };
    case menuTypes.CHANGE_SUBMENU:
      return { ...state, indexSubMenu: action.payload };
    case menuTypes.CHANGE_FILTERS:
      return { ...state, filters: action.payload };
    case menuTypes.CHANGE_PAGE:
      return { ...state, mainPage: action.payload };
    case menuTypes.CHANGE_CONTENT:
      return { ...state, contentDash: action.payload };
    default:
      return state;
  }
};

export default reducerMenu;
