import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useCallback, useContext, useEffect, useState } from "react";
import { Flex, Select } from "@chakra-ui/react";
import { IoFunnelOutline } from "react-icons/io5";
import { GlobalProps } from "../../../../store";
import {
  IStateFilters,
  TPromoFilter,
  IProductFilter,
  INITIAL_FILTERS,
} from "../../../../store/SideFilters/types";

import { stateMarketShare } from "../../../../store/SideFilters/RequestFilters/types";
import * as AFilters from "../../../../store/SideFilters/actions";
import * as ANavigation from "../../../../store/Navigation/actions";

// Graphics
import MarketSharePeriod from "../../../../components/Graphics/MarketSharePeriodLtyYtd/MarketSharePeriodLtyYtd";
import MarketSharePeriodBarLtyYtd from "../../../../components/Graphics/MarketSharePeriodBarLtyYtd/MarketSharePeriodBarLtyYtd";
import BarChartPosNegHorizontal from "../../../../components/Graphics/BarChartPositiveNegativeHorizontal";

// Images
import magnifyingImg from "../../../../assets/img/icons/magnifying_glass_black.png";

// Styles
import { Card, Content, Container, CardBody } from "./styles";
import {
  InputFilters,
  Legend,
  Multimput,
  SeeDatail,
  WrapperGraphics,
  Main,
  FirstGroup,
  SecondGroup,
} from "../../../../components/Contents/Resources/Cards/container.element";
import { Header } from "../../../../components/Contents/Resources/Cards/Cards.element";

// functions
import {
  filterBanner,
  filterBrand,
  filterCategory,
  filterRegion,
  filterUF,
} from "../../../../components/SideFilterMenu/Resource";

// components
import { addVoidValueGraphic } from "../../../../components/Graphics/Resource/GraphicsFunctions";
import { TableEvolutivo } from "./TableEvolutivo";
import { theme } from "../../../../styles/theme";
import FiltersList from "../../../../components/SideFilterMenu/Resource/FiltersList";

import { filterComponent } from "../../../../store/Navigation/types";
import LegendIcon, { ETypeLegend } from "../../../../components/LegendIcon";
import { ConfigContext } from "../../../../services/Context/ConfigContext";

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
  };

  DNavigator: {
    changeFilters(filters: filterComponent[]): void;
  };
}

type props = IStateProps & IDispatchProps;

const MSL = ({
  SAllFilters,
  SFilters,
  DFilters,
  DNavigator,
}: // setContent,
props): JSX.Element => {
  const [toggle, setToggle] = useState<string>("1");
  const [filterSection, setFilterSection] = useState<string>("category");

  const { config } = useContext(ConfigContext);

  function handleSelectedCard(id: string, section: string) {
    setToggle(id);
    setFilterSection(section);
  }

  // Render filter
  const renderFilter = useCallback(() => {
    if (config.showSideFilters.myExecution)
      DNavigator.changeFilters(
        FiltersList(SAllFilters, SFilters, {
          ...INITIAL_FILTERS,
          typeExecution: false,
          period: false,
        })
      );
  }, [SAllFilters, SFilters, DNavigator]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter]);

  return (
    <Container>
      <Content margin="0 0 5px 0" h="390px">
        <Card w="61%" margin="0 5px 0 0">
          <Header>
            <h1>
              Evolução do ROI% <i>(Mensal)</i>
            </h1>
          </Header>
          <WrapperGraphics height="calc(100% - 50px)">
            <Main>
              <FirstGroup>
                <MarketSharePeriod
                  dataGraphic={{
                    label: ["63,2%", "63,2%"],
                    y: [63.2, 63.1],
                  }}
                  ml={12}
                  mr={12}
                />
                <MarketSharePeriodBarLtyYtd
                  dataGraphic={{
                    label: ["36,8%", "36,8%"],
                    x: ["YTD'20", "YTD'21"],
                    y: [36.8, 36.7],
                  }}
                />
              </FirstGroup>
              <SecondGroup>
                <Flex justifyContent="end">
                  <MarketSharePeriod
                    dataGraphic={{
                      label: ["52,45%", "57,0%", "74,8%", "52,4%"],
                      y: [52.4, 57, 74.8, 52.3],
                    }}
                    mr={8}
                    ml={8}
                  />
                </Flex>
                <MarketSharePeriodBarLtyYtd
                  dataGraphic={{
                    label: [
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "47,6%",
                      "43,0%",
                      "25,2%",
                      "47,6%",
                    ],
                    x: [
                      "P5'20",
                      "P6'20",
                      "P7'20",
                      "P8'20",
                      "P9'20",
                      "P10'20",
                      "P11'20",
                      "P12'20",
                      "P1'21",
                      "P2'21",
                      "P3'21",
                      "P4'21",
                      "WTD<br>(16)",
                    ],
                    y: [0, 0, 0, 0, 0, 0, 0, 0, 0, 47.6, 43, 25.2, 47.6],
                  }}
                  bgColor={theme.graphics.light}
                />
              </SecondGroup>
            </Main>
            <Legend>
              <LegendIcon typeLegend={ETypeLegend.bar}>Variação %</LegendIcon>
              <LegendIcon ml="10px" typeLegend={ETypeLegend.period}>
                MSL %
              </LegendIcon>
            </Legend>
          </WrapperGraphics>
        </Card>
        <Card w="590px" minW="calc(40% - 15px)">
          <Header>
            <h1>
              ROI Alavancas <i>(YTD)</i>
            </h1>
          </Header>
          <CardBody h="calc(100% - 50px)">
            <InputFilters>
              <Multimput right>
                <IoFunnelOutline />
                <Select
                  as="select"
                  className="selectForm"
                  onChange={(e) =>
                    filterRegion(
                      e.target.value,
                      SFilters,
                      SAllFilters,
                      DFilters
                    )
                  }
                >
                  <option value="">Todas</option>
                  {SAllFilters.data.marketShare.subviewCategory.main?.region.map(
                    (region) => (
                      <option
                        value={region.id}
                        selected={
                          SFilters.promoFilter.region.selectedFilterName ===
                          region.label
                        }
                        key={Math.random()}
                      >
                        {region.label}
                      </option>
                    )
                  )}
                </Select>
                <Select
                  as="select"
                  className="selectForm"
                  onChange={(e) =>
                    filterUF(e.target.value, SFilters, SAllFilters, DFilters)
                  }
                >
                  <option value="0">Todos</option>
                  {SFilters.promoFilter.uf.listFilters?.map((uf) => (
                    <option
                      value={uf.id}
                      selected={
                        SFilters.promoFilter.uf.selectedFilterName === uf.label
                      }
                      key={Math.random()}
                    >
                      {uf.label}
                    </option>
                  ))}
                </Select>
              </Multimput>
            </InputFilters>
            <WrapperGraphics height="calc(100% - 50px)">
              <BarChartPosNegHorizontal
                dataGraphic={{
                  label: ["70", "25", "-20", "35", "30", "-45", "60"],
                  x: [
                    "Ponto<br>Gondola",
                    "Display",
                    "MPDV",
                    "Promotor",
                    "Campanhas",
                    "Checkout",
                    "Rebaixa",
                  ],
                  y: [70, 25, -20, 35, 30, -45, 60],
                }}
              />
            </WrapperGraphics>
          </CardBody>
        </Card>
      </Content>

      <Content h="300px" margin="0 0 5px 0">
        <Card w="20%" margin="0">
          <Header>
            <h1>
              ROI% Marcas <i>(Mensal)</i>
            </h1>
          </Header>
          <CardBody
            h="calc(100% - 50px)"
            border={toggle === "1"}
            onClick={() => handleSelectedCard("1", "category")}
            id="id1"
          >
            <InputFilters right padding={toggle === "1" ? "0 8px 0 0" : "0"}>
              <SeeDatail width="140px" img={magnifyingImg}>
                <i>{toggle === "1" ? "Fechar Evolutivo" : "Abrir Evolutivo"}</i>
              </SeeDatail>
            </InputFilters>

            <WrapperGraphics>
              <MarketSharePeriodBarLtyYtd
                dataGraphic={addVoidValueGraphic(5, {
                  label: ["58,0%", "62,0%", "38,0%", "94,0%"],
                  x: ["Vitaminas", "Hig.&.Beleza", "Nutrição", "OTC"],
                  y: [58, 62, 38, 94],
                })}
                autosize
                bgColor={
                  toggle === "1" ? theme.graphics.dark : theme.graphics.light
                }
                b={toggle === "1" ? 30 : 36}
              />
            </WrapperGraphics>
          </CardBody>
        </Card>
        <Card w="20%" margin="0 5px">
          <Header>
            <h1>
              ROI% Regionais <i>(Mensal)</i>
            </h1>
          </Header>
          <CardBody
            h="calc(100% - 50px)"
            border={toggle === "2"}
            onClick={() => handleSelectedCard("2", "region")}
            id="id2"
          >
            <InputFilters right padding="0">
              <SeeDatail width="140px" img={magnifyingImg}>
                <i>{toggle === "2" ? "Fechar Evolutivo" : "Abrir Evolutivo"}</i>
              </SeeDatail>
            </InputFilters>

            <WrapperGraphics>
              <MarketSharePeriodBarLtyYtd
                dataGraphic={addVoidValueGraphic(5, {
                  label: ["58,0%", "62,0%", "38,0%", "94,0%", "89,0%"],
                  x: ["Centro Oeste", "Nordeste", "Norte", "Sudeste", "Sul"],
                  y: [58, 62, 38, 94, 89],
                })}
                autosize
                bgColor={
                  toggle === "2" ? theme.graphics.dark : theme.graphics.light
                }
                b={toggle === "2" ? 30 : 36}
              />
            </WrapperGraphics>
          </CardBody>
        </Card>
        <Card w="20%" margin="0 5px 0 0">
          <Header>
            <h1>
              ROI% SubCanais <i>(Mensal)</i>
            </h1>
          </Header>
          <CardBody
            h="calc(100% - 50px)"
            border={toggle === "3"}
            onClick={() => handleSelectedCard("3", "subchannel")}
            id="id3"
          >
            <InputFilters right padding="0">
              <SeeDatail width="140px" img={magnifyingImg}>
                <i>{toggle === "3" ? "Fechar Evolutivo" : "Abrir Evolutivo"}</i>
              </SeeDatail>
            </InputFilters>

            <WrapperGraphics>
              <MarketSharePeriodBarLtyYtd
                dataGraphic={addVoidValueGraphic(5, {
                  label: ["58,0%", "62,0%", "38,0%", "94,0%", "89,0%"],
                  x: [
                    "Grandes redes",
                    "Pequenas redes",
                    "Médias redes",
                    "Independentes",
                    "Associativistas",
                  ],
                  y: [58, 62, 38, 94, 89],
                })}
                autosize
                bgColor={
                  toggle === "3" ? theme.graphics.dark : theme.graphics.light
                }
                b={toggle === "3" ? 30 : 36}
              />
            </WrapperGraphics>
          </CardBody>
        </Card>
        <Card w="590px" minW="calc(40% - 15px)">
          <Header>
            <h1>
              ROI% Top 10 Bandeiras <i>(Mensal)</i>
            </h1>
          </Header>
          <CardBody
            h="calc(100% - 50px)"
            border={toggle === "4"}
            onClick={() => handleSelectedCard("4", "brand")}
            id="id4"
          >
            <InputFilters right padding="0">
              <SeeDatail width="140px" img={magnifyingImg}>
                <i>{toggle === "4" ? "Fechar Evolutivo" : "Abrir Evolutivo"}</i>
              </SeeDatail>
            </InputFilters>

            <WrapperGraphics>
              <MarketSharePeriodBarLtyYtd
                dataGraphic={addVoidValueGraphic(10, {
                  label: [
                    "57,0%",
                    "43,4%",
                    "50,5%",
                    "65,5%",
                    "52,6%",
                    "19,3%",
                    "75,3%",
                    "53,7%",
                    "61,6%",
                    "68,8%",
                  ],
                  x: [
                    "DSP",
                    "Drogaria Venancio",
                    "Drogasil",
                    "Indiano",
                    "Pague Menos",
                    "Carrefour",
                    "Farmacia Lider",
                    "Raia",
                    "Ultra popular",
                    "Coop",
                  ],
                  y: [
                    57.8, 43.4, 50.5, 65.5, 52.6, 19.3, 75.3, 53.7, 61.8, 68.8,
                  ],
                })}
                autosize
                bgColor={
                  toggle === "4" ? theme.graphics.dark : theme.graphics.light
                }
                b={toggle === "4" ? 30 : 36}
              />
            </WrapperGraphics>
          </CardBody>
        </Card>
      </Content>

      <Content h="auto" toggle={toggle === "0"}>
        <Card w="100%" h="auto">
          <CardBody margin="0">
            {toggle === "1" && filterSection === "category" && (
              <InputFilters>
                <Multimput>
                  <IoFunnelOutline />
                  <Select
                    as="select"
                    className="selectForm"
                    onChange={(e) =>
                      filterCategory(
                        e.target.value,
                        SFilters,
                        SAllFilters,
                        DFilters,
                        false
                      )
                    }
                  >
                    <option value="-1">Todas</option>;
                    {filterSection === "category" &&
                      SAllFilters.data.myExecution?.subviewOSA.secondary.categories.map(
                        (itemFilter) => {
                          return (
                            <option
                              value={itemFilter.id}
                              selected={
                                SFilters.promoFilter.category
                                  .selectedFilterName === itemFilter.label
                              }
                              key={Math.random()}
                            >
                              {itemFilter.label}
                            </option>
                          );
                        }
                      )}
                  </Select>
                  <Select
                    as="select"
                    className="selectForm"
                    onChange={(e) =>
                      filterBrand(
                        e.target.value,
                        SFilters,
                        SAllFilters,
                        DFilters,
                        false
                      )
                    }
                  >
                    <option value="0">Todas</option>
                    {filterSection === "category" &&
                      SFilters.promoFilter.brand.listFilters?.map((brand) => {
                        return (
                          <option
                            value={brand.id}
                            selected={
                              SFilters.promoFilter.brand.selectedFilterName ===
                              brand.label
                            }
                            key={Math.random()}
                          >
                            {brand.label}
                          </option>
                        );
                      })}
                  </Select>
                </Multimput>
              </InputFilters>
            )}

            {toggle === "2" && filterSection === "region" && (
              <InputFilters>
                <Multimput>
                  <IoFunnelOutline />
                  <Select
                    as="select"
                    className="selectForm"
                    onChange={(e) =>
                      filterRegion(
                        e.target.value,
                        SFilters,
                        SAllFilters,
                        DFilters
                      )
                    }
                  >
                    <option value="-1">Todas</option>;
                    {filterSection === "region" &&
                      SAllFilters.data.marketShare?.subviewCategory.main.region.map(
                        (region) => {
                          return (
                            <option
                              selected={
                                SFilters.promoFilter.region
                                  .selectedFilterName === region.label
                              }
                              value={region.id}
                              key={Math.random()}
                            >
                              {region.label}
                            </option>
                          );
                        }
                      )}
                  </Select>
                  <Select
                    as="select"
                    className="selectForm"
                    onChange={(e) =>
                      filterUF(e.target.value, SFilters, SAllFilters, DFilters)
                    }
                  >
                    <option value="0">Todas</option>
                    {filterSection === "region" &&
                      SFilters.promoFilter.uf.listFilters?.map((uf) => {
                        return (
                          <option
                            value={uf.id}
                            selected={
                              SFilters.promoFilter.uf.selectedFilterName ===
                              uf.label
                            }
                            key={Math.random()}
                          >
                            {uf.label}
                          </option>
                        );
                      })}
                  </Select>
                </Multimput>
              </InputFilters>
            )}

            {toggle === "3" && filterSection === "subchannel" && (
              <InputFilters />
            )}

            {toggle === "4" && filterSection === "brand" && (
              <InputFilters>
                <Multimput>
                  <IoFunnelOutline />
                  <Select
                    as="select"
                    className="selectForm"
                    onChange={(e) =>
                      filterBanner(
                        e.target.value,
                        SFilters,
                        SAllFilters,
                        DFilters
                      )
                    }
                  >
                    <option value="-1">Todas</option>;
                    {filterSection === "brand" &&
                      SAllFilters.data.marketShare?.subviewCategory.main.subChannel.map(
                        (itemFilter) => {
                          return (
                            <option
                              selected={
                                SFilters.promoFilter.banner
                                  .selectedFilterName === itemFilter.label
                              }
                              key={Math.random()}
                              value={itemFilter.id}
                            >
                              {itemFilter.label}
                            </option>
                          );
                        }
                      )}
                  </Select>
                </Multimput>
              </InputFilters>
            )}

            <TableEvolutivo filterSection={filterSection} />
          </CardBody>
        </Card>
      </Content>
    </Container>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  INavigator: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DFilters: bindActionCreators(AFilters, dispatch),
    DNavigator: bindActionCreators(ANavigation, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MSL);
