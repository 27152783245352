import {
  Box,
  Flex,
  Image,
  Stack,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import Column from "../../../../../../../components/Table/Column";
import HeaderTable from "../../../../../../../components/Table/Header";
import success from "../../../../../../../assets/myExecution/DetailStore/checkmark-circle.svg";
import error from "../../../../../../../assets/myExecution/DetailStore/close-circle.svg";
import { ITypeGraphicHorizontalBar } from "../../../../../../../services/Hooks/OnePage/onePageTypes";
import MarketShareValue from "../../../../../../../components/Graphics/MarketShareValue/MarketShareValue";

export interface IContentColumn {
  header: {
    title: string;
    image: string;
  };
  subHeader: string[];
  content: string[][];
  growth?: ITypeGraphicHorizontalBar;
}

interface IOwnProps {
  data: IContentColumn;
}

const ColumnInfoYellowPharma: React.FC<IOwnProps> = ({
  data,
}: IOwnProps): JSX.Element => {
  return (
    <Stack minW="270px" mr="5px" w="100%">
      <Box
        position="sticky"
        bg="white"
        zIndex={9}
        pt="25px"
        top="-5px"
        h="60px"
      >
        <Flex bg="#E7E6E6">
          <Box position="relative" w="60px" mt="-23px" pl="5px">
            <Image position="absolute" src={data.header.image} w="120px" />
          </Box>
          <Text color="black" fontSize="14px" fontWeight="bold" p="10px">
            {data.header.title}
          </Text>
        </Flex>
      </Box>
      <Table marginTop="13px !important">
        <Thead position="sticky" top="60px" bg="white" zIndex={99}>
          <Tr>
            {data.subHeader.map((value) => (
              <HeaderTable bg="transparent">{value}</HeaderTable>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.content.map((row, rindex) => (
            <Tr>
              {row.map(
                (column, index) =>
                  ((column === "true" || column === "false") && (
                    <Column
                      isTextCenter
                      bg={index === 0 ? "#A6A6A6" : "white"}
                      color={index === 0 ? "white" : "black"}
                      isBold
                    >
                      <Image
                        m="auto"
                        w="18px"
                        src={column === "true" ? success : error}
                      />
                    </Column>
                  )) || (
                    <Column
                      isTextCenter
                      bg={index === 0 ? "#A6A6A6" : "white"}
                      color={index === 0 ? "white" : "black"}
                      isBold
                      value={column}
                    />
                  )
              )}
              {data.growth && rindex === 0 && (
                <Column rs={data.growth.x.length}>
                  <Box mt="-5px !important" h="100%">
                    <MarketShareValue
                      dataGraphic={data.growth}
                      color="#FFE699"
                      height={data.growth.label.length * 31.01}
                    />
                  </Box>
                </Column>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  );
};

export default ColumnInfoYellowPharma;
