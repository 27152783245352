import { Box, Flex, Grid, Select, Stack, Text } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { IPSPontuationTemporalLine } from "../../../../pages/Private/MyPerfectStore/types";
import { ConfigContext } from "../../../../services/Context/ConfigContext";
import {
  filter,
  stateMarketShare,
} from "../../../../store/SideFilters/RequestFilters/types";
import Card from "../../../Card";
import { RenderList } from "../../../Contents/Resources/SubView/subView";
import HeaderGraphic from "../../../Graphics/HeaderGraphic";
import MarketShareValue from "../../../Graphics/MarketShareValue/MarketShareValue";
import WaterfallSubChannel from "../../../Graphics/Waterfall";
import SeeDetailButton from "../../../SeeDetailButton";
import imageKPI from "../../../../assets/MyPerfectStore/kpi.svg";
import TableWithGraphic from "../../../TableWithGraphic";
import TemporalFilter from "../../../TemporalFilter";
import {
  DetailPerfectStoreData,
  perfectStoreAbsolut,
  PSDetailsTable,
} from "../../data2";
import SubDetailPerfectStore from "../SubDetailPerfectStore";
import { GlobalProps } from "../../../../store";

interface IStateProps {
  SAllFilters: stateMarketShare;
}

const DetailPerfectStore: React.FC<IStateProps> = ({
  SAllFilters,
}: IStateProps): JSX.Element => {
  const [temporalLine, setTemporalLine] = useState<filter>({
    id: IPSPontuationTemporalLine.YTD,
    label: "YTD",
  });

  const [selectedFilter, setSelectedFilter] = useState<number>(-1);
  const [showDetailPS, setShowDetailPS] = useState<boolean>(false);
  const [selectedSubChannelFilter, setSelectedSubChannelFilter] =
    useState<string>("");

  const { config } = useContext(ConfigContext);

  return (
    <>
      <Card mb="5px !important" title="Detalhamento Loja Perfeita">
        <Flex w="100%">
          <Stack w="100%" p="5px">
            <Flex align="center">
              <TemporalFilter
                itens={[
                  { id: IPSPontuationTemporalLine.YTD, label: "YTD" },
                  { id: IPSPontuationTemporalLine.L3M, label: "L3M" },
                  { id: IPSPontuationTemporalLine.MES, label: "MES" },
                ]}
                setTemporalFilter={setTemporalLine}
                temporalFilter={temporalLine}
              />

              <Select
                ml="5px"
                p="0"
                borderRadius="5px"
                as="select"
                className="selectForm"
                borderColor="black"
                h="28px"
                fontSize="12px"
                color="black"
                w="100px"
                onChange={(e) => {
                  const period = parseInt(e.target.value, 10);
                  setSelectedFilter(period);
                }}
              >
                <option value="-1">Todas</option>
                {perfectStoreAbsolut.rightChartBar.x.map((label, index) => (
                  <option
                    selected={index === selectedFilter}
                    value={index}
                    key={Math.random()}
                  >
                    {label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Grid
              templateColumns="repeat(4, 1fr)"
              columnGap={2}
              w="100%"
              color="black"
              px="5%"
            >
              <Stack>
                <Text
                  fontStyle="italic"
                  px="5px"
                  fontSize="16px"
                  whiteSpace="nowrap"
                  mb="7px"
                >
                  % Contribuição por KPIs (pp)
                </Text>
                <Box mt="0 !important">
                  <WaterfallSubChannel
                    dataGraphic={DetailPerfectStoreData.waterfall}
                  />
                </Box>
              </Stack>
              <Stack>
                <Text
                  px="5px"
                  fontSize="16px"
                  whiteSpace="nowrap"
                  textAlign="center"
                  fontStyle="italic"
                  mb="10px"
                >
                  Aproveitamento
                </Text>
                <HeaderGraphic fs="16px" mb="4px !important">
                  77 / 100
                </HeaderGraphic>
                <RenderList
                  background="#AFABAB"
                  fontSize={config.fonts.graphics}
                  fontColor="white"
                  margin="0"
                  padding="0.3"
                  data={DetailPerfectStoreData.realized}
                />
              </Stack>
              <Stack>
                <Text
                  px="5px"
                  fontSize="16px"
                  whiteSpace="nowrap"
                  textAlign="center"
                  fontStyle="italic"
                  mb="10px"
                >
                  % Aproveitamento
                </Text>
                <HeaderGraphic fs="16px">{"77%" || "-----"}</HeaderGraphic>
                <Box mt="-1px !important">
                  <MarketShareValue
                    dataGraphic={DetailPerfectStoreData.storeUniverse}
                  />
                </Box>
              </Stack>
              <Stack>
                <Text
                  px="5px"
                  whiteSpace="nowrap"
                  fontSize="16px"
                  textAlign="center"
                  fontStyle="italic"
                  mb="10px"
                >
                  % Var. vs Periodo
                </Text>
                <HeaderGraphic fs="16px">{"4,7%" || "-----"}</HeaderGraphic>
                <Box mt="-1px !important">
                  <MarketShareValue
                    dataGraphic={DetailPerfectStoreData.varPeriod}
                  />
                </Box>
              </Stack>
            </Grid>
          </Stack>
          <Stack
            position="relative"
            w="100%"
            p="5px"
            _before={
              (showDetailPS && {
                content: `""`,
                position: "absolute",
                width: "100%",
                height: "100%",
                borderWidth: "4px",
                borderColor: "selectedColor",
                zIndex: "5",
                left: "0",
                top: "0",
              }) ||
              {}
            }
          >
            <Flex
              align="center"
              justifyContent="space-between"
              pr="5px"
              zIndex={99}
            >
              <Select
                ml="5px"
                p="0"
                borderRadius="5px"
                as="select"
                className="selectForm"
                borderColor="black"
                h="28px"
                fontSize="12px"
                color="black"
                w="150px"
                onChange={(e) => {
                  const period = e.target.value;
                  setSelectedSubChannelFilter(period);
                }}
              >
                <option value="-1">Todas</option>
                {SAllFilters.data.marketShare.subviewCategory.main.subChannel.map(
                  (item, index) => (
                    <option
                      selected={item.id === selectedSubChannelFilter}
                      value={item.id}
                      key={Math.random()}
                    >
                      {item.label}
                    </option>
                  )
                )}
              </Select>
              <SeeDetailButton
                label={`${showDetailPS ? "Fechar" : "Ver"} Detalhe`}
                onAction={() => setShowDetailPS(!showDetailPS)}
              />
            </Flex>
            <Flex pt="18px">
              <TableWithGraphic
                isLoading={false}
                tableData={PSDetailsTable}
                image={imageKPI}
              />
            </Flex>
          </Stack>
        </Flex>
      </Card>
      <SubDetailPerfectStore show={showDetailPS} />
    </>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  SAllFilters: state.marketShareCategory,
});

export default connect(mapStateToProps)(DetailPerfectStore);
