import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useContext, useState } from "react";
import {
  Box,
  Text,
  Image,
  Flex,
  Grid,
  Table,
  Thead,
  Tbody,
  TableContainer,
  Tr,
  Stack,
} from "@chakra-ui/react";

// Components
import HeaderGraphic from "../../../../../components/Graphics/HeaderGraphic";
import HeaderTable from "../../../../../components/Table/Header";
import Column from "../../../../../components/Table/Column";
import {
  Legend,
  WrapperGraphics,
} from "../../../../../components/Contents/Resources/Cards/container.element";

// Global Props
import { GlobalProps } from "../../../../../store";

// Types
import { IStateFilters } from "../../../../../store/SideFilters/types";
import { stateMarketShare } from "../../../../../store/SideFilters/RequestFilters/types";
import { INavContent } from "../../../../../store/Navigation/types";

// Actions
import * as AFilters from "../../../../../store/SideFilters/actions";

// Graphics
import MarketSharePeriod from "../../../../../components/Graphics/MarketSharePeriodLtyYtd/MarketSharePeriodLtyYtd";
import MarketSharePeriodBarLtyYtd from "../../../../../components/Graphics/MarketSharePeriodBarLtyYtd/MarketSharePeriodBarLtyYtd";
import MarketShareValue from "../../../../../components/Graphics/MarketShareValue/MarketShareValue";

// Images
import Store from "../../../../../assets/Menu/store.png";
import cycle from "../../../../../assets/myExecution/cycle.svg";
import Price from "../../../../../assets/img/icons/promo_02.png";
import arrow from "../../../../../assets/img/icons/arrowLegend.svg";
import logo1 from "../../../../../assets/Industria/logo.png";
import paredao from "../../../../../assets/Laverage/Paredao.png";
import displayChao from "../../../../../assets/Laverage/DisplayChao.png";

import { addVoidValueGraphic } from "../../../../../components/Graphics/Resource/GraphicsFunctions";

// configuration platform
import TableComplements, {
  IDataFormat,
} from "../../../../../components/TableComplements";
import CardSubMenu, { EPeriod } from "../../../../../components/CardSubMenu";
import { Container } from "../../../MyExecution/NPD/main/styles";
import SeeDetailButton from "../../../../../components/SeeDetailButton";
import Card from "../../../../../components/Card";
import LegendIcon, { ETypeLegend } from "../../../../../components/LegendIcon";
import { ConfigContext } from "../../../../../services/Context/ConfigContext";

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

enum ESelectedFirstLine {
  SELLOUT = "main/SELLOUT",
  STORE = "main/STORE",
  PRICE = "main/PRICE",
}

enum EToggle {
  PRESENCE = 0,
  REGION = 1,
  SUB_CHANNEL = 2,
  BANNER = 3,
}

interface ISelectedCards {
  selectPeriod: EPeriod;
  selectedCard: ESelectedFirstLine;
  selectedPresence: EToggle;
}

const Rocket = ({ SAllFilters, SFilters }: IStateProps): JSX.Element => {
  const [selections, setSelections] = useState<ISelectedCards>({
    selectPeriod: EPeriod.MONTHLY,
    selectedCard: ESelectedFirstLine.SELLOUT,
    selectedPresence: EToggle.PRESENCE,
  });

  const { config } = useContext(ConfigContext);

  const handleSelectedPeriod = (periodSelected: EPeriod) => {
    setSelections({ ...selections, selectPeriod: periodSelected });
  };

  const mockSellout: IDataFormat = {
    contentPrimary: {
      title: "SubCategoria",
      image: logo1,
      headers: [
        "Segmento",
        "YTD'21",
        "YTD'22",
        "Var pp",
        "U3M",
        "LP",
        "Proj P3",
        "Var U3M",
        "Var LP",
        "P12'21",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: `${config.general.industryName}`,
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Termometro",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Cardio",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Eq. Med-Hosp",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Respiratoria",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
    contentSecundary: [
      {
        title: "Exposição PONTA EXTRA",
        image: displayChao,
        headers: [
          "Segmento",
          "YTD'21",
          "YTD'22",
          "Var pp",
          "U3M",
          "LP",
          "Proj P3",
          "Var U3M",
          "Var LP",
          "P12'21",
          "JAN'22",
          "FEV'22",
          "MAR'22",
          "ABR'22",
          "MAI'22",
          "JUN'22",
          "JUL'22",
          "AGO'22",
          "SET'22",
          "OUT'22",
          "NOV'22",
          "P12'22",
        ],
        content: [
          {
            category: `${config.general.industryName}`,
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca A",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca B",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca C",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Others",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
        ],
      },
      {
        title: "Exposição PLANOGRAMA",
        image: paredao,
        headers: [
          "Segmento",
          "YTD'21",
          "YTD'22",
          "Var pp",
          "U3M",
          "LP",
          "Proj P3",
          "Var U3M",
          "Var LP",
          "P12'21",
          "JAN'22",
          "FEV'22",
          "MAR'22",
          "ABR'22",
          "MAI'22",
          "JUN'22",
          "JUL'22",
          "AGO'22",
          "SET'22",
          "OUT'22",
          "NOV'22",
          "P12'22",
        ],
        content: [
          {
            category: `${config.general.industryName}`,
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca A",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca B",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca C",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Others",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
        ],
      },
    ],
  };

  const mockMonth: IDataFormat = {
    contentPrimary: {
      title: "SubCategoria",
      image: logo1,
      headers: [
        "Segmento",
        "YTD'21",
        "YTD'22",
        "Var pp",
        "U3M",
        "LP",
        "Proj P3",
        "Var U3M",
        "Var LP",
        "P12'21",
        "JAN'22",
        "FEV'22",
        "MAR'22",
        "ABR'22",
        "MAI'22",
        "JUN'22",
        "JUL'22",
        "AGO'22",
        "SET'22",
        "OUT'22",
        "NOV'22",
        "P12'22",
      ],
      content: [
        {
          category: `${config.general.industryName}`,
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Termometro",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Cardio",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Eq. Med-Hosp",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Respiratoria",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
    contentSecundary: [
      {
        title: "Exposição PONTA EXTRA",
        image: displayChao,
        headers: [
          "Segmento",
          "YTD'21",
          "YTD'22",
          "Var pp",
          "U3M",
          "LP",
          "Proj P3",
          "Var U3M",
          "Var LP",
          "P12'21",
          "JAN'22",
          "FEV'22",
          "MAR'22",
          "ABR'22",
          "MAI'22",
          "JUN'22",
          "JUL'22",
          "AGO'22",
          "SET'22",
          "OUT'22",
          "NOV'22",
          "P12'22",
        ],
        content: [
          {
            category: `${config.general.industryName}`,
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca A",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca B",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca C",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Others",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
        ],
      },
      {
        title: "Exposição PLANOGRAMA",
        image: paredao,
        headers: [
          "Segmento",
          "YTD'21",
          "YTD'22",
          "Var pp",
          "U3M",
          "LP",
          "Proj P3",
          "Var U3M",
          "Var LP",
          "P12'21",
          "JAN'22",
          "FEV'22",
          "MAR'22",
          "ABR'22",
          "MAI'22",
          "JUN'22",
          "JUL'22",
          "AGO'22",
          "SET'22",
          "OUT'22",
          "NOV'22",
          "P12'22",
        ],
        content: [
          {
            category: `${config.general.industryName}`,
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca A",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca B",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Marca C",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Others",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
        ],
      },
    ],
  };

  const topStore = [
    {
      store: "DROGARIA GLOBO-RN-NATAL-CAPIM MACIO-3376",
      banner: "Drogaria Globo",
      rupture: "13 / 13",
    },
    {
      store: "DROGARIA VENANCIO-RJ-RIO DE JANEIRO-CONSUCESSO-4935",
      banner: "Drogaria Venancio",
      rupture: "13 / 18",
    },
    {
      store: "CARREFOUR-RJ-DUQUE DE CAXIAS-JARDIM GRAMACHO-7531",
      banner: "Carrefour",
      rupture: "13 / 14",
    },
    {
      store: "PACHECO-RJ-RIO DE JANEIRO CATETE-8708",
      banner: "Pacheco",
      rupture: "12 / 16",
    },
    {
      store: "ULTRAFARMA-SP-SAO PAULO-MIRANDOPOLIS-0690",
      banner: "Ultrafarma",
      rupture: "12 / 12",
    },
    {
      store: "DROGARIA SANTO REMEDIO-AM-MANAUS-CIDADE NOVA-4305",
      banner: "Drogaria Santo",
      rupture: "12 / 14",
    },
    {
      store: "DROGARIA-BA-LAURO DE FREITAS-CENTRO-4798",
      banner: "Drogasil",
      rupture: "12 / 14",
    },
    {
      store: "CARREFOUR-SP-SAO BERNARDO DO CAMPO-CENTRO-1100",
      banner: "Carrefour",
      rupture: "12 / 13",
    },
    {
      store: "DROGARIA VENANCIO-RJ-RIO DE JANEIRO-BARRA DA TIJUCA-0271",
      banner: "Drogaria Venancio",
      rupture: "11 / 17",
    },
    {
      store: "FARMACIA ESTRELA-PR-CASCAVEL-CENTRO-0310",
      banner: "Farmacia Estrela",
      rupture: "11 / 15",
    },
  ];

  const topProduct = [
    {
      product: "OTC",
      brand: "Sym",
      segment: "219 / 312",
    },
    {
      product: "Hig. & Beleza",
      brand: "Personal",
      segment: "148 / 228",
    },
    {
      product: "Hig. & Beleza",
      brand: "Personal",
      segment: "147 / 346",
    },
    {
      product: "Fralda",
      brand: "Snob",
      segment: "120 / 291",
    },
    {
      product: "Nutrição",
      brand: "Kiss",
      segment: "147 / 346",
    },
    {
      product: "Fralda",
      brand: "Snob",
      segment: "139 / 376",
    },
    {
      product: "Fralda",
      brand: "Snob",
      segment: "134 / 309",
    },
    {
      product: "Fralda",
      brand: "Snob",
      segment: "127 / 285",
    },

    {
      product: "Fralda",
      brand: "Snob",
      segment: "104 / 176",
    },
    {
      product: "Nutrição",
      brand: "Kiss",
      segment: "187 / 350",
    },
  ];

  return (
    <Container>
      <Flex mb="5px">
        <CardSubMenu
          headers={[
            {
              title: "Sell Out",
              complements: "(Ultimas 7 semanas)",
            },
            {
              title: "Giro p/ Loja",
              complements: "(Ultimas 7 Semanas)",
            },
            {
              title: "Preço Médio ",
              complements: "(Mensal)",
            },
          ]}
          subItens={[
            {
              id: EPeriod.MONTHLY,
              label: "Mensal",
            },
            {
              id: EPeriod.WEEKLY,
              label: "Semanal",
            },
          ]}
          selectedPeriod={handleSelectedPeriod}
        >
          <>
            <Stack
              bg="white"
              w="100%"
              ml="5px"
              position="relative"
              cursor="pointer"
              alignItems="center"
              onClick={() =>
                setSelections({
                  ...selections,
                  selectedCard: ESelectedFirstLine.SELLOUT,
                })
              }
              _before={
                (selections.selectedCard === ESelectedFirstLine.SELLOUT && {
                  content: `""`,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderWidth: "4px",
                  borderColor: "selectedColor",
                  zIndex: "5",
                }) ||
                {}
              }
              _after={
                (selections.selectedCard === ESelectedFirstLine.SELLOUT && {
                  content: '""',
                  w: 0,
                  h: 0,
                  borderTopWidth: "15px",
                  borderTopColor: "selectedColor",
                  borderLeft: "solid 50px transparent",
                  borderRight: "solid 50px transparent",
                  position: "absolute",
                  left: "calc(50% - 48px)",
                  bottom: "-14px",
                  zIndex: 5,
                }) ||
                {}
              }
            >
              <Grid
                templateRows="75px 1fr"
                templateColumns="1fr"
                h="calc(100% - 36px)"
                w="fit-content"
              >
                <Flex justifyContent="space-between" w="100%" h="75px" pr={3}>
                  <Image
                    src={Store}
                    alt="icon-store"
                    w="65px"
                    h="65px"
                    margin="5px 0 0 5px"
                  />
                  <SeeDetailButton
                    label="Ver detalhe"
                    goPage={INavContent.MS_BANNER_DATAIL_PRODUCT}
                  />
                </Flex>
                <Stack>
                  <MarketSharePeriod
                    dataGraphic={{
                      label: [
                        "0,0%",
                        "15,5%",
                        "12,8%",
                        "-2,4%",
                        "-48,8%",
                        "29,8%",
                        "-5,9%",
                      ],
                      y: [0, 15.5, 12.8, -2.4, -48.8, 29.8, -5.9],
                    }}
                    ml={20}
                    mr={3}
                    h={70}
                  />
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={{
                      label: ["258", "298", "336", "328", "168", "218", "205"],
                      y: [258, 298, 336, 328, 168, 218, 205],
                      x: ["M1", "M2", "M3", "M4", "M5", "M6", "M7"],
                    }}
                    bgColor="#FFE699"
                    b={20}
                    h={190}
                    l={20}
                    showSideLegend
                  />
                </Stack>
              </Grid>
              <Legend>
                <Box w="10px" h="10px" bg="#FFE699" />
                <Text
                  color="black"
                  fontSize={config.fonts.legends}
                  margin="0 15px 0 5px"
                >
                  Real (vol)
                </Text>
                <LegendIcon typeLegend={ETypeLegend.period}>
                  Perf. vs Sem. Anterior %
                </LegendIcon>
              </Legend>
            </Stack>
            <Stack
              bg="white"
              w="100%"
              ml="5px"
              position="relative"
              cursor="pointer"
              alignItems="center"
              onClick={() =>
                setSelections({
                  ...selections,
                  selectedCard: ESelectedFirstLine.STORE,
                })
              }
              _before={
                (selections.selectedCard === ESelectedFirstLine.STORE && {
                  content: `""`,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderWidth: "4px",
                  borderColor: "selectedColor",
                  zIndex: "5",
                }) ||
                {}
              }
              _after={
                (selections.selectedCard === ESelectedFirstLine.STORE && {
                  content: '""',
                  w: 0,
                  h: 0,
                  borderTopWidth: "15px",
                  borderTopColor: "selectedColor",
                  borderLeft: "solid 50px transparent",
                  borderRight: "solid 50px transparent",
                  position: "absolute",
                  left: "calc(50% - 48px)",
                  bottom: "-14px",
                  zIndex: 5,
                }) ||
                {}
              }
            >
              <Grid
                templateRows="75px 1fr"
                templateColumns="1fr"
                h="calc(100% - 36px)"
                w="fit-content"
              >
                <Flex justifyContent="space-between" w="100%" h="75px" pr={4}>
                  <Image
                    src={cycle}
                    alt="icon-store"
                    w="65px"
                    h="65px"
                    margin="5px 0 0 5px"
                  />
                  <SeeDetailButton
                    label="Ver detalhe"
                    goPage={INavContent.MS_BANNER_DATAIL_PRODUCT}
                  />
                </Flex>
                <Stack justifyContent="center">
                  <MarketSharePeriod
                    dataGraphic={{
                      label: [
                        "0,0%",
                        "1,9%",
                        "5,0%",
                        "6,4%",
                        "-31,4%",
                        "-11,1%",
                        "2,5%",
                      ],
                      y: [0, 1.9, 5, 6.4, -31.4, -11.1, 2.5],
                    }}
                    h={70}
                    ml={20}
                    mr={5}
                  />
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={addVoidValueGraphic(5, {
                      label: [
                        "0,31%",
                        "0,31%",
                        "0,33%",
                        "0,35%",
                        "0,24%",
                        "0,21%",
                        "0,22%",
                      ],
                      y: [0.31, 0.31, 0.33, 0.35, 0.24, 0.21, 0.22],
                      x: ["M1", "M2", "M3", "M4", "M5", "M6", "M7"],
                    })}
                    bgColor="#FFE699"
                    b={20}
                    h={190}
                    l={20}
                    showSideLegend
                  />
                </Stack>
              </Grid>
              <Legend>
                <Box w="10px" h="10px" bg="#FFE699" />
                <Text
                  color="black"
                  fontSize={config.fonts.legends}
                  margin="0 15px 0 5px"
                >
                  Real
                </Text>
                <LegendIcon typeLegend={ETypeLegend.period}>
                  Perf. vs Sem. Anterior %
                </LegendIcon>
              </Legend>
            </Stack>
            <Stack
              bg="white"
              w="100%"
              ml="5px"
              position="relative"
              cursor="pointer"
              onClick={() =>
                setSelections({
                  ...selections,
                  selectedCard: ESelectedFirstLine.PRICE,
                })
              }
              _before={
                (selections.selectedCard === ESelectedFirstLine.PRICE && {
                  content: `""`,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderWidth: "4px",
                  borderColor: "selectedColor",
                  zIndex: "5",
                }) ||
                {}
              }
              _after={
                (selections.selectedCard === ESelectedFirstLine.PRICE && {
                  content: '""',
                  w: 0,
                  h: 0,
                  borderTopWidth: "15px",
                  borderTopColor: "selectedColor",
                  borderLeft: "solid 50px transparent",
                  borderRight: "solid 50px transparent",
                  position: "absolute",
                  left: "calc(50% - 48px)",
                  bottom: "-14px",
                  zIndex: 5,
                }) ||
                {}
              }
            >
              <Grid
                templateRows="75px 1fr"
                templateColumns="1fr"
                h="calc(100% - 36px)"
              >
                <Flex justifyContent="space-between" w="100%" h="75px">
                  <Image
                    src={Price}
                    alt="icon-store"
                    w="65px"
                    h="65px"
                    margin="5px 0 0 5px"
                  />
                  <SeeDetailButton
                    label="Ver detalhe"
                    goPage={INavContent.MS_BANNER_DATAIL_PRODUCT}
                  />
                </Flex>
                <WrapperGraphics align="center" height="106%" overflow="none">
                  <MarketSharePeriodBarLtyYtd
                    dataGraphic={addVoidValueGraphic(5, {
                      label: [
                        "45,0%",
                        "45,0%",
                        "41,2%",
                        "0,0%",
                        "0,0%",
                        "0,0%",
                      ],
                      y: [45, 45, 41.2, 0, 0, 0],
                      x: ["M1", "M2", "M3", "M4", "M5", "M6"],
                    })}
                    bgColor="#FFE699"
                    b={34}
                    h={226}
                    target={55}
                    l={20}
                    showSideLegend
                  />
                </WrapperGraphics>
              </Grid>
              <Flex h="41px" align="center" zIndex="2" justifyContent="center">
                <Box w="10px" h="10px" bg="#FFE699" />
                <Text color="black" fontSize="12px" margin="0 15px 0 5px">
                  Aderência
                </Text>
                <Image src={arrow} width="34px" />
                <Text color="black" margin="0 15px 0 5px" fontSize="12px">
                  Target
                </Text>
              </Flex>
            </Stack>
          </>
        </CardSubMenu>
      </Flex>
      {(selections.selectedCard === ESelectedFirstLine.SELLOUT && (
        <TableComplements
          mainFilter={
            SAllFilters.data?.myExecution?.subviewOSA?.secondary.categories
          }
          secundaryFilter={SFilters.filter.brand.listFilters || []}
          titleCard="Sell Out Mensal"
          subTitleCard="(Volume Unidades)"
          data={mockSellout}
          noFilter
        />
      )) ||
        (selections.selectedCard === ESelectedFirstLine.STORE && (
          <TableComplements
            mainFilter={
              SAllFilters.data?.myExecution?.subviewOSA?.secondary.categories
            }
            secundaryFilter={SFilters.filter.brand.listFilters || []}
            titleCard="Share de Pontos Extras % "
            subTitleCard="(Mensal)"
            data={mockMonth}
            noFilter
          />
        )) ||
        (selections.selectedCard === ESelectedFirstLine.PRICE && (
          <Flex>
            <Card
              title="Preço Médio Mensal"
              subTitle="(Vendas Volume x Vendas Valor)"
            >
              <Stack w="100%">
                {/* <Flex>
                  <SeeDetailButton
                    label="Ver detalhe Produto"
                    goPage={INavContent.NPD_DETAIL_PRODUCT}
                  />
                  <Box color="black" w="100%" p="5px 7px">
                    <ListBox itens={[]} />
                  </Box>
                </Flex> */}
                <TableContainer>
                  <Flex>
                    <Table style={{ borderCollapse: "separate" }}>
                      <Thead>
                        <Tr>
                          <HeaderTable
                            color="#C00000"
                            isTextCenter={false}
                            colSpan={2}
                          >
                            ITENS
                          </HeaderTable>
                          <HeaderTable color="#C00000" colSpan={2}>
                            Banca Preços
                          </HeaderTable>
                          <HeaderTable color="#C00000" colSpan={2}>
                            Jan`22
                          </HeaderTable>
                          <HeaderTable color="#C00000" colSpan={2}>
                            Fev`22
                          </HeaderTable>
                          <HeaderTable color="#C00000" colSpan={2}>
                            Mar`22
                          </HeaderTable>
                          <HeaderTable color="#C00000" colSpan={2}>
                            Abr`22
                          </HeaderTable>
                          <HeaderTable color="#C00000" colSpan={2}>
                            Mai`22
                          </HeaderTable>
                          <HeaderTable color="#C00000" colSpan={2}>
                            Jan`22
                          </HeaderTable>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {topProduct.map((product) => {
                          return (
                            <Tr key={Math.random()}>
                              <Column
                                isTextCenter={false}
                                key={Math.random()}
                                isUppercase
                                value={product.product}
                              />
                              <Column
                                mw="250px"
                                isTextCenter={false}
                                key={Math.random()}
                                isUppercase
                                value={product.product}
                              />
                              <Column
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#AFABAB"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#E7E6E6"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#AFABAB"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#E7E6E6"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#AFABAB"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#E7E6E6"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#AFABAB"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#E7E6E6"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#AFABAB"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#E7E6E6"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#AFABAB"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                              <Column
                                bg="#E7E6E6"
                                isBold
                                key={Math.random()}
                                value={product.product}
                              />
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </Flex>
                </TableContainer>
              </Stack>
            </Card>
          </Flex>
        ))}
      {(selections.selectedCard === ESelectedFirstLine.SELLOUT ||
        selections.selectedCard === ESelectedFirstLine.STORE) && (
        <>
          <Grid templateColumns="1fr 1fr" columnGap="5px" mt="5px">
            <Card title="TOP Skus" subTitle="(Ultimas 2 Semanas)">
              <Stack w="100%">
                {/* <Flex>
                  <SeeDetailButton
                    label="Ver detalhe Produto"
                    goPage={INavContent.NPD_DETAIL_PRODUCT}
                  />
                  <Box color="black" w="100%" p="5px 7px">
                    <ListBox itens={[]} />
                  </Box>
                </Flex> */}
                <TableContainer>
                  <Flex>
                    <Table style={{ borderCollapse: "separate" }}>
                      <Thead>
                        <Tr>
                          <HeaderTable isTextCenter={false}>
                            Descrição do Produto
                          </HeaderTable>
                          <HeaderTable>Marca</HeaderTable>
                          <HeaderTable>S11</HeaderTable>
                          <HeaderTable>S12</HeaderTable>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {topProduct.map((product) => {
                          return (
                            <Tr key={Math.random()}>
                              <Column
                                mw="250px"
                                isTextCenter={false}
                                key={Math.random()}
                                isUppercase
                                value={product.product}
                              />
                              <Column
                                w="160px"
                                key={Math.random()}
                                value={product.brand}
                              />
                              <Column
                                bg="#AFABAB"
                                color="white"
                                isBold
                                key={Math.random()}
                                w="110px"
                                value={product.segment}
                              />
                              <Column
                                bg="#AFABAB"
                                color="white"
                                isBold
                                key={Math.random()}
                                w="110px"
                                value={product.segment}
                              />
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                    <Stack mt="2px">
                      <HeaderGraphic mw="170px">% Cresc.</HeaderGraphic>
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={{
                            label: [
                              "70,2%",
                              "64,9%",
                              "42,5%",
                              "36,3%",
                              "37,0%",
                              "43,4%",
                              "44,6%",
                              "41,2%",
                              "46,4%",
                              "56,8%",
                            ],
                            x: [
                              70.2, 64.9, 42.5, 36.3, 37, 43.4, 44.6, 41.2,
                              46.4, 56.8,
                            ],
                            y: [
                              "70.2",
                              "64.9",
                              "42.5",
                              "36.3",
                              "37.0",
                              "43.4",
                              "44.6",
                              "41.2",
                              "46.4",
                              "56.8",
                            ],
                          }}
                          color="#00B0F0"
                          height={332}
                        />
                      </Box>
                    </Stack>
                  </Flex>
                </TableContainer>
              </Stack>
            </Card>
            <Card title="Flop Skus" subTitle="(Ultimas 2 Semanas)">
              <Stack w="100%">
                {/* <Flex>
                  <SeeDetailButton
                    label="Ver detalhe Produto"
                    goPage={INavContent.NPD_DETAIL_PRODUCT}
                  />
                  <Box color="black" w="100%" p="5px 7px">
                    <ListBox itens={[]} />
                  </Box>
                </Flex> */}
                <TableContainer>
                  <Flex>
                    <Table style={{ borderCollapse: "separate" }}>
                      <Thead>
                        <Tr>
                          <HeaderTable isTextCenter={false}>
                            Descrição do Produto
                          </HeaderTable>
                          <HeaderTable>Bandeira</HeaderTable>
                          <HeaderTable>S11</HeaderTable>
                          <HeaderTable>S12</HeaderTable>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {topStore.map((bandeira) => {
                          return (
                            <Tr key={Math.random()}>
                              <Column
                                mw="250px"
                                isTextCenter={false}
                                key={Math.random()}
                                isUppercase
                                value={bandeira.store}
                              />
                              <Column
                                w="160px"
                                key={Math.random()}
                                value={bandeira.banner}
                              />
                              <Column
                                bg="#AFABAB"
                                color="white"
                                isBold
                                key={Math.random()}
                                w="110px"
                                value={bandeira.rupture}
                              />
                              <Column
                                bg="#AFABAB"
                                color="white"
                                isBold
                                key={Math.random()}
                                w="110px"
                                value={bandeira.rupture}
                              />
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                    <Stack mt="2px">
                      <HeaderGraphic mw="170px">% Cresc.</HeaderGraphic>
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={{
                            label: [
                              "100,0%",
                              "72,2%",
                              "92,9%",
                              "75,0%",
                              "100,0%",
                              "85,7%",
                              "85,7%",
                              "92,3%",
                              "64,7%",
                              "73,3%",
                            ],
                            x: [
                              100, 72.2, 92.9, 75, 100.1, 85.71, 85.7, 92.3,
                              64.7, 73.3,
                            ],
                            y: [
                              "100.0",
                              "72.2",
                              "92.9",
                              "75.0",
                              "100.0",
                              "85.7",
                              "85.7",
                              "92.3",
                              "64.7",
                              "73.3",
                            ],
                          }}
                          color="#00B0F0"
                          height={332}
                        />
                      </Box>
                    </Stack>
                  </Flex>
                </TableContainer>
              </Stack>
            </Card>
          </Grid>
          <Grid templateColumns="1fr 1fr" columnGap="5px" mt="5px">
            <Card title="TOP 10 Lojas" subTitle="(Ultimas 2 Semanas)">
              <Stack w="100%">
                {/* <Flex>
                  <SeeDetailButton
                    label="Ver detalhe Produto"
                    goPage={INavContent.NPD_DETAIL_PRODUCT}
                  />
                  <Box color="black" w="100%" p="5px 7px">
                    <ListBox itens={[]} />
                  </Box>
                </Flex> */}
                <TableContainer>
                  <Flex>
                    <Table style={{ borderCollapse: "separate" }}>
                      <Thead>
                        <Tr>
                          <HeaderTable isTextCenter={false}>
                            Descrição da Loja
                          </HeaderTable>
                          <HeaderTable>Marca</HeaderTable>
                          <HeaderTable>S11</HeaderTable>
                          <HeaderTable>S12</HeaderTable>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {topProduct.map((product) => {
                          return (
                            <Tr key={Math.random()}>
                              <Column
                                mw="250px"
                                isTextCenter={false}
                                key={Math.random()}
                                isUppercase
                                value={product.product}
                              />
                              <Column
                                w="160px"
                                key={Math.random()}
                                value={product.brand}
                              />
                              <Column
                                bg="#AFABAB"
                                color="white"
                                isBold
                                key={Math.random()}
                                w="110px"
                                value={product.segment}
                              />
                              <Column
                                bg="#AFABAB"
                                color="white"
                                isBold
                                key={Math.random()}
                                w="110px"
                                value={product.segment}
                              />
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                    <Stack mt="2px">
                      <HeaderGraphic mw="170px">% Cresc.</HeaderGraphic>
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={{
                            label: [
                              "70,2%",
                              "64,9%",
                              "42,5%",
                              "36,3%",
                              "37,0%",
                              "43,4%",
                              "44,6%",
                              "41,2%",
                              "46,4%",
                              "56,8%",
                            ],
                            x: [
                              70.2, 64.9, 42.5, 36.3, 37, 43.4, 44.6, 41.2,
                              46.4, 56.8,
                            ],
                            y: [
                              "70.2",
                              "64.9",
                              "42.5",
                              "36.3",
                              "37.0",
                              "43.4",
                              "44.6",
                              "41.2",
                              "46.4",
                              "56.8",
                            ],
                          }}
                          color="#00B0F0"
                          height={332}
                        />
                      </Box>
                    </Stack>
                  </Flex>
                </TableContainer>
              </Stack>
            </Card>
            <Card title="FLOP 10 Lojas" subTitle="(Ultimas 2 Semanas)">
              <Stack w="100%">
                {/* <Flex>
                  <SeeDetailButton
                    label="Ver detalhe Produto"
                    goPage={INavContent.NPD_DETAIL_PRODUCT}
                  />
                  <Box color="black" w="100%" p="5px 7px">
                    <ListBox itens={[]} />
                  </Box>
                </Flex> */}
                <TableContainer>
                  <Flex>
                    <Table style={{ borderCollapse: "separate" }}>
                      <Thead>
                        <Tr>
                          <HeaderTable isTextCenter={false}>
                            Descrição da Loja
                          </HeaderTable>
                          <HeaderTable>Bandeira</HeaderTable>
                          <HeaderTable>S11</HeaderTable>
                          <HeaderTable>S12</HeaderTable>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {topStore.map((bandeira) => {
                          return (
                            <Tr key={Math.random()}>
                              <Column
                                mw="250px"
                                isTextCenter={false}
                                key={Math.random()}
                                isUppercase
                                value={bandeira.store}
                              />
                              <Column
                                w="160px"
                                key={Math.random()}
                                value={bandeira.banner}
                              />
                              <Column
                                bg="#AFABAB"
                                color="white"
                                isBold
                                key={Math.random()}
                                w="110px"
                                value={bandeira.rupture}
                              />
                              <Column
                                bg="#AFABAB"
                                color="white"
                                isBold
                                key={Math.random()}
                                w="110px"
                                value={bandeira.rupture}
                              />
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                    <Stack mt="2px">
                      <HeaderGraphic mw="170px">% Cresc.</HeaderGraphic>
                      <Box mt="-1px !important">
                        <MarketShareValue
                          dataGraphic={{
                            label: [
                              "100,0%",
                              "72,2%",
                              "92,9%",
                              "75,0%",
                              "100,0%",
                              "85,7%",
                              "85,7%",
                              "92,3%",
                              "64,7%",
                              "73,3%",
                            ],
                            x: [
                              100, 72.2, 92.9, 75, 100.1, 85.71, 85.7, 92.3,
                              64.7, 73.3,
                            ],
                            y: [
                              "100.0",
                              "72.2",
                              "92.9",
                              "75.0",
                              "100.0",
                              "85.7",
                              "85.7",
                              "92.3",
                              "64.7",
                              "73.3",
                            ],
                          }}
                          color="#00B0F0"
                          height={332}
                        />
                      </Box>
                    </Stack>
                  </Flex>
                </TableContainer>
              </Stack>
            </Card>
          </Grid>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DFilters: bindActionCreators(AFilters, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rocket);
