/* eslint-disable camelcase */
import axios from "axios";
import { useQuery, UseQueryResult } from "react-query";
import api from "../../api";
import {
  IFormatReturn,
  IParameters,
  IParamsExtractData,
  IReturnExtractData,
  baseUrl,
} from "./types";

const requestExportData = async ({
  industryId,
  filters,
  kpisSelected,
}: IParamsExtractData): Promise<IReturnExtractData> => {
  const urlExtractData: IReturnExtractData = {
    urlCompliance: "",
    urlExtraExposure: "",
    urlRupture: "",
    urlShareOfShelf: "",
    urlPerfectStore: "",
    urlAdoptStore: "",
  };

  const parameters: IParameters = {
    industry_id: industryId || null,
    initial_execution_date: filters.promoFilter.dateInitial || null,
    end_execution_date: filters.promoFilter.dateFinal || null,
    businessUnit: filters.promoFilter.businessUnitId.selectedFilterName || null,
    category: filters.promoFilter.category.selectedFilterName || null,
    subCategory: filters.promoFilter.brand.selectedFilterName || null,
    subChannel: filters.promoFilter.subChannel.selectedFilterName || null,
    banner: filters.promoFilter.banner.selectedFilterName || null,
    region: filters.promoFilter.region.selectedFilterName || null,
    uf: filters.promoFilter.uf.selectedFilterName || null,
    executionType: filters.promoFilter.executionType.selectedFilterName || null,
    executionLeverage:
      filters.promoFilter.executionLeverage.selectedFilterName || null,
    cnpj: filters.promoFilter.storeCNPJ || null,
  };

  if (kpisSelected.extraExposure) {
    const extraExposure = await axios.get<IFormatReturn>(
      `${baseUrl}export_extra_exposure/`,
      {
        params: parameters,
      }
    );

    urlExtractData.urlExtraExposure = extraExposure.data.file;
  }

  if (kpisSelected.shareOfShelf) {
    const shareOfShelf = await axios.get<IFormatReturn>(
      `${baseUrl}export_share_of_shelf/`,
      {
        params: parameters,
      }
    );

    urlExtractData.urlShareOfShelf = shareOfShelf.data.file;
  }

  if (kpisSelected.compliance) {
    const compliance = await axios.get<IFormatReturn>(
      `${baseUrl}export_compliance/`,
      {
        params: parameters,
      }
    );

    urlExtractData.urlCompliance = compliance.data.file;
  }

  if (kpisSelected.rupture) {
    const rupture = await axios.get<IFormatReturn>(
      `${baseUrl}export_rupture/`,
      {
        params: parameters,
      }
    );

    urlExtractData.urlRupture = rupture.data.file;
  }

  if (kpisSelected.perfectStore) {
    const perfectStore = await axios.get<IFormatReturn>(
      `${baseUrl}export_perfect_store/`,
      {
        params: parameters,
      }
    );

    urlExtractData.urlPerfectStore = perfectStore.data.file;
  }

  if (kpisSelected.adoptStore) {
    delete parameters.industry_id;
    const adoptStore = await api.get<IFormatReturn>(`export_adopt_a_store/`, {
      params: parameters,
    });

    urlExtractData.urlAdoptStore = adoptStore.data.file;
  }

  return urlExtractData;
};

export const useExportData = (
  data: IParamsExtractData
): UseQueryResult<IReturnExtractData, unknown> => {
  return useQuery(["exportData"], () => requestExportData(data), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};
