import styled from "styled-components";
import { theme } from "../../../../styles/theme";

const titleHeaderColor = "#a144ac";

const gray70 = "#7A7A7A";
const blue90 = "#a144ac";
interface PropsBookCardContainer {
  width?: string;
  rows?: string;
  columns?: string;
  rowGap?: string;
  margin?: string;
  flex?: string;
  background?: string;
  height?: string;
}

interface PropsBookCardContent {
  margin?: string;
  width?: string;
}

interface PropsBookCardContentTitle {
  margin?: string;
}

interface PropsRowContainer {
  height: string;
  wrap: string;
  margin?: string;
}

interface PropsBookCardContainerImage {
  width: string;
  height: string;
  position?: string;
  top?: string;
  margin?: string;
  grid?: boolean;
  rowGap?: string;
  marginTop?: string;
}

interface PropsBookCardContentImage {
  width: string;
  height: string;
  shadown?: string;
  margin?: string;
}

export const RowContainer = styled.div<PropsRowContainer>`
  display: flex;
  flex-wrap: ${(props) => props.wrap};
  width: 99.7%;
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
`;

export const BookCardContainer = styled.div<PropsBookCardContainer>`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  background: ${(props) => props.background || "none"};

  display: ${(props) => props.flex || "grid"};
  grid-template-columns: ${(props) => props.columns};
  grid-row-gap: ${(props) => props.rowGap || "0"};

  margin: ${(props) => props.margin || "0 auto"};

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const BookCardContent = styled.div<PropsBookCardContent>`
  width: ${(props) => props.width || "100%"};
  height: 100%;
  margin: ${(props) => props.margin};

  background: var(--white);
  color: var(--base-text);
`;

export const BookCardContentTitle = styled.div<PropsBookCardContentTitle>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};

  h1 {
    color: ${titleHeaderColor};
    margin-left: 5px;
    margin-right: 5px;
  }

  img {
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }

  div {
    color: ${gray70};

    p {
      margin-bottom: 0;
    }
  }
`;

export const BookCardContainerImage = styled.div<PropsBookCardContainerImage>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  margin-right: 35px;
  margin-top: -50px;

  @media only screen and (max-width: 1000px) {
    margin: 0 0 0 auto;
  }

  ${(props) =>
    props.grid &&
    `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
  `}

  .try {
    display: block;
    text-align: center;
    width: 150px;

    p {
      margin-bottom: -2.5rem;
      color: #7f7f7f;
      font-weight: bold;
    }
  }
`;

export const BookCardContentImage = styled.img<PropsBookCardContentImage>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  object-fit: cover;
  box-shadow: ${(props) => props.shadown || "none"};
  margin: ${(props) => props.margin || "0"};

  .zoom {
    position: absolute;
    top: 10px;
  }
`;

export const BookCardContentBody = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const BookCardSubHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: var(--white);
  color: var(--base-text);
  height: 55px;
  align-items: center;
  text-align: center;
  width: 100%;

  & > div > p {
    margin: 0;
  }

  button {
    background: ${blue90};
    color: var(--white);
    width: 120px;
    height: 30px;
    border-radius: 5px;
    border: none;
    padding: 1.275rem 1rem;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > :last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    margin-right: 10px;
    img {
      width: 40px;
      height: 40px;
    }

    img:hover {
      cursor: pointer;
    }
  }
`;

export const PaginatorL = styled.div`
  width: 100%;
  height: 100%;
  /* min-height: 418px; */
  position: relative;
  background: white;
  margin-top: 5px;
  display: flex;
  align-items: center;

  transition: filter 0.2s;

  .blockL {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  svg {
    width: 50px;
    height: 50px;
    font-weight: bold;
    position: relative;
    color: ${theme.colors.arrow};
  }

  :hover {
    filter: brightness(0.8);
    cursor: pointer;
    .blockL {
      cursor: not-allowed;
      pointer-events: all !important;
    }
  }
`;
export const PaginatorR = styled.div`
  width: 100%;
  height: 100%;

  /* padding-top: 31%;
  padding-bottom: 31%; */

  position: relative;
  height: 100%;
  margin-top: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  background: #fff;

  transition: filter 0.2s;

  /* @media only screen and (min-height: 720px) {
    height: 65vh;
  } */

  svg {
    width: 50px;
    height: 50px;
    position: relative;
    color: ${theme.colors.arrow};
  }

  :hover {
    filter: brightness(0.8);
    cursor: pointer;
  }
`;

export const ExecutionMessage = styled.p`
  color: ${gray70};
  font-size: 16px;
  font-weight: bold;
  margin: 0 auto;
`;
