import { Reducer } from "redux";
import { stateMarketShare, typeCategory } from "./types";

export const INITIAL_STATE: stateMarketShare = {
  data: {
    commercial_structure: {
      director: [],
      manager: [],
      coordinator: [],
      cutomer_sales_rep: [],
    },
    marketShare: {
      subviewCategory: {
        main: {
          region: [],
          subChannel: [],
        },
        secondary: {
          banner: [],
          uf: [],
          retail_enviroment: [],
        },
      },
    },
    myExecution: {
      subviewOSA: {
        main: {
          business_unit: [],
          business_unit_promo: [],
          subChannel: [],
          executions_type: [],
          region: [],
          itens: [],
        },

        secondary: {
          execution_leverage: [],
          categories: [],
          categories_promo: [],
          brands: [],
          brands_promo: [],
          segments: [],
          segments_promo: [],
        },
      },
    },
  },
  loading: true,
  status: 0,
  error: false,
};

const reducerCategory: Reducer<stateMarketShare> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case typeCategory.LOAD_REQUEST:
      return { ...state, loading: true };
    case typeCategory.LOAD_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case typeCategory.LOAD_FAILURE:
      return { ...state, error: true, loading: false, data: [] };
    default:
      return state;
  }
};

export default reducerCategory;
