import { Box, Flex, Grid, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import LoadingComponent from "../LoadingComponent";

export enum EPeriod {
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export enum ESellOutFilter {
  VALUE = "value",
  QUANTITY = "amount",
}

interface ISubItens {
  id: string;
  label: string;
}

export interface IHeadersCard {
  title: string;
  complements?: string;
}

interface IOwnProps {
  headers: IHeadersCard[];
  subItens: ISubItens[];
  children: JSX.Element;
  minWHeader?: string;
  selectedPeriod(filterSelected: string): void;
}

const CardSubMenu: React.FC<IOwnProps> = ({
  headers,
  children,
  subItens,
  minWHeader,
  selectedPeriod,
}: IOwnProps): JSX.Element => {
  const [period, setPeriod] = useState<string>(
    subItens.length > 0 ? subItens[0].id : ""
  );

  return (
    <Grid
      templateColumns={`repeat(${headers.length}, 1fr)`}
      gridColumnGap="5px"
      width="100%"
    >
      {headers.map((header) => (
        <Flex
          h="45px"
          bg="cardHeaderBackgroundColor"
          align="center"
          justify="center"
          color="white"
          minW="600px"
        >
          <Text fontSize="20px" fontWeight="bold" whiteSpace="nowrap">
            {header.title}
          </Text>
        </Flex>
      ))}
      {subItens.length > 0 && (
        <Flex gridColumn="1 / -1" background="white" my="5px !important" p={2}>
          <Flex
            p="5px"
            fontWeight="bold"
            color="black"
            bg="#E7E6E6"
            borderRadius="5px"
          >
            {subItens.map((item, index) => {
              return (
                <Text
                  borderRadius="5px"
                  p="4px 15px"
                  ml={(index !== 0 && "3px") || "0"}
                  key={Math.random()}
                  bg={(period === item.id && "white") || ""}
                  cursor="pointer"
                  minW="100px"
                  textAlign="center"
                  onClick={() => {
                    selectedPeriod(item.id);
                    setPeriod(item.id);
                  }}
                >
                  {item.label}
                </Text>
              );
            })}
          </Flex>
        </Flex>
      )}
      {children}
    </Grid>
  );
};

CardSubMenu.defaultProps = {
  minWHeader: "",
};

export default CardSubMenu;
