import { Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";

interface IOwnProps {
  lavarages: {
    name: string;
    icon: string;
  }[];
  onChange(active: boolean): void;
}

const CardIndicator: React.FC<IOwnProps> = ({ lavarages, onChange }) => {
  const [active, setActive] = useState(false);

  const mountNameLavarage = (): string => {
    let lavarageAll = "";

    lavarages.forEach((lavarage, index) => {
      if (index !== 0) lavarageAll += " > ";
      lavarageAll += lavarage.name;
    });

    return lavarageAll;
  };

  return (
    <Stack
      w="100%"
      mx="10px !important"
      borderRadius="5px"
      boxShadow="0 8px 10px #00000052"
      p="5px"
      minW="380px"
      maxW="395px"
      h="fit-content"
      mb="20px"
    >
      <Flex>
        <Stack w="100%">
          <Text
            bg="#dfdfdf"
            p="6px"
            px="10px"
            fontWeight="bold"
            fontSize={14}
            color="black"
            borderRadius="5px"
          >
            Detalhes da Métrica
          </Text>
          <Flex
            fontSize={15}
            pl="10px"
            mr="20px !important"
            h="30px"
            align="center"
            color="black"
          >
            <Text>{mountNameLavarage()}</Text>
          </Flex>
        </Stack>
        <Stack alignItems="center" pl="10px">
          <Text
            bg="#dfdfdf"
            p="6px"
            fontWeight="bold"
            fontSize={14}
            color="black"
            borderRadius="5px"
            px="15px"
          >
            Selecionado
          </Text>
          <Flex>
            <Flex
              mt="0 !important"
              aria-hidden="true"
              border="1px solid black"
              borderRadius="50%"
              w="27px"
              h="27px"
              cursor="pointer"
              onClick={() => {
                setActive(!active);
                onChange(!active);
              }}
            >
              {active && (
                <Flex
                  borderRadius="50%"
                  align="center"
                  justify="center"
                  bg="#8ccfb9"
                  w="25px"
                  h="25px"
                  cursor="pointer"
                  fontSize={15}
                >
                  &#10004;
                </Flex>
              )}
            </Flex>
          </Flex>
        </Stack>
      </Flex>
      <Flex
        borderRadius="5px"
        bg="#dfdfdf"
        justifyContent="space-around"
        boxSizing="border-box"
        p="10px"
      >
        {lavarages.map((lavarage) => (
          <Image src={lavarage.icon} width="100px" />
        ))}
      </Flex>
    </Stack>
  );
};

export default CardIndicator;
