/* eslint-disable react/no-unused-prop-types */
import React, { useState } from "react";
import {
  filter,
  categoryFilter,
  stateMarketShare,
} from "../../store/SideFilters/RequestFilters/types";

import CustomSelect from "./SidebarFilters/CustomSelect";
import { IStateFilters } from "../../store/SideFilters/types";
import { IDispatchFilters } from "../SideFilterMenu/Resource";

export interface itemFilter {
  filterName: string;
  action: (filterData: filter, subFilters: categoryFilter) => void;
}

interface IOwnProps {
  filter: (
    id: string,
    SFilters: IStateFilters,
    SAllFilters: stateMarketShare,
    DFilters: IDispatchFilters,
    isPromo: boolean
  ) => void;
  selectedItemName: string;
  listCategory: filter[];
  border?: string;
  width?: string;
  position?: string;
  inputType?: string;
  placeholder?: string;
  mt?: string;
  disabled?: boolean;
}

const Filters: React.FC<IOwnProps> = (props: IOwnProps) => {
  const [formData, setFormData] = useState({
    filter: {
      id: "",
      label: "",
    },
  });

  const changeHandler = (value: string, name: string | number) => {
    setFormData((prev) => ({
      ...prev,
      [name]: {
        value,
      },
    }));
  };
  return (
    <>
      <CustomSelect
        searchPlaceholder={props.placeholder}
        data={props?.listCategory}
        value={formData?.filter.label}
        onChange={changeHandler}
        name="filter"
        marginTop={props.mt}
        requestFilter={props.filter}
        selectedItem={props.selectedItemName}
        position={props.position}
        disableInput={props.disabled || false}
      />
    </>
  );
};

Filters.defaultProps = {
  border: "none",
  width: "100%",
  position: "relative",
  inputType: "text",
  placeholder: "Digite seu filtro...",
  mt: "0.5rem",
  disabled: false,
};

export default Filters;
