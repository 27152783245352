import { Box, Flex, Grid, Image, Stack } from "@chakra-ui/react";
import MarketSharePeriod from "../../Graphics/MarketSharePeriodLtyYtd/MarketSharePeriodLtyYtd";
import MarketSharePeriodBar from "../../Graphics/MarketSharePeriodBarLtyYtd/MarketSharePeriodBarLtyYtd";
import LegendIcon, { ETypeLegend } from "../../LegendIcon";
import { theme } from "../../../styles/theme";
import LoadingComponent from "../../LoadingComponent";
import { EPeriod } from "../../CardSubMenu";
import {
  ITypeGraphicPeriod,
  ITypeGraphicPeriodBar,
} from "../../../services/Hooks/OnePage/onePageTypes";

interface ILegends {
  value: string;
  type: ETypeLegend;
}

export enum EToggle {
  PRESENCE = "category",
  REGION = "region",
  SUB_CHANNEL = "sub_channel",
  BANNER = "banner",
}

export enum ESelectedFirstLine {
  PRESENCE = "main/presence",
  GONDOLA = "main/gondola",
  EXTRA_POINT = "main/extra_point",
  YELLOW_PHARMA = "main/yellow_pharma",
  COMPLIANCE_EXTRA_POINT = "main/compliance_extra_point",
  PRICE = "main/price",
  ON = "main/ON",
}

export enum EExtraPoint {
  BRAND = "category",
  REGION = "region",
  SUB_CHANNEL = "sub_channel",
  BANNERS = "products",
}

export interface ISelectedCards {
  selectPeriod: EPeriod;
  selectedCard: ESelectedFirstLine;
  selectedPresence: EToggle;
  selectedExtraPoint: EExtraPoint;
  selectedTemporalFilter: string;
}

export interface IDataGraphics {
  periodBar?: ITypeGraphicPeriod;
  rightBar: ITypeGraphicPeriodBar;
  leftBar: ITypeGraphicPeriodBar;
}

interface IOwnProps {
  selectPeriod: string;
  legends: ILegends[];
  data?: IDataGraphics;
  icon: string;
  value: ESelectedFirstLine;
  stateSelections: ISelectedCards;
  isLoading: boolean;
  hasMargin?: boolean;
  minWidth?: string;
  setSelections(data: ISelectedCards): void;
}

const CustomMainCards: React.FC<IOwnProps> = ({
  selectPeriod,
  legends,
  icon,
  data,
  value,
  isLoading,
  setSelections,
  stateSelections,
  hasMargin,
  minWidth,
}: IOwnProps) => {
  return (
    <Stack
      bg="white"
      mr={hasMargin ? "5px" : "0"}
      w="100%"
      position="relative"
      cursor="pointer"
      alignItems="center"
      minW={minWidth || "400px"}
      overflow="hidden"
      onClick={() =>
        setSelections({
          ...stateSelections,
          selectedCard: value,
        })
      }
      _before={
        (stateSelections.selectedCard === value && {
          content: `""`,
          position: "absolute",
          width: "100%",
          height: "100%",
          borderWidth: "4px",
          borderColor: "selectedColor",
          zIndex: "5",
        }) ||
        {}
      }
      _after={
        (stateSelections.selectedCard === value && {
          content: '""',
          w: 0,
          h: 0,
          borderTopWidth: "15px",
          borderTopColor: "selectedColor",
          borderLeft: "solid 50px transparent",
          borderRight: "solid 50px transparent",
          position: "absolute",
          left: "calc(50% - 48px)",
          bottom: "-14px",
          zIndex: 5,
        }) ||
        {}
      }
    >
      {(isLoading && <LoadingComponent />) || (
        <>
          <Grid
            templateRows="75px 1fr"
            templateColumns="1fr"
            h="calc(100% - 36px)"
            justifyItems="center"
            w="fit-content"
            pt="20px"
          >
            <Flex justifyContent="space-between" w="100%" h="75px">
              <Flex w="105px" justify="center">
                <Image
                  src={icon}
                  alt="icon-store"
                  w="65px"
                  h="65px"
                  margin="5px 0 0 5px"
                />
              </Flex>
              {data?.periodBar?.label.length && (
                <MarketSharePeriod
                  dataGraphic={data?.periodBar}
                  h={70}
                  ml={15}
                />
              )}
            </Flex>
            <Flex marginTop={selectPeriod === "weekly" ? "30px" : "0px"}>
              <Box
                minW="106px"
                marginTop={selectPeriod === "weekly" ? "-32px" : "0px"}
              >
                <MarketSharePeriodBar dataGraphic={data?.leftBar} h={200} />
              </Box>
              <MarketSharePeriodBar
                dataGraphic={data?.rightBar}
                bgColor={theme.graphics.light}
                h={200}
                l={10}
              />
            </Flex>
          </Grid>
          <Flex align="center" pb="10px">
            {legends.map((legend) => (
              <LegendIcon typeLegend={legend.type}>{legend.value}</LegendIcon>
            ))}
          </Flex>
        </>
      )}
    </Stack>
  );
};

CustomMainCards.defaultProps = {
  data: {
    leftBar: {
      label: [],
      x: [],
      y: [],
    },
    periodBar: {
      label: [],
      y: [],
    },
    rightBar: {
      label: [],
      x: [],
      y: [],
    },
  },
  hasMargin: false,
  minWidth: "400px",
};

export default CustomMainCards;
