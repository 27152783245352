import { Flex, List, ListItem, Stack, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

export interface orderedByData {
  filter: number;
  desc: boolean;
}

interface IOwnProps {
  title: string;
  subTitle: string;
  itens?: string[];
  children?: JSX.Element;
  selectedOrder?: orderedByData;
  id?: number;
  selectFilter?(id: number, desc: boolean): void;
}

const Column: React.FC<IOwnProps> = ({
  id,
  selectedOrder,
  title,
  subTitle,
  itens,
  children,
  selectFilter,
}) => {
  const formatedSubLabel = (label: string, onlyData?: boolean): string => {
    if (onlyData) {
      return `(${label.split("(")[1].trim()}`;
    }
    return `${label.replace(")", "").split("-")[0].trim()})`;
  };

  return (
    <Stack w="100%" ml="5px" color="black" minW="160px" maxW="200px" bg="white">
      <Text
        fontSize="14px"
        background="#e7e6e6"
        p="8px 12px"
        textAlign="center"
        fontWeight="bold"
      >
        {title}
      </Text>
      <Flex
        mt="5px !important"
        pr="5px"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          minH="33px"
          alignItems="center"
          justifyContent="center"
          w="100%"
          pl="15px"
        >
          <Text fontWeight="bold" whiteSpace="pre-line" textAlign="center">
            {(subTitle.length > 20 && (
              <Tooltip label={formatedSubLabel(subTitle, true)}>
                {formatedSubLabel(subTitle)}
              </Tooltip>
            )) ||
              subTitle}
          </Text>
        </Flex>
        {selectedOrder && selectFilter && id && (
          <Stack mt="0px !important">
            <AiFillCaretUp
              color={
                selectedOrder.filter === id && selectedOrder.desc === false
                  ? "blue"
                  : "black"
              }
              onClick={() => selectFilter(id, false)}
              size={12}
              cursor="pointer"
            />
            <AiFillCaretDown
              color={
                selectedOrder?.filter === id && selectedOrder.desc
                  ? "blue"
                  : "black"
              }
              onClick={() => selectFilter(id, true)}
              size={12}
              cursor="pointer"
              style={{ marginTop: "2px" }}
            />
          </Stack>
        )}
      </Flex>
      {(itens && (
        <List mt="0px !important">
          {itens.map((item) => (
            <ListItem
              textAlign="center"
              h="32px"
              lineHeight="31px"
              mt="2px !important"
              bg="#A6A6A6"
              color="white"
            >
              <Text fontWeight="bold">{item}</Text>
            </ListItem>
          ))}
        </List>
      )) ||
        children}
    </Stack>
  );
};

Column.defaultProps = {
  itens: undefined,
  children: <></>,
  id: undefined,
  selectedOrder: {
    filter: -1,
    desc: false,
  },
  selectFilter: undefined,
};

export default Column;
