import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  Grid,
  Select,
  Stack,
  TableContainer,
  Text,
} from "@chakra-ui/react";

import { BSSubMenu, BSLegendTable, BSHeader } from "./DatailStores.element";

// Images
import store from "../../../../../../assets/Menu/store-white.png";
import shopping from "../../../../../../assets/myExecution/iconShopping.png";

// Actions
import * as ANavigator from "../../../../../../store/Navigation/actions";

import {
  ENavSubMenu,
  filterComponent,
  IMainMenu,
  INavContent,
  stateType,
} from "../../../../../../store/Navigation/types";

import {
  Divisor,
  ItemAtivo,
} from "../../../../../../components/Contents/subMenu";

import { TableSubView } from "../../../../../../components/Contents/Resources/SubView/subView";

import { GlobalProps } from "../../../../../../store";
import {
  INITIAL_FILTERS,
  IStateFilters,
} from "../../../../../../store/SideFilters/types";
import ColumnInfoYellowPharma from "./ColumnInfoYellowPharma";
import { useRequestDetailStore } from "../../../../../../services/Hooks/MyExecution/DetailStore/useRequestDetailStore";
import LoadingComponent from "../../../../../../components/LoadingComponent";

// config system
import { stateMarketShare } from "../../../../../../store/SideFilters/RequestFilters/types";
import { theme } from "../../../../../../styles/theme";
import FiltersList from "../../../../../../components/SideFilterMenu/Resource/FiltersList";
import { ConfigContext } from "../../../../../../services/Context/ConfigContext";

interface IStateProps {
  navigation: stateType;
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeMenu(menuItem: IMainMenu): void;
    changeSubMenu(index: ENavSubMenu): void;
    changeFilters(filters: filterComponent[]): void;
  };
}

type props = IStateProps & IDispatchProps;

const DetailProducts: React.FC<props> = ({
  navigation,
  DNavigator,
  SFilters,
  SAllFilters,
}: props): JSX.Element => {
  const [widthContent, setWidthContent] = useState<string>("");
  const refDivContent = useRef<HTMLDivElement>(null);
  const [temporalFilter, setTemporalFilter] = useState<string>("MAT");
  // const [limitItem, setLimitItem] = useState<number>(50);
  const refTable = useRef<HTMLTableElement>(null);
  const refSubMenu = useRef<HTMLDivElement>(null);

  const { config } = useContext(ConfigContext);

  const detail = useRequestDetailStore(SFilters);

  const headers = ["#", "Cnpj", "Nome da Loja", "Bandeira", "UF"];

  useEffect(() => {
    DNavigator.changeMenu({
      index: navigation.menuItem.index,
      name: "Minha Execução | Farmácia Amarela",
    });
  }, []);

  setTimeout(() => {
    if (refTable.current !== null) {
      const widthDiv = refTable.current.clientWidth - 143;
      const currentWidth = parseInt(widthContent.replace("px", ""), 10);
      if (widthDiv !== currentWidth) {
        setWidthContent(`${widthDiv}px`);
      }
    }
  });

  const handleLimitItem = (e: ChangeEvent<HTMLSelectElement>) => {
    const limitSelected = parseInt(e.currentTarget.value, 10);
    return limitSelected;
    // setLimitItem(limitSelected);
  };

  const renderFilter = useCallback(() => {
    if (config.showSideFilters.myExecution)
      DNavigator.changeFilters(
        FiltersList(SAllFilters, SFilters, {
          ...INITIAL_FILTERS,
          category: false,
          typeExecution: false,
          businessUnitId: false,
          mainCategory: false,
          subCategory: false,
          segment: false,
          priorityItens: false,
        })
      );
  }, [SAllFilters, SFilters, DNavigator]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter, SFilters]);

  return (
    <Grid
      templateRows="auto 50px 1fr"
      m="5px"
      h="calc(100% - 130px)"
      color="black"
      css={`
        &::-webkit-scrollbar {
          background-color: darkgray;
          border-radius: 20px;
          width: 12px;
          height: 12px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background-color: ${theme.scrollColor};
        }
      `}
    >
      <BSSubMenu ref={refSubMenu}>
        <ItemAtivo icon={shopping} noMargin active={false} width="150px" />
        <Divisor />
        <BSLegendTable minWidth={widthContent || "300px"}>
          <Text fontSize="20px" fontWeight="bold">
            Todas
          </Text>
        </BSLegendTable>
      </BSSubMenu>
      <Flex>
        <BSHeader>
          <div className="header-msl-products">
            <img src={store} alt="icon-page" />
            <h1>
              Detalhamento da execução nível LOJA | Bandeira
              {` ${SFilters.promoFilter.banner.selectedFilterName || "Todas"} `}
              | Região
              {` ${SFilters.promoFilter.uf.selectedFilterName || "Todas"}`}
            </h1>
          </div>
        </BSHeader>
      </Flex>
      {(detail.isLoading && (
        <Flex h="calc(100% - 5px)" mt="5px" bg="white">
          <LoadingComponent />
        </Flex>
      )) || (
        <Stack
          h="100%"
          mt="5px !important"
          bg="white"
          overflow="auto"
          css={`
            &::-webkit-scrollbar {
              background-color: darkgray;
              border-radius: 20px;
              width: 12px;
              height: 12px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 20px;
              background-color: ${theme.scrollColor};
            }
          `}
        >
          <Flex pl="16px" pt="13px" alignItems="center" zIndex={9999}>
            {/* <Image src={store} w="50px" /> */}
            {/* <TemporalFilter
              setTemporalFilter={setTemporalFilter}
              temporalFilter={temporalFilter}
              itens={["MAT", "YTD", "U3M", "LP"]}
            /> */}
            <Text ml="20px">Exibir</Text>
            <Select w="90px" ml="10px" h="30px" onChange={handleLimitItem}>
              <option>25</option>
              <option selected>50</option>
              <option>100</option>
            </Select>
          </Flex>
          <TableContainer
            p="5px"
            display="flex"
            ref={refDivContent}
            fixedHeader
            overflowY="auto"
            css={`
              &::-webkit-scrollbar {
                background-color: darkgray;
                border-radius: 20px;
                width: 12px;
                height: 12px;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 20px;
                background-color: ${theme.scrollColor};
              }
            `}
          >
            <Stack position="sticky" left={0} bg="white" zIndex={1000} pr="5px">
              <Stack>
                <Box position="sticky" pt="25px" bg="white" top="-5px">
                  <Text bg="#E7E6E6" fontSize="14px" fontWeight="bold" p="10px">
                    Descrição da Loja
                  </Text>
                </Box>
                <TableSubView
                  refElement={refTable}
                  headers={headers}
                  dataTable={detail.data?.table || []}
                  topFixedHeader="60px"
                />
              </Stack>
            </Stack>
            <Flex height="fit-content" w="100%">
              {detail.data?.content.map((columns) => {
                return <ColumnInfoYellowPharma data={columns} />;
              })}
            </Flex>
          </TableContainer>
        </Stack>
      )}
    </Grid>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailProducts);
