import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useEffect } from "react";
import { Container, Flex, Stack, Text } from "@chakra-ui/react";
import { FiFilter } from "react-icons/fi";

import * as AMenu from "../../store/Navigation/actions";
import * as ACategory from "../../store/SideFilters/RequestFilters/actions";
import * as ASubFilters from "../../store/SideFilters/actions";
import { GlobalProps } from "../../store/index";
import { stateType } from "../../store/Navigation/types";
import { stateMarketShare } from "../../store/SideFilters/RequestFilters/types";

interface StateProps {
  IMainMenu: stateType;
  categoryType: stateMarketShare;
}

interface DispatchProps {
  category: {
    loadRequest(): void;
  };
}

type props = StateProps & DispatchProps;

const SideFilterMenu: React.FC<props> = (props: props) => {
  const { category } = props;

  const { IMainMenu, categoryType } = props;

  useEffect(() => {
    category.loadRequest();
  }, [category]);

  return (
    <Container h="100%" p={0}>
      {!categoryType.loading &&
        IMainMenu.filters.map((itens) => {
          return (
            <Stack key={Math.random()} mb="15px">
              <Flex color="textColor">
                <FiFilter size="18px" />
                <Text fontSize="12px" ml="5px" as="span">
                  <b>{itens.name}</b>
                </Text>
              </Flex>
              {itens.componentFilters}
            </Stack>
          );
        })}
    </Container>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  IMainMenu: state.navigation,
  categoryType: state.marketShareCategory,
  SFilters: state.RSubFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(AMenu, dispatch),
    category: bindActionCreators(ACategory, dispatch),
    subFilters: bindActionCreators(ASubFilters, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideFilterMenu);
