import { Reducer } from 'redux';
import { AProfileType, stateProfile } from './types';

const INITIAL_STATE: stateProfile = {
  data: {
    id: '',
    firstName: '',
    email: '',
    avatar: '',
    industrie: '',
    lastName: '',
    phoneNumber: '',
  },
  isLoading: false,
  error: false,
};

const reducerProfile: Reducer<stateProfile> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case AProfileType.REQUEST_PROFILE:
      return { ...state, isLoading: true };
    case AProfileType.PROFILE_SUCCESS:
      return { isLoading: false, error: false, data: action.payload[0] };
    case AProfileType.PROFILE_FAIL:
      return { isLoading: false, error: true, data: INITIAL_STATE.data };
    default:
      return state;
  }
};

export default reducerProfile;
