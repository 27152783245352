/* eslint-disable camelcase */
import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

interface ICommomData {
  storeName: string;
  storeCnpj: string;
  storeBanner: string;
  storeSubChannel: string;
  storeUf: string;
  storeCity: string;
  promoter: string;
  executionLeverage: string;
  typeExecutionLeverage: string;
  orderName: string;
  date: string;
}

interface IEvidenceExtraExposure {
  evidences: string[];
  brand: string;
}

interface IEvidence {
  // eslint-disable-next-line camelcase
  banner_logo: string;
  evidencesBefore: string[];
  evidencesAfter: string[];
}

export type TEvidenceExtraExposure = ICommomData & IEvidenceExtraExposure;
export type TEvidence = ICommomData & IEvidence;
export type TGroupedPayload = TEvidence | TEvidenceExtraExposure;

export interface IEvidences {
  executionsEvidences: TEvidence[];
  executionsEvidencesExtraExposure: TEvidenceExtraExposure[];
  numberOfStores: number;
}

export interface IReturnEvidence {
  evidences: TGroupedPayload[];
  numberOfStores: number;
  count: number;
  numberOfPage: number;
}

export async function requestCategory(filters: IStateFilters, page_number: number, industryId?: string, page_size_number = 6) {
  try {


    const response = await api.get<any>("/evidences_new/", {
      params: {
        page: page_number,
        page_size: page_size_number,
        business_unit_id:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        sub_channel_id:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.subChannel.selectedFilterId || null
            : null,
        banner_id:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.banner.selectedFilterId || null
            : null,
        region_id:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.region.selectedFilterId || null
            : null,
        uf_id:
          filters.promoFilter.storeCNPJ.length === 0
            ? filters.promoFilter.uf.selectedFilterId || null
            : null,
        category_id: filters.promoFilter.category.selectedFilterId || null,
        sub_category_id: filters.promoFilter.brand.selectedFilterId || null,
        industry_id: industryId,
        start_date:
          filters.promoFilter.dateInitial === "dd/mm/aaaa"
            ? null
            : filters.promoFilter.dateInitial,
            end_date:
          filters.promoFilter.dateFinal === "dd/mm/aaaa"
            ? null
            : filters.promoFilter.dateFinal,
        execution_type_id:
          filters.promoFilter.executionType.selectedFilterId || null,
        execution_leverage_id:
          filters.promoFilter.executionLeverage.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
        ordering: 'start_date',
      },
    });

    const returnEvidence: IReturnEvidence = {
      evidences: [
        ...response.data.results
      ],
      numberOfStores: response.data.numberOfStores,
      count: response.data.count,
      numberOfPage: (response.data.count % 6) === 0 ? (response.data.count / 6) : Math.trunc((response.data.count / 6)) + 1,
    };

    return returnEvidence;
  } catch (error) {
    return null;
  }
}

export function useFetchEvidences(
  filters: IStateFilters,
  page: number,
  industryId?: string
): UseQueryResult<IReturnEvidence | null, unknown> {
  return useQuery(
    ["promoEvidences", filters, page, industryId],
    () => requestCategory(filters, page, industryId),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: 2,
    }
  );
}
