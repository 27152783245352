import React, { useContext } from "react";
import { connect } from "react-redux";
import { ENavSubMenu, stateType } from "../../../store/Navigation/types";

// Components
import {
  ContainerSubMenu,
  SubItem,
} from "../../../components/Contents/subMenu";
import OnePage from "./hasPromo";
import OnePageRocket from "./NoPromo/Rocket";
import OnePageProduct from "./NoPromo/Product";
import OnePageBanner from "./NoPromo/Banner";

// imagens
import onePageIcon from "../../../assets/OnePage/dashboard.svg";
import rocketIcon from "../../../assets/OnePage/rocket.svg";
import productIcon from "../../../assets/OnePage/products.svg";
import bannerIcon from "../../../assets/OnePage/store.svg";

import { GlobalProps } from "../../../store";
import { ConfigContext } from "../../../services/Context/ConfigContext";

interface IOwnProp {
  indexSubMenu: ENavSubMenu;
  changeSubMenu: (data: ENavSubMenu) => void;
}

export const SubMenuOnePage = ({
  indexSubMenu,
  changeSubMenu,
}: IOwnProp): JSX.Element => {
  const { config } = useContext(ConfigContext);

  return (
    <>
      <ContainerSubMenu>
        {(config.general.hasPromo && (
          <SubItem
            icon={onePageIcon}
            onClick={() => changeSubMenu(ENavSubMenu.ONE_PAGE)}
            active={indexSubMenu === ENavSubMenu.ONE_PAGE}
          />
        )) || (
          <>
            <SubItem
              icon={rocketIcon}
              onClick={() => changeSubMenu(ENavSubMenu.ONE_PAGE_ROCKET)}
              active={indexSubMenu === ENavSubMenu.ONE_PAGE_ROCKET}
            />
            <SubItem
              icon={productIcon}
              onClick={() => changeSubMenu(ENavSubMenu.ONE_PAGE_PRODUCT)}
              active={indexSubMenu === ENavSubMenu.ONE_PAGE_PRODUCT}
            />
            <SubItem
              icon={bannerIcon}
              onClick={() => changeSubMenu(ENavSubMenu.ONE_PAGE_BANNER)}
              active={indexSubMenu === ENavSubMenu.ONE_PAGE_BANNER}
            />
          </>
        )}
      </ContainerSubMenu>
    </>
  );
};

interface stateProps {
  navigation: stateType;
}

const ContentMyNegotiations: React.FC<stateProps> = ({
  navigation,
}: stateProps) => {
  switch (navigation.indexSubMenu) {
    case ENavSubMenu.ONE_PAGE:
      return <OnePage />;
    case ENavSubMenu.ONE_PAGE_ROCKET:
      return <OnePageRocket />;
    case ENavSubMenu.ONE_PAGE_PRODUCT:
      return <OnePageProduct />;
    case ENavSubMenu.ONE_PAGE_BANNER:
      return <OnePageBanner />;
    default:
      return null;
  }
};

// Mapeia os Estados globais e pega os que quiser usar
const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
});

export default connect(mapStateToProps)(ContentMyNegotiations);
