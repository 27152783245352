import { useQuery, UseQueryResult } from "react-query";
import { IMonTable } from "../../../../components/Table/Column";
import { IStateFilters } from "../../../../store/SideFilters/types";
import api from "../../../api";

import { ITypeGraphicHorizontalBar } from "../../OnePage/onePageTypes";

export enum ELavarage {
  PRESENCA = "PRESENCA",
  GONDOLA = "GONDOLA",
  PONTO_EXTRA = "PONTO_EXTRA",
}

export interface IColumnChartFormatData {
  data: ITypeGraphicHorizontalBar;
  header: string;
  label: string;
  total: string;
}

export interface IPayloadKpi {
  dataTable: IMonTable[];
  activeStore: string[];
  act: IColumnChartFormatData;
  lm: IColumnChartFormatData;
  l3m: IColumnChartFormatData;
  ytd: IColumnChartFormatData;
  lastWeek: IColumnChartFormatData;
  penultimateWeek: IColumnChartFormatData;
}

const defaultColumnData: IColumnChartFormatData = {
  header: "",
  label: "",
  total: "",
  data: {
    label: [],
    x: [],
    y: [],
  },
};

export const defaultValue: IPayloadKpi = {
  activeStore: [],
  dataTable: [],
  act: defaultColumnData,
  l3m: defaultColumnData,
  lm: defaultColumnData,
  ytd: defaultColumnData,
  penultimateWeek: defaultColumnData,
  lastWeek: defaultColumnData,
};

export interface IKpi {
  periodFilter: string;
  total: number;
  data: IPayloadKpi;
}

const requestKpiProductSOS = async (filters: IStateFilters): Promise<IKpi> => {
  let filterCategorySideBar = {
    businessUnitId: filters.promoFilter.businessUnitId.selectedFilterId,
    category: filters.promoFilter.category.selectedFilterId,
    brand: filters.promoFilter.brand.selectedFilterId,
    segments: filters.promoFilter.segments.selectedFilterId,
  };

  if (filters.internalKpiProduct.selectedPresence.id === ELavarage.PRESENCA) {
    filterCategorySideBar = {
      businessUnitId: filters.promoFilter.businessUnitId.selectedFilterId,
      category: filters.promoFilter.category.selectedFilterId,
      brand: filters.promoFilter.brand.selectedFilterId,
      segments: filters.promoFilter.segments.selectedFilterId,
    };
  } else {
    filterCategorySideBar = {
      businessUnitId: "",
      category: filters.internalKpiProduct.selectedCategoryFilter,
      brand: filters.internalKpiProduct.selectedBrandFilter,
      segments: "",
    };
  }

  const result = await api.get<any>(`kpi_product_sos/`, {
    params: {
      leverage: filters.internalKpiProduct.selectedPresence.id || null,
      period: filters.internalKpiProduct.selectPeriod.id || null,
      businessUnit: filterCategorySideBar.businessUnitId || null,
      category: filterCategorySideBar.category || null,
      subCategory: filterCategorySideBar.brand || null,
      segment: filterCategorySideBar.segments || null,
      subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
      banner: filters.promoFilter.banner.selectedFilterId || null,
      region: filters.promoFilter.region.selectedFilterId || null,
      uf: filters.promoFilter.uf.selectedFilterId || null,
      director: filters.promoFilter.commercialDirector.selectedFilterId || null,
      manager: filters.promoFilter.commercialManager.selectedFilterId || null,
      customer_sales_rep:
        filters.promoFilter.commercialSaler.selectedFilterId || null,
      cnpj: filters.promoFilter.storeCNPJ || null,
      size: filters.internalKpiProduct.limitItem,
    },
  });

  return result.data;
};

export const useRequestKpiProductSOS = (
  filters: IStateFilters
): UseQueryResult<IKpi, unknown> => {
  return useQuery(
    ["presenceKpiProductSOS", filters],
    () => requestKpiProductSOS(filters),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: 2,
    }
  );
};
