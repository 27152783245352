import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Box,
  Flex,
  Grid,
  Image,
  Link,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CgClose } from "react-icons/cg";
import { HiOutlineArrowDown } from "react-icons/hi";
import { RiFileExcel2Line } from "react-icons/ri";
import * as ANavigator from "../../../../../store/Navigation/actions";
import * as AFilters from "../../../../../store/SideFilters/actions";
import {
  ENavSubMenu,
  filterComponent,
  INavContent,
} from "../../../../../store/Navigation/types";

import excel from "../../../../../assets/MyDay/excel.svg";
import lavarageIcon from "../../../../../assets/MyDay/iconLavarage.svg";
import productsIcon from "../../../../../assets/MyDay/products.svg";
import kpIcon from "../../../../../assets/MyDay/kpi.svg";
import calendarIcon from "../../../../../assets/MyDay/calendar.svg";
import ShareValue from "../../../../../components/Graphics/MarketShareValue/MarketShareValue";

import { TableSubView } from "../../../../../components/Contents/Resources/SubView/subView";

import { GlobalProps } from "../../../../../store";
import TemporalFilter from "../../../../../components/TemporalFilter";
import {
  filter,
  stateMarketShare,
} from "../../../../../store/SideFilters/RequestFilters/types";
import Column, {
  orderedByData,
} from "../../../../../components/DetailPages/Columns";
import FiltersList from "../../../../../components/SideFilterMenu/Resource/FiltersList";
import { ConfigContext } from "../../../../../services/Context/ConfigContext";
import {
  INITIAL_FILTERS,
  IProductFilter,
  ISelectedInternalFiltersKpi,
  IShowFilters,
  IStateFilters,
  TPromoFilter,
} from "../../../../../store/SideFilters/types";
import { ELavarage } from "../../../../../services/Hooks/MyExecution/DetailKpiStore/useRequestKpiStore";
import { EPeriod } from "../../../../../components/CardSubMenu";
import LoadingComponent from "../../../../../components/LoadingComponent";
import {
  ModalOverlay,
  ModalWrapper,
} from "../../../../../components/Modal/styles";
import { UModalCalendar } from "../../../MyNegotiations/ViewCalendar/ModalCalendar/styles";
import { theme } from "../../../../../styles/theme";
import EmptyMessage from "../../../../../components/EmptyMessage";
import {
  defaultValue,
  IPayloadKpi,
  useRequestKpiProduct,
} from "../../../../../services/Hooks/MyExecution/DetailKpiProduct/useRequestKpi";
import { useRequestKpiProductExcel } from "../../../../../services/Hooks/MyExecution/DetailKpiProduct/useKpiProductExportData";
import { useRequestKpiProductSOS } from "../../../../../services/Hooks/MyExecution/DetailKpiProduct/useRequestKpiSOS";
import {
  insertDataCache,
  orderColumn,
  redefineOrder,
  sortByPresence,
} from "../kpiUtils";
import { useRequestKpiProductSOV } from "../../../../../services/Hooks/MyExecution/DetailKpiProduct/useRequestKpiSOV";

interface IStateProps {
  SAllFilters: stateMarketShare;
  SFilters: IStateFilters;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeTitleMenu(newTitle: string): void;
    changeSubMenu(index: ENavSubMenu): void;
    changeFilters(filters: filterComponent[]): void;
  };

  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
    changeCnpjStore(payload: string): void;
    changeInternalFilterKpiProduct(payload: ISelectedInternalFiltersKpi): void;
  };
}

type props = IStateProps & IDispatchProps;

const DetailKPI: React.FC<props> = ({
  DNavigator,
  SAllFilters,
  DFilters,
  SFilters,
}): JSX.Element => {
  const { config } = useContext(ConfigContext);

  const [orderedBy, setOrderedBy] = useState<orderedByData>({
    desc: false,
    filter: -1,
  });
  const [widthContent, setWidthContent] = useState<number>(0);
  const [heightGraphics, setHeightGraphics] = useState<number>(0);
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
  const [popUpExportExcel, setPopUpExportExcel] = useState<boolean>(false);
  const [cacheData, setCacheData] = useState<IPayloadKpi>(defaultValue);

  const refTable = useRef<HTMLTableElement>(null);
  const refDivContent = useRef<HTMLDivElement>(null);
  const refSubMenu = useRef<HTMLDivElement>(null);

  const [stores, setStores] = useState<any>([]);
  const [selectedStore, setSelectedStore] = useState<string>("");

  let headers: string[];

  if (SFilters.internalKpiProduct.selectedPresence.id !== ELavarage.PRESENCA) {
    headers = ["#", "Indicador de Performance", "Marca", "Fabricante"];
  } else {
    headers = ["#", "EAN", "Descricao do produto", "Marca", "Categoria"];
  }

  const requestSOS = useRequestKpiProductSOS(SFilters);
  const requestSOV = useRequestKpiProductSOV(SFilters);
  const { data, isLoading, refetch } = useRequestKpiProduct(SFilters);
  const file = useRequestKpiProductExcel(SFilters);

  let visionKPI: IPayloadKpi = defaultValue;
  switch (SFilters.internalKpiProduct.selectedPresence.id) {
    case ELavarage.PRESENCA:
      visionKPI = data?.data || defaultValue;
      break;
    case ELavarage.GONDOLA:
      visionKPI = requestSOS.data?.data || defaultValue;
      break;
    case ELavarage.PONTO_EXTRA:
      visionKPI = requestSOV.data?.data || defaultValue;
      break;
    default:
      break;
  }

  const loadingData: boolean =
    requestSOS.isLoading || isLoading || requestSOV.isLoading;

  useEffect(() => {
    if (
      SFilters.internalKpiProduct.selectedPresence.id === ELavarage.PRESENCA &&
      data &&
      data.data !== cacheData
    ) {
      insertDataCache(
        SFilters.internalKpiProduct.limitItem,
        data.data,
        setCacheData
      );
    } else if (
      SFilters.internalKpiProduct.selectedPresence.id === ELavarage.GONDOLA &&
      requestSOS.data &&
      requestSOS.data.data !== cacheData
    ) {
      insertDataCache(
        SFilters.internalKpiProduct.limitItem,
        requestSOS.data.data,
        setCacheData
      );
    } else if (
      SFilters.internalKpiProduct.selectedPresence.id ===
        ELavarage.PONTO_EXTRA &&
      requestSOV.data &&
      requestSOV.data.data !== cacheData
    ) {
      insertDataCache(
        SFilters.internalKpiProduct.limitItem,
        requestSOV.data.data,
        setCacheData
      );
    }
  }, [data, requestSOS.data, requestSOV.data]);

  useEffect(() => {
    if (
      SFilters.internalKpiProduct.selectedPresence.id === ELavarage.PRESENCA
    ) {
      refetch();
    } else if (
      SFilters.internalKpiProduct.selectedPresence.id === ELavarage.GONDOLA
    ) {
      requestSOS.refetch();
    } else if (
      SFilters.internalKpiProduct.selectedPresence.id === ELavarage.PONTO_EXTRA
    ) {
      requestSOV.refetch();
    }

    setOrderedBy({ desc: false, filter: -1 });
  }, [SFilters.internalKpiProduct]);

  const handleSelectedPeriod = (periodSelected: filter) => {
    // DFilters.changeInternalFilterKpiProduct({
    //   ...SFilters.internalKpiProduct,
    //   selectPeriod: periodSelected,
    // });
  };

  const handleSelectedLavarage = (lavarageSelected: filter) => {
    setStores([]);
    setSelectedStore("");

    DFilters.changeInternalFilterKpiProduct({
      ...SFilters.internalKpiProduct,
      selectedPresence: lavarageSelected,
    });
  };

  const handleLimitItem = (e: ChangeEvent<HTMLSelectElement>) => {
    const limitSelected = parseInt(e.currentTarget.value, 10);
    DFilters.changeInternalFilterKpiProduct({
      ...SFilters.internalKpiProduct,
      limitItem: limitSelected,
    });
  };

  const convertFirstChartToUpperCase = (text: string) => {
    return text.charAt(0) + text.slice(1).toLowerCase();
  };

  useEffect(() => {
    DNavigator.changeTitleMenu("Minha Execução | Presença | Visão Produto");
  }, [DNavigator]);

  useEffect(() => {
    if (
      SFilters.internalKpiProduct.selectedPresence.id === ELavarage.PRESENCA
    ) {
      refetch();
    } else if (
      SFilters.internalKpiProduct.selectedPresence.id === ELavarage.GONDOLA
    ) {
      requestSOS.refetch();
    } else if (
      SFilters.internalKpiProduct.selectedPresence.id === ELavarage.PONTO_EXTRA
    ) {
      requestSOV.refetch();
    }
    setOrderedBy({ desc: false, filter: -1 });
  }, [
    SFilters.internalKpiProduct.limitItem,
    SFilters.internalKpiProduct.selectPeriod,
    SFilters.internalKpiProduct.selectedPresence,
  ]);

  setTimeout(() => {
    if (loadingData) return;
    if (refDivContent.current !== null) {
      const widthDiv = refDivContent.current.clientWidth - 148.5;
      if (widthDiv !== widthContent) {
        setWidthContent(widthDiv);
      }
    }

    if (refTable.current) {
      const heightDiv = refTable.current.clientHeight - 34;
      setHeightGraphics(heightDiv);
    }
  });

  const orderListTable = (index: number, desc: boolean) => {
    if (index === orderedBy.filter && orderedBy.desc === desc) {
      redefineOrder(
        SFilters.internalKpiProduct.limitItem,
        visionKPI,
        setCacheData
      );
      setOrderedBy({
        desc: false,
        filter: -1,
      });
    } else {
      sortByPresence(
        index,
        desc,
        SFilters.internalKpiProduct.limitItem,
        visionKPI,
        setCacheData
      );
      setOrderedBy({
        desc,
        filter: index,
      });
    }
  };

  const orderListColumns = (id: number, desc: boolean) => {
    if (id === orderedBy.filter && desc === orderedBy.desc) {
      redefineOrder(
        SFilters.internalKpiProduct.limitItem,
        visionKPI,
        setCacheData
      );
      setOrderedBy({ desc: false, filter: -1 });
    } else {
      orderColumn(
        id,
        desc,
        SFilters.internalKpiProduct.limitItem,
        visionKPI,
        setCacheData
      );
      setOrderedBy({
        desc,
        filter: id,
      });
    }
  };

  const renderFilter = useCallback(() => {
    let showFilters: IShowFilters = {
      ...INITIAL_FILTERS,
      subChannel: true,
      banner: true,
      region: true,
      uf: true,
      isPromo: true,
      // commercialStructureDirector: true,
      // commercialStructureManager: true,
      // commercialStructureSales: true,
      storeCNPJ: true,
      // merchandising: true,
    };

    if (
      SFilters.internalKpiProduct.selectedPresence.id === ELavarage.PRESENCA
    ) {
      showFilters = {
        ...showFilters,
        businessUnitId: true,
        category: true,
        subCategory: true,
        // segment: true,
      };
    }

    if (config.showSideFilters.myExecution)
      DNavigator.changeFilters(
        FiltersList(SAllFilters, SFilters, showFilters, DFilters)
      );
  }, [SAllFilters, SFilters, SFilters.internalKpiProduct]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter, SFilters.internalKpiProduct]);

  const exportExcelSelected = () => {
    setIsLoadingExcel(true);
    file.refetch().finally(() => setIsLoadingExcel(false));
    setPopUpExportExcel(true);
  };

  const switchTitleByIndicators = (): string => {
    switch (SFilters.internalKpiProduct.selectedPresence.id) {
      case ELavarage.PRESENCA:
        return SFilters.internalKpiProduct.selectPeriod.id === EPeriod.MONTHLY
          ? "#Lojas Ativas"
          : "#Lojas Ativas (Act)";
      case ELavarage.GONDOLA:
        return "Meta";
      case ELavarage.PONTO_EXTRA:
        return "Qtd de Pontos Extras";
      default:
        return "SKUs Ativos";
    }
  };

  return (
    (popUpExportExcel && (
      <>
        <ModalOverlay className="remove" />
        <ModalWrapper className="remove">
          <UModalCalendar width="30vw" className="remove">
            {(false && (
              <LoadingComponent text="Preparando os dados para exportação!" />
            )) || (
              <Stack>
                <Flex justifyContent="flex-end" cursor="pointer">
                  <CgClose
                    size={30}
                    color={theme.colors.button}
                    onClick={() => setPopUpExportExcel(false)}
                  />
                </Flex>
                <Flex
                  w="100%"
                  alignItems="center"
                  p="50px 10px"
                  justify="space-around"
                >
                  {(isLoadingExcel && <LoadingComponent />) || (
                    <>
                      <Stack alignItems="center">
                        <RiFileExcel2Line color="green" size={85} />
                        <Text
                          color="black"
                          p="5px 10px"
                          background="#dfdfdf"
                          w="100%"
                          textAlign="center"
                          borderRadius="8px"
                          mt="5px !important"
                          mb="8px !important"
                        >
                          Kpi
                        </Text>
                        <Link
                          href={file.data?.file}
                          _focus={{
                            outline: false,
                          }}
                          download
                        >
                          <Flex
                            w="45px"
                            h="40px"
                            borderRadius="50%"
                            justify="center"
                            align="center"
                            cursor="pointer"
                            _hover={{
                              background: "#befdc3",
                              transition: "0.4s",
                            }}
                            boxShadow="2px 3px 10px #00000055"
                          >
                            <HiOutlineArrowDown color="black" size="23px" />
                          </Flex>
                        </Link>
                      </Stack>
                    </>
                  )}
                </Flex>
              </Stack>
            )}
          </UModalCalendar>
        </ModalWrapper>
      </>
    )) || (
      <Grid
        templateRows="auto 1fr"
        h="calc(100% - 120px)"
        m="5px !important"
        overflowY="hidden"
      >
        <Box w="max-content" minW="100%">
          {/* <Flex
            bg="white"
            color="black"
            h="85px"
            alignItems="center"
            px="4px"
            ref={refSubMenu}
            pr="15px"
          >
            <Image src={selectABrand} width="142px" p={5} />
            <Divisor />
            <BSLegendTable minWidth={`${widthContent || 550}px`}>
              <SubMenuItem
                text="Total de Lojas"
                bold
                value="--"
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                text="Lojas c/ GAP"
                value="--"
                icon={More}
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                text="Lojas s/ GAP"
                value="--"
                icon={Less}
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
              <SubMenuItem
                text="Performance"
                bold
                noMargin
                value="--"
                font="24px"
                marginP
                titleBoxAlign="center"
                subTitleBold
              />
            </BSLegendTable>
            <WrapperCardSubMenu
              gridColumns={`repeat(${
                selections.selectPeriod.id === EPeriod.WEEKLY ? 7 : 5
              }, 1.5fr)`}
              margin="0 12.5px 0 0"
            >
              <SubMenuItem
                font="14px"
                text={switchTitleByIndicators()}
                bold
                noMargin
                value="--"
                minWidth="160px"
                subTitleBold
              />
              <SubMenuItem
                font="14px"
                text={`% ${convertFirstChartToUpperCase(
                  selections.selectedPresence.label
                )} (${
                  selections.selectPeriod.id === EPeriod.WEEKLY ? "MTD" : "Act"
                })`}
                bold
                noMargin
                value="--"
                minWidth="160px"
                subTitleBold
              />

              <SubMenuItem
                text={`% ${convertFirstChartToUpperCase(
                  selections.selectedPresence.label
                )} (${
                  selections.selectPeriod.id === EPeriod.WEEKLY ? "W49" : "LM"
                })`}
                bold
                noMargin
                font="14px"
                value="--"
                minWidth="160px"
                subTitleBold
              />

              <SubMenuItem
                text={`% ${convertFirstChartToUpperCase(
                  selections.selectedPresence.label
                )} (${
                  selections.selectPeriod.id === EPeriod.WEEKLY ? "W48" : "L3M"
                })`}
                bold
                noMargin
                font="14px"
                value="--"
                minWidth="160px"
                subTitleBold
              />

              <SubMenuItem
                text={`% ${convertFirstChartToUpperCase(
                  selections.selectedPresence.label
                )} (${
                  selections.selectPeriod.id === EPeriod.WEEKLY ? "W47" : "YTD"
                })`}
                font="14px"
                bold
                noMargin
                value="--"
                minWidth="160px"
                subTitleBold
              />
              {selections.selectPeriod.id === EPeriod.WEEKLY && (
                <>
                  <SubMenuItem
                    text={`% ${convertFirstChartToUpperCase(
                      selections.selectedPresence.label
                    )} (W46)`}
                    bold
                    noMargin
                    font="14px"
                    value="--"
                    minWidth="160px"
                    subTitleBold
                  />

                  <SubMenuItem
                    text={`% ${convertFirstChartToUpperCase(
                      selections.selectedPresence.label
                    )} (W45)`}
                    font="14px"
                    bold
                    noMargin
                    value="--"
                    minWidth="160px"
                    subTitleBold
                  />
                </>
              )}
            </WrapperCardSubMenu>
          </Flex> */}
          <Flex
            position="relative"
            mt="5px !important"
            h="45px"
            bg={theme.colors.header}
            w="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Flex position="fixed">
              <Text fontSize="20px" fontWeight="bold">
                Detalhamento de execução | Bandeira:{" "}
                {` ${
                  SFilters.promoFilter.banner.selectedFilterName || "Todas"
                } `}{" "}
                | Região:{" "}
                {` ${
                  SFilters.promoFilter.region.selectedFilterName || "Todas"
                }`}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Stack bg="white" mt="5px !important" p="5px" overflow="auto">
          <Flex>
            <Flex
              w={`${widthContent + 148}px`}
              minW="fit-content"
              p="10px"
              justifyContent="space-between"
            >
              <Flex>
                <Image src={calendarIcon} alt="logo-store" w="40px" />
                {/* setTemporalFilter={setTemporalFilter} */}
                <TemporalFilter
                  setTemporalFilter={handleSelectedPeriod}
                  temporalFilter={SFilters.internalKpiProduct.selectPeriod}
                  itens={[
                    { id: EPeriod.MONTHLY, label: "Mensal" },
                    { id: EPeriod.WEEKLY, label: "Semanal" },
                  ]}
                />
              </Flex>
              <Flex>
                <Image src={kpIcon} alt="logo-store" w="40px" />
                {/* setTemporalFilter={setLavarageFilter} */}
                {/* setTemporalFilter={handleSelectedLavarage} */}
                <TemporalFilter
                  setTemporalFilter={handleSelectedLavarage}
                  temporalFilter={SFilters.internalKpiProduct.selectedPresence}
                  itens={[
                    { id: ELavarage.PRESENCA, label: "Presenca" },
                    { id: ELavarage.GONDOLA, label: "Gondola" },
                    { id: ELavarage.PONTO_EXTRA, label: "Ponto Extra" },
                  ]}
                />
              </Flex>
            </Flex>
            <Flex
              flex={1}
              justifyContent={
                SFilters.internalKpiProduct.selectedPresence.id !==
                ELavarage.PRESENCA
                  ? "space-between"
                  : "flex-end"
              }
              pl="20px"
            >
              {SFilters.internalKpiProduct.selectedPresence.id !==
                ELavarage.PRESENCA && (
                <Flex position="relative">
                  {/* <ListBox
                    itens={generateListFilter()}
                    icon={lavarageIcon}
                    iconSize="50px"
                    minWidth="200px"
                  /> */}
                  {stores && (
                    <Flex justifyContent="flex-end" align="center" mr={2}>
                      <Image
                        src={lavarageIcon}
                        alt="icon-funnel"
                        width="40px"
                      />
                      <Select
                        ml="5px"
                        p="0"
                        borderRadius="5px"
                        as="select"
                        className="selectForm"
                        borderColor="black"
                        h="28px"
                        fontSize="12px"
                        color="black"
                        onChange={(e) => {
                          const category = e.target.value;
                          DFilters.changeInternalFilterKpiProduct({
                            ...SFilters.internalKpiProduct,
                            selectedCategoryFilter: category,
                            selectedBrandFilter: "",
                          });
                        }}
                      >
                        <option value="">Todas</option>
                        {SAllFilters.data?.myExecution?.subviewOSA?.secondary.categories_promo?.map(
                          (item) => (
                            <option
                              selected={
                                item.id ===
                                SFilters.internalKpiProduct
                                  .selectedCategoryFilter
                              }
                              value={item.id}
                              key={Math.random()}
                            >
                              {(SFilters.internalKpiProduct.selectedPresence
                                .id === ELavarage.GONDOLA &&
                                "SOS") ||
                                "PE"}{" "}
                              - {item.label}
                            </option>
                          )
                        )}
                      </Select>
                    </Flex>
                  )}
                  {/* <ListBox
                      itens={generateListFilter()}
                      icon={productsIcon}
                      iconSize="50px"
                      minWidth="200px"
                      marginLeft="20px"
                  /> */}
                  {/* {banners && (
                    <Flex justifyContent="flex-end" align="center">
                      <Image
                        src={productsIcon}
                        alt="icon-banner"
                        width="40px"
                      />
                      <Select
                        ml="5px"
                        p="0"
                        borderRadius="5px"
                        as="select"
                        className="selectForm"
                        borderColor="black"
                        h="28px"
                        fontSize="12px"
                        color="black"
                        onChange={(e) => {
                          const brand = e.target.value;
                          DFilters.changeInternalFilterKpiProduct({
                            ...SFilters.internalKpiProduct,
                            selectedBrandFilter: brand,
                          });
                        }}
                      >
                        <option value="">Todas</option>
                        {SAllFilters.data.myExecution.subviewOSA.secondary.brands_promo
                          .filter(
                            (category) =>
                              category.categoryPromoId === selectedStore
                          )
                          ?.map((item: filter) => (
                            <option
                              selected={
                                item.id ===
                                SFilters.internalKpiProduct.selectedBrandFilter
                              }
                              value={item.id}
                              key={Math.random()}
                            >
                              {item.label}
                            </option>
                          ))}
                      </Select>
                    </Flex>
                  )} */}
                </Flex>
              )}
              {/* <Flex
                align="center"
                cursor="pointer"
                position="fixed"
                right="25px"
                onClick={() => exportExcelSelected()}
              >
                <Text fontWeight="bold" pr="15px" color="black">
                  Exportar para
                </Text>
                <Image src={excel} w="50px" h="50px" />
              </Flex> */}
            </Flex>
          </Flex>
          {/* <Flex pl="13px" py="5px" alignItems="center" color="black">
            <Text ml="20px">Exibir</Text>
            <Select
              w="100px"
              ml="10px"
              h="30px"
              onChange={handleLimitItem}
              borderColor="black"
            >
              <option value={data?.total || 0}>Todos</option>
              <option
                selected={SFilters.internalKpiProduct.limitItem === 25}
                value={25}
              >
                25
              </option>
              <option
                selected={SFilters.internalKpiProduct.limitItem === 50}
                value={50}
              >
                50
              </option>
              <option
                selected={SFilters.internalKpiProduct.limitItem === 100}
                value={100}
              >
                100
              </option>
            </Select>
            <Text ml="10px !important">Itens</Text>
          </Flex> */}
          {(loadingData && <LoadingComponent />) ||
            ((!cacheData || cacheData.dataTable.length === 0) && (
              <EmptyMessage />
            )) || (
              <>
                <Flex
                  overflow="auto"
                  h="auto"
                  maxW="100%"
                  zIndex={1000}
                  bg="white"
                >
                  <Stack ref={refDivContent} minW="auto">
                    <Flex
                      backgroundColor="#e7e6e6"
                      p="8px 12px"
                      alignItems="center"
                    >
                      <Text color="#c00000" fontWeight="bold" fontSize="14px">
                        DETALHAMENTO
                      </Text>
                    </Flex>
                    <TableSubView
                      refElement={refTable}
                      headers={headers}
                      dataTable={cacheData?.dataTable || []}
                      collapse
                      selectedFilter={orderedBy}
                      selectFilter={orderListTable}
                    />
                  </Stack>
                  <Column
                    title="-"
                    subTitle={switchTitleByIndicators()}
                    itens={cacheData?.activeStore || []}
                  />
                  <Column
                    title={cacheData?.act.total || "-"}
                    subTitle={`% ${convertFirstChartToUpperCase(
                      SFilters.internalKpiProduct.selectedPresence.label
                    )} \n(${
                      SFilters.internalKpiProduct.selectPeriod.id ===
                      EPeriod.WEEKLY
                        ? cacheData.act.label
                        : "Act"
                    })`}
                    selectedOrder={orderedBy}
                    id={6}
                    selectFilter={orderListColumns}
                  >
                    <Stack mt="-1px !important">
                      <ShareValue
                        mt={0}
                        dataGraphic={cacheData?.act.data}
                        height={heightGraphics}
                        gap={0.07}
                        color="#a6a6a6"
                      />
                    </Stack>
                  </Column>
                  {/* subTitle={`% ${convertFirstChartToUpperCase(
                    SFilters.internalKpiProduct.selectedPresence.label
                  )} (${
                    SFilters.internalKpiProduct.selectPeriod.id === EPeriod.WEEKLY
                      ? cacheData?.lm.label || "-"
                      : "LM"
                  })`} */}
                  <Column
                    title={cacheData?.lm.total || "-"}
                    subTitle={`% ${convertFirstChartToUpperCase(
                      SFilters.internalKpiProduct.selectedPresence.label
                    )} \n(${
                      SFilters.internalKpiProduct.selectPeriod.id ===
                      EPeriod.WEEKLY
                        ? cacheData?.lm.label || "-"
                        : "LM"
                    })`}
                    selectedOrder={orderedBy}
                    id={7}
                    selectFilter={orderListColumns}
                  >
                    <Stack ref={refSubMenu} mt="-1px !important">
                      <ShareValue
                        mt={0}
                        dataGraphic={cacheData?.lm.data}
                        height={heightGraphics}
                        gap={0.07}
                        color="#1f497d"
                      />
                    </Stack>
                  </Column>
                  <Column
                    title={cacheData?.l3m.total || "-"}
                    subTitle={`% ${convertFirstChartToUpperCase(
                      SFilters.internalKpiProduct.selectedPresence.label
                    )} \n(${
                      SFilters.internalKpiProduct.selectPeriod.id ===
                      EPeriod.WEEKLY
                        ? cacheData?.l3m.label || "-"
                        : "L3M"
                    })`}
                    selectedOrder={orderedBy}
                    id={8}
                    selectFilter={orderListColumns}
                  >
                    <Stack ref={refSubMenu} mt="-1px !important">
                      <ShareValue
                        mt={0}
                        dataGraphic={cacheData?.l3m.data}
                        height={heightGraphics}
                        gap={0.07}
                      />
                    </Stack>
                  </Column>
                  <Column
                    title={cacheData?.ytd.total || "-"}
                    subTitle={`% ${convertFirstChartToUpperCase(
                      SFilters.internalKpiProduct.selectedPresence.label
                    )} \n(${
                      SFilters.internalKpiProduct.selectPeriod.id ===
                      EPeriod.WEEKLY
                        ? cacheData?.ytd.label || "-"
                        : "YTD"
                    })`}
                    selectedOrder={orderedBy}
                    id={9}
                    selectFilter={orderListColumns}
                  >
                    <Stack ref={refSubMenu} mt="-1px !important">
                      <ShareValue
                        mt={0}
                        dataGraphic={cacheData?.ytd.data}
                        height={heightGraphics}
                        gap={0.07}
                      />
                    </Stack>
                  </Column>
                  {SFilters.internalKpiProduct.selectPeriod.id ===
                    EPeriod.WEEKLY && (
                    <>
                      {cacheData?.lastWeek && (
                        <Column
                          title={cacheData?.lastWeek.total || "-"}
                          subTitle={`% ${convertFirstChartToUpperCase(
                            SFilters.internalKpiProduct.selectedPresence.id
                          )} \n(${cacheData?.lastWeek.label || "-"})`}
                          selectedOrder={orderedBy}
                          id={10}
                          selectFilter={orderListColumns}
                        >
                          <Stack ref={refSubMenu} mt="-1px !important">
                            <ShareValue
                              mt={0}
                              dataGraphic={cacheData?.lastWeek.data}
                              height={heightGraphics}
                              gap={0.07}
                            />
                          </Stack>
                        </Column>
                      )}

                      {cacheData?.penultimateWeek && (
                        <Column
                          title={cacheData?.penultimateWeek.total || "-"}
                          subTitle={`% ${convertFirstChartToUpperCase(
                            SFilters.internalKpiProduct.selectedPresence.id
                          )} \n(${cacheData?.penultimateWeek.label || "-"})`}
                          selectedOrder={orderedBy}
                          id={11}
                          selectFilter={orderListColumns}
                        >
                          <Stack ref={refSubMenu} mt="-1px !important">
                            <ShareValue
                              dataGraphic={cacheData?.penultimateWeek.data}
                              height={heightGraphics}
                              gap={0.07}
                            />
                          </Stack>
                        </Column>
                      )}
                    </>
                  )}
                </Flex>
              </>
            )}
        </Stack>
      </Grid>
    )
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
    DFilters: bindActionCreators(AFilters, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailKPI);
