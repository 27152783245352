import { Box, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import { SubMenuItem } from "../Contents/Resources/SubView/subView";
import { Divisor } from "../Contents/subMenu";
import selectABrand from "../../assets/Industria/logo.png";
import Less from "../../assets/img/icons/minus-circle.png";
import More from "../../assets/img/icons/plus-circle.png";
import { BSLegendTable } from "./style.element";
import { IHeaderPanelData } from "../../services/Hooks/MyExecution/DetailStore/useComplianceStore";
import { IStateFilters } from "../../store/SideFilters/types";

export interface IGeneralPanel {
  text: string;
  value: string;
}

interface IOwnProps {
  widthLeftHeader: string;
  margin: string;
  children: JSX.Element;
  gridColumn: string;
  generalPanel: IGeneralPanel[];
  isLoading: boolean;
  isEmpty: boolean;
  filters: IStateFilters;
  quantityItem: number;
}

const HeaderDetailPage: React.FC<IOwnProps> = ({
  widthLeftHeader,
  gridColumn,
  children,
  margin,
  generalPanel,
  isLoading,
  isEmpty,
  filters,
  quantityItem,
}) => {
  return (
    <Box w="max-content" minW="100%">
      <Flex
        bg="white"
        color="black"
        h="85px"
        alignItems="center"
        px="4px"
        pr="10px"
      >
        <Image src={selectABrand} width="142px" p={5} />
        <Divisor />
        {!isLoading && !isEmpty && (
          <>
            <BSLegendTable minWidth={widthLeftHeader}>
              {generalPanel.map((value, index) => (
                <SubMenuItem
                  bold={index === 0 || index === 3}
                  font="24px"
                  marginP
                  titleBoxAlign={index === 0 || index === 3 ? "center" : ""}
                  icon={(index === 1 && More) || (index === 2 && Less) || ""}
                  subTitleBold
                  text={value.text}
                  value="-"
                />
              ))}
            </BSLegendTable>
            <Grid gridTemplateColumns={gridColumn} margin={margin} w="100%">
              {children}
            </Grid>
          </>
        )}
      </Flex>
      <Flex
        position="relative"
        mt="5px !important"
        h="45px"
        bg="#152b68"
        w="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          position="fixed"
          right="0px"
          mr="5px !important"
          justifyContent="center"
          w="calc(100vw - 240px)"
        >
          <Text fontSize="20px" fontWeight="bold">
            Detalhamento do COMPLIANCE % TOP {quantityItem || "0"} Lojas
            Ofensoras | Bandeira:{" "}
            {filters.promoFilter.banner.selectedFilterName || "Todos"} | Região:{" "}
            {filters.promoFilter.region.selectedFilterName || "Todos"}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HeaderDetailPage;
