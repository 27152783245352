import { useQuery, UseQueryResult } from "react-query";
import api from "../../api";

interface IOwnProps {
  inputEmailRecovery: string;
  setErrorMessage(error: string): void;
  setRenderingForms(screen: number): void;
  setInputEmailRecovery(emailRecovery: string): void;
}

export enum ERenderingForms {
  "FormsLogins" = 0,
  "SendEmail" = 1,
  "SendNewPassword" = 2,
  "ScreenMessage" = 3,
}

const requestResetPassword = async (props: IOwnProps) => {
  const { data } = await api.get("password_reset_request/", {
    params: {
      email: props.inputEmailRecovery,
    },
  });

  if (data.status === 200) {
    props.setErrorMessage("");
    props.setInputEmailRecovery("");
    props.setRenderingForms(ERenderingForms.ScreenMessage);
  } else {
    props.setErrorMessage(data.message);
  }

  return data;
};

export function useResetPassword(
  props: IOwnProps
): UseQueryResult<any, unknown> {
  return useQuery(["resetPassword", props], () => requestResetPassword(props), {
    refetchOnWindowFocus: false,
    enabled: false,
    retry: 2,
  });
}
