import styled from "styled-components";

interface IPropsStyle {
  showCloseButton?: boolean;
  onScreen?: boolean;
  bgColor?: string;
  height?: string;
  width?: string;
  margin?: string;
  color?: string;
  border?: string;
  position?: string;
  isToast?: boolean;
  fontSize?: string;
  opacity?: string;
  borderRadius?: string;
  marginX?: string;
  overflow?: string;
  maxH?: string;
}

export const ModalOverlay = styled.div<IPropsStyle>`
  @keyframes FadeOut {
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${(props) => !props.onScreen && `animation: FadeIn 0.3s;`}
  ${(props) => props.onScreen && `animation: FadeOut 0.3s;`}

  position: ${(props) => (props.isToast ? "absolute" : "fixed")};
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) =>
    props.isToast ? "transparent" : " rgba(0, 0, 0, 0.5)"};
  overflow: hidden;
  margin-top: 0px !important;
`;

export const ModalWrapper = styled.div<IPropsStyle>`
  position: ${(props) => (props.isToast ? "absolute" : "fixed")};
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  overflow: hidden;
`;

export const ModalContainer = styled.div<IPropsStyle>`
  z-index: 100;
  background: ${(props) => props.bgColor || "#fff"};
  opacity: ${(props) => props.opacity || "1"};
  position: relative;
  margin: ${(props) => props.margin || "20px auto"};
  border-radius: 3px;
  border: ${(props) => props.border};
  width: ${(props) => props.width || "auto"};
  max-width: 800px;
  max-height: ${(props) => props.height || ""};
  padding: 8px;
  border-radius: ${(props) => props.borderRadius || "4px"};
  overflow: ${(props) => props.overflow || "hidden"};
`;

export const ModalHeaderContainer = styled.header<IPropsStyle>`
  color: ${(props) => props.color || "var(--base-text)"};
  text-align: ${(props) =>
    props.position === "flex-start" ? "left" : "center"};
  display: flex;
  justify-content: ${(props) => props.position || "center"};
  align-items: center;
  border-bottom: ${(props) => props.border || "1px solid #ddd"};
  padding: ${(props) =>
    props.position === "flex-start" ? "4px 12px" : "4px 0"};
  font-weight: bold;
  background: ${(props) => props.bgColor};
  margin-left: ${(props) => props.marginX};
  margin-right: ${(props) => props.marginX};

  h4 {
    width: 99%;
    font-size: ${(props) => props.fontSize};
    /* margin-bottom: -2px; */
    margin-top: 14px;
    margin-right: 10px;

    display: ${(props) => (props.isToast ? "flex" : "")};
    align-items: ${(props) => (props.isToast ? "center" : "")};
    justify-content: ${(props) => (props.isToast ? "space-evenly" : "")};

    svg {
      margin-right: 12px;
      margin-top: -4px;
    }
  }
  button {
    transition: filter 0.2s;

    :hover {
      filter: brightness(0.9);
    }
  }
`;

export const ModalButtonClose = styled.button<IPropsStyle>`
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;

  display: ${(props) => (props.showCloseButton ? "show" : "none")};
`;

export const ModalBodyContainer = styled.main<IPropsStyle>`
  padding: 8px 0;
  height: ${(props) => props.height};
  margin: 0 auto;
  overflow: ${(props) => props.overflow || "auto"};
  max-height: ${(props) => props.maxH};

  p {
    font-size: 14px;
    padding-left: 8px;
  }
`;

export const ModalFooterContainer = styled.footer<IPropsStyle>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 0 8px 0;

  border-top: ${(props) => props.border || "none"};
  margin: ${(props) => props.margin || "0"};
`;
