import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, createStore, Store } from "redux";

import reducers from "./rootReducers";
import rootSaga from "./rootSaga";

import { stateType } from "./Navigation/types";
import { stateMarketShare } from "./SideFilters/RequestFilters/types";
import { stateAuth } from "./Authentication/Login/types";
import { stateLogout } from "./Authentication/Logout/types";
import { stateProfile } from "./Profile/types";
import { IStateFilters } from "./SideFilters/types";

export interface GlobalProps {
  navigation: stateType;
  marketShareCategory: stateMarketShare;
  loginAuth: stateAuth;
  profile: stateProfile;
  RSubFilter: IStateFilters;
  logoutAuth: stateLogout;
}

const sagaMiddleware = createSagaMiddleware();

const store: Store<GlobalProps> = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
