import { action } from 'typesafe-actions';
import {
  typeCategory,
  stateMarketShare,
  ILoadError,
  ILoadRequest,
  ILoadSuccess,
} from './types';

export const loadRequest = (): ILoadRequest =>
  action(typeCategory.LOAD_REQUEST);

export const loadSuccess = (data: stateMarketShare): ILoadSuccess =>
  action(typeCategory.LOAD_SUCCESS, data);

export const loadFailure = (): ILoadError => action(typeCategory.LOAD_FAILURE);
