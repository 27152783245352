import React from "react";
import { ImSpinner2 } from "react-icons/im";
import { ConteinerSpinnerLoading } from "./styled";

interface IOwnProps {
  text?: string;
}

const LoadingComponent: React.FC<IOwnProps> = ({ text }: IOwnProps) => {
  return (
    <ConteinerSpinnerLoading>
      <ImSpinner2 size={30} />
      <span>{text || "Carregando dados..."}</span>
    </ConteinerSpinnerLoading>
  );
};

LoadingComponent.defaultProps = {
  text: undefined,
};

export default LoadingComponent;
