import React, { useContext } from "react";
import PlotlyChart from "react-plotlyjs-ts";
import { ConfigContext } from "../../../services/Context/ConfigContext";

// configiguration platform
import { ITypeGraphicPeriodBar } from "../../../services/Hooks/OnePage/onePageTypes";

interface ownProps {
  dataGraphic: ITypeGraphicPeriodBar;
  showSideLegend?: boolean;
}

const MarketSharePeriodBarLtyYtd: React.FC<ownProps> = ({
  dataGraphic,
  showSideLegend,
}: ownProps) => {
  const { x } = dataGraphic ?? { label: [], x: [], y: [] };

  const { config } = useContext(ConfigContext);

  const trace1 = {
    x,
    y: [60, 35, -30, 40, 38, -35, 55],
    marker: {
      color: [
        "#00AAD2",
        "#00AAD2",
        "#f00",
        "#00AAD2",
        "#00AAD2",
        "#f00",
        "#00AAD2",
      ],
    },
    type: "bar",
    textfont: { color: "#000000", size: config.fonts.graphics },
    hoverinfo: "x+text",
    showlegend: false,
    orientation: "v",
    textposition: "outside",
  };

  const data = [trace1];

  const layout = {
    height: 250,
    bargap: 0.35,
    annotations: [
      {
        x: -1,
        y: 1,
        xref: "x",
        yref: "y",
        text: "Base line 0",
        showarrow: false,
        bgcolor: "white",
        width: 70,
        font: {
          color: "black",
          size: config.fonts.graphics,
        },
      },
    ],
    xaxis: {
      side: "bottom",
      type: "category",
      range: [-0.6, 20.2],
      ticks: "",
      title: { font: { color: "rgb(0, 0, 0)" } },
      showgrid: false,
      showline: false,
      tickfont: { color: "#595959", size: config.fonts.graphics },
      tickmode: "auto",
      zeroline: false,
      linecolor: "#c0c0c0",
      autorange: true,
      tickangle: "auto",
      automargin: true,
      fixedrange: true,
      showspikes: false,
      tickprefix: "",
      showticklabels: true,
    },
    yaxis: {
      type: "linear",
      range: [-50, 60],
      showgrid: false,
      zeroline: true,
      autorange: false,
      fixedrange: false,
      showspikes: false,
      zerolinewidth: 1,
      showticklabels: showSideLegend,
      yanchor: "top",
    },
    margin: {
      b: 30,
      l: 0,
      r: 15,
      t: 50,
      pad: 0,
    },
  };

  const configGraphic = {
    displayModeBar: false,
  };

  return <PlotlyChart data={data} layout={layout} config={configGraphic} />;
};

MarketSharePeriodBarLtyYtd.defaultProps = {
  showSideLegend: false,
};

export default MarketSharePeriodBarLtyYtd;
