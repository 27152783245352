import styled from "styled-components";
import { theme } from "../../../../styles/theme";

const lightGray = "#E7E6E6";

interface propStyle {
  block?: boolean;
  marginTop?: string;
  marginBottom?: string;
  padding?: string;
  right?: boolean;
  img?: string;
  marginLeft?: string;
  width?: string;
  margin?: string;
  isWaterfall?: boolean;
  align?: string;
  height?: string;
  minWidth?: string;
}

export const InputFilters = styled.div<propStyle>`
  display: flex;
  justify-content: ${(props) => (props.right ? "flex-end" : "space-between")};
  align-items: center;
  width: 100%;
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  ${(props) =>
    props.isWaterfall &&
    `
      position: absolute;
      top: 50%;
      left: 20%;
      height: 304px;
    `};

  & > .selectForm {
    width: 130px;
    margin-left: 3px;
    height: 25px;
    padding-top: 0px;
    font-size: 14px;
  }

  & > .right {
    display: block;
    text-align: end;
    padding-right: 3px;
  }
`;

export const ImageCentral = styled.div`
  left: calc(50% - 44px);
  top: calc(50% - 44px);
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 89px;
  width: 88px;
  border-radius: 52%;
  overflow: hidden;

  & > img {
    width: 95px;
  }

  & > span {
    position: absolute;
    text-align: center;
    width: 15%;
    font-weight: 700;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
  }
`;

interface IPropsWrapperGraphics {
  height?: string;
  align?: string;
  padding?: string;
  selectedCard?: boolean;
  overflow?: string;
  overflowX?: string;
}

export const WrapperGraphics = styled.div<IPropsWrapperGraphics>`
  width: 100%;
  height: ${(props) => props.height || "calc(100% - 40px)"};
  overflow-y: ${(props) => props.overflow || "hidden"};
  display: flex;
  overflow-x: ${(props) => props.overflowX || ""};
  justify-content: flex-end;
  align-items: ${(props) => props.align || ""};
  flex-direction: column;
  border-bottom: 40px;
  box-sizing: border-box;
  padding: ${(props) => props.padding || "0"};
  background-color: white;
  position: relative;

  .img-omr {
    width: 50%;
    height: 100%;

    margin: 0 auto;
  }

  & > img {
    display: block;
    margin: 0 auto;
    object-fit: contain;
    padding: 0;
    height: 100%;
  }

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }

  ${(props) =>
    props.selectedCard &&
    `
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      border: 4px solid #6ec1e4;
    }

	&::after {
		content: '';
		width: 0;
		height: 0;
		border-top: solid 15px #6ec1e4;
	  	border-left: solid 50px transparent;
	  	border-right: solid 50px transparent;
		position: absolute;
		left: calc(50% - 48px);
		bottom: -14px;
		z-index: 5;
	  }



  `}
`;

export const FirstGroup = styled.div`
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SecondGroup = styled.div`
  height: 100%;
`;

export const Main = styled.main<propStyle>`
  width: 100%;
  height: ${(props) => props.height || "100%"};
  display: flex;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
`;

export const Legend = styled.div<propStyle>`
  height: auto;
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.block ? "column" : "row")};
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  min-width: ${(props) => props.minWidth};
`;

export const LegendOMS = styled.div`
  & > div:first-child {
    font-size: 14px;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & > span + span {
      position: absolute;
      right: 0;
      text-align: center;
      width: 125px;
      font-size: 10px;
      top: -4px;

      b {
        font-size: 10.5px;
      }
    }
  }
`;

export const LabelText = styled.div<{ top?: string }>`
  margin-top: ${(props) => props.top || "0px"};
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  right: 0;
`;

export const ImgLabel = styled.img`
  width: 36px;
  height: 36px;
`;

export const TopicLabelText = styled.h3`
  color: #fff;
  font-size: 1.3rem;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  background-color: #bf9000;
  display: block;
  position: relative;
  line-height: 1.5;
  text-align: center;
  font-weight: 700;
`;

export const LegendLabel = styled.span`
  font-size: 12px;
  padding: 5px 30px 5px 6px;
  font-weight: 400;
`;

export const SelectGroup = styled.div`
  .selectForm {
    margin: 2px;
  }
`;

export const SeeDatail = styled.button<propStyle>`
  height: 30px;
  color: black;
  background-color: ${lightGray};
  text-align: left;
  padding-left: 10px;
  padding-right: 50px;
  border: none;
  display: flex;
  align-items: center;

  &::after {
    content: " ";
    background-image: url(${(props) => props.img});
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    background-size: 45px 45px;
    background-repeat: no-repeat;
    margin-top: 10px;
    z-index: 1;
  }
`;

export const Rotate = styled.span`
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
  /* margin-left: -140px;
  margin-top: -93px; */
`;

export const Multimput = styled.div<propStyle>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
  font-size: 10.5px;

  height: 100%;
  justify-content: ${(props) => (props.right ? "flex-end" : "flex")};

  .msl-store-img {
    height: 40px;
    width: 40px;
  }

  & > b {
    text-align: center;
    margin-right: 10px;
  }

  & > .selectForm {
    width: 130px;
    margin-left: 3px;
    height: 25px;
    padding-top: 0px;
    font-size: 14px;
  }

  .data-referencia {
    text-align: center;
    font-weight: 500;
    font-size: 10.5px;

    margin-right: auto;
    margin-top: auto;
    z-index: 1;

    p {
      font-weight: 400;
    }
  }
`;

export const ContainerSellOut = styled.span`
  display: flex;
  margin-top: 10px;
  overflow: hidden;
`;

export const MarketShareDC = styled.div<{
  marginTop?: string;
  columns?: string;
}>`
  i {
    font-weight: bold;
    font-size: 18px;
  }

  display: grid;
  grid-template-columns: ${(props) => props.columns};
  grid-column-gap: 5px;
`;

export const MarketImage = styled.span`
  position: absolute;
  text-align: center;
  width: 18%;
  font-weight: 600;
  left: 5px;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  & > i {
    font-size: 14px;
    font-weight: normal;
  }

  & > img {
    width: 85%;
  }
`;

export const GridContribuition = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(3, 140px);
  grid-column-gap: 5px;
`;
