import { Flex } from "@chakra-ui/react";
import { filter } from "../../store/SideFilters/RequestFilters/types";

export enum ESubContribuition {
  MAT = "MAT",
  YTD = "YTD",
  U3M = "U3M",
  LP = "LP",
}

interface IOwnProps {
  setTemporalFilter(filter: filter): void;
  temporalFilter: filter;
  itens: filter[];
}

const TemporalFilter: React.FC<IOwnProps> = ({
  setTemporalFilter,
  temporalFilter,
  itens,
}: IOwnProps): JSX.Element => {
  return (
    <Flex
      bg="#E7E6E6"
      m="5px"
      w="fit-content"
      h="fit-content"
      borderRadius={7}
      p="3px 5px"
      color="black"
      zIndex={10}
    >
      {itens.map((item, index) => {
        return (
          <Flex
            fontSize="12px"
            h="25px"
            w="95px"
            bg={
              (temporalFilter.id.toLowerCase() === item.id.toLowerCase() &&
                "white") ||
              "transparent"
            }
            onClick={() => setTemporalFilter(item)}
            cursor="pointer"
            ml={index !== 0 ? "3px" : "0"}
            borderRadius={5}
            justify="center"
            align="center"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            {item.label}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default TemporalFilter;
