import { ISubTableComplements } from "../../services/Hooks/MyExecution/types";
import {
  ITypeGraphicHorizontalBar,
  ITypeGraphicPeriod,
  ITypeGraphicPeriodBar,
} from "../../services/Hooks/OnePage/onePageTypes";
import {
  IFormatData,
  IFormatDataWaterfall,
} from "../../services/Hooks/OnePage/useMarketShareContribution";
import { ITableData } from "../TableWithGraphic";

interface IDataPSAbsolute {
  leftChartPeriod: ITypeGraphicPeriod;
  rightChartPeriod: ITypeGraphicPeriod;
  leftChartBar: ITypeGraphicPeriodBar;
  rightChartBar: ITypeGraphicPeriodBar;
}

interface IPSDetails {
  waterfall: IFormatDataWaterfall;
  realized: IFormatData;
  storeUniverse: ITypeGraphicHorizontalBar;
  varPeriod: ITypeGraphicHorizontalBar;
}

export interface IBottomTable {
  ofensores: ITableData;
  top: ITableData;
}

export const perfectStoreAbsolut: IDataPSAbsolute = {
  rightChartBar: {
    x: [
      "P1'23",
      "P2'23",
      "P3'23",
      "P4'23",
      "P5'23",
      "P6'23",
      "P7'23",
      "P8'23",
      "P9'23",
      "P10'23",
      "P11'23",
      "P12'23",
    ],
    label: ["492", "599", "577", "594", "562", "", "", "", "", "", "", ""],
    y: [492, 599, 577, 594, 562, 0, 0, 0, 0, 0, 0, 0],
  },
  leftChartBar: {
    label: ["592", "525"],
    x: ["YTD", "L3M"],
    y: [592, 525],
  },
  leftChartPeriod: {
    label: ["0", "0"],
    y: [0, 0],
  },
  rightChartPeriod: {
    label: [
      "463",
      "500",
      "485",
      "546",
      "467",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
    ],
    y: [463, 500, 485, 546, 467, 0, 0, 0, 0, 0, 0, 0],
  },
};

export const PSAbsolutSubCard: ISubTableComplements = {
  presenceSubChannel: {
    rightBar: {
      x: ["Varejo", "Cash & Carry", "Key Account"],
      label: ["259", "173", "58"],
      y: [259, 173, 58],
    },
    periodLine: {
      label: ["311", "207", "69"],
      y: [311, 207, 69],
    },
  },
  presenceRegion: {
    rightBar: {
      label: ["184", "161", "69", "115", "45"],
      x: ["Centro Oeste", "Nordeste", "Norte", "Sudeste", "Sul"],
      y: [184, 161, 69, 115, 46],
    },
    periodLine: {
      label: ["221", "194", "83", "138", "55"],
      y: [221, 194, 83, 138, 55],
    },
  },
  presenceBanner: {
    rightBar: {
      label: ["69", "46", "121", "40", "69", "86", "104", "17", "17", "6"],
      x: [
        "Atacadão",
        "Assai",
        "BH",
        "Carrefour",
        "Nagumo",
        "Tenda",
        "Villef.",
        "Coelho diniz",
        "Jau Serve",
        "Savegn.",
      ],
      y: [69, 46, 121, 40, 69, 86, 104, 17, 17, 6],
    },
    periodLine: {
      label: ["83", "55", "145", "48", "83", "104", "124", "21", "21", "7"],
      y: [83, 55, 145, 48, 83, 104, 124, 21, 21, 7],
    },
  },
};

export const DetailPerfectStoreData: IPSDetails = {
  waterfall: {
    label: ["", "<b>3</b>", "<b>1</b>", "<b>-3</b>", "<b>4</b>", ""],
    x: ["580", "0", "0", "0", "0", "573"],
    y: ["P5`23", "Varejo", "Cash & Carry", "Key Account", "Outros", "P4`23"],
    measure: ["total", "relative", "relative", "relative", "relative", "total"],
  },
  realized: {
    label: ["259", "173", "58", "25 / 25"],
    x: [],
    y: [],
  },
  storeUniverse: {
    label: ["75%", "87%", "67%", "100%"],
    x: [75, 87, 67, 100],
    y: ["75", "87", "67", "100"],
  },
  varPeriod: {
    label: ["4,0%", "1,8%", "-2,5%", "1,2%"],
    x: [4, 1.8, -2.5, 1.2],
    y: ["4", "1.8", "-2.5", "1.2"],
  },
};

export const PSDetailsTable: ITableData = {
  header: [
    "Canal",
    "Top Clientes",
    "Realizado",
    "% Lojas",
    "% Var",
    "% Contribuição",
  ],
  growth: {
    label: ["0,0%", "0,0%", "0,0%", "0,0%", "0,0%", "0,0%", "0,0%", "0,0%"],
    x: [0, 0, 0, 0, 0, 0, 0, 0],
    y: ["0,0%", "0,0%", "0,0%", "0,0%", "0,0%", "0,0%", "0,0%", "0,0%"],
  },
  data: [
    ["Varejo", "25", "67,3%", "-1,2%"],
    ["Cash & Carry", "10", "92,0%", "4,3%"],
    ["Key Account", "15", "75,4%", "3,2%"],
    ["", "", "-", "0,0%"],
    ["", "", "-", "0,0%"],
    ["", "", "-", "0,0%"],
    ["", "", "-", "0,0%"],
    ["", "", "-", "0,0%"],
  ],
};

export const DetailPerfectStoreDataTable: IPSDetails = {
  waterfall: {
    label: ["", "<b>3</b>", "<b>1</b>", "<b>-3</b>", "<b>4</b>", ""],
    x: ["580", "0", "0", "0", "0", "573"],
    y: ["P5`23", "Varejo", "Cash & Carry", "Key Account", "Outros", "P4`23"],
    measure: ["total", "relative", "relative", "relative", "relative", "total"],
  },
  realized: {
    label: ["259", "173", "58", "86", "25 / 25"],
    x: [],
    y: [],
  },
  storeUniverse: {
    label: ["75%", "87%", "67%", "100%", "100%"],
    x: [75, 87, 67, 100, 100],
    y: ["75", "87", "67", "100", "100"],
  },
  varPeriod: {
    label: ["4,0%", "1,8%", "-2,5%", "1,9%", "1,2%"],
    x: [4, 1.8, -2.5, 1.9, 1.2],
    y: ["4", "1.8", "-2.5", "1.9", "1.2"],
  },
};

export const subTableDetailPSPrimary = {
  header: [
    "Categoria",
    "YTD'22",
    "YTD'23",
    "Var.%",
    "L3M",
    "LP",
    "Actual",
    "L3M %",
    "LP %",
  ],
  data: [
    [
      "KPI - Presença",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
    ],
    [
      "KPI - SOS",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
    ],
    [
      "KPI - Ponto Extra",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
    ],
    [
      "KPI - Compliance",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
      "Var.%",
    ],
    ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
  ],
};

export const subTableDetailPSSecundary = {
  header: [
    "P1'23",
    "P2'23",
    "P3'23",
    "P4'23",
    "P5'23",
    "P6'23",
    "P7'23",
    "P8'23",
    "P9'23",
    "P10'23",
    "P11'23",
    "P12'23",
  ],
  data: [
    [
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
    ],
    [
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
    ],
    [
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
    ],
    [
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
      "0,0%",
    ],
    ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
  ],
};

export const PSAbsolutBottomTable: IBottomTable = {
  top: {
    header: ["Top 10 Produtos", "Bandeira", "Canal", "% Pontos"],
    data: [
      ["LOJA 01", "B", "Varejo"],
      ["LOJA 02", "C", "Varejo"],
      ["LOJA 03", "D", "Cash & Carry"],
      ["LOJA 04", "D", "Cash & Carry"],
      ["LOJA 05", "D", "Cash & Carry"],
      ["LOJA 06", "D", "Cash & Carry"],
      ["LOJA 07", "D", "Cash & Carry"],
      ["LOJA 08", "C", "Varejo"],
      ["LOJA 09", "B", "Varejo"],
      ["LOJA 10", "A", "Key Account"],
    ],
    growth: {
      label: [
        "10.0%",
        "10.0%",
        "10.0%",
        "10.0%",
        "10.0%",
        "10.0%",
        "10.0%",
        "10.0%",
        "10.0%",
        "10.0%",
      ],
      x: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      y: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    },
  },
  ofensores: {
    header: ["Top 10 Lojas", "Bandeira", "Canal", "% Pontos"],
    data: [
      ["LOJA 11", "B", "10/100"],
      ["LOJA 12", "C", "10/100"],
      ["LOJA 13", "B", "10/100"],
      ["LOJA 14", "B", "10/100"],
      ["LOJA 15", "A", "10/100"],
      ["LOJA 16", "D", "10/100"],
      ["LOJA 17", "D", "11/100"],
      ["LOJA 18", "A", "11/100"],
      ["LOJA 19", "A", "11/100"],
      ["LOJA 20", "A", "11/100"],
    ],
    growth: {
      label: [
        "10.0%",
        "10.0%",
        "10.0%",
        "10.0%",
        "10.0%",
        "10.0%",
        "11.0%",
        "11.0%",
        "11.0%",
        "12.0%",
      ],
      x: [10, 10, 10, 10, 10, 10, 11, 11, 11, 12],
      y: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    },
  },
};

export const perfectStorePontuation: IDataPSAbsolute = {
  rightChartBar: {
    x: [
      "P1'23",
      "P2'23",
      "P3'23",
      "P4'23",
      "P5'23",
      "P6'23",
      "P7'23",
      "P8'23",
      "P9'23",
      "P10'23",
      "P11'23",
      "P12'23",
    ],
    label: ["73", "73", "66", "66", "70", "", "", "", "", "", "", ""],
    y: [73, 73, 66, 66, 70, 0, 0, 0, 0, 0, 0, 0],
  },
  leftChartBar: {
    label: ["88", "67"],
    x: ["YTD", "L3M"],
    y: [88, 67],
  },
  leftChartPeriod: {
    label: ["0", "0"],
    y: [0, 0],
  },
  rightChartPeriod: {
    label: ["70", "72", "77", "74", "72", "0", "0", "0", "0", "0", "0", "0"],
    y: [70, 72, 77, 74, 72, 0, 0, 0, 0, 0, 0, 0],
  },
};

export const pontuationKPIsBar: ITypeGraphicPeriodBar = {
  x: ["KPI-1", "KPI-2", "KPI-3", "KPI-4", "KPI-5"],
  label: ["78%", "84%", "47%", "92%", "85%"],
  y: [78, 84, 47, 92, 85],
};

export const pontuationKPIsPeriod: ITypeGraphicPeriod = {
  label: ["<b>0%</b>", "<b>0%</b>", "<b>0%</b>", "<b>0%</b>"],
  y: [0, 0, 0, 0],
};
