import styled from "styled-components";
import { theme } from "../../../../styles/theme";

const gray70 = "#7A7A7A";
const gray40 = "#E7E6E6";
const gray60 = "#A6A6A6";
const lightBlue = "#a144ac";
const smoothBlue = "#44546A";

interface PropsTextCard {
  margin?: string;
}

interface PropsCardContainerImage {
  width: string;
  height: string;
  margin?: string;
}

interface PropsHeaderMonth {
  bg?: boolean;
}

interface PropsCardContentImage {
  width: string;
  height: string;
}

interface PropsRowCalendar {
  border?: boolean;
  width?: boolean;
  column?: boolean;
}
interface PropsSubRow {
  border?: boolean;
  borderBold?: boolean;
  align?: boolean;
  h4?: string;
}

interface PropsImageColumn {
  border?: boolean;
  h?: boolean;
}

interface PropsImage {
  w?: string;
  h?: string;
  bg?: string;
}

interface PropsHeaderColumn {
  align?: boolean;
  h?: boolean;
  bl?: boolean;
}

interface PropsColumnH100 {
  w?: boolean;
  ml?: string;
  minw?: boolean;
}

interface PropsSubColumn {
  w?: boolean;
  h?: boolean;
  border?: boolean;
}

interface PropsContainerColumn {
  h?: boolean;
}

export const ViewCalendarTextCard = styled.div<PropsTextCard>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};

  h1 {
    color: ${theme.colors.numberCards};
    margin-left: 4px;
    margin-right: 6px;
    font-size: 40px;
  }

  img {
    width: 32px;
    height: 32px;
    margin-right: 6px;
  }

  div {
    color: ${gray70};

    p {
      margin-bottom: 0;
    }
  }
`;

export const CardContainerImage = styled.div<PropsCardContainerImage>`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
`;

export const CardContentImage = styled.img<PropsCardContentImage>`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  margin-top: -50px;
  object-fit: cover;
`;

export const ViewCalendarBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 35px;
`;

export const CardSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--white);
  color: var(--base-text);
  padding: 0 0 4px;
  text-align: center;
  width: calc(100% - 5px);
  margin: 0 auto;

  border-bottom: 2px solid ${gray40};

  div {
    margin-left: -50px;
  }

  p {
    margin: 0;
  }
`;

export const AddNegotiation = styled.button`
  background: ${theme.colors.button};
  color: var(--white);
  width: 200px;
  border-radius: 5px;
  border: none;
  padding: 10px;
  font-weight: bold;
  margin-left: 3px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  transition: filter 0.2s;

  :hover {
    filter: brightness(0.9);
  }

  svg {
    margin-right: 10px;
  }
`;

export const ContainerCalendar = styled.div`
  width: 100%;
  min-height: 359px;
  height: calc(100% - 46px);
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 0px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }
`;

export const ShowMoreCalendar = styled.div`
  height: auto;
  overflow: auto;
`;

export const Calendar = styled.div`
  width: 100%;
  /* min-width: 1390px; */
  height: 100%;

  display: flex;
  margin-top: 10px;

  /* border: 1px solid black; */
`;

export const RowCalendar = styled.div<PropsRowCalendar>`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  width: ${(props) => (props.width ? "100%" : "50%")};
  margin-top: 10px;
  background-color: lightblue;
  /* border: 1px solid black; */
`;

export const InnerRowCalendar = styled.div<PropsRowCalendar>`
  width: ${(props) => (props.width ? "100%" : "50%")};

  /* border: 1px solid red; */
`;

export const InnerColumnCalendar = styled.div`
  width: 25%;
  z-index: 1;

  /* border: 1px solid black; */
`;

export const ColumnCalendar = styled.div`
  width: 25%;

  /* border: 1px solid green; */
`;

export const Column50 = styled.div`
  width: 50%;
  height: 100%;
`;

export const HeaderColumn = styled.div<PropsHeaderColumn>`
  width: 100%;
  height: ${(props) => (props.h ? "27px" : "36px")};
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.align ? "flex-start" : "center")};
  padding-left: ${(props) => (props.align ? "8px" : "0")};
  background: ${gray40};

  border-left: ${(props) => (props.bl ? "1px solid white" : "none")};

  /* border: 1px solid purple; */
`;

export const ImageColumn = styled.div<PropsImageColumn>`
  width: 100%;
  height: ${(props) => (props.h ? "80%" : "100%")};

  border-bottom: ${(props) =>
    props.border ? `1.5px solid ${gray70}` : "none"};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Image = styled.img<PropsImage>`
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  background: ${(props) => props.bg};

  object-fit: cover;
`;

export const SubRow = styled.div<PropsSubRow>`
  width: 100%;
  /* height: 40%; */
  height: ${(props) => (props.h4 ? props.h4 : "50%")};
  text-align: ${(props) => (props.align ? "left" : "center")};
  padding-left: ${(props) => (props.align ? "8px" : "0")};

  border-bottom: ${(props) =>
    props.border ? `1.5px solid ${gray70}` : "none"};

  .divisor:after {
    content: "";
    position: absolute;
    margin-top: 72px;
    width: 12px;
    height: 1.4px;
    background: ${gray70};
  }

  /* border: 2px solid gray; */

  p {
    margin-bottom: 0;
    line-height: 24px;
  }

  span {
    position: relative;
    top: 90%;
    left: 25%;
  }
`;

export const ColumnH100 = styled.div<PropsColumnH100>`
  height: 100%;
  width: ${(props) => (props.w ? "25%" : "50%")};
  min-width: ${(props) => (props.minw ? "50%" : "25%")};

  margin-left: ${(props) => props.ml};
`;

export const ColumnH100W = styled.div<PropsColumnH100>`
  height: 100%;
  min-width: 100px;
  width: 10%;

  margin-left: ${(props) => props.ml};
`;

export const HeaderMonth = styled.div<PropsHeaderMonth>`
  width: 100%;
  height: 11px;
  font-size: ${(props) => (props.bg ? "10.5px" : "10.75")};

  background: ${(props) => (props.bg ? `${smoothBlue}` : `${gray40}`)};
  color: ${(props) => (props.bg ? "var(--white)" : "")};
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid white;
`;

export const ContainerSubColumn = styled.div<PropsSubColumn>`
  height: 100%;
  width: ${(props) => (props.w ? "40%" : "20%")};

  border-right: ${(props) => (props.border ? "1px solid white" : "none")};
`;

export const ContainerSubColumnw = styled.div<PropsSubColumn>`
  height: 100%;
  width: 50%;

  border-right: ${(props) => (props.border ? "1px solid white" : "none")};
`;

export const SubColumn = styled.div<PropsSubColumn>`
  width: 100%;
  height: ${(props) => (props.h ? "145px" : "181px")};

  border-bottom: 1px solid white;
`;

export const HeaderSubColumn = styled.div<PropsHeaderMonth>`
  width: 100%;
  height: 25px;
  font-size: 11px;
  line-height: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background: ${(props) => (props.bg ? `${lightBlue}` : `${gray60}`)};
  border-bottom: 1px solid white;
  border-right: 1px solid white;
`;

export const ContainerColumn = styled.div<PropsContainerColumn>`
  width: 100%;
  height: ${(props) => (props.h ? "169px" : "180px")};
  display: flex;
  background: ${gray40};
`;
