import { Reducer } from "redux";
import { EPeriod } from "../../components/CardSubMenu";
import { ELavarage } from "../../services/Hooks/MyExecution/DetailKpiProduct/useRequestKpi";
import { IStateFilters, EFilters, IFormatFilters } from "./types";

const INITIAL_VALUES: IFormatFilters = {
  selectedFilterId: "",
  selectedFilterName: "",
  listFilters: [],
};

export const INITIAL_STATE: IStateFilters = {
  filter: {
    businessUnitId: INITIAL_VALUES,
    category: INITIAL_VALUES,
    brand: INITIAL_VALUES,
    segments: INITIAL_VALUES,
  },
  promoFilter: {
    executionLeverage: INITIAL_VALUES,
    executionType: INITIAL_VALUES,
    businessUnitId: INITIAL_VALUES,
    category: INITIAL_VALUES,
    brand: INITIAL_VALUES,
    banner: INITIAL_VALUES,
    region: INITIAL_VALUES,
    segments: INITIAL_VALUES,
    merchandising: {
      ...INITIAL_VALUES,
      listFilters: [
        { id: "True", label: "Sim" },
        { id: "False", label: "Não" },
      ],
    },
    subChannel: INITIAL_VALUES,
    retailEnviroment: INITIAL_VALUES,
    uf: INITIAL_VALUES,
    commercialDirector: INITIAL_VALUES,
    commercialManager: INITIAL_VALUES,
    commercialCoordinator: INITIAL_VALUES,
    commercialSaler: INITIAL_VALUES,
    priorityItems: {
      ...INITIAL_VALUES,
      listFilters: [
        { id: "true", label: "MSL" },
        { id: "false", label: "Regular" },
      ],
    },
    storeCNPJ: "",
    dateFinal: "dd/mm/aaaa",
    dateInitial: "dd/mm/aaaa",
  },
  internalKpiProduct: {
    selectPeriod: {
      id: EPeriod.MONTHLY,
      label: "Mensal",
    },
    selectedPresence: {
      id: ELavarage.PRESENCA,
      label: "Presença",
    },
    limitItem: 50,
    selectedCategoryFilter: "",
    selectedBrandFilter: "",
  },
  internalKpiStore: {
    selectPeriod: {
      id: EPeriod.MONTHLY,
      label: "Mensal",
    },
    selectedPresence: {
      id: ELavarage.PRESENCA,
      label: "Presença",
    },
    limitItem: 50,
    selectedCategoryFilter: "",
    selectedBrandFilter: "",
    firstItem: 1,
    lastItem: 51,
  },
};

const reducerSubFilters: Reducer<IStateFilters> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case EFilters.CHANGE_PROMO_FILTER:
      return {
        ...state,
        promoFilter: action.payload,
      };
    case EFilters.CHANGE_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case EFilters.CHANGE_DATE_INITIAL_FILTER:
      return {
        ...state,
        promoFilter: {
          ...state.promoFilter,
          dateInitial: action.payload,
        },
      };
    case EFilters.CHANGE_DATE_FINAL_FILTER:
      return {
        ...state,
        promoFilter: {
          ...state.promoFilter,
          dateFinal: action.payload,
        },
      };
    case EFilters.CHANGE_STORE_CNPJ_FILTER:
      return {
        ...state,
        promoFilter: {
          ...state.promoFilter,
          storeCNPJ: action.payload,
        },
      };
    case EFilters.CHANGE_INTERNAL_FILTER_KPI_PRODUCT:
      return {
        ...state,
        internalKpiProduct: action.payload,
      };
    case EFilters.CHANGE_INTERNAL_FILTER_KPI_STORE:
      return {
        ...state,
        internalKpiStore: action.payload,
      };
    default:
      return state;
  }
};

export default reducerSubFilters;
