import { combineReducers } from "redux";
import navigation from "./Navigation";
import marketShareCategory from "./SideFilters/RequestFilters";
import loginAuth from "./Authentication/Login";
import logoutAuth from "./Authentication/Logout";
import profile from "./Profile";
import RSubFilter from "./SideFilters";

export default combineReducers({
  navigation,
  marketShareCategory,
  loginAuth,
  logoutAuth,
  profile,
  RSubFilter,
});
