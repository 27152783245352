import React, { useContext } from "react";
import PlotlyChart from "react-plotlyjs-ts";
import { ConfigContext } from "../../../services/Context/ConfigContext";
import { IFormatDataWaterfall } from "../../../services/Hooks/OnePage/useMarketShareContribution";

interface ownProps {
  dataGraphic?: IFormatDataWaterfall;
  l?: number;
  r?: number;
}

const WaterfallSubChannel: React.FC<ownProps> = ({
  dataGraphic,
  l,
  r,
}: ownProps) => {
  const { x, y, label, measure } = dataGraphic ?? {
    measure: [],
    label: [],
    x: [],
    y: [],
  };
  const listNamber = x.map((number) => parseFloat(number));
  const rangeXMax = Math.max.apply(null, listNamber);
  const rangeXMin = Math.min.apply(null, listNamber);

  const { config } = useContext(ConfigContext);

  const trace = {
    meta: {
      columnNames: {
        x: "A",
        y: "B",
        text: "B",
        measure: "C",
      },
    },
    type: "waterfall",
    x,
    y,
    measure,
    text: label,
    totals: { marker: { color: "rgb(31, 73, 125)" } },
    textfont: {
      size: [
        14,
        config.fonts.graphics,
        config.fonts.graphics,
        config.fonts.graphics,
        config.fonts.graphics,
        config.fonts.graphics,
        14,
      ],
    },
    connector: {
      line: {
        dash: "solid",
        color: "rgb(163, 163, 163)",
        width: 1,
      },
      mode: "between",
      visible: true,
    },
    hoverinfo: "y+text",
    textangle: 0,
    cliponaxis: true,
    increasing: { marker: { color: "rgb(0, 176, 240)" } },
    decreasing: {
      marker: {
        line: { width: 0 },
        color: "rgb(192, 0, 0)",
      },
    },
    orientation: "h",
    textposition: x.map((value, index) =>
      index === 0 || index === x.length - 1 ? "inside" : "outside"
    ),
    insidetextanchor: "middle",
    visible: true,
  };

  const layout = {
    font: { size: config.fonts.graphics },
    height: x.length * 33.7,
    xaxis: {
      type: "linear",
      range: [rangeXMin, rangeXMax + rangeXMax * 0.9],
      showgrid: false,
      showline: false,
      zeroline: false,
      autorange: false,
      fixedrange: true,
      showspikes: false,
      showticklabels: false,
    },
    yaxis: {
      type: "category",
      autorange: "reversed",
      automargin: true,
      categoryarray: y,
      categoryorder: "array",
      showticklabels: true,
      fixedrange: true,
    },

    waterfallmode: "group",
    waterfallgroupgap: 0.001,
    margin: {
      b: 0,
      l,
      r,
      t: 0,
      pad: 7,
    },
    autosize: true,
    modebar: { orientation: "v" },
  };

  const data = [trace];
  const configGraphic = { displayModeBar: false };

  return (
    <>
      <PlotlyChart data={data} layout={layout} config={configGraphic} />
    </>
  );
};

WaterfallSubChannel.defaultProps = {
  l: 0,
  r: 0,
  dataGraphic: {
    measure: [],
    label: [],
    x: [],
    y: [],
  },
};

export default WaterfallSubChannel;
