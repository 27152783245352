import styled, { keyframes } from 'styled-components';
import background from '../../../assets/img/background-login.png';
import backgroundMinimal from '../../../assets/img/background-minimal.png';

export const BackgroundConteiner = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url(${background});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 720px) {
    background-image: url(${backgroundMinimal});
  }
`;

export const FormConteiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > img {
    width: 320px;
    margin-bottom: 30px;
  }
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
  }

  & > input {
    border-radius: 24px;
    font-size: 14px;
    padding: 8px 15px;
    margin-bottom: 20px;
    border: 2px solid lightgray;
    margin-top: 8px;
    color: #616480;
  }

  & > span {
    text-align: right;
    margin-right: 8px;
    font-size: 12px;
    color: #d1d2dc;
    margin-bottom: 30px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  & > p {
    font-size: 14px;
    color: #e53e3e;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  color: #9699b0;
  margin: 0;
  margin-left: 8px;
`;

const animationSpinner = keyframes`
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`;

export const Submit = styled.button<{ margin?: boolean; background: string }>`
  width: 50%;
  margin: 0 auto;
  padding: 0 16px;
  height: 40px;
  border-radius: 24px;
  border: none;
  background-color: ${props => props.background};
  color: #000040;
  font-size: 16px;
  margin-top: 1rem;
  padding: 0 auto;

  ${props =>
    props.margin &&
    `
      margin-left: 10px;
  `}

  svg {
    animation: ${animationSpinner} 1s linear infinite;
  }
`;

export const InputPasswordConteiner = styled.div`
  width: 100%;
  border: 2px solid lightgray;
  border-radius: 24px;
  display: flex;
  padding-right: 12px;
  align-items: center;
  background-color: white;
  margin-bottom: 20px;
  margin-top: 8px;
`;

export const InputPassword = styled.input`
  width: 100%;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border: none;
  font-size: 14px;
  padding: 8px 15px;
  color: #616480;
`;

export const MessageResetSuccess = styled.div`
  text-align: center;
  & > p {
    font-size: 18px;
    margin-bottom: 8px;
  }

  & > span {
    font-size: 16px;
    cursor: pointer;

    span {
      text-decoration: underline;
    }
  }
`;

export const ErrorMessage = styled.p`
  text-align: center;
  margin-top: -10px;
`;
