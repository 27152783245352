import styled from "styled-components";
import { theme } from "../../../../styles/theme";

interface propStyle {
  width?: string;
  margin?: boolean;
  marginTop?: string;
  marginBottom?: string;
  inline?: boolean;
  marginResponsive?: boolean;
  height?: string;
  background?: string;
  color?: string;
  align?: string;
  wrap?: string;
  overflow?: string;
  position?: string;
  minHeight?: string;
  minWidth?: string;
  responsiveHeight?: string;
}

export const RowLayout = styled.div<propStyle>`
  display: flex;
  flex-wrap: ${(props) => props.wrap};
  width: 100%;
  height: ${(props) => props.height || "100%"};
  min-height: ${(props) => props.minHeight || "auto"};
  min-width: ${(props) => props.minWidth || "1300px"};
  margin-top: ${(props) => props.marginTop};

  @media screen and (max-width: 1120px) {
    display: contents;
    flex-direction: column;
    margin: 0;
    width: 99.7%;
    height: auto;
    background-color: var(--light-gray);
  }
`;

export const Columns = styled.div<propStyle>`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};

  @media screen and (max-width: 1120px) {
    display: flex;
    flex-direction: ${(props) => (props.inline ? "column" : "row")};
  }
`;

export const WrapperCard = styled.div<propStyle>`
  width: ${(props) => props.width};
  height: 100%;
  margin-right: ${(props) => (props.margin ? "5px" : "")};

  @media screen and (max-width: 1120px) {
    margin: 0;
    margin-top: 5px;
    width: 100%;
    height: 100%;
    margin-right: ${(props) => (props.marginResponsive ? "5px" : "")};
  }
`;

export const Header = styled.header<propStyle>`
  background-color: ${(props) => props.background || theme.colors.header};
  color: ${(props) => props.color || "var(--white)"};
  margin: ${(props) => props.marginBottom || "0"};
  margin-bottom: 5px;
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.align || "center"};
  align-items: center;
  position: ${(props) => props.position || "relative"};
  overflow: hidden;

  .header-msl-products {
    width: 100%;
    align-items: center;
    display: flex;

    img {
      width: 40px;
      height: 40px;
      margin-left: 8px;
    }

    h1 {
      color: red;
      font-weight: bold;
      text-align: center;
      /* margin: 0 20%; */
      position: fixed;
      /* transform: translateX(30%); */
      width: 100%;
      margin: 0 -7.5%;
    }

    i {
      font-weight: 400;
      font-size: 14px;
    }
  }

  i {
    font-weight: 400;
    font-size: 14px;
  }

  & > h1 {
    font-size: 20px;
    margin: 0;
    font-weight: bold;
    text-align: center;
  }

  @media screen and (max-width: 1120px) {
    background-color: #000040;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > h1 {
      font-size: 20px;
      margin: 0;
      font-weight: bold;
    }
  }
`;

interface ICardProps {
  width?: string;
  background?: string;
  height?: string;
  minHeight?: string;
  margin?: boolean;
  overflow?: string;
  responsiveHeight?: string;
  display?: string;
  alignItem?: string;
  justifyContent?: string;
}

export const ContentCard = styled.main<ICardProps>`
  display: ${(props) => props.display};
  align-items: ${(props) => props.alignItem};
  justify-content: ${(props) => props.justifyContent};
  box-sizing: border-box;
  padding: 5px;
  position: relative;
  width: ${(props) => (props.width ? props.width : "100%")};
  background-color: ${(props) => props.background || "#fff"};
  height: ${(props) => (props.height ? props.height : "calc(100% - 50px)")};
  min-height: ${(props) => props.minHeight || "315px"};
  color: black;
  margin-right: ${(props) => (props.margin ? "5px" : "0")};
  overflow: ${(props) => props.overflow || ""};

  &::-webkit-scrollbar {
    background-color: darkgray;
    border-radius: 20px;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${theme.scrollColor};
  }

  .chart-compilance {
    width: 100%;
    height: 90%;
  }

  @media screen and (max-width: 1120px) {
    background-color: white;
    margin-top: 5px;
    height: ${(props) => props.responsiveHeight || "380px"};
    color: black;
    overflow: hidden;
    width: 100%;
  }
`;
