import React from "react";
import { Box } from "@chakra-ui/react";
import THead from "./THead/THead";
import {
  Table,
  ContainerItem,
  ItemCalendar,
  THeadCalendar,
} from "./table.element";
import { MonthCategory, MonthIndustry } from "./Month/Month";
import { IStructTable } from "../../../../../services/Hooks/MyNegociation/useRequestCalendar";
import notImage from "../../../../../assets/Industria/notImage.png";
import { theme } from "../../../../../styles/theme";
import { getCurrentQuarterMonths } from "../../../../../Resource/functions";

interface IOwnProps {
  structData?: IStructTable;
  currentQuarter: number;
}

const TableCalendar: React.FC<IOwnProps> = ({
  structData,
  currentQuarter,
}: IOwnProps) => {
  return (
    <Box
      mt="0 !important"
      w="100%"
      display="flex"
      h="calc(100vh - 572px)"
      minH="250px"
      overflow="auto"
      css={`
        &::-webkit-scrollbar {
          background-color: darkgray;
          border-radius: 20px;
          width: 12px;
          height: 12px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background-color: ${theme.scrollColor};
        }
      `}
    >
      <Table mt="0px !important" main>
        <thead>
          <tr>
            <THeadCalendar whiteSpace="nowrap" minWidth="200px">
              Marca
            </THeadCalendar>
            <THeadCalendar whiteSpace="nowrap" minWidth="150px">
              Tipo Alavanca
            </THeadCalendar>
            <THeadCalendar whiteSpace="nowrap" minWidth="150px">
              Alavanca
            </THeadCalendar>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ borderBottom: "1px solid black", margin: 0 }}>
              <img src={structData?.calendar.industry.logo} alt="" />
            </td>
            <td colSpan={2}>
              <ContainerItem>
                {structData?.calendar.industry.data.map((itens) => (
                  <ItemCalendar key={Math.random()}>
                    <td key={Math.random()}>{itens.typeLaverage}</td>
                    <td key={Math.random()}>
                      {itens.lavarage.map((lavarage) => (
                        <p key={Math.random()}>{lavarage.LavaregeName}</p>
                      ))}
                    </td>
                  </ItemCalendar>
                ))}
              </ContainerItem>
            </td>
          </tr>
          {structData?.calendar.categories.map((category) => (
            <tr key={Math.random()}>
              <td style={{ borderBottom: "1px solid black", margin: 0 }}>
                <img src={category.logoBrand || notImage} alt="" />
              </td>
              <td colSpan={2}>
                {category.itens?.map((item) => (
                  <ContainerItem key={Math.random()}>
                    {item.data.map((itens, index) => (
                      <ItemCalendar
                        noBorder={index + 1 !== item.data.length}
                        key={Math.random()}
                      >
                        <td>{itens.typeLaverage}</td>
                        <td>
                          {itens.lavarages.map((lavarage) => (
                            <p key={Math.random()}>{lavarage.LavaregeName}</p>
                          ))}
                        </td>
                      </ItemCalendar>
                    ))}
                  </ContainerItem>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Table mt="0px !important">
        <thead>
          <tr>
            {getCurrentQuarterMonths(
              structData?.months || [],
              currentQuarter
            ).map((month) => (
              <THead
                key={Math.random()}
                title={month}
                subTitle={["# Qtd Lojas", "Duração (dias)", "Vigência"]}
              />
            ))}
            {/* <THead
              col="1fr"
              title="TRIMESTRE 4"
              subTitle={["Qtd de Lojas"]}
              minW="100px"
            /> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <MonthIndustry data={structData} currentQuarter={currentQuarter} />
            {/* <td>
              {structData?.calendar.industry.data.map((lavarage) => (
                <QuarterIndustry typeLavarage={lavarage} key={Math.random()} />
              ))}
            </td> */}
          </tr>
          {structData?.calendar.categories.map((category) => (
            <tr key={Math.random()}>
              <MonthCategory
                quarter={currentQuarter}
                data={category}
                listMonth={getCurrentQuarterMonths(
                  structData.months || [],
                  currentQuarter
                )}
                key={Math.random()}
              />
              {/* <td key={Math.random()}>
                {category.itens?.map((item) => (
                  <QuarterCategory items={item} key={Math.random()} />
                ))}
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </Box>
  );
};

TableCalendar.defaultProps = {
  structData: {
    calendar: {
      categories: [],
      industry: {
        data: [],
        logo: "",
      },
    },
    months: [],
  },
};

export default TableCalendar;
