import React from "react";
import PlotlyChart from "react-plotlyjs-ts";

interface ITripleGroupedFormat {
  first: string[];
  secondary: string[];
  tertiary: string[];
}

interface IOwnProps {
  title: string;
  data: {
    y: ITripleGroupedFormat;
    text: ITripleGroupedFormat;
    x: string[];
    legend: string[];
  };
}

const TripleGroupedBar: React.FC<IOwnProps> = ({
  title,
  data,
}: IOwnProps): JSX.Element => {
  const trace1 = {
    x: data.x,
    text: data.text.first,
    y: data.y.first,
    labels: ["Estoque virtual", "Cadastro"],
    name: data.legend[2],
    type: "bar",
    textposition: "inside",
    insidetextanchor: "middle",
    marker: {
      color: "#001D58",
    },
  };

  const trace2 = {
    x: data.x,
    text: data.text.secondary,
    y: data.y.secondary,
    name: data.legend[1],
    type: "bar",
    textposition: "inside",
    insidetextanchor: "middle",
    marker: {
      color: "#ADB9CA",
    },
  };

  const trace3 = {
    x: data.x,
    text: data.text.secondary,
    y: data.y.tertiary,
    name: data.legend[0],
    type: "bar",
    textposition: "inside",
    insidetextanchor: "middle",
    marker: {
      color: "#E7E6E6",
    },
  };

  const dataChart = [trace1, trace2, trace3];

  const layout = {
    width: 345,
    bargap: 0.45,
    title,
    yaxis: {
      showticklabels: false,
      yanchor: "center",
    },
    barmode: "stack",
    legend: {
      xanchor: "center",
      yanchor: "top",
      orientation: "h",
      y: -0.3, // play with it
      x: 0.5, // play with it
    },
  };

  const configGraphic = {
    displayModeBar: false,
  };

  return (
    <PlotlyChart data={dataChart} layout={layout} config={configGraphic} />
  );
};

export default TripleGroupedBar;
