import React, { useEffect, useRef } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Box, Grid } from "@chakra-ui/react";
import { stateType, INavMainMenu } from "../../store/Navigation/types";
import * as ANavigation from "../../store/Navigation/actions";
import { GlobalProps } from "../../store";

import MyExecutions from "../../pages/Private/MyExecution/subMenu";
import MyNegotiations from "../../pages/Private/MyNegotiations/subMenu";
import Promo from "../../pages/Private/MyDay/subMenu";
import OnePage from "../../pages/Private/OnePage/subMenu";

import * as AProfile from "../../store/Profile/actions";
import SubMenu from "../SubMenu";
import { theme } from "../../styles/theme";
import MyPerfectStore from "../../pages/Private/MyPerfectStore";

interface IStateProps {
  IMainMenu: stateType;
}

interface IDispatchProps {
  DProfile: {
    requestProfile(): void;
  };
}

type props = IStateProps & IDispatchProps;

const MainContent: React.FC<props> = (props: props) => {
  const { menuItem } = props.IMainMenu;
  const { requestProfile } = props.DProfile;

  const scrollWindows = useRef<HTMLDivElement>(null);

  const LoadContent = (indexItemMenu: INavMainMenu) => {
    switch (indexItemMenu) {
      case INavMainMenu.MY_ONE_PAGE:
        return <OnePage />;
      case INavMainMenu.MY_EXECUTION:
        return <MyExecutions />;
      case INavMainMenu.MY_NEGOCIATIONS:
        return <MyNegotiations />;
      case INavMainMenu.PROMO:
        return <Promo />;
      case INavMainMenu.MY_PERFECT_STORE:
        return <MyPerfectStore />;
      default:
        return null;
    }
  };

  useEffect(() => {
    requestProfile();
  }, [requestProfile]);

  return (
    <Grid
      templateRows="80px 1fr"
      mt="0px !important"
      p="5px"
      h="calc(100% - 120px)"
      maxW="100%"
    >
      <SubMenu reference={scrollWindows} />
      <Box
        overflow="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
            height: "12px",
            borderRadius: "20px",
            backgroundColor: "darkgray",
          },
          "&::-webkit-scrollbar-thumb": {
            background: theme.scrollColor,
            borderRadius: "20px",
          },
        }}
        h="calc(100% - 5px)"
        my="5px"
        ref={scrollWindows}
      >
        {LoadContent(menuItem.index)}
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  IMainMenu: state.navigation,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigation: bindActionCreators(ANavigation, dispatch),
    DProfile: bindActionCreators(AProfile, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);
