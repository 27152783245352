import { Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "../../../Card";
import iconStore from "../../../../assets/MyPerfectStore/store.png";
import TemporalFilter from "../../../TemporalFilter";
import {
  IPSAbsolutTemporalLine,
  IPSMainCards,
} from "../../../../pages/Private/MyPerfectStore/types";
import { filter } from "../../../../store/SideFilters/RequestFilters/types";
import LegendIcon, { ETypeLegend } from "../../../LegendIcon";
import MarketSharePeriodLtyYtd from "../../../Graphics/MarketSharePeriodLtyYtd/MarketSharePeriodLtyYtd";
import MarketSharePeriodBarLtyYtd from "../../../Graphics/MarketSharePeriodBarLtyYtd/MarketSharePeriodBarLtyYtd";
import { perfectStoreAbsolut } from "../../data";
import { IMainCardAbsolutePerfectStore } from "../../../../services/Hooks/MyPerfectStore/useStoreAbsolute";

interface IOwnProps {
  selected: boolean;
  onSelected(card: IPSMainCards): void;
  dataMainCard: IMainCardAbsolutePerfectStore;
}

const PerfectStoreAbsolut: React.FC<IOwnProps> = ({
  selected,
  onSelected,
  dataMainCard,
}: IOwnProps): JSX.Element => {
  const [temporalLine, setTemporalLine] = useState<filter>({
    id: IPSAbsolutTemporalLine.ABS,
    label: "ABS",
  });

  const legends = [
    { label: "Ljs Perf. 2022", type: ETypeLegend.period },
    { label: "Ljs Perf. 2023", type: ETypeLegend.bar },
  ];

  const changeSelectedCard = () => {
    onSelected(IPSMainCards.PERFECT_STORE_ABSOLUTE);
  };

  return (
    <Card
      title="Lojas Perfeitas | Absoluto"
      overflow="none"
      selected={selected}
      setSelected={changeSelectedCard}
    >
      <Stack p="5px 8px">
        <Flex>
          <Stack w="110px">
            <Image margin="auto" src={iconStore} w="65px" h="65px" />
            <MarketSharePeriodLtyYtd
              lineWidth={false}
              dataGraphic={perfectStoreAbsolut.leftChartPeriod}
              colors="#C00000"
              ml={15}
              mr={15}
              autosize
            />
            <MarketSharePeriodBarLtyYtd
              gap={0.13}
              autosize
              dataGraphic={dataMainCard.leftBar}
            />
          </Stack>
          <Stack minW="680px" ml="20px">
            <Flex justifyContent="flex-end" h="65px">
              {/* <TemporalFilter
                itens={[
                  { id: IPSAbsolutTemporalLine.ABS, label: "ABS" },
                  { id: IPSAbsolutTemporalLine.PERCENT, label: "%" },
                ]}
                setTemporalFilter={setTemporalLine}
                temporalFilter={temporalLine}
              /> */}
            </Flex>
            <MarketSharePeriodLtyYtd
              dataGraphic={dataMainCard.periodBar}
              colors="#C00000"
              autosize
            />
            <MarketSharePeriodBarLtyYtd
              r={15}
              l={15}
              gap={0.13}
              autosize
              dataGraphic={dataMainCard.rightBar}
            />
          </Stack>
        </Flex>
        <Flex justify="center" align="center">
          {legends.map((legend, index) => (
            <LegendIcon
              ml={index === 1 ? "10px" : "0px"}
              typeLegend={legend.type}
            >
              {legend.label}
            </LegendIcon>
          ))}
        </Flex>
      </Stack>
    </Card>
  );
};

export default PerfectStoreAbsolut;
