import {
  Flex,
  Grid,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import react, { useCallback, useContext, useEffect, useState } from "react";

// action redux
import * as ANavigator from "../../../../store/Navigation/actions";

// props Gloval (redux)
import { GlobalProps } from "../../../../store";

// Imagens
import binoculars from "../../../../assets/subMenu/binoculars_black.png";
import productBlackIcon from "../../../../assets/OnePage/products_black.png";
import checkImage from "../../../../assets/MyDay/Imagem2.png";
import officeImage from "../../../../assets/MyDay/Imagem3.png";
import arrow from "../../../../assets/MyDay/arrow.svg";
import arrowRight from "../../../../assets/MyDay/arrow-right.svg";
import bannerBlackIcon from "../../../../assets/OnePage/store_black.png";
import logo from "../../../../assets/Industria/logo.png";
import pontaGondola from "../../../../assets/Laverage/PontaGondola.png";
import planograma from "../../../../assets/Laverage/Planograma.png";
import loupe from "../../../../assets/MyDay/loupe.svg";
import excel from "../../../../assets/MyDay/excel.svg";

// Components
import TableComplements, {
  IDataFormat,
} from "../../../../components/TableComplements";
import TemporalFilter from "../../../../components/TemporalFilter";
import Column from "../../../../components/Table/Column";
import ListBox from "../../../../components/ListBox";
import Card from "../../../../components/Card";
import { SubItem } from "../../../../components/Contents/subMenu";
import {
  ENavSubMenu,
  filterComponent,
  IMainMenu,
  INavContent,
} from "../../../../store/Navigation/types";
import {
  filter,
  stateMarketShare,
} from "../../../../store/SideFilters/RequestFilters/types";
import { IStateFilters } from "../../../../store/SideFilters/types";
import { ConfigContext } from "../../../../services/Context/ConfigContext";

// enums

enum EContribuition {
  PRODUCT = "Categoria",
  BANNER = "Banner",
}

enum ELineFilter {
  TOP = "TOP",
  ESG = "ESG",
  CLN_PROMO = "CLN PROMO",
  FCIA_AMARELA = "FCIA AMARELA",
}

// interfaces

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DNavigator: {
    changeDash(indexContent: INavContent): void;
    changeMenu(menuItem: IMainMenu): void;
    changeSubMenu(index: ENavSubMenu): void;
    changeFilters(filters: filterComponent[]): void;
  };
}

type props = IStateProps & IDispatchProps;

const ViewPlanning: react.FC<props> = ({
  DNavigator,
  SAllFilters,
  SFilters,
}: props) => {
  const [contribuition, setContribuition] = useState<EContribuition>(
    EContribuition.PRODUCT
  );

  const { config } = useContext(ConfigContext);

  const [filterKPI, setFilterKPI] = useState<filter>({
    id: ELineFilter.ESG,
    label: ELineFilter.ESG,
  });

  const mockBrands: IDataFormat = {
    contentPrimary: {
      title: "PRESENÇA (TOP 50)",
      image: logo,
      headers: [
        "Marcas",
        "YTD",
        "Ult 3m",
        "Ult Mês",
        "Mês",
        "S10",
        "S11",
        "S12",
        "S13",
        "S14",
        "S15",
        "S16",
        "S17",
        "S18",
        "S19",
        "S20",
        "S21",
      ],
      content: [
        {
          category: config.general.industryName,
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Termometro",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Cardio",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Xo Inseto",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
        {
          category: "Acnezil",
          values: [
            "Var.%",
            "Var.%",
            "Var.%",
            "Var.%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
            "0,0%",
          ],
        },
      ],
    },
    contentSecundary: [
      {
        title: "ESPAÇO DE GONDOLA",
        image: pontaGondola,
        headers: [
          "Marcas",
          "YTD",
          "Ult 3m",
          "Ult Mês",
          "Mês",
          "S10",
          "S11",
          "S12",
          "S13",
          "S14",
          "S15",
          "S16",
          "S17",
          "S18",
          "S19",
          "S20",
          "S21",
        ],
        content: [
          {
            category: config.general.industryName,
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Termometro",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Cardio",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Xo Inseto",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Acnezil",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
        ],
      },
      {
        title: "CALENDARIO PROMOCIONAL",
        image: planograma,
        headers: [
          "Marcas",
          "YTD",
          "Ult 3m",
          "Ult Mês",
          "Mês",
          "S10",
          "S11",
          "S12",
          "S13",
          "S14",
          "S15",
          "S16",
          "S17",
          "S18",
          "S19",
          "S20",
          "S21",
        ],
        content: [
          {
            category: config.general.industryName,
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Termometro",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Cardio",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Xo Inseto",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
          {
            category: "Acnezil",
            values: [
              "Var.%",
              "Var.%",
              "Var.%",
              "Var.%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
              "0,0%",
            ],
          },
        ],
      },
    ],
  };

  const setTemporalFilter = (filterSelected: filter) => {
    setFilterKPI(filterSelected);
  };

  const mockData = [
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 01",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "DPSP",
      store: "DPSP LOJA 23",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 18",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "VENANCIO",
      store: "VENANCIO LOJA 13",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "PAGUE MENOS",
      store: "PAGUE MENOS LOJA 24",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 01",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "DPSP",
      store: "DPSP LOJA 23",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 18",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "VENANCIO",
      store: "VENANCIO LOJA 13",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "PAGUE MENOS",
      store: "PAGUE MENOS LOJA 24",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 01",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "DPSP",
      store: "DPSP LOJA 23",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 18",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "VENANCIO",
      store: "VENANCIO LOJA 13",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "PAGUE MENOS",
      store: "PAGUE MENOS LOJA 24",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 01",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "DPSP",
      store: "DPSP LOJA 23",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 18",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "VENANCIO",
      store: "VENANCIO LOJA 13",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "PAGUE MENOS",
      store: "PAGUE MENOS LOJA 24",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 01",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "DPSP",
      store: "DPSP LOJA 23",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 18",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "VENANCIO",
      store: "VENANCIO LOJA 13",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "PAGUE MENOS",
      store: "PAGUE MENOS LOJA 24",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 01",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "DPSP",
      store: "DPSP LOJA 23",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "RAIA DROGASIL",
      store: "RAIA DROGASIL LOJA 18",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
    {
      banner: "VENANCIO",
      store: "VENANCIO LOJA 13",
      ytd: "var.%",
      ult3m: "var.%",
      ultMes: "var.%",
      mes: "var.%",
      s10: "0,0%",
      s11: "0,0%",
      s12: "0,0%",
      s13: "0,0%",
      s14: "0,0%",
      s15: "0,0%",
      s16: "0,0%",
      s17: "0,0%",
      s18: "0,0%",
      s19: "0,0%",
      s20: "0,0%",
      s21: "0,0%",
    },
  ];

  const renderFilter = useCallback(() => {
    // if (config.showSideFilters.detailProductOP)
    //   DNavigator.changeFilters(
    //     FiltersList(SAllFilters, SFilters, {
    //       ...INITIAL_FILTERS,
    //       typeExecution: false,
    //       period: false,
    //     })
    //   );
  }, [SAllFilters, SFilters, DNavigator]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter, SFilters]);

  return (
    <Grid templateRows="repeat(3, auto)" h="100%" gridRowGap="5px" back>
      <Flex>
        <Card title="Meu Dia a Dia" bg="transparent">
          <Stack w="100%">
            <Flex
              h="80px"
              mt="0px !important"
              bg="subHeaderColor"
              align="center"
            />
            <Flex mt="5px !important" h="100%">
              <Flex bg="white" w="50%" mr="5px">
                <Flex px="10px">
                  <Flex align="flex-end" mr="-13px">
                    <Image
                      mr="-14px"
                      w="210px"
                      h="250px"
                      src={checkImage}
                      zIndex={1}
                    />
                  </Flex>
                  <Flex align="flex-start" py="40px" h="100%">
                    <Stack
                      bg="#C00000"
                      w="100%"
                      h="100%"
                      p="20px"
                      mr="20px"
                      borderRadius={10}
                      fontSize="14px"
                    >
                      <Text fontWeight="bold">JOAO,</Text>
                      <Text mt="-3px !important" fontWeight="bold">
                        Bom dia.
                      </Text>
                      <Text mt="15px !important">
                        Baseado no programa FARMACIA AMARELA, indentifiquei que
                        a a mais de 3 semanas a performance do ESG % está abaixo
                        da meta 30,5% das Lojas.
                      </Text>
                      <Text mt="15px !important">
                        E as categorias/marcas que está causando maior impacto
                        são LAVITAN seguida de K-MED e Acnezil.
                      </Text>
                      <Flex
                        align="flex-end"
                        mt="30px !important"
                        justifyContent="flex-end"
                        cursor="pointer"
                        onClick={() =>
                          DNavigator.changeDash(INavContent.MD_DETAIL_STORE)
                        }
                        pr="20px"
                        h="100%"
                      >
                        <Flex align="center">
                          <Image w="30px" h="30px" mr="10px" src={arrow} />
                          <Text fontSize="12px">
                            <i>Ver detalhe loja</i>
                          </Text>
                        </Flex>
                      </Flex>
                    </Stack>
                  </Flex>
                </Flex>
              </Flex>
              <Flex bg="white" w="50%">
                <Flex h="100%" w="100%" align="flex-end">
                  <Stack
                    w="100%"
                    h="100%"
                    p="40px 0 40px 20px"
                    justifyContent="space-between"
                  >
                    <Stack
                      w="100%"
                      bg="#FFF2CC"
                      p="10px"
                      borderRadius={10}
                      color="black"
                    >
                      <Text
                        fontWeight="bold"
                        p="5px"
                        fontSize="14px"
                        color="black"
                      >
                        Calendario Promocional.
                      </Text>
                      <Text fontSize="14px">
                        Identificamos tambem uma redução do cumprimento do
                        calendario promocional nas ultimas 3 semanas de 93,2%
                        PARA 84,5%.
                      </Text>
                      <Flex
                        align="center"
                        mt="10px !important"
                        justifyContent="flex-end"
                        cursor="pointer"
                        onClick={() =>
                          DNavigator.changeDash(INavContent.MD_DETAIL_STORE)
                        }
                        pr="20px"
                        h="100%"
                        alignItems="center"
                        justifyItems="flex-end"
                      >
                        <Image w="30px" h="30px" mr="10px" src={arrowRight} />
                        <Text fontSize="12px">
                          <i>Ver detalhe loja</i>
                        </Text>
                      </Flex>
                    </Stack>
                    <Stack
                      w="100%"
                      bg="#E2F0D9"
                      p="10px"
                      borderRadius={10}
                      color="black"
                    >
                      <Text fontWeight="bold" fontSize="14px" color="black">
                        Presença.
                      </Text>
                      <Text fontSize="14px">
                        Tudo certo, estamos atigindo a meta de 90% do sortimento
                        presente em loja.
                      </Text>
                      <Flex
                        align="center"
                        mt="10px !important"
                        justifyContent="flex-end"
                        cursor="pointer"
                        onClick={() =>
                          DNavigator.changeDash(INavContent.MD_DETAIL_PRODUCT)
                        }
                        pr="20px"
                        h="100%"
                        alignItems="center"
                        justifyItems="flex-end"
                      >
                        <Image w="30px" h="30px" mr="10px" src={arrowRight} />
                        <Text fontSize="12px">
                          <i>Ver detalhe skus</i>
                        </Text>
                      </Flex>
                    </Stack>
                  </Stack>
                  <Image h="250px" src={officeImage} />
                </Flex>
              </Flex>
            </Flex>
          </Stack>
        </Card>
      </Flex>
      <Flex>
        <TableComplements
          titleCard="Meus Indicadores de Execução %"
          data={mockBrands}
          noFilter
          noSeparate
        />
      </Flex>
      <Flex>
        <Card title="Detalhes dos KPIs Produto e Loja" bg="transparent">
          <Stack w="100%">
            <Flex
              h="80px"
              mt="0px !important"
              bg="subHeaderColor"
              align="center"
              justifyContent="space-between"
            >
              <Flex alignItems="center">
                <Flex
                  h="57px"
                  outline="0"
                  align="center"
                  justify="center"
                  borderRight="2px solid #e2e2e2"
                  w="145px"
                >
                  <Image src={binoculars} alt="icon Binoculars" w="65px" />
                </Flex>
                <SubItem
                  icon={productBlackIcon}
                  active={contribuition === EContribuition.PRODUCT}
                  onClick={() => setContribuition(EContribuition.PRODUCT)}
                />
                <SubItem
                  icon={bannerBlackIcon}
                  active={contribuition === EContribuition.BANNER}
                  onClick={() => setContribuition(EContribuition.BANNER)}
                />
              </Flex>
              <Flex pr="30px" alignItems="center">
                {/* <Image src={loupe} w="65px" h="65px" /> */}
                <ListBox
                  icon={loupe}
                  itens={[
                    {
                      optionSelect: [
                        { id: "1", label: "TOP 50" },
                        { id: "2", label: "TOP 100" },
                        { id: "3", label: "TOP 200" },
                      ],
                      selected: "",
                      id: "aoqwpeiou",
                      onChange: () => console.log("lol"),
                    },
                  ]}
                  iconSize="55px"
                  disableAllOptions
                />
              </Flex>
            </Flex>
            <Stack bg="white" mt="5px !important">
              <Flex
                justifyContent="space-between"
                pt="20px"
                pb="10px"
                pr="30px"
                align="center"
              >
                <TemporalFilter
                  setTemporalFilter={setTemporalFilter}
                  temporalFilter={filterKPI}
                  itens={[
                    { id: ELineFilter.TOP, label: "TOP 50" },
                    { id: ELineFilter.ESG, label: "ESG" },
                    { id: ELineFilter.CLN_PROMO, label: "CLN PROMO" },
                    { id: ELineFilter.FCIA_AMARELA, label: "FCIA AMARELA" },
                  ]}
                />
                <Flex align="center" cursor="pointer">
                  <Text fontWeight="bold" pr="15px" color="black">
                    Exportar para
                  </Text>
                  <Image src={excel} w="50px" h="50px" />
                </Flex>
              </Flex>
              <Grid templateColumns="1fr auto" color="black" fontSize="14px">
                <TableContainer mr="5px">
                  <Table
                    style={{ borderCollapse: "separate" }}
                    border="1px solid #E7E6E6"
                  >
                    <Thead>
                      <Tr>
                        <Th
                          background="#E7E6E6"
                          color="black"
                          fontWeight="bold"
                        >
                          BANDEIRA
                        </Th>
                        <Th
                          background="#E7E6E6"
                          color="black"
                          fontWeight="bold"
                        >
                          Lojas
                        </Th>
                        <Th
                          background="#E7E6E6"
                          color="black"
                          fontWeight="bold"
                          px="0"
                          textAlign="center"
                        >
                          YTD
                        </Th>
                        <Th
                          background="#E7E6E6"
                          color="black"
                          fontWeight="bold"
                          px="0"
                          textAlign="center"
                        >
                          Ult 3m
                        </Th>
                        <Th
                          background="#E7E6E6"
                          color="black"
                          fontWeight="bold"
                          px="0"
                          textAlign="center"
                        >
                          Ult Mês
                        </Th>
                        <Th
                          background="#E7E6E6"
                          color="black"
                          fontWeight="bold"
                          px="0"
                          textAlign="center"
                        >
                          Mes
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {mockData.map((item) => {
                        return (
                          <Tr>
                            <Column isTextCenter={false} value={item.banner} />
                            <Column isTextCenter={false} value={item.store} />
                            <Column value={item.ytd} />
                            <Column value={item.ult3m} />
                            <Column bg="#E7E6E6" isBold value={item.ultMes} />
                            <Column bg="#E7E6E6" isBold value={item.mes} />
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <Table
                    style={{ borderCollapse: "separate" }}
                    border="1px solid #E7E6E6"
                  >
                    <Thead>
                      <Tr>
                        <Th background="#E7E6E6" color="black">
                          S10
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S11
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S12
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S13
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S14
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S15
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S16
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S17
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S18
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S19
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S20
                        </Th>
                        <Th background="#E7E6E6" color="black">
                          S21
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {mockData.map((item) => {
                        return (
                          <Tr>
                            <Column value={item.s10} />
                            <Column value={item.s11} />
                            <Column value={item.s12} />
                            <Column value={item.s13} />
                            <Column value={item.s14} />
                            <Column value={item.s15} />
                            <Column value={item.s16} />
                            <Column value={item.s17} />
                            <Column value={item.s18} />
                            <Column value={item.s19} />
                            <Column value={item.s20} />
                            <Column value={item.s21} />
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Grid>
            </Stack>
          </Stack>
        </Card>
      </Flex>
    </Grid>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPlanning);
