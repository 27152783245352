import { connect } from "react-redux";
import React from "react";
import Dashboard from "./Private/Dashboard/Dashboard";
import Login from "./Public/Login/Login";
import { GlobalProps } from "../store/index";
import { INavPage } from "../store/Navigation/types";
import api from "../services/api";

const verifyAuth = () => {
  const currentToken = localStorage.getItem("AccessTokenBipWeb");
  if (currentToken !== null && currentToken !== undefined) {
    api.defaults.headers.Authorization = currentToken;
    return INavPage.MAIN_PAGE;
  }

  return INavPage.LOGIN_PAGE;
};

const RenderPage: React.FC = () => {
  switch (verifyAuth()) {
    case INavPage.LOGIN_PAGE:
      return <Login />;
    case INavPage.MAIN_PAGE:
      return <Dashboard />;
    default:
      return <Login />;
  }
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
});

export default connect(mapStateToProps)(RenderPage);
