import { connect } from "react-redux";
import React, { useContext } from "react";
import { bindActionCreators, Dispatch } from "redux";

import {
  ContainerSubMenu,
  SubItem,
} from "../../../components/Contents/subMenu";

// imagens
import calendarImg from "../../../assets/img/icons/mynegociation_calendar.png";
import complianceImg from "../../../assets/MyNegociation/iconCompliance.png";
import financialPlanningImg from "../../../assets/subMenu/financialPlanningImg.svg";
import roiImg from "../../../assets/subMenu/roi.svg";

import ViewCalendar from "./ViewCalendar/ViewCalendar";
import ViewCompliance from "./ViewCompliance";

import {
  ENavSubMenu,
  filterComponent,
  INavContent,
  stateType,
} from "../../../store/Navigation/types";
import { GlobalProps } from "../../../store";

// actions redux
import * as ANavigation from "../../../store/Navigation/actions";
import * as AFilters from "../../../store/SideFilters/actions";

import {
  IStateFilters,
  TPromoFilter,
  IProductFilter,
} from "../../../store/SideFilters/types";
import ViewFinancialPlanning from "./ViewFinancialPlanning";
import ViewROI from "./ViewROI";
import { stateMarketShare } from "../../../store/SideFilters/RequestFilters/types";
import { ConfigContext } from "../../../services/Context/ConfigContext";

interface ownProp {
  indexSubMenu: ENavSubMenu;
  changeSubMenu: (data: ENavSubMenu) => void;
}

export const SubMenuMyNegotiations = ({
  changeSubMenu,
  indexSubMenu,
}: ownProp): JSX.Element => {
  const { config } = useContext(ConfigContext);

  return (
    <ContainerSubMenu>
      {config.modules.myNegotiation.subModules.ROI && (
        <SubItem
          icon={roiImg}
          onClick={() => changeSubMenu(ENavSubMenu.MN_ROI)}
          active={indexSubMenu === ENavSubMenu.MN_ROI}
        />
      )}
      {config.modules.myNegotiation.subModules.calendar && (
        <SubItem
          icon={calendarImg}
          onClick={() => changeSubMenu(ENavSubMenu.MN_CALENDAR)}
          active={indexSubMenu === ENavSubMenu.MN_CALENDAR}
        />
      )}
      {config.modules.myNegotiation.subModules.compliance && (
        <SubItem
          icon={complianceImg}
          onClick={() => changeSubMenu(ENavSubMenu.MN_COMPLIANCE)}
          active={indexSubMenu === ENavSubMenu.MN_COMPLIANCE}
        />
      )}
      {config.modules.myNegotiation.subModules.financialPlanning && (
        <SubItem
          icon={financialPlanningImg}
          onClick={() => changeSubMenu(ENavSubMenu.MN_FINANCE_PLANNING)}
          active={indexSubMenu === ENavSubMenu.MN_FINANCE_PLANNING}
        />
      )}
    </ContainerSubMenu>
  );
};

interface stateProps {
  navigation: stateType;
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DNavigation: {
    changeDash(content: INavContent): void;
    changeSubMenu: (data: ENavSubMenu) => void;
    changeFilters(filters: filterComponent[]): void;
  };

  DFilters: {
    changeFilterIsPromo(payload: TPromoFilter): void;
    changeFilter(payload: IProductFilter): void;
  };
}

type props = stateProps & IDispatchProps;

const ContentMyNegotiations: React.FC<props> = ({
  navigation,
  DNavigation,
  SFilters,
  SAllFilters,
  DFilters,
}: props) => {
  switch (navigation.indexSubMenu) {
    case ENavSubMenu.MN_ROI:
      return <ViewROI />;
    case ENavSubMenu.MN_CALENDAR:
      return (
        <ViewCalendar
          renderSideFilter={DNavigation.changeFilters}
          SFilters={SFilters}
          SAllFilters={SAllFilters}
          DStateFilters={DFilters}
        />
      );
    case ENavSubMenu.MN_COMPLIANCE:
      return <ViewCompliance />;
    case ENavSubMenu.MN_FINANCE_PLANNING:
      return <ViewFinancialPlanning />;
    default:
      break;
  }

  return null;
};

// Mapeia os Estados globais e pega os que quiser usar
const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

// Mapeia os actions globais e pega os que quiser usar
const mapDispatchToProps = (dispatch: Dispatch) => ({
  // bindActions recebe dois parametros a action que eu quiser usar
  // E o dispatch
  DNavigation: bindActionCreators(ANavigation, dispatch),
  DFilters: bindActionCreators(AFilters, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentMyNegotiations);
