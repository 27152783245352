import React from "react";
import { getCurrentQuarterMonths } from "../../../../../../Resource/functions";
import {
  IMonthLavarege,
  IStructTable,
} from "../../../../../../services/Hooks/MyNegociation/useRequestCalendar";
import { RowMonth, ConteinerMonth } from "./month.element";

interface IOwnPropsIndustry {
  data?: IStructTable;
  currentQuarter: number;
}

export const MonthIndustry: React.FC<IOwnPropsIndustry> = ({
  data,
  currentQuarter,
}: IOwnPropsIndustry) => {
  return (
    <>
      {getCurrentQuarterMonths(data?.months || [], currentQuarter).map(
        (month, index) => (
          <td key={Math.random()}>
            {data?.calendar.industry.data.map((lavarage) => (
              <ConteinerMonth key={Math.random()}>
                {lavarage.lavarage.map((item) => {
                  const valuesMonth = getCurrentQuarterMonths(
                    item.month,
                    currentQuarter
                  )[index]?.values;
                  return (
                    <RowMonth key={Math.random()}>
                      {valuesMonth?.map((lol: any) => (
                        <span key={Math.random()}>{lol}</span>
                      ))}
                    </RowMonth>
                  );
                })}
              </ConteinerMonth>
            ))}
          </td>
        )
      )}
    </>
  );
};

MonthIndustry.defaultProps = {
  data: {
    calendar: {
      categories: [],
      industry: {
        data: [],
        logo: "",
      },
    },
    months: [],
  },
};

interface IOwnPropsCategory {
  data: {
    logoBrand: string;
    itens: {
      itemChannel: string;
      data: {
        typeLaverage: string;
        lavarages: IMonthLavarege[];
      }[];
    }[];
  };
  quarter: number;
  listMonth: string[];
}

export const MonthCategory: React.FC<IOwnPropsCategory> = (
  props: IOwnPropsCategory
) => {
  return (
    <>
      {props.listMonth.map((month, index) => (
        <td key={Math.random()}>
          {props.data.itens?.map((item) => {
            return item.data.map((lavarage) => (
              <ConteinerMonth key={Math.random()}>
                {lavarage.lavarages.map((calendar) => {
                  const valuesMonth = getCurrentQuarterMonths(
                    calendar.month,
                    props.quarter
                  )[index]?.values;

                  return (
                    <RowMonth key={Math.random()}>
                      {valuesMonth?.map((value: any) => (
                        <span key={Math.random()}>{value}</span>
                      ))}
                    </RowMonth>
                  );
                })}
              </ConteinerMonth>
            ));
          })}
        </td>
      ))}
    </>
  );
};
