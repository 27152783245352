import { CallEffect, put, PutEffect } from 'redux-saga/effects';
// import api from '../../../services/api';
import { stateLogout, ISuccessLogout, IErrorLogout } from './types';
import { errorLogout, requestLogout } from './actions';
import { INavPage } from '../../Navigation/types';

export function* loadLogout({
  payload,
}: ReturnType<typeof requestLogout>): Generator<
  CallEffect<unknown> | PutEffect<ISuccessLogout> | PutEffect<IErrorLogout>,
  void,
  stateLogout
> {
  try {
    // const refreshToken = JSON.stringify({
    //   refresh: localStorage.getItem('RefreshTokenBipWeb'),
    // });
    // const token: stateLogout = yield call(api.post, 'logout/', refreshToken, {
    //   headers: {
    //     authorization: localStorage.getItem('AccessTokenBipWeb'),
    //   },
    // });
    localStorage.removeItem('AccessTokenBipWeb');
    localStorage.removeItem('RefreshTokenBipWeb');
    payload.setPage(INavPage.LOGIN_PAGE);
  } catch (error) {
    yield put(errorLogout());
  }
}
