import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";
import {
  ITypeGraphicHorizontalBar,
  ITypeGraphicPeriod,
  ITypeGraphicPeriodBar,
  ITypeWaterfall,
} from "../OnePage/onePageTypes";

export interface IMainCardAbsolutePerfectStore {
  leftBar: ITypeGraphicPeriodBar;
  periodBar: ITypeGraphicPeriod;
  rightBar: ITypeGraphicPeriodBar;
}

export interface IAbsoluteData {
  mainCard: IMainCardAbsolutePerfectStore;
  subCards: {
    subChannel: {
      periodBar: ITypeGraphicPeriod;
      rightBar: ITypeGraphicPeriodBar;
    };
    region: {
      periodBar: ITypeGraphicPeriod;
      rightBar: ITypeGraphicPeriodBar;
    };
    banner: {
      periodBar: ITypeGraphicPeriod;
      rightBar: ITypeGraphicPeriodBar;
    };
  };
}

export interface IKPIData {
  label: string[];
  x: string[];
  y: number[];
  period: string;
}

interface IPerfectStoreAbsolute {
  avrTotal: number;
  label: string;
  month: number;
  perfectStorePercent: string;
  total: number;
  universeStores: number;
}

export interface IDetailPerfectStore {
  realized: ITypeGraphicHorizontalBar;
  storeUniverse: ITypeGraphicHorizontalBar;
  varPeriod: ITypeGraphicHorizontalBar;
  waterfall: ITypeWaterfall;
}

export const INITIAL_DETAIL_PERFECT_STORE: IDetailPerfectStore = {
  realized: {
    label: [],
    x: [],
    y: [],
  },
  storeUniverse: {
    label: [],
    x: [],
    y: [],
  },
  varPeriod: {
    label: [],
    x: [],
    y: [],
  },
  waterfall: {
    label: [],
    x: [],
    y: [],
    measure: [],
  },
};

interface IAbsoluteStore {
  absoluteData: IAbsoluteData;
  averageScoreData: IAbsoluteData;
  kpiData: IKPIData[];
  message: string;
  perfctStoreAbsolute: IPerfectStoreAbsolute[];
  storeDetailBanner: IDetailPerfectStore;
  storeDetailRegion: IDetailPerfectStore;
  storeDetailSubChannel: IDetailPerfectStore;
  status: string;
  period: string;
}

const requestPerfectStore = async (
  filters: IStateFilters
): Promise<IAbsoluteStore> => {
  const response = await api.get<IAbsoluteStore>("perfect_store/", {
    params: {
      businessUnit: filters.promoFilter.businessUnitId.selectedFilterId || null,
      category: filters.promoFilter.category.selectedFilterId || null,
      subCategory: filters.promoFilter.brand.selectedFilterId || null,
      segment: filters.promoFilter.segments.selectedFilterId || null,
      subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
      banner: filters.promoFilter.banner.selectedFilterId || null,
      retailEnviroment:
        filters.promoFilter.retailEnviroment.selectedFilterId || null,
      region: filters.promoFilter.region.selectedFilterId || null,
      uf: filters.promoFilter.uf.selectedFilterId || null,
      director: filters.promoFilter.commercialDirector.selectedFilterId || null,
      manager: filters.promoFilter.commercialManager.selectedFilterId || null,
      customer_sales_rep:
        filters.promoFilter.commercialSaler.selectedFilterId || null,
      cnpj: filters.promoFilter.storeCNPJ || null,
    },
  });

  const periods = [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ];

  const quantityItensAbsoluteData =
    response.data.absoluteData.mainCard.rightBar.label.length;

  const quantityItensStorePontuation =
    response.data.averageScoreData.mainCard.rightBar.label.length;

  const currentYear = new Date().getFullYear().toString().slice(-2);

  for (let index = quantityItensAbsoluteData; index < 12; index += 1) {
    response.data.absoluteData.mainCard.rightBar.x.push(
      `${periods[index]}/${currentYear}`
    );
    response.data.absoluteData.mainCard.rightBar.y.push(0);
    response.data.absoluteData.mainCard.rightBar.label.push("");

    response.data.absoluteData.mainCard.periodBar.y.push(0);
    response.data.absoluteData.mainCard.periodBar.label.push("0,0%");
  }

  for (let index = quantityItensStorePontuation; index < 12; index += 1) {
    response.data.averageScoreData.mainCard.rightBar.x.push(
      `${periods[index]}/${currentYear}`
    );
    response.data.averageScoreData.mainCard.rightBar.y.push(0);
    response.data.averageScoreData.mainCard.rightBar.label.push("");

    response.data.averageScoreData.mainCard.periodBar.y.push(0);
    response.data.averageScoreData.mainCard.periodBar.label.push("0");
  }

  return response.data;
};

export const useStoreAbsolute = (
  filters: IStateFilters
): UseQueryResult<IAbsoluteStore, unknown> => {
  return useQuery(["storeAbsolute"], () => requestPerfectStore(filters), {
    refetchOnWindowFocus: false,
  });
};
