import { IMonTable } from "../../../../../components/Table/Column";
import {
  defaultValue,
  IPayloadKpi,
} from "../../../../../services/Hooks/MyExecution/DetailKpiProduct/useRequestKpi";
import { ITypeGraphicHorizontalBar } from "../../../../../services/Hooks/OnePage/onePageTypes";

export const insertDataCache = (
  limitItem: number,
  dataKPIProduct: IPayloadKpi,
  setCacheData: (payload: IPayloadKpi) => void
): void => {
  const limitItensTable = (dataKPIProduct.dataTable || []).slice(0, limitItem);
  const limitActiveStore = (dataKPIProduct.activeStore || []).slice(
    0,
    limitItem
  );
  const limitAct: ITypeGraphicHorizontalBar = {
    label: dataKPIProduct.act.data.label.slice(0, limitItem) || [],
    x: dataKPIProduct.act.data.x.slice(0, limitItem) || [],
    y: dataKPIProduct.act.data.y.slice(0, limitItem) || [],
  };
  const limitLM: ITypeGraphicHorizontalBar = {
    label: dataKPIProduct.lm.data.label.slice(0, limitItem) || [],
    x: dataKPIProduct.lm.data.x.slice(0, limitItem) || [],
    y: dataKPIProduct.lm.data.y.slice(0, limitItem) || [],
  };
  const limitL3M: ITypeGraphicHorizontalBar = {
    label: dataKPIProduct.l3m.data.label.slice(0, limitItem) || [],
    x: dataKPIProduct.l3m.data.x.slice(0, limitItem) || [],
    y: dataKPIProduct.l3m.data.y.slice(0, limitItem) || [],
  };
  const limitYTD: ITypeGraphicHorizontalBar = {
    label: dataKPIProduct.ytd.data.label.slice(0, limitItem) || [],
    x: dataKPIProduct.ytd.data.x.slice(0, limitItem) || [],
    y: dataKPIProduct.ytd.data.y.slice(0, limitItem) || [],
  };
  const limitLastWeek: ITypeGraphicHorizontalBar = {
    label: dataKPIProduct.lastWeek?.data.label.slice(0, limitItem) || [],
    x: dataKPIProduct.lastWeek?.data.x.slice(0, limitItem) || [],
    y: dataKPIProduct.lastWeek?.data.y.slice(0, limitItem) || [],
  };
  const limitPenultimateWeek: ITypeGraphicHorizontalBar = {
    label: dataKPIProduct.penultimateWeek?.data.label.slice(0, limitItem) || [],
    x: dataKPIProduct.penultimateWeek?.data.x.slice(0, limitItem) || [],
    y: dataKPIProduct.penultimateWeek?.data.y.slice(0, limitItem) || [],
  };

  setCacheData({
    dataTable: limitItensTable,
    activeStore: limitActiveStore,
    act: {
      ...dataKPIProduct.act,
      data: limitAct,
    },
    lm: {
      ...dataKPIProduct.lm,
      data: limitLM,
    },
    l3m: {
      ...dataKPIProduct.l3m,
      data: limitL3M,
    },
    lastWeek: {
      ...dataKPIProduct.lastWeek,
      data: limitLastWeek,
    },
    penultimateWeek: {
      ...dataKPIProduct.penultimateWeek,
      data: limitPenultimateWeek,
    },
    ytd: {
      ...dataKPIProduct.ytd,
      data: limitYTD,
    },
  });
};

export const redefineOrder = (
  limitItem: number,
  dataKPIProduct: IPayloadKpi = defaultValue,
  setCacheData: (payload: IPayloadKpi) => void
): void => {
  setCacheData({
    dataTable: dataKPIProduct.dataTable.slice(0, limitItem),
    activeStore: dataKPIProduct.activeStore.slice(0, limitItem),
    act: {
      ...dataKPIProduct.act,
      data: {
        label: dataKPIProduct.act.data.label.slice(0, limitItem),
        x: dataKPIProduct.act.data.x.slice(0, limitItem),
        y: dataKPIProduct.act.data.y.slice(0, limitItem),
      },
    },
    l3m: {
      ...dataKPIProduct.l3m,
      data: {
        label: dataKPIProduct.l3m.data.label.slice(0, limitItem),
        x: dataKPIProduct.l3m.data.x.slice(0, limitItem),
        y: dataKPIProduct.l3m.data.y.slice(0, limitItem),
      },
    },
    lm: {
      ...dataKPIProduct.lm,
      data: {
        label: dataKPIProduct.lm.data.label.slice(0, limitItem),
        x: dataKPIProduct.lm.data.x.slice(0, limitItem),
        y: dataKPIProduct.lm.data.y.slice(0, limitItem),
      },
    },
    ytd: {
      ...dataKPIProduct.ytd,
      data: {
        label: dataKPIProduct.ytd.data.label.slice(0, limitItem),
        x: dataKPIProduct.ytd.data.x.slice(0, limitItem),
        y: dataKPIProduct.ytd.data.y.slice(0, limitItem),
      },
    },
    penultimateWeek: {
      ...dataKPIProduct?.penultimateWeek,
      data: {
        label: dataKPIProduct?.penultimateWeek?.data.label.slice(0, limitItem),
        x: dataKPIProduct?.penultimateWeek?.data.x.slice(0, limitItem),
        y: dataKPIProduct?.penultimateWeek?.data.y.slice(0, limitItem),
      },
    },
    lastWeek: {
      ...dataKPIProduct?.lastWeek,
      data: {
        label: dataKPIProduct?.lastWeek?.data.label.slice(0, limitItem),
        x: dataKPIProduct?.lastWeek?.data.x.slice(0, limitItem),
        y: dataKPIProduct?.lastWeek?.data.y.slice(0, limitItem),
      },
    },
  });
};

export const orderColumn = (
  id: number,
  desc: boolean,
  limitItem: number,
  dataKPIProduct: IPayloadKpi = defaultValue,
  setCacheData: (payload: IPayloadKpi) => void
): void => {
  const dataTable: IMonTable[] = [];
  const activeSkus: string[] = [];

  const listValueAct: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };
  const listValueL3m: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };
  const listValueLm: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };
  const listValueYtd: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };
  const listValuePenultimateWeek: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };
  const listValueLastWeek: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };

  let orderBy: number[];
  switch (id) {
    case 6:
      orderBy = dataKPIProduct.act.data.x;
      break;
    case 7:
      orderBy = dataKPIProduct.lm.data.x;
      break;
    case 8:
      orderBy = dataKPIProduct.l3m.data.x;
      break;
    case 9:
      orderBy = dataKPIProduct.ytd.data.x;
      break;
    case 10:
      orderBy = dataKPIProduct.lastWeek.data.x;
      break;
    case 11:
      orderBy = dataKPIProduct.penultimateWeek.data.x;
      break;
    default:
      orderBy = [];
  }
  const listFilter = orderBy
    .slice(0, limitItem)
    .map((item, index) => {
      return {
        count: index,
        value: item,
      };
    })
    .sort((value, nextValue) => {
      if (value.value > nextValue.value) {
        return desc ? -1 : 1;
      }

      if (value.value < nextValue.value) {
        return desc ? 1 : -1;
      }

      return 0;
    });

  listFilter.forEach((item) => {
    listValueAct.label.push(dataKPIProduct.act.data.label[item.count]);
    listValueAct.x.push(dataKPIProduct.act.data.x[item.count]);
    listValueAct.y.push(dataKPIProduct.act.data.y[item.count]);
    //-----------------------------
    listValueL3m.label.push(dataKPIProduct.l3m.data.label[item.count]);
    listValueL3m.x.push(dataKPIProduct.l3m.data.x[item.count]);
    listValueL3m.y.push(dataKPIProduct.l3m.data.y[item.count]);
    //-----------------------------
    listValueLm.label.push(dataKPIProduct.lm.data.label[item.count]);
    listValueLm.x.push(dataKPIProduct.lm.data.x[item.count]);
    listValueLm.y.push(dataKPIProduct.lm.data.y[item.count]);
    //-----------------------------
    listValueYtd.label.push(dataKPIProduct.ytd.data.label[item.count]);
    listValueYtd.x.push(dataKPIProduct.ytd.data.x[item.count]);
    listValueYtd.y.push(dataKPIProduct.ytd.data.y[item.count]);
    //-----------------------------
    if (dataKPIProduct.penultimateWeek) {
      listValuePenultimateWeek.label.push(
        dataKPIProduct.penultimateWeek.data.label[item.count]
      );
      listValuePenultimateWeek.x.push(
        dataKPIProduct.penultimateWeek.data.x[item.count]
      );
      listValuePenultimateWeek.y.push(
        dataKPIProduct.penultimateWeek.data.y[item.count]
      );
    }
    //-----------------------------
    if (dataKPIProduct.lastWeek) {
      listValueLastWeek.label.push(
        dataKPIProduct.lastWeek.data.label[item.count]
      );
      listValueLastWeek.x.push(dataKPIProduct.lastWeek.data.x[item.count]);
      listValueLastWeek.y.push(dataKPIProduct.lastWeek.data.y[item.count]);
    }
    //-----------------------------
    activeSkus.push(dataKPIProduct.activeStore[item.count]);
    //-----------------------------
    dataTable.push(dataKPIProduct.dataTable[item.count]);
  });

  setCacheData({
    dataTable,
    activeStore: activeSkus,
    act: {
      ...dataKPIProduct.act,
      data: listValueAct,
    },
    l3m: {
      ...dataKPIProduct.l3m,
      data: listValueL3m,
    },
    lm: {
      ...dataKPIProduct.lm,
      data: listValueLm,
    },
    ytd: {
      ...dataKPIProduct.ytd,
      data: listValueYtd,
    },
    penultimateWeek: {
      ...dataKPIProduct.penultimateWeek,
      data: listValuePenultimateWeek,
    },
    lastWeek: {
      ...dataKPIProduct.lastWeek,
      data: listValueLastWeek,
    },
  });
};

export const sortByPresence = (
  index: number,
  desc: boolean,
  limitItem: number,
  dataKPIProduct: IPayloadKpi = defaultValue,
  setCacheData: (payload: IPayloadKpi) => void
): void => {
  const activeSkus: string[] = [];
  const listValueAct: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };
  const listValueL3m: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };
  const listValueLm: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };
  const listValueYtd: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };
  const listValuePenultimateWeek: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };
  const listValueLastWeek: ITypeGraphicHorizontalBar = {
    label: [],
    x: [],
    y: [],
  };

  const listDataTable: IMonTable[] = [
    ...dataKPIProduct.dataTable.slice(0, limitItem),
  ];

  listDataTable.sort((value: any, nextValue: any) => {
    switch (index) {
      case 1:
        if (value.storesIds > nextValue.storesIds) {
          return desc ? -1 : 1;
        }

        if (value.storesIds < nextValue.storesIds) {
          return desc ? 1 : -1;
        }

        return 0;
      case 2:
        if (value.storeNames > nextValue.storeNames) {
          return desc ? -1 : 1;
        }
        if (value.storeNames < nextValue.storeNames) {
          return desc ? 1 : -1;
        }

        return 0;
      case 3:
        if (value.storeBanners > nextValue.storeBanners) {
          return desc ? -1 : 1;
        }
        if (value.storeBanners < nextValue.storeBanners) {
          return desc ? 1 : -1;
        }

        return 0;
      case 4:
        if (value.storeUf > nextValue.storeUf) {
          return desc ? -1 : 1;
        }
        if (value.storeUf < nextValue.storeUf) {
          return desc ? 1 : -1;
        }

        return 0;
      default:
        return 0;
    }
  });

  listDataTable.forEach((item) => {
    const indexTable = item.order - 1;

    activeSkus.push(dataKPIProduct.activeStore[indexTable]);
    //------------------------------------
    listValueAct.label.push(dataKPIProduct.act.data.label[indexTable]);
    listValueAct.x.push(dataKPIProduct.act.data.x[indexTable]);
    listValueAct.y.push(dataKPIProduct.act.data.y[indexTable]);
    //------------------------------------
    listValueL3m.label.push(dataKPIProduct.l3m.data.label[indexTable]);
    listValueL3m.x.push(dataKPIProduct.l3m.data.x[indexTable]);
    listValueL3m.y.push(dataKPIProduct.l3m.data.y[indexTable]);
    //------------------------------------
    listValueLm.label.push(dataKPIProduct.lm.data.label[indexTable]);
    listValueLm.x.push(dataKPIProduct.lm.data.x[indexTable]);
    listValueLm.y.push(dataKPIProduct.lm.data.y[indexTable]);
    //------------------------------------
    listValueYtd.label.push(dataKPIProduct.ytd.data.label[indexTable]);
    listValueYtd.x.push(dataKPIProduct.ytd.data.x[indexTable]);
    listValueYtd.y.push(dataKPIProduct.ytd.data.y[indexTable]);
    //------------------------------------
    if (dataKPIProduct.penultimateWeek) {
      listValuePenultimateWeek.label.push(
        dataKPIProduct.penultimateWeek.data.label[indexTable]
      );
      listValuePenultimateWeek.x.push(
        dataKPIProduct.penultimateWeek.data.x[indexTable]
      );
      listValuePenultimateWeek.y.push(
        dataKPIProduct.penultimateWeek.data.y[indexTable]
      );
    }

    if (dataKPIProduct.lastWeek) {
      listValueLastWeek.label.push(
        dataKPIProduct.lastWeek.data.label[indexTable]
      );
      listValueLastWeek.x.push(dataKPIProduct.lastWeek.data.x[indexTable]);
      listValueLastWeek.y.push(dataKPIProduct.lastWeek.data.y[indexTable]);
    }
  });

  setCacheData({
    dataTable: listDataTable,
    activeStore: activeSkus,
    act: {
      ...dataKPIProduct.act,
      data: listValueAct,
    },
    l3m: {
      ...dataKPIProduct.l3m,
      data: listValueL3m,
    },
    lm: {
      ...dataKPIProduct.lm,
      data: listValueLm,
    },
    ytd: {
      ...dataKPIProduct.ytd,
      data: listValueYtd,
    },
    penultimateWeek: {
      ...dataKPIProduct.penultimateWeek,
      data: listValuePenultimateWeek,
    },
    lastWeek: {
      ...dataKPIProduct.lastWeek,
      data: listValueLastWeek,
    },
  });
};
