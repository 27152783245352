import {
  Box,
  Flex,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import imageKPI from "../../../../assets/MyPerfectStore/kpi.svg";
import { IPSPontuationTemporalLine } from "../../../../pages/Private/MyPerfectStore/types";
import { filter } from "../../../../store/SideFilters/RequestFilters/types";
import { theme } from "../../../../styles/theme";
import HeaderGraphic from "../../../Graphics/HeaderGraphic";
import PieChart from "../../../Graphics/PieChart";
import TripleGroupedBar from "../../../Graphics/TripleGroupedBar";
import Column from "../../../Table/Column";
import HeaderTable from "../../../Table/Header";
import TemporalFilter from "../../../TemporalFilter";
import { subTableDetailPSPrimary, subTableDetailPSSecundary } from "../../data";

interface IOwnProps {
  show: boolean;
}

const SubDetailPerfectStore: React.FC<IOwnProps> = ({
  show,
}: IOwnProps): JSX.Element => {
  const [category, setCategory] = useState<number>(-1);
  const [periodFilter, setPeriodFilter] = useState<filter>({
    id: IPSPontuationTemporalLine.YTD,
    label: "YTD",
  });

  return (
    <>
      <Stack
        color="#000"
        bg="white"
        p={show ? `5px` : 0}
        mb={show ? "5px !important" : 0}
        mt="0px !important"
        h={show ? "260px" : 0}
        transition="0.4s"
        overflow="hidden "
      >
        <Text pl="10px" fontStyle="italic" fontSize="16px">
          Evolutivo Mensal
        </Text>
        <Flex>
          <Stack
            whiteSpace="nowrap"
            minW="180px"
            mt="2px"
            border="1px solid #E7E6E6"
            p={1}
          >
            <HeaderGraphic>Sub Canal</HeaderGraphic>
            <Flex
              mt="5px !important"
              h="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                src={imageKPI}
                alt="categoria"
                maxWidth="150px"
                maxHeight="150px"
              />
            </Flex>
          </Stack>
          <Flex
            w="100%"
            overflow="auto"
            css={`
              &::-webkit-scrollbar {
                background-color: darkgray;
                border-radius: 20px;
                width: 12px;
                height: 12px;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 20px;
                background-color: ${theme.scrollColor};
              }
            `}
          >
            <Flex
              border="1px solid #E7E6E6"
              p="1px"
              mt="3px"
              h="fit-content"
              w="100%"
            >
              <Table style={{ borderCollapse: "separate" }}>
                <Thead>
                  <Tr>
                    {subTableDetailPSPrimary.header.map((brand, index) => (
                      <HeaderTable key={Math.random()} mw="fit-content">
                        {brand}
                      </HeaderTable>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {subTableDetailPSPrimary.data.map((item, idx) => (
                    <Tr key={Math.random()}>
                      {item.map((brand, index) => (
                        <Column
                          onClick={
                            index === 0
                              ? () => setCategory(category === idx ? -1 : idx)
                              : undefined
                          }
                          key={Math.random()}
                          isTextCenter={index === 1}
                          bg={
                            (idx === category && "#B4C7E7") ||
                            (index === 2 || index === 6 || index === 7
                              ? "#E7E6E6"
                              : "white")
                          }
                          isBold={!!(index === 2 || index === 6 || index === 7)}
                          value={brand}
                        />
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Flex>
            <Flex
              border="1px solid #E7E6E6"
              p="1px"
              mt="3px"
              ml={1}
              h="fit-content"
              w="100%"
            >
              <Table style={{ borderCollapse: "separate" }}>
                <Thead>
                  <Tr>
                    {subTableDetailPSSecundary.header.map((brand) => (
                      <HeaderTable key={Math.random()} mw="fit-content">
                        {brand}
                      </HeaderTable>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {subTableDetailPSSecundary.data.map((item, idx) => (
                    <Tr key={Math.random()}>
                      {item.map((brand, index) => (
                        <Column
                          key={Math.random()}
                          bg={(idx === category && "#B4C7E7") || "#fff"}
                          value={brand}
                        />
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Flex>
          </Flex>
        </Flex>
      </Stack>
      <Stack
        bg="white"
        mt="0px !important"
        h={category === -1 ? 0 : 450}
        mb={`${category === -1 ? 0 : 5}px !important`}
        overflow="hidden"
        transition="0.4s"
        color="black"
      >
        <Flex>
          <TemporalFilter
            temporalFilter={periodFilter}
            setTemporalFilter={setPeriodFilter}
            itens={[
              {
                id: IPSPontuationTemporalLine.YTD,
                label: "YTD",
              },
              {
                id: IPSPontuationTemporalLine.L3M,
                label: "L3M",
              },
            ]}
          />
        </Flex>
        <Flex justifyContent="center">
          <PieChart />
          <TripleGroupedBar
            data={{
              y: {
                first: ["7.8", "7.3"],
                secondary: ["6.6", "6.1"],
                tertiary: ["3.1", "2.6"],
              },
              text: {
                first: ["7.8%", "7.3%"],
                secondary: ["6.6%", "6.1%"],
                tertiary: ["3.1%", "2.6%"],
              },
              x: ["Ano 2022", "Ano 2023"],
              legend: ["Marca A", "Marca B", "Marca C"],
            }}
            title="<b>Cadastro</b>"
          />
          <TripleGroupedBar
            data={{
              y: {
                first: ["12.5", "12.0"],
                secondary: ["12.3", "11.8"],
                tertiary: ["13.5", "13.0"],
              },
              text: {
                first: ["12.5%", "12.0%"],
                secondary: ["12.3%", "11.8%"],
                tertiary: ["13.5%", "13.0%"],
              },
              x: ["Ano 2022", "Ano 2023"],
              legend: ["Marca A", "Marca B", "Marca C"],
            }}
            title="<b>Est. Virtual</b>"
          />
          <TripleGroupedBar
            data={{
              y: {
                first: ["18.5", "18.0"],
                secondary: ["24.5", "24.0"],
                tertiary: ["11.5", "11.0"],
              },
              text: {
                first: ["18.5%", "18.0%"],
                secondary: ["24.5%", "24.0%"],
                tertiary: ["11.5%", "11.0%"],
              },
              x: ["Ano 2022", "Ano 2023"],
              legend: ["Marca A", "Marca B", "Marca C"],
            }}
            title="<b>Ruptura</b>"
          />
        </Flex>
      </Stack>
    </>
  );
};

export default SubDetailPerfectStore;
