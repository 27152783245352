import { action } from "typesafe-actions";
import {
  actionsType,
  login,
  IErrorRefreshToken,
  IRequestRefreshToken,
  ISuccessRefreshToken,
} from "./types";

export const requestRefreshToken = (data: login): IRequestRefreshToken =>
  action(actionsType.REQUEST_REFRESH_TOKEN, data);

export const successRefreshToken = (): ISuccessRefreshToken =>
  action(actionsType.REFRESH_TOKEN_SUCCESS);

export const errorRefreshToken = (): IErrorRefreshToken =>
  action(actionsType.REFRESH_TOKEN_ERROR);
