import React from "react";
import { HeaderMonth, ThCustom } from "./THead.element";

interface IOwnProps {
  title: string;
  subTitle: string[];
  col?: string;
  minW?: string;
}

const THead: React.FC<IOwnProps> = (props: IOwnProps) => {
  return (
    <ThCustom minW={props.minW}>
      {props.title}
      <HeaderMonth grid={props.col}>
        {props.subTitle.map((sub) => (
          <span key={Math.random()}>{sub}</span>
        ))}
      </HeaderMonth>
    </ThCustom>
  );
};

THead.defaultProps = {
  col: "repeat(3, 1fr)",
  minW: "325px",
};

export default THead;
