import { StyleSheet } from "@react-pdf/renderer";
import { theme } from "../../../../../styles/theme";

export const cover = StyleSheet.create({
  wrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    color: "white",
  },
  background: {
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
  },
  logo: {
    width: 70,
    height: 70,
  },
  boxTitle: {
    height: "84%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  industryNameBox: {
    width: "100%",
    opacity: 0.7,
    backgroundColor: theme.colors.numberCards,
    padding: 5,
    height: 60,
    position: "relative",
  },
  industryName: {
    width: "100%",
    textAlign: "center",
    fontSize: 44,
    fontWeight: "bold",
    position: "absolute",
    top: 300,
  },
  subTitleBox: {
    width: "100%",
    paddingTop: 20,
    paddingLeft: 15,
    fontSize: 16,
    fontWeight: "normal",
  },
  footer: {
    color: "white",
    fontSize: 15,
    padding: 5,
    marginLeft: 2,
    position: "absolute",
    bottom: 0,
    fontWeight: "bold",
  },
});

export const layout = StyleSheet.create({
  viewHeader: {
    backgroundColor: "#000040",
    height: 80,
    paddingLeft: 110,
    color: "white",
    display: "flex",
    justifyContent: "center",
    lineHeight: 1.4,
    fontSize: 24,
    position: "relative",
    marginBottom: 20,
  },
  logo: {
    position: "absolute",
    width: 70,
    height: 70,
    right: 0,
    top: 2,
  },
  logoColaboration: {
    position: "absolute",
    width: 140,
    top: -15,
    left: -15,
  },
  viewContent: {
    height: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 15,
    marginRight: 15,
  },
  footer: {
    backgroundColor: "#000040",
    width: "100%",
    color: "white",
    fontSize: 14,
    padding: 5,
    position: "absolute",
    bottom: 0,
  },
});

export const premiseBook = StyleSheet.create({
  main: {
    height: "100%",
    width: "100%",
  },
  title: {
    backgroundColor: "#E7E6E6",
    textAlign: "center",
    padding: 10,
    color: "#001059",
    fontWeight: "bold",
    fontSize: 20,
  },
  content: {
    display: "flex",
    flexGrow: 1,
  },
});

export const stylePremiseItem = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
  },
  box: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
  },
  wrapperView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#7A7A7A",
    padding: 5,
    paddingLeft: 10,
    fontSize: 14,
    marginBottom: 20,
  },
  number: {
    fontSize: 36,
    color: theme.colors.numberCards,
    fontWeight: "bold",
  },
  image: {
    marginLeft: 5,
    marginRight: 5,
    width: 35,
  },
  boxInfo: {
    fontSize: 14,
    color: "#7a7a7a",
    marginTop: 20,
    marginLeft: 10,
  },
  icons: {
    width: "45%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

export const evidenceStyle = StyleSheet.create({
  wrapperBox: {
    marginTop: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "97%",
    color: "#7F7F7F",
  },
  icon: {
    width: 60,
    height: 60,
    marginRight: 10,
  },
  wrapperEvidence: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    // backgroundColor: "red",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "49.8%",
    height: "100%",
    // backgroundColor: "blue",
  },
  image: {
    marginTop: 1,
    width: "138px",
    height: "240px",
  },
  footer: {
    marginTop: 40,
    marginRight: 10,
    marginBottom: 30,
    marginLeft: 80,
    lineHeight: 1.2,
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
    color: "#7F7F7F",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
});

export const feedbackStyle = StyleSheet.create({
  wrapperContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
    marginTop: 30,
    marginLeft: 50,
    marginRight: 50,
  },
  boxText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: 20,
  },
  logo: {
    width: 250,
  },
  marker: {
    backgroundColor: "#7f7f7f",
    width: 7,
    height: 7,
    marginTop: 6,
    marginRight: 15,
    borderRadius: "50%",
  },
});
