import BannerDetailProduct from "../OnePage/Products/DetailProducts";
import BannerDetailStore from "../OnePage/Stores/DetailStores";
import NPDDatailStore from "../MyExecution/NPD/detailYellowPharma/Stores/DetailStores";
import MainContent from "../../../components/MainContent/MainContent";
import ComplianceStore from "../MyNegotiations/ViewCompliance/Store/DetailStore";
import { INavContent } from "../../../store/Navigation/types";
import MyDayDetailProducts from "../MyDay/Planning/Products/MyDayDetailProducts";
import MyDayDetailStores from "../MyDay/Planning/Stores/MyDayDetailStores";
import DetailKPI from "../MyExecution/NPD/detailKPI";
import DetailKPIStore from "../MyExecution/NPD/detailKPIStore";

const RenderContent = (currentContent: INavContent): JSX.Element => {
  switch (currentContent) {
    case INavContent.MAIN_DASH_CONTENT:
      return <MainContent />;
    case INavContent.MS_BANNER_DATAIL_PRODUCT:
      return <BannerDetailProduct />;
    case INavContent.MS_BANNER_DATAIL_STORE:
      return <BannerDetailStore />;
    case INavContent.ME_DETAIL_STORE:
      return <NPDDatailStore />;
    case INavContent.ME_DETAIL_KPI:
      return <DetailKPI />;
    case INavContent.ME_DETAIL_KPI_STORE:
      return <DetailKPIStore />;
    case INavContent.MN_DETAIL_COMPLIANCE:
      return <ComplianceStore />;
    case INavContent.MD_DETAIL_PRODUCT:
      return <MyDayDetailProducts />;
    case INavContent.MD_DETAIL_STORE:
      return <MyDayDetailStores />;
    default:
      return <MainContent />;
  }
};

export default RenderContent;
