import styled from 'styled-components';

export const Input = styled.input`
  /* border-radius: 4px;
  border: 0.5px solid #000;
  width: 100%;
  max-width: 180px;
  padding: 4px 8px;
  outline: 0;
  margin: 0 auto;
  margin-top: 4px;
  margin-bottom: 8px;
  */
  outline: 0;

  border-color: transparent;
  /* margin-left: 6px; */

  background: transparent;
  padding: 0 4px;
  /* margin-left: 4px; */
  width: 95%;

  ::placeholder {
    padding: 0 4px;
  }
`;
