/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Box, Button, Flex, Input, Stack, Text } from "@chakra-ui/react";
import {
  SideBarPrifile,
  BackgroundProfile,
  Header,
  DataProfile,
  Image,
} from "./sideBarProfile.element";
import * as ANavigator from "../../store/Navigation/actions";
import { dataProfile } from "../../store/Profile/types";
import { actionLogout } from "../../store/Authentication/Logout/types";
import avatarNotDefined from "../../assets/Profile/avatar-not-defined.jpg";
import { INavPage } from "../../store/Navigation/types";
import { GlobalProps } from "../../store";
import {
  IChangePassword,
  useChangePassword,
} from "../../services/Hooks/modifyPassword/useChangePassword";
import ItemListProfile from "../ListItensProfile";
import { theme } from "../../styles/theme";
import { IFeedbackNotification } from "../../services/Hooks/Notification";

interface ownProps {
  active: boolean;
  hiddenProfile(show: boolean): void;
  signOut(page: actionLogout): void;
  payloadProfile: dataProfile;
  changePage(indexPage: INavPage): void;
  notification: IFeedbackNotification[];
}

type props = ownProps;

const BarProfile: React.FC<props> = ({
  active,
  payloadProfile,
  hiddenProfile,
  signOut,
  changePage,
}: props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<IChangePassword>({
    newPassword: "",
    newPasswordConfirmation: "",
    oldPassword: "",
  });
  const [responseError, setResponseError] = useState<string>("");

  const openModal = () => {
    setShowModal(!showModal);
  };

  const setProfile = () => {
    hiddenProfile(false);
  };

  const actionPage: actionLogout = {
    setPage: changePage,
  };

  const validateInputs = Yup.object().shape({
    old_password: Yup.string().required("O campo é obrigatório."),
    new_password: Yup.string()
      .required("O campo é obrigatório.")
      .min(8, "A senha deve ter no minimo 8 Caracteres!"),
    new_password_confirmation: Yup.string()
      .required("O campo é obrigatório.")
      .test("As senhas não coincidem", "As senhas não coincidem", (value) => {
        return newPassword.newPassword === value;
      }),
  });

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(validateInputs),
  });

  const changePassword = useChangePassword(newPassword);

  const clearInputAndState = () => {
    setNewPassword({
      newPassword: "",
      newPasswordConfirmation: "",
      oldPassword: "",
    });
    reset();
  };

  const handleChangePassword = () => {
    changePassword.refetch().then((response) => {
      const objResponse = response.data;

      if (objResponse && objResponse.status !== "200") {
        setResponseError(objResponse.message);
      } else {
        clearInputAndState();
        setResponseError("");
        setShowModal(!showModal);
      }
    });
  };

  return (
    <>
      <BackgroundProfile onClick={() => setProfile()} hidden={!active} />
      <SideBarPrifile show={active}>
        <Header>
          <h2>Perfil do Usuario</h2>
          <span aria-hidden="true" onClick={() => setProfile()}>
            &#10006;
          </span>
        </Header>
        <DataProfile>
          <Image>
            <img
              src={payloadProfile.avatar || avatarNotDefined}
              alt="avatar-profile"
            />
          </Image>
          <Text className="userName">
            <b>{`${payloadProfile.firstName || "Sem nome"} ${
              payloadProfile.lastName
            }`}</b>
          </Text>
          <Text>{payloadProfile.industrie || "Indrustrie"}</Text>
          <Text className="userEmail">
            {payloadProfile.email || "exemple@exemple.com"}
          </Text>
          <Flex justify="flex-end">
            <Button
              mr="10px"
              h="33px"
              minW="100px"
              fontSize="12px"
              color="white"
              bg={theme.colors.button}
              onClick={() => signOut(actionPage)}
            >
              Sair
            </Button>
          </Flex>
        </DataProfile>
        <Box overflowX="hidden" h="calc(100% - 240px)">
          <ItemListProfile label="Alterar Senha">
            <Stack
              as="form"
              w="100%"
              onSubmit={handleSubmit(handleChangePassword)}
            >
              <Stack w="100%" fontSize="12px" color="gray">
                <Text fontWeight="bold">Digite sua senha atual</Text>
                <Input
                  placeholder="Digite a senha atual"
                  {...register("old_password")}
                  border="1px solid red"
                  type="password"
                  fontSize="12px"
                  h="33px"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setNewPassword({
                      ...newPassword,
                      oldPassword: e.currentTarget.value,
                    })
                  }
                />
                <Text color="red" my="2px !important">
                  {formState.errors.old_password?.message}
                </Text>
                <Text fontWeight="bold">Digite sua nova senha</Text>
                <Input
                  {...register("new_password")}
                  placeholder="Digite sua senha"
                  border="1px solid gray"
                  type="password"
                  fontSize="12px"
                  h="33px"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setNewPassword({
                      ...newPassword,
                      newPassword: e.currentTarget.value,
                    })
                  }
                />
                <Text color="red" my="2px !important">
                  {formState.errors.new_password?.message}
                </Text>
                <Text fontWeight="bold">Confirme sua nova senha</Text>
                <Input
                  {...register("new_password_confirmation")}
                  placeholder="Confirme sua nova senha"
                  border="1px solid gray"
                  type="password"
                  fontSize="12px"
                  h="33px"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setNewPassword({
                      ...newPassword,
                      newPasswordConfirmation: e.currentTarget.value,
                    })
                  }
                />
                <Text color="red" my="2px !important">
                  {formState.errors.new_password_confirmation?.message}
                </Text>
              </Stack>
              {responseError && (
                <Text
                  bg="#E53E3E"
                  textAlign="center"
                  py="5px"
                  mx="20px !important"
                  borderRadius={4}
                  fontWeight="bold"
                  fontSize="12px"
                >
                  {responseError}
                </Text>
              )}
              <Flex>
                <Button
                  onClick={() => {
                    clearInputAndState();
                    openModal();
                  }}
                  w="100%"
                  color="black"
                  border={`1px solid ${theme.colors.button}`}
                  mr="15px"
                  h="35px"
                  fontSize="12px"
                >
                  Cancelar
                </Button>
                <Button
                  w="100%"
                  h="35px"
                  type="submit"
                  background={theme.colors.button}
                  color="white"
                  fontSize="12px"
                  disabled={
                    newPassword.oldPassword === "" ||
                    newPassword.newPassword === "" ||
                    newPassword.newPasswordConfirmation === ""
                  }
                >
                  Salvar
                </Button>
              </Flex>
            </Stack>
          </ItemListProfile>
          {/* <Flex alignItems="center" mt="15px !important" mb="5px">
            <IoMdNotificationsOutline size={23} color="black" />
            <Text fontSize="13px" color="black" fontWeight="bold">
              Notificações
            </Text>
          </Flex> */}
          {/* <Stack
            maxH="calc(100vh - 400px)"
            w="100%"
            overflow="auto"
            border="1px solid lightgray"
            borderRadius={6}
            transition="0.5s"
            p="7px"
            color="black"
            css={`
              &::-webkit-scrollbar {
                background-color: darkgray;
                border-radius: 20px;
                width: 12px;
                height: 12px;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 20px;
                background-color: ${theme.scrollColor};
              }
            `}
          >
            {(notification.length > 0 &&
              notification.map((feedback) => (
                <Stack
                  key={Math.random()}
                  bg="#bcd2e3"
                  borderRadius={5}
                  p="10px"
                >
                  <Flex align="center">
                    <MdOutlineFeedback size={18} />
                    <Text
                      w="100%"
                      color="gray"
                      textAlign="right"
                      fontSize="9px"
                    >
                      {formatedDateTimeToBr(feedback.date, true)}
                    </Text>
                  </Flex>
                  <Text
                    borderBottom="1px solid gray"
                    fontWeight="bold"
                    textAlign="center"
                    pb="10px"
                  >
                    {feedback.store}
                  </Text>
                  <Text>{feedback.feedback}</Text>
                </Stack>
              ))) || (
              <Text
                textAlign="center"
                py="25px"
                color="gray"
                mx="auto"
                fontSize="14px"
              >
                Sem Notificações
              </Text>
            )}
          </Stack> */}
        </Box>
      </SideBarPrifile>
    </>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigator: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarProfile);
