import styled, { keyframes } from 'styled-components';

interface IPropsStyle {
  showCloseButton?: boolean;
  onScreen?: boolean;
  bgColor?: string;
  height?: string;
  width?: string;
  margin?: string;
  color?: string;
  border?: string;
  position?: string;
  isToast?: boolean;
  fontSize?: string;
}

const Alert = keyframes`
  from {
      opacity: 0;
      transition: translateY(-50px);
    }
  to{
      transition: translateY(0);
      opacity: 1;
    }
`;

export const ToastContainer = styled.div<IPropsStyle>`
  animation: ${Alert} 0.2s ease-out;
  border: 1px solid inherit;

  display: flex;

  z-index: 10000;
  background: ${props => props.bgColor || '#fff'};
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  position: absolute;
  margin: ${props => props.margin || '20px auto'};
  border-radius: 3px;
  width: ${props => props.width || 'auto'};
  max-width: 800px;
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
`;

export const ToastContent = styled.div<IPropsStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.175rem;
  z-index: 10000;

  div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div:nth-child(2) {
    margin-left: 0.375rem;

    p {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
`;

export const ToastFooterContainer = styled.footer<IPropsStyle>`
  display: flex;
  z-index: 10000;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 0 8px 0;

  border-top: ${props => props.border || 'none'};
  margin: ${props => props.margin || '0'};
`;
