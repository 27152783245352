import { Flex, Select, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  IPSMainCards,
  IPSPontuationTemporalLine,
} from "../../../../pages/Private/MyPerfectStore/types";
import { IKPIData } from "../../../../services/Hooks/MyPerfectStore/useStoreAbsolute";
import {
  ITypeGraphicPeriod,
  ITypeGraphicPeriodBar,
} from "../../../../services/Hooks/OnePage/onePageTypes";
import { filter } from "../../../../store/SideFilters/RequestFilters/types";
import Card from "../../../Card";
import MarketSharePeriodBarLtyYtd from "../../../Graphics/MarketSharePeriodBarLtyYtd/MarketSharePeriodBarLtyYtd";
import MarketSharePeriodLtyYtd from "../../../Graphics/MarketSharePeriodLtyYtd/MarketSharePeriodLtyYtd";
import LegendIcon, { ETypeLegend } from "../../../LegendIcon";
import TemporalFilter from "../../../TemporalFilter";
import {
  perfectStoreAbsolut,
  pontuationKPIsBar,
  pontuationKPIsPeriod,
} from "../../data";

interface IMainCardKpiPerfectStore {
  periodBar: ITypeGraphicPeriod;
  graphicBar: ITypeGraphicPeriodBar;
}

interface IOwnProps {
  // selected: boolean;
  // onSelected(card: IPSMainCards): void;
  mainCard: IKPIData[];
  currentPeriod: string;
}

const PontuationKPIs: React.FC<IOwnProps> = ({
  // selected,
  // onSelected,
  mainCard,
  currentPeriod,
}: IOwnProps): JSX.Element => {
  const [temporalLine, setTemporalLine] = useState<filter>({
    id: IPSPontuationTemporalLine.YTD,
    label: "YTD",
  });

  const [selectedFilter, setSelectedFilter] = useState<string>(currentPeriod);

  const dataGraphic = mainCard.filter(
    (data) => data.period.split("'")[0].toUpperCase() === selectedFilter
  )[0];

  const legends = [
    { label: "KPIs 2022", type: ETypeLegend.period },
    { label: "KPIs 2023", type: ETypeLegend.bar },
  ];

  // const changeSelectedCard = () => {
  //   onSelected(IPSMainCards.PONTUATION_KPIS);
  // };

  return (
    <Card
      title="Pontuação | KPIs"
      w="75%"
      overflow="none"
      minW="400px"
      // selected={selected}
      // setSelected={changeSelectedCard}
    >
      <Stack flex={1} p="5px 8px">
        <Flex h="73px" justifyContent="space-between">
          {/* <TemporalFilter
            itens={[
              { id: IPSPontuationTemporalLine.YTD, label: "YTD" },
              { id: IPSPontuationTemporalLine.L3M, label: "L3M" },
              { id: IPSPontuationTemporalLine.MES, label: "MES" },
            ]}
            setTemporalFilter={setTemporalLine}
            temporalFilter={temporalLine}
          /> */}

          <Flex m="5px" justifyContent="flex-end">
            <Select
              ml="5px"
              p="0"
              borderRadius="5px"
              as="select"
              className="selectForm"
              borderColor="black"
              h="28px"
              fontSize="12px"
              color="black"
              w="100px"
              zIndex={10}
              onChange={(e) => {
                setSelectedFilter(e.target.value);
              }}
            >
              <option value="-1">Todas</option>
              {perfectStoreAbsolut.rightChartBar.x
                .filter((values) => {
                  const period = parseInt(currentPeriod.replace(/\D/g, ""), 10);
                  const filterPeriod = parseInt(
                    values.split("'")[0].replace(/\D/g, ""),
                    10
                  );

                  return filterPeriod <= period;
                })
                .map((label) => (
                  <option
                    selected={
                      label.split("'")[0].toUpperCase() === selectedFilter
                    }
                    value={label.split("'")[0].toUpperCase()}
                    key={Math.random()}
                  >
                    {label}
                  </option>
                ))}
            </Select>
          </Flex>
        </Flex>
        <Flex justifyContent="center" mt="0px !important">
          <Stack w="80%">
            <MarketSharePeriodLtyYtd
              lineWidth={false}
              dataGraphic={pontuationKPIsPeriod}
              colors="#C00000"
              ml={13}
              mr={15}
              autosize
            />
            <MarketSharePeriodBarLtyYtd
              gap={0.13}
              autosize
              dataGraphic={{
                label: dataGraphic?.label || [],
                x: dataGraphic?.x || [],
                y: dataGraphic?.y || [],
              }}
            />
          </Stack>
        </Flex>
        <Flex justify="center" align="center">
          {legends.map((legend, index) => (
            <LegendIcon
              ml={index === 1 ? "10px" : "0px"}
              typeLegend={legend.type}
            >
              {legend.label}
            </LegendIcon>
          ))}
        </Flex>
      </Stack>
    </Card>
  );
};

export default PontuationKPIs;
