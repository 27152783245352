import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import { FaBluetooth } from "react-icons/fa";
import { PaginationItem } from "./PaginationItem";

interface PaginationProps {
  totalCountOfRegisters: number | undefined;
  registersPerPage?: number;
  currentPage?: number;
  onPageChange: (page: number) => void;
}

const siblingsCount = 1;

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => {
      return from + index + 1;
    })
    .filter((page) => page > 0);
}

export const Pagination: React.FC<PaginationProps> = ({
  totalCountOfRegisters = 0,
  registersPerPage = 12,
  currentPage = 1,
  onPageChange,
}: PaginationProps): JSX.Element => {
  const lastPage = Math.round(totalCountOfRegisters / registersPerPage);

  const previousPage =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : [];

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage)
        )
      : [];

  return (
    <Stack
      direction={["column", "row"]}
      spacing="6"
      justify="space-between"
      align="center"
      w="100%"
    >
      <Box color="gray.600">
        <strong>{(currentPage - 1) * 12 + 1} </strong> -{" "}
        <strong>
          {totalCountOfRegisters < 12
            ? totalCountOfRegisters
            : currentPage * 12}{" "}
        </strong>{" "}
        de
        <strong>{` ${Math.round(totalCountOfRegisters / 12)}`}</strong>
      </Box>

      <HStack spacing="2">
        {currentPage > 1 + siblingsCount && (
          <>
            <PaginationItem {...{ onPageChange }} number={1} bg="#2a90b8" />
            {currentPage > 2 * siblingsCount && (
              <Text
                color="black"
                fontSize="16px"
                fontWeight="bold"
                width="8"
                textAlign="center"
              >
                ...
              </Text>
            )}
          </>
        )}

        {previousPage.length > 0 &&
          previousPage.map((page) => {
            return (
              <PaginationItem {...{ onPageChange }} key={page} number={page} />
            );
          })}

        <PaginationItem {...{ onPageChange }} number={currentPage} isCurrent />

        {nextPages.length > 0 &&
          nextPages.map((page) => {
            return (
              <PaginationItem {...{ onPageChange }} key={page} number={page} />
            );
          })}

        {currentPage + siblingsCount < lastPage && (
          <>
            {currentPage + 1 + siblingsCount < lastPage && (
              <Text
                color="black"
                fontSize="16px"
                fontWeight="bold"
                width="8"
                textAlign="center"
              >
                ...
              </Text>
            )}
            <PaginationItem
              {...{ onPageChange }}
              number={lastPage}
              bg="#2a90b8"
            />
          </>
        )}
      </HStack>
    </Stack>
  );
};

Pagination.defaultProps = {
  registersPerPage: 12,
  currentPage: 1,
};
