import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";
import { ITypeGraphicPeriodBar } from "../OnePage/onePageTypes";
import { ISubTableComplements } from "./types";

interface ISubTableGraphics {
  rightBar: ITypeGraphicPeriodBar;
}

const requestSubPresence = async (
  period: string,
  filters: IStateFilters
): Promise<ISubTableComplements> => {
  const presenceCategory = await api.get<{ data: ISubTableGraphics }>(
    `presence_category/?period=${period}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        retailEnviroment:
          filters.promoFilter.retailEnviroment.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        coordinator:
          filters.promoFilter.commercialCoordinator.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
        // segment: filters.segments.selectedFilterId || null,
      },
    }
  );

  const presenceRegion = await api.get<{ data: ISubTableGraphics }>(
    `presence_region/?period=${period}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        retailEnviroment:
          filters.promoFilter.retailEnviroment.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        segment: filters.promoFilter.segments.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        coordinator:
          filters.promoFilter.commercialCoordinator.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  const presenceSubChannel = await api.get<{ data: ISubTableGraphics }>(
    `presence_sub_channel/?period=${period}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        retailEnviroment:
          filters.promoFilter.retailEnviroment.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        segment: filters.promoFilter.segments.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        coordinator:
          filters.promoFilter.commercialCoordinator.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  const presenceBanner = await api.get<{ data: ISubTableGraphics }>(
    `presence_banner/?period=${period}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        retailEnviroment:
          filters.promoFilter.retailEnviroment.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        segment: filters.promoFilter.segments.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        coordinator:
          filters.promoFilter.commercialCoordinator.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  return {
    presenceCategory: presenceCategory.data.data,
    presenceRegion: presenceRegion.data.data,
    presenceSubChannel: presenceSubChannel.data.data,
    presenceBanner: presenceBanner.data.data,
  };
};

export const useRequestSubPresence = (
  period: string,
  filters: IStateFilters
): UseQueryResult<ISubTableComplements, unknown> => {
  return useQuery(
    ["subPresence", filters],
    () => requestSubPresence(period, filters),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
};
