/* eslint-disable camelcase */
import { IStateFilters } from "../../../store/SideFilters/types";

export const baseUrl = "https://api.integration.tradexbip.com/";

export interface IKPIExtract {
  extraExposure: boolean;
  shareOfShelf: boolean;
  compliance: boolean;
  rupture: boolean;
  perfectStore: boolean;
  adoptStore: boolean;
}

export interface IParamsExtractData {
  industryId: string;
  filters: IStateFilters;
  kpisSelected: IKPIExtract;
}

export interface IReturnExtractData {
  urlShareOfShelf: string;
  urlRupture: string;
  urlExtraExposure: string;
  urlCompliance: string;
  urlPerfectStore: string;
  urlAdoptStore: string;
}

export interface IFormatReturn {
  file: string;
  message: string;
  status: string;
}

export interface IParameters {
  industry_id?: string | null;
  initial_execution_date: string | null;
  end_execution_date: string | null;
  businessUnit: string | null;
  category: string | null;
  subCategory: string | null;
  subChannel: string | null;
  banner: string | null;
  region: string | null;
  uf: string | null;
  executionType: string | null;
  executionLeverage: string | null;
  cnpj: string | null;
}
