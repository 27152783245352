import React, { useRef, useEffect, ChangeEvent } from "react";
import { Flex, Box, Text, Icon } from "@chakra-ui/react";
import { BiSearchAlt } from "react-icons/bi";
import { Input } from "./styles";
import { theme } from "../../../../styles/theme";
import { IFilter } from "../../../../store/SideFilters/types";

interface IOwnProps {
  searchPlaceholder?: string;
  search: string;
  searchChangeHandler(e: ChangeEvent<HTMLInputElement>): void;
  options?: IFilter[];
  selectedValue: string;
  changeSelectedHandler: any;
  filter: any;
  selectedIndex: string;
  data: string;
}

const Dropdown: React.FC<IOwnProps> = ({
  searchPlaceholder,
  search,
  searchChangeHandler,
  options,
  selectedValue,
  changeSelectedHandler,
  filter,
  selectedIndex,
  data,
}: IOwnProps): JSX.Element => {
  const searchInputEl = useRef<HTMLInputElement>(null);
  const itemsEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    searchInputEl.current?.focus();
    if (selectedValue && itemsEl.current) {
      const element = itemsEl.current.querySelector<HTMLDivElement>(
        `.item-${selectedIndex}`
      );

      if (element) itemsEl.current.scrollTop = element.offsetTop - 42;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Flex
      w="100% !important"
      direction="column"
      align="flex-start"
      className="dropdown__menu"
      pr="1px"
      pl="1px"
      width="100% !important"
      color="black"
    >
      <Flex
        borderRadius=" 4px"
        border="0.5px solid #E7E6E6"
        // maxWidth="205px"
        padding="4px 0"
        outline="0"
        margin="0 5px"
        marginTop=" 4px"
        marginBottom=" 8px"
        fontSize="11px"
        justify="space-around"
        width="calc(100% - 10px)"
      >
        <Input
          type="text"
          placeholder={searchPlaceholder || "Buscar por..."}
          className="dropdown__menu_search"
          value={search}
          onChange={searchChangeHandler}
          ref={searchInputEl}
        />

        <Icon
          as={BiSearchAlt}
          fontSize={22}
          fontWeight="normal"
          color="#8689A0"
        />
      </Flex>
      <Box
        overflow="auto"
        h="140px"
        p="0 10px"
        w="100%"
        ref={itemsEl}
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
            height: "12px",
            borderRadius: "20px",
            backgroundColor: "darkgray",
            // marginRight: '4px',
            // display: 'none',
          },
          "&::-webkit-scrollbar-thumb": {
            background: theme.scrollColor,
            borderRadius: "20px",
            // marginRight: '4px',

            // display: 'none',
          },
        }}
      >
        <Box
          cursor="pointer"
          onClick={() =>
            changeSelectedHandler(
              { id: "", value: "Todos" },
              "Todos",
              data.indexOf("Todos"),
              filter
            )
          }
        >
          <Text>Todos</Text>
        </Box>
        {options?.map((item) => (
          <Box
            cursor="pointer"
            key={item.id}
            onClick={() =>
              changeSelectedHandler(
                item,
                item.label,
                data.indexOf(item.label),
                filter
              )
            }
          >
            <Text>{item.label}</Text>
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

Dropdown.defaultProps = {
  searchPlaceholder: undefined,
  options: undefined,
};

export default Dropdown;
