import React, { useCallback, useContext, useEffect, useState } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import PontuationKPIs from "../../../components/MyPerfectStore/PontuationKPI/PontuationKPIs";

import PerfectStoreAbsolut from "../../../components/MyPerfectStore/Absolut/PerfectStoreAbsolut";
import AbsolutCard from "../../../components/MyPerfectStore/Absolut";
import PontuationKpiCard from "../../../components/MyPerfectStore/PontuationKPI";
import PerfectStorePontuation from "../../../components/MyPerfectStore/Pontuation/PerfectStorePontuation";
import { IPSMainCards } from "./types";
import FiltersList from "../../../components/SideFilterMenu/Resource/FiltersList";
import { ConfigContext } from "../../../services/Context/ConfigContext";
import { filterComponent } from "../../../store/Navigation/types";
import {
  INITIAL_FILTERS,
  IShowFilters,
  IStateFilters,
  TPromoFilter,
} from "../../../store/SideFilters/types";
import { stateMarketShare } from "../../../store/SideFilters/RequestFilters/types";
import { GlobalProps } from "../../../store";

// Actions
import * as ANavigation from "../../../store/Navigation/actions";
import * as AFilters from "../../../store/SideFilters/actions";
import {
  IAbsoluteData,
  INITIAL_DETAIL_PERFECT_STORE,
  useStoreAbsolute,
} from "../../../services/Hooks/MyPerfectStore/useStoreAbsolute";
import { ITypeGraphicPeriodBar } from "../../../services/Hooks/OnePage/onePageTypes";
import RankingStoreByPontuation from "../../../components/MyPerfectStore/Absolut/RankingStoreByPontuation";

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}
interface IDispatchProps {
  DFilters: {
    changeFilterIsPromo(dataFilter: TPromoFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
    changeCnpjStore(payload: string): void;
  };

  DNavigation: {
    changeFilters(filters: filterComponent[]): void;
  };
}

type props = IStateProps & IDispatchProps;

const MyPerfectStore: React.FC<props> = ({
  SFilters,
  DFilters,
  SAllFilters,
  DNavigation,
}: props): JSX.Element => {
  const [PSSelectedCard, setPSSelectedCard] = useState<IPSMainCards>(
    IPSMainCards.PERFECT_STORE_ABSOLUTE
  );

  const { config } = useContext(ConfigContext);
  const perfectStoreData = useStoreAbsolute(SFilters);

  const validateStoreAbsolute: IAbsoluteData = perfectStoreData.data
    ?.absoluteData ?? {
    mainCard: {
      leftBar: {
        label: [],
        x: [],
        y: [],
      },
      periodBar: {
        label: [],
        y: [],
      },
      rightBar: {
        label: [],
        x: [],
        y: [],
      },
    },
    subCards: {
      banner: {
        periodBar: {
          label: [],
          y: [],
        },
        rightBar: {
          label: [],
          x: [],
          y: [],
        },
      },
      region: {
        periodBar: {
          label: [],
          y: [],
        },
        rightBar: {
          label: [],
          x: [],
          y: [],
        },
      },
      subChannel: {
        periodBar: {
          label: [],
          y: [],
        },
        rightBar: {
          label: [],
          x: [],
          y: [],
        },
      },
    },
  };

  const validateStorePontuation: IAbsoluteData = perfectStoreData.data
    ?.averageScoreData ?? {
    mainCard: {
      leftBar: {
        label: [],
        x: [],
        y: [],
      },
      periodBar: {
        label: [],
        y: [],
      },
      rightBar: {
        label: [],
        x: [],
        y: [],
      },
    },
    subCards: {
      banner: {
        periodBar: {
          label: [],
          y: [],
        },
        rightBar: {
          label: [],
          x: [],
          y: [],
        },
      },
      region: {
        periodBar: {
          label: [],
          y: [],
        },
        rightBar: {
          label: [],
          x: [],
          y: [],
        },
      },
      subChannel: {
        periodBar: {
          label: [],
          y: [],
        },
        rightBar: {
          label: [],
          x: [],
          y: [],
        },
      },
    },
  };

  const filterPontuationKPI = (): ITypeGraphicPeriodBar => {
    const graphicPontuationKPI: ITypeGraphicPeriodBar = {
      label: [],
      x: [],
      y: [],
    };

    perfectStoreData.data?.kpiData.forEach((pontuationKPI) => {
      const period = pontuationKPI.period.split("'")[0].toUpperCase();
      if (pontuationKPI.period.toUpperCase() === period) {
        graphicPontuationKPI.label = pontuationKPI.label;
        graphicPontuationKPI.x = pontuationKPI.x;
        graphicPontuationKPI.y = pontuationKPI.y;
      }
    });

    return graphicPontuationKPI;
  };

  const renderFilter = useCallback(() => {
    const showFilters: IShowFilters = {
      ...INITIAL_FILTERS,
      // businessUnitId: true,
      // category: true,
      // subCategory: true,
      // segment: true,
      subChannel: true,
      banner: true,
      region: true,
      uf: true,
      isPromo: true,
      // retailEnviroment: true,
      // commercialStructureDirector: true,
      // commercialStructureManager: true,
      // commercialStructurCoordinator: true,
      // commercialStructureSales: true,
      // storeCNPJ: true,
      // merchandising: true,
    };

    if (config.showSideFilters.myExecution)
      DNavigation.changeFilters(
        FiltersList(SAllFilters, SFilters, showFilters, DFilters)
      );
  }, [SFilters, DNavigation]);

  useEffect(() => {
    renderFilter();
  }, [SFilters]);

  return (
    <Stack>
      <Flex overflow="auto" mb="5px !important">
        <PerfectStoreAbsolut
          selected={PSSelectedCard === IPSMainCards.PERFECT_STORE_ABSOLUTE}
          onSelected={setPSSelectedCard}
          dataMainCard={validateStoreAbsolute.mainCard}
        />
        <PerfectStorePontuation
          selected={PSSelectedCard === IPSMainCards.PERFECT_STORE_PONTUATION}
          onSelected={setPSSelectedCard}
          dataMainCard={validateStorePontuation.mainCard}
        />
        <PontuationKPIs
          // selected={PSSelectedCard === IPSMainCards.PONTUATION_KPIS}
          // onSelected={setPSSelectedCard}
          mainCard={perfectStoreData.data?.kpiData || []}
          currentPeriod={perfectStoreData.data?.period || ""}
        />
      </Flex>
      {PSSelectedCard === IPSMainCards.PERFECT_STORE_ABSOLUTE && (
        <AbsolutCard
          storeAbsolute={validateStoreAbsolute}
          period={perfectStoreData.data?.period || "--"}
          SFilters={SFilters}
          detailPerfectStore={{
            banner:
              perfectStoreData.data?.storeDetailBanner ??
              INITIAL_DETAIL_PERFECT_STORE,
            region:
              perfectStoreData.data?.storeDetailRegion ??
              INITIAL_DETAIL_PERFECT_STORE,
            subChannel:
              perfectStoreData.data?.storeDetailSubChannel ??
              INITIAL_DETAIL_PERFECT_STORE,
          }}
        />
      )}
      {PSSelectedCard === IPSMainCards.PERFECT_STORE_PONTUATION && (
        <AbsolutCard
          storeAbsolute={validateStorePontuation}
          period={perfectStoreData.data?.period || "--"}
          SFilters={SFilters}
          detailPerfectStore={{
            banner:
              perfectStoreData.data?.storeDetailBanner ??
              INITIAL_DETAIL_PERFECT_STORE,
            region:
              perfectStoreData.data?.storeDetailRegion ??
              INITIAL_DETAIL_PERFECT_STORE,
            subChannel:
              perfectStoreData.data?.storeDetailSubChannel ??
              INITIAL_DETAIL_PERFECT_STORE,
          }}
        />
      )}
      {PSSelectedCard === IPSMainCards.PONTUATION_KPIS && <PontuationKpiCard />}
      <RankingStoreByPontuation filters={SFilters} />
    </Stack>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  DFilters: bindActionCreators(AFilters, dispatch),
  DNavigation: bindActionCreators(ANavigation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPerfectStore);
