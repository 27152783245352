import XLSX from "xlsx";
import { Flex, Input, Stack, Text } from "@chakra-ui/react";
import React, { ChangeEvent } from "react";
import { ImSpinner2 } from "react-icons/im";
import { TbCloudUpload } from "react-icons/tb";
import { ModalButton } from "../../../pages/Private/MyNegotiations/ViewCalendar/ModalCalendar/styles";
import { theme } from "../../../styles/theme";

interface IOwnProps {
  onChange(list: string[]): void;
  onChangeTitleButton(): void;
  setButtonValue?(title: string): void;
  setButtonColor?(color: string): void;
  setFileName(title: string): void;
  buttonValue?: string;
  buttonColor?: string;
  fileName: string;
  hiddenButton?: boolean;
}

const ButtonExcelImport: React.FC<IOwnProps> = ({
  onChange,
  onChangeTitleButton,
  setButtonValue,
  setButtonColor,
  setFileName,
  buttonColor,
  buttonValue,
  fileName,
  hiddenButton,
}: IOwnProps) => {
  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      if (!hiddenButton && setButtonColor && setButtonValue) {
        setButtonValue("Importar");
        setButtonColor(theme.colors.button);
      }
      const infoFile = e.target.files?.item(0);
      setFileName(infoFile ? infoFile.name : "");
      const file = e.target.files[0];
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (evt) => {
          const buffer = evt.target?.result;
          const readBurref = XLSX.read(buffer, { type: "buffer" });
          const sheetName = readBurref.SheetNames[0];
          const readSheet = readBurref.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(readSheet);

          const listTextColumns: string[] = [];

          data.map((itens: any) =>
            Object.values(itens).forEach((item: any) => {
              listTextColumns.push(item);
            })
          );

          onChange(listTextColumns);

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then();
    }
  };

  return (
    <Stack as="form" mt="0px !important">
      <Text mb={0}>Selecione o Arquivo de importação</Text>
      <Flex
        border="1px solid #7a7a7a"
        borderRadius={5}
        align="center"
        mt="0px !important"
        h="27px"
      >
        <Input type="text" value={fileName} disabled border="none !important" />
        <Flex
          w="50px !important"
          justifyContent="center"
          alignItems="center"
          borderLeft="1px solid #7a7a7a"
          h="21px"
          overflow="hidden"
        >
          <Input
            id="file"
            type="file"
            accept=".xls,.xlsx"
            onChange={(e) => handleFileInput(e)}
            position="absolute"
            w="45px !important"
            opacity={0}
            h="27px"
            cursor="pointer"
          />
          <TbCloudUpload color="black" size={24} cursor="pointer" />
        </Flex>
      </Flex>
      {!hiddenButton && (
        <ModalButton
          type="button"
          onClick={onChangeTitleButton}
          bg={buttonColor}
          showSpinner={buttonValue === ""}
        >
          {buttonValue}
          <span>
            <ImSpinner2 size="18px" />
          </span>
        </ModalButton>
      )}
    </Stack>
  );
};

ButtonExcelImport.defaultProps = {
  hiddenButton: false,
  buttonColor: "",
  buttonValue: "",
  setButtonValue: () => null,
  setButtonColor: () => null,
};

export default ButtonExcelImport;
