import react from "react";
import { Flex, Stack, Text } from "@chakra-ui/react";
import { BiBrushAlt } from "react-icons/bi";

const EmptyMessage = (): JSX.Element => {
  return (
    <Stack
      mt="0 !important"
      h="100%"
      w="100%"
      bg="white"
      align="center"
      justify="center"
    >
      <BiBrushAlt size={45} color="#7A7A7A" />
      <Text mt="15px" color="#7A7A7A" fontWeight="bold" fontSize="16px">
        Não existe dados disponiveis para a sua seleção.
      </Text>
    </Stack>
  );
};

export default EmptyMessage;
