import { Flex, Stack } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { getMonthName } from "../../../Resource/functions";
import { ConfigContext } from "../../../services/Context/ConfigContext";
import { useRequestBottomTables } from "../../../services/Hooks/MyExecution/useRequestBottomTables";
import { useRequestPresenceTableEvolution } from "../../../services/Hooks/MyExecution/useRequestPresenceTableEvolution";
import { useRequestSubPresence } from "../../../services/Hooks/MyExecution/useRequestSubPresence";
import { INavContent } from "../../../store/Navigation/types";
import { IStateFilters } from "../../../store/SideFilters/types";
import Card from "../../Card";
import SeeDetailButton from "../../SeeDetailButton";
import SubTableComplements from "../../subTableComplements";
import TableComplements from "../../TableComplements";
import TableWithGraphic from "../../TableWithGraphic";
import { EToggle } from "../CustomMainCards";

interface IOwnProps {
  SFilters: IStateFilters;
  period: string;
}

const PresenceTable: React.FC<IOwnProps> = ({ SFilters, period }) => {
  const [selectedSubCardPresence, setSelectedSubCardPresence] =
    useState<string>(EToggle.PRESENCE);
  const subPresence = useRequestSubPresence(period, SFilters);
  const presenceTable = useRequestPresenceTableEvolution(
    period,
    SFilters,
    selectedSubCardPresence
  );
  const bottomTable = useRequestBottomTables(period, SFilters);

  const { config } = useContext(ConfigContext);
  const referenceDate = getMonthName(config.general.referenceDateMyExecution);

  return (
    <>
      <SubTableComplements
        isLoading={subPresence.isLoading}
        data={subPresence.data}
        period={referenceDate || "-----"}
        subCardSelected={selectedSubCardPresence}
        setSubCardSelected={setSelectedSubCardPresence}
      />
      <TableComplements
        // mainFilter={
        //   SAllFilters.data.myExecution.subviewOSA.main.categories
        // }
        // secundaryFilter={SFilters.brand.listFilters || []}
        noFilter
        titleCard="Presença Itens prioritários"
        subTitleCard="(Mensal)"
        data={presenceTable.data}
        isLoading={presenceTable.isLoading}
      />
      <Flex mt="5px">
        <Card
          title="Top 10 Produtos Ofensores"
          subTitle={`(Periodo: ${referenceDate || "-------"})`}
          mr="5px"
        >
          <Stack w="100%">
            <Flex
              justifyContent="space-between"
              px="5px"
              align="center"
              // mt="45px"
            >
              <Flex />
              {/* <SeeDetailButton
                label="Ver detalhe de KPIs"
                goPage={INavContent.ME_DETAIL_KPI}
              /> */}
            </Flex>
            <TableWithGraphic
              isLoading={bottomTable.isLoading && !bottomTable.data}
              tableData={bottomTable.data?.topProduct}
            />
          </Stack>
        </Card>
        <Card
          title="Top 10 Lojas Ofensoras"
          subTitle={`(Periodo: ${referenceDate || "-------"})`}
        >
          <Stack w="100%">
            {/* <Flex justifyContent="space-between" px="5px" align="center">
                <TemporalFilter
                  temporalFilter={selections.selectedTemporalFilter}
                  setTemporalFilter={setTemporalFilter}
                  itens={["MAT", "YTD", "U3M", "LP"]}
                />
                <ListBox
                  itens={[
                    SAllFilters.data.myExecution.subviewOSA.main.categories,
                    SFilters.brand.listFilters || [],
                  ]}
                />
              </Flex> */}
            <Flex justifyContent="space-between" px="5px" align="center">
              <Flex />
              {/* <SeeDetailButton
                label="Ver detalhe de KPIs Loja"
                goPage={INavContent.ME_DETAIL_KPI_STORE}
              /> */}
            </Flex>
            <TableWithGraphic
              isLoading={bottomTable.isLoading && !bottomTable.data}
              tableData={bottomTable.data?.topStore}
            />
          </Stack>
        </Card>
      </Flex>
    </>
  );
};

export default PresenceTable;
