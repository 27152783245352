import { action } from 'typesafe-actions';
import {
  ALogout,
  IErrorLogout,
  IRequestLogout,
  ISuccessLogout,
  actionLogout,
} from './types';

export const requestLogout = (data: actionLogout): IRequestLogout =>
  action(ALogout.REQUEST_LOGOUT, data);

export const successLogout = (): ISuccessLogout =>
  action(ALogout.LOGOUT_SUCCESS);

export const errorLogout = (): IErrorLogout => action(ALogout.LOGOUT_ERROR);
