import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import binoculars from "../../assets/subMenu/binoculars_black.png";
import {
  ENavSubMenu,
  INavContent,
  INavMainMenu,
  stateType,
} from "../../store/Navigation/types";
import { SubMenuOnePage } from "../../pages/Private/OnePage/subMenu";
import { SubMenuMyExecutions } from "../../pages/Private/MyExecution/subMenu";
import { SubMenuMyNegotiations } from "../../pages/Private/MyNegotiations/subMenu";
import { SubMenuPromo } from "../../pages/Private/MyDay/subMenu";
import { GlobalProps } from "../../store";

import * as ANavigation from "../../store/Navigation/actions";

interface IStateProps {
  IMainMenu: stateType;
}

interface IDispatchProps {
  DNavigation: {
    changeSubMenu(index: ENavSubMenu): void;
    changeDash(indexContent: INavContent): void;
  };
}

interface IOwnProps {
  reference: React.RefObject<HTMLDivElement>;
}

// interface IOwnProps {}

type props = IStateProps & IDispatchProps & IOwnProps;

const SubMenu: React.FC<props> = (props: props) => {
  const { menuItem, indexSubMenu } = props.IMainMenu;

  const scrollTop = (SubMenuPage: ENavSubMenu) => {
    props.reference.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    props.DNavigation.changeSubMenu(SubMenuPage);
  };

  const loadSubmenu = () => {
    switch (menuItem.index) {
      case INavMainMenu.MY_ONE_PAGE:
        return (
          <SubMenuOnePage
            indexSubMenu={indexSubMenu}
            changeSubMenu={scrollTop}
          />
        );
      case INavMainMenu.MY_EXECUTION:
        return (
          <SubMenuMyExecutions
            indexSubMenu={indexSubMenu}
            changeSubMenu={scrollTop}
          />
        );
      case INavMainMenu.MY_NEGOCIATIONS:
        return (
          <SubMenuMyNegotiations
            indexSubMenu={indexSubMenu}
            changeSubMenu={scrollTop}
          />
        );
      case INavMainMenu.PROMO:
        return (
          <SubMenuPromo indexSubMenu={indexSubMenu} changeSubMenu={scrollTop} />
        );
      default:
        return null;
    }
  };

  return (
    <Box bg="subHeaderColor" h="80px">
      <Flex align="center" h="100%">
        <Flex
          h="57px"
          outline="none"
          align="center"
          justify="center"
          borderRight="2px solid #e2e2e2"
          w="156px"
        >
          <Image src={binoculars} alt="icon Binoculars" w="65px" />
        </Flex>
        {loadSubmenu()}
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  IMainMenu: state.navigation,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    DNavigation: bindActionCreators(ANavigation, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);
