import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";
import {
  ITypeGraphicHorizontalBar,
  ITypeGraphicPeriod,
  ITypeGraphicPeriodBar,
  ITypeWaterfall,
} from "../OnePage/onePageTypes";

export interface IMainCardAbsolutePerfectStore {
  leftBar: ITypeGraphicPeriodBar;
  periodBar: ITypeGraphicPeriod;
  rightBar: ITypeGraphicPeriodBar;
}

export interface IAbsoluteData {
  mainCard: IMainCardAbsolutePerfectStore;
  subCards: {
    subChannel: {
      periodBar: ITypeGraphicPeriod;
      rightBar: ITypeGraphicPeriodBar;
    };
    region: {
      periodBar: ITypeGraphicPeriod;
      rightBar: ITypeGraphicPeriodBar;
    };
    banner: {
      periodBar: ITypeGraphicPeriod;
      rightBar: ITypeGraphicPeriodBar;
    };
  };
}

export interface IKPIData {
  label: string[];
  x: string[];
  y: number[];
  period: string;
}

interface ITableCard {
  data: string[][];
  growth: ITypeGraphicHorizontalBar;
  header: string[];
}

interface IBottomTable {
  message: string;
  offenders: ITableCard;
  period: string;
  status: string;
  top: ITableCard;
}

const requestBottomTableStoreAbsolute = async (
  filters: IStateFilters
): Promise<IBottomTable> => {
  const response = await api.get<IBottomTable>("perfect_store_top/", {
    params: {
      businessUnit: filters.promoFilter.businessUnitId.selectedFilterId || null,
      category: filters.promoFilter.category.selectedFilterId || null,
      subCategory: filters.promoFilter.brand.selectedFilterId || null,
      segment: filters.promoFilter.segments.selectedFilterId || null,
      subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
      banner: filters.promoFilter.banner.selectedFilterId || null,
      retailEnviroment:
        filters.promoFilter.retailEnviroment.selectedFilterId || null,
      region: filters.promoFilter.region.selectedFilterId || null,
      uf: filters.promoFilter.uf.selectedFilterId || null,
      director: filters.promoFilter.commercialDirector.selectedFilterId || null,
      manager: filters.promoFilter.commercialManager.selectedFilterId || null,
      customer_sales_rep:
        filters.promoFilter.commercialSaler.selectedFilterId || null,
      cnpj: filters.promoFilter.storeCNPJ || null,
    },
  });

  return response.data;
};

export const useBottomTableStoreAbsolute = (
  filters: IStateFilters
): UseQueryResult<IBottomTable, unknown> => {
  return useQuery(
    ["bottomTableStoreAbsolute"],
    () => requestBottomTableStoreAbsolute(filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
